import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Typography, Modal, Button, Form, Input, Select, Popconfirm, DatePicker, Table } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, doc, updateDoc, deleteDoc, getDocs, query, where, addDoc } from 'firebase/firestore';
import { db } from '../../environment';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

const { Title, Text } = Typography;
const { Option } = Select;
const role = localStorage.getItem('role');

export default function ApprovedCardsList() {
  const approvedCardsRef = collection(db, 'onaylananUretimKartlari');
  const [approvedCards] = useCollectionData(approvedCardsRef, { idField: 'id' });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [materialOptions, setMaterialOptions] = useState([]);

  useEffect(() => {
    const fetchMaterialOptions = async () => {
      const materialsRef = collection(db, 'materials');
      const materialsSnapshot = await getDocs(materialsRef);
      const materials = materialsSnapshot.docs.map(doc => doc.data().MaterialName);
      setMaterialOptions(materials);
    };
    fetchMaterialOptions();
  }, []);

  const showModal = (card) => {
    setSelectedCard(card);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCard(null);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleFormChange = (changedFields, allFields) => {
    const rawMaterials = allFields.find(field => field.name[0] === 'rawMaterials')?.value || selectedCard.rawMaterials || [];
    const batchTotalKg = rawMaterials.reduce((total, material) => total + (parseFloat(material?.amount) || 0), 0);
    const batchQuantity = (selectedCard.batchRows || 0) * (selectedCard.batchColumns || 0);
    const totalProductionAmount = batchTotalKg * batchQuantity;
    const totalProductionAmountPcs = totalProductionAmount / (selectedCard.packaging_weight || 1);

    setSelectedCard(prevCard => ({
      ...prevCard,
      total_production_amount: totalProductionAmount,
      total_production_amount_pcs: totalProductionAmountPcs,
      batch_total_kg: batchTotalKg,
      rawMaterials,
    }));
  };

  const handleSave = async (values) => {
    try {
      const q = query(collection(db, 'onaylananUretimKartlari'), where('card_number', '==', selectedCard.card_number));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error('No matching document found.');
      }

      const cardDocRef = querySnapshot.docs[0].ref;

      const sanitizeValues = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => {
            if (key === 'rawMaterials' && Array.isArray(value)) {
              return [key, value.map(material => ({
                ...material,
                description: material.description ?? null,
              }))];
            }
            return [key, value === undefined ? null : value];
          })
        );
      };

      const updatedValues = sanitizeValues({
        ...selectedCard,
        ...values,
        start_date: values.start_date ? dayjs(values.start_date).format('DD-MM-YYYY') : selectedCard.start_date,
        end_date: values.end_date ? dayjs(values.end_date).format('DD-MM-YYYY') : selectedCard.end_date,
        rawMaterials: values.rawMaterials || selectedCard.rawMaterials,
      });

      await updateDoc(cardDocRef, updatedValues);

      setIsEditing(false);
      setIsModalVisible(false);
      Swal.fire({
        title: 'Başarılı!',
        text: 'Üretim kartı başarıyla güncellendi.',
        icon: 'success',
        confirmButtonText: 'Tamam',
      });
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      Swal.fire({
        title: 'Hata!',
        text: `Güncelleme sırasında bir hata oluştu: ${error.message}`,
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  };

  const handleDelete = async () => {
    if (selectedCard && selectedCard.id) {
      try {
        await deleteDoc(doc(db, 'onaylananUretimKartlari', selectedCard.id));
        setIsModalVisible(false);
        Swal.fire({
          title: 'Silindi!',
          text: 'Üretim kartı başarıyla silindi.',
          icon: 'success',
          confirmButtonText: 'Tamam',
        });
      } catch (error) {
        console.error('Silme hatası:', error);
        Swal.fire({
          title: 'Hata!',
          text: 'Silme sırasında bir hata oluştu.',
          icon: 'error',
          confirmButtonText: 'Tamam',
        });
      }
    }
  };

  const sendBackToProduction = async () => {
    try {
      if (selectedCard && selectedCard.card_number) {
        const q = query(collection(db, 'onaylananUretimKartlari'), where('card_number', '==', selectedCard.card_number));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          throw new Error('No matching document found.');
        }

        const docRef = querySnapshot.docs[0].ref;

        await addDoc(collection(db, 'productionData'), {
          ...selectedCard,
          sent_back_date: dayjs().format('DD-MM-YYYY HH:mm:ss'), // Add sent back date
        });

        await deleteDoc(docRef);

        Swal.fire({
          title: 'Gönderildi!',
          text: 'Kart başarıyla üretim verilerine geri gönderildi.',
          icon: 'success',
          confirmButtonText: 'Tamam',
        });

        setIsModalVisible(false);
      }
    } catch (error) {
      console.error('Gönderme hatası:', error);
      Swal.fire({
        title: 'Hata!',
        text: `Kartı geri gönderirken bir hata oluştu: ${error.message}`,
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  };

  return (
    <div className="page-wrapper">
      <Title level={2}>Onaylanan Üretim Kartları</Title>
      <Row gutter={[16, 16]}>
        {approvedCards && approvedCards.map((card) => (
          <Col xs={24} sm={12} md={8} lg={6} key={card.id}>
            <Card
              title={card.card_number}
              bordered={false}
              style={{
                backgroundColor: '#E0F7FA',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
              }}
              hoverable
              onClick={() => showModal(card)}
            >
              <p><Text strong>Ürün Adı:</Text> {card.product_name}</p>
              <p><Text strong>Onay Tarihi:</Text> {card.approval_date}</p>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Onaylanan Kart Detayları"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Kapat
          </Button>,
          isEditing ? (
            <Button key="save" type="primary" form="editForm" htmlType="submit">
              Kaydet
            </Button>
          ) : (
            <>
              {role === "Sistem Admini" && (
                <div>
                  <Button key="edit" type="primary" onClick={handleEdit}>
                    Düzenle
                  </Button>
                  <Popconfirm
                    title="Bu üretim kartını silmek istediğinize emin misiniz?"
                    onConfirm={handleDelete}
                    okText="Evet"
                    cancelText="Hayır"
                  >
                    <Button key="delete" danger>
                      Sil
                    </Button>
                  </Popconfirm>
                  <Button key="sendBack" type="default" onClick={sendBackToProduction}>
                    Onay Bekleyenlere Geri Gönder
                  </Button>
                </div>
              )}
            </>
          ),
        ]}
      >
        {selectedCard && !isEditing && (
          <div>
            <p><Text strong>Kart Numarası:</Text> {selectedCard.card_number}</p>
            <p><Text strong>Ürün Adı:</Text> {selectedCard.product_name}</p>
            <p><Text strong>Paketleme Ağırlığı:</Text> {selectedCard.packaging_weight} kg</p>
            <p><Text strong>Üretim Personeli:</Text> {selectedCard.production_staff_name}</p>
            <p><Text strong>Onay Tarihi:</Text> {selectedCard.approval_date}</p>
            <p><Text strong>Ek Bilgi:</Text> {selectedCard.additional_info}</p>
            <p><Text strong>Hammadde Listesi:</Text></p>
            <Table
              dataSource={selectedCard.rawMaterials}
              columns={[
                { title: 'Adı', dataIndex: 'materialName', key: 'materialName' },
                { title: 'Cinsi', dataIndex: 'type', key: 'type' },
                { title: 'Miktar (kg)', dataIndex: 'amount', key: 'amount' },
                { title: 'Açıklama', dataIndex: 'description', key: 'description' },
              ]}
              pagination={false}
              rowKey="materialName"
              bordered
            />
          </div>
        )}

        {selectedCard && isEditing && (
          <Form
            id="editForm"
            initialValues={{
              ...selectedCard,
              start_date: selectedCard.start_date ? dayjs(selectedCard.start_date, 'DD-MM-YYYY') : null,
              end_date: selectedCard.end_date ? dayjs(selectedCard.end_date, 'DD-MM-YYYY') : null,
              batchRows: selectedCard.batchRows || 0,
              batchColumns: selectedCard.batchColumns || 0,
              selectedBatch: selectedCard.selectedBatch || {},
            }}
            onFinish={handleSave}
            onFieldsChange={handleFormChange}
            layout="vertical"
            style={{ maxHeight: '70vh', overflowY: 'auto' }}
          >
            <Form.Item name="card_number" label="Kart Numarası">
              <Input />
            </Form.Item>
            <Form.Item name="product_name" label="Ürün Adı">
              <Input />
            </Form.Item>
            <Form.Item name="packaging_weight" label="Paketleme Ağırlığı">
              <Input type="number" addonAfter="kg" />
            </Form.Item>
            <Form.Item name="batchRows" label="Şarj Satır Sayısı">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="batchColumns" label="Şarj Sütun Sayısı">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="production_staff_name" label="Üretim Personeli">
              <Input />
            </Form.Item>
            <Form.Item name="start_date" label="Başlangıç Tarihi">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item name="end_date" label="Bitiş Tarihi">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item name="production_status" label="Üretim Durumu">
              <Select>
                <Option value="Üretiliyor">Üretiliyor</Option>
                <Option value="Üretildi">Üretildi</Option>
                <Option value="Hazırlık Aşamasında">Hazırlık Aşamasında</Option>
              </Select>
            </Form.Item>

            <Form.List name="rawMaterials">
              {(fields, { add, remove }) => (
                <>
                  <Table
                    dataSource={fields.map((field, index) => ({
                      key: index,
                      ...field,
                    }))}
                    columns={[
                      {
                        title: 'Malzeme Adı',
                        dataIndex: ['materialName'],
                        key: 'materialName',
                        render: (_, field) => (
                          <Form.Item
                            name={[field.name, 'materialName']}
                            fieldKey={[field.fieldKey, 'materialName']}
                            rules={[{ required: true, message: 'Malzeme Adı gerekli!' }]}
                          >
                            <Select placeholder="Malzeme Seçin">
                              {materialOptions.map((material, index) => (
                                <Option key={index} value={material}>
                                  {material}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'Tip',
                        dataIndex: ['type'],
                        key: 'type',
                        render: (_, field) => (
                          <Form.Item
                            name={[field.name, 'type']}
                            fieldKey={[field.fieldKey, 'type']}
                            rules={[{ required: true, message: 'Tip gerekli!' }]}
                          >
                            <Input placeholder="Tip Girin" />
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'Miktar',
                        dataIndex: ['amount'],
                        key: 'amount',
                        render: (_, field) => (
                          <Form.Item
                            name={[field.name, 'amount']}
                            fieldKey={[field.fieldKey, 'amount']}
                            rules={[{ required: true, message: 'Miktar gerekli!' }]}
                          >
                            <Input type="number" addonAfter="kg" placeholder="Miktar Girin" />
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'Açıklama',
                        dataIndex: ['description'],
                        key: 'description',
                        render: (_, field) => (
                          <Form.Item
                            name={[field.name, 'description']}
                            fieldKey={[field.fieldKey, 'description']}
                          >
                            <Input.TextArea placeholder="Açıklama Girin" />
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'İşlem',
                        key: 'action',
                        render: (_, field) => (
                          <Button onClick={() => remove(field.name)} danger>Sil</Button>
                        ),
                      },
                    ]}
                    pagination={false}
                    bordered
                    size="small"
                  />
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{ marginTop: '10px', fontWeight: 'bold' }}
                  >
                    + Hammadde Ekle
                  </Button>
                </>
              )}
            </Form.List>

            <Form.Item name="additional_info" label="Ek Bilgi" style={{ marginTop: '20px' }}>
              <Input.TextArea />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}
