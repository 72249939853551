// src/App.js

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMeeting } from '@videosdk.live/react-sdk';
import ParticipantView from './participantview';
import MessageComponent from './messagecomponent';
import { Button, Layout, Spin } from 'antd';
import { VideoCameraOutlined, LogoutOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { db } from '../../environment';
import { doc, getDoc } from 'firebase/firestore';

const { Content, Footer } = Layout;

const MySwal = withReactContent(Swal);

const App = () => {
  const [joined, setJoined] = useState(null);
  const [username,setUsername]=useState("");
  const meetingId = 'STATIC_MEETING_ID'; // Statik bir toplantı ID'si belirledik
  const { join, leave, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined('JOINED');
    },
    onMeetingLeft: () => {
      setJoined(null);
    },
    meetingId: meetingId,
  });

  const joinMeeting = () => {
    setJoined('JOINING');
    join();
  };
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const userDocRef = doc(db, 'users', userId);
      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setUsername(docSnap.data().username || '');
        }
      }).catch((error) => {
        console.error("Error fetching user data: ", error);
      });
    }
  
    return () => {
      
    }
  }, [])
  
  const confirmLeaveMeeting = () => {
    MySwal.fire({
      title: 'Toplantıdan ayrılmak istiyor musunuz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, ayrıl',
      cancelButtonText: 'Hayır, kal',
    }).then((result) => {
      if (result.isConfirmed) {
        leave();
      }
    });
  };

  return (
    <div className='page-wrapper'>
      <Layout className="min-vh-100 d-flex flex-column">
        {joined === 'JOINED' && (
          <Button type="primary" icon={<LogoutOutlined />} onClick={confirmLeaveMeeting} style={{ margin: '10px' }}>
            Toplantıdan Ayrıl
          </Button>
        )}
        <Content className="flex-grow-1 d-flex" style={{ padding: '50px' }}>
          {joined && joined === 'JOINED' ? (
            <>
              <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
                {Array.from(participants.keys()).map(participantId => (
                  <ParticipantView key={participantId} participantId={participantId} />
                ))}
              </div>
              <div style={{ width: '300px', height: '50vh', borderLeft: '1px solid #ddd', overflowY: 'auto' }}>
                <MessageComponent username={username} userId={localStorage.getItem('userId')} />
              </div>
            </>
          ) : joined && joined === 'JOINING' ? (
            <Spin tip="Bağlantı kuruluyor..." size="large" />
          ) : (
            <Button type="primary" icon={<VideoCameraOutlined />} size="large" onClick={joinMeeting}>
              Toplantıya katıl
            </Button>
          )}
        </Content>
        <Footer className="text-center">© 2024-HepaTech</Footer>
      </Layout>
    </div>
  );
};

export default App;
