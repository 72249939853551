import React from 'react';
import TreeView from './kategorileriyonet';
import GrupView from './grupyonet';
import TypeYonet from './typeyonet';

function KategoriMain() {
  return (
    <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
        <GrupView />
      </div>
      <div style={{ flex: 1 }}>
        <TreeView />
      </div>
      <div style={{ flex: 1 }}>
        <TypeYonet />
      </div>
    </div>
  );
}

export default KategoriMain;
