import React, { useState, useEffect, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { collection, addDoc, query, orderBy, limit, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { Form, Input, Button, Row, Col, DatePicker, Select, Modal, Collapse, Typography, Card, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { db } from '../../environment';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import PrintComponent from './utils/printcomponent';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { Table, Space } from 'antd';

const API_KEY = '025f1d2d3e0b4217bfed93c3';
const BASE_URL = 'https://v6.exchangerate-api.com/v6';
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
let roles = localStorage.getItem('role');
const OrderForm = () => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      urunler: [{ urunAdi: '', urunKodu: '', miktar: '', birim: '', birimFiyat: '', paraBirimi: '', iskonto: '', kdv: '', tutar: '' }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "urunler"
  });
  // Added state for payment info



  const [isProductEdited, setIsProductEdited] = useState(false); // Ürün düzenleme durumunu takip eden state
  const [manualEntry, setManualEntry] = useState(false); // Elle giriş durumu için state
  const componentRef = useRef();
  const [allCaris, setAllCaris] = useState([]);
  const [, setSelectedCari] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(false);
  const [allStockData, setAllStockData] = useState([]);
  const [language, setLanguage] = useState('tr');
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);

  const watchBelgeNo = watch('belgeNo', '');
  const watchUrunler = watch('urunler', []);

  const getTitle = () => {
    if (watchBelgeNo.startsWith('STS')) {
      return language === 'tr' ? 'Proforma Fatura' : 'Proforma Invoice';
    } else if (watchBelgeNo.startsWith('ALS')) {
      return language === 'tr' ? 'Alış Sipariş Formu' : 'Purchase Order Form';
    } else if (watchBelgeNo.startsWith('PRF')) {
      return language === 'tr' ? 'Proforma Fatura' : 'Proforma Invoice';
    } else {
      return '';
    }
  };
  const [allInvoices, setAllInvoices] = useState([]); // State for storing all invoices

  const fetchAllInvoices = async () => {
    try {
      const invoiceSnapshot = await getDocs(collection(db, 'proformaFaturalar'));
      const invoicesList = invoiceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllInvoices(invoicesList);
    } catch (error) {
      console.error("Proforma faturaları alınamadı: ", error);
    }
  };

  useEffect(() => {
    fetchAllInvoices(); // Fetch all invoices when component loads
  }, []);

  const fetchAllCaris = async () => {
    try {
      const carisSnapshot = await getDocs(collection(db, 'allCaris'));
      const carisList = carisSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllCaris(carisList);
    } catch (error) {
      console.error("Cari verisi alınamadı: ", error);
    }
  };

  const handleUrunAdiChange = async (urunAdi, index) => {
    const selectedStock = allStockData.find(stock => stock.StockName === urunAdi);
    setIsProductEdited(true);
    if (selectedStock) {
      try {
        setValue(`urunler[${index}].urunKodu`, selectedStock.FullStockCode || '');
        setValue(`urunler[${index}].urunKategorisi`, selectedStock.StockCategory || '');
        setValue(`urunler[${index}].birim`, selectedStock.Unit || '');
        setValue(`urunler[${index}].birimFiyat`, selectedStock.StandardCost || '');
        setValue(`urunler[${index}].paraBirimi`, selectedStock.Currency || '');
        setValue(`urunler[${index}].UnitAmount`, selectedStock.UnitAmount || '');
        setValue(`urunler[${index}].MinimumStock`, selectedStock.MinimumStock || '');
        setValue(`urunler[${index}].SafetyInfo`, selectedStock.SafetyInfo || []);
        setValue(`urunler[${index}].ShelfLifeStart`, selectedStock.ShelfLifeStart || '');
        setValue(`urunler[${index}].SafetyStock`, selectedStock.SafetyStock || '');
        setValue(`urunler[${index}].ImportExportInfo`, selectedStock.ImportExportInfo || '');
        setValue(`urunler[${index}].StockNo`, selectedStock.StockNo || '');
        setValue(`urunler[${index}].MaximumStock`, selectedStock.MaximumStock || '');
        setValue(`urunler[${index}].ShelfLifeEnd`, selectedStock.ShelfLifeEnd || '');
        setValue(`urunler[${index}].BatchNumber`, selectedStock.BatchNumber || '');
        setValue(`urunler[${index}].LeadTime`, selectedStock.LeadTime || '');
        setValue(`urunler[${index}].StorageConditions`, selectedStock.StorageConditions || '');
        setValue(`urunler[${index}].GroupName`, selectedStock.GroupName || '');
        setValue(`urunler[${index}].Certificates`, selectedStock.Certificates || []);
        setValue(`urunler[${index}].StandardCost`, selectedStock.StandardCost || '');
        setValue(`urunler[${index}].Types`, selectedStock.Types || '');
        setValue(`urunler[${index}].SupplierInfo`, selectedStock.SupplierInfo || '');
        setValue(`urunler[${index}].SubgroupCode`, selectedStock.SubgroupCode || '');
        setValue(`urunler[${index}].auxiliaryUnits`, selectedStock.auxiliaryUnits || []);
      } catch (error) {
        console.error("Ürün bilgisi alınamadı: ", error);
      }
    }
  };

  const fetchAllStockData = async () => {
    setLoading(true);
    try {
      const stockSnapshot = await getDocs(collection(db, 'stocks'));
      const stockList = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Ensure that FullStockCode exists before using startsWith
      const filteredStockList = stockList.filter(stock => stock.FullStockCode && stock.FullStockCode.startsWith('152'));
      
      setAllStockData(filteredStockList);
    } catch (error) {
      console.error("Stok verisi alınamadı: ", error);
    }
    setLoading(false);
  };
  

  const fetchExchangeRates = async () => {
    setLoading(true);
    try {
      const responseUSD = await axios.get(`${BASE_URL}/${API_KEY}/latest/USD`);
      const responseEUR = await axios.get(`${BASE_URL}/${API_KEY}/latest/EUR`);

      const rates = {
        USD: responseUSD.data.conversion_rates.TRY,
        EUR: responseEUR.data.conversion_rates.TRY,
        TRY: 1
      };
      setExchangeRates(rates);
    } catch (error) {
      console.error("Döviz kuru alınamadı: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllCaris();
    fetchAllStockData();
    fetchExchangeRates();
  }, []);

  const translateValueToEnglish = (value) => {
    const translations = {
      'Deniz Yolu': 'Sea',
      'Kara Yolu': 'Land',
      'Fabrikadan Depo Teslimatı': 'Factory to Warehouse Delivery',
      'Depo Teslimatı': 'Warehouse Delivery'
    };
    return translations[value] || value;
  };

  const translateValueToTurkish = (value) => {
    const translations = {
      'Sea': 'Deniz Yolu',
      'Land': 'Kara Yolu',
      'Factory to Warehouse Delivery': 'Fabrikadan Depo Teslimatı',
      'Warehouse Delivery': 'Depo Teslimatı'
    };
    return translations[value] || value;
  };

  const handleCariChange = async (cariAdi) => {
    const selectedCari = allCaris.find(cari => cari.KartAdi === cariAdi);

    const handleNullValue = (value) => {
      return value == null ? "" : value;
    };

    if (selectedCari) {
      setValue('cariKodu', handleNullValue(selectedCari.KartKodu));
      setValue('cariAdi', handleNullValue(selectedCari.KartAdi));
      setValue('vergiDairesi', handleNullValue(selectedCari.VergiDairesi));
      setValue('vergiNumarasi', handleNullValue(selectedCari.VergiNo));
      setValue('adres', handleNullValue(selectedCari.Adres));
      setValue('email', handleNullValue(selectedCari.EPosta));
      setValue('muhHesapKodu', handleNullValue(selectedCari.MuhHesapKodu));
      setValue('muhHesapAdi', handleNullValue(selectedCari.MuhHesapAdi));
      setSelectedCari(selectedCari);
    }
  };

  useEffect(() => {
    calculatePrices();
  }, [watchUrunler]);

  const calculatePrices = () => {
    setLoading(true);
    setIsProductEdited(false);

    setTimeout(() => {
      watchUrunler.forEach((urun, index) => {
        const birimFiyat = parseFloat(urun.birimFiyat) || 0;
        const miktar = parseFloat(urun.miktar) || 0;
        const kdv = parseFloat(urun.kdv) || 0;
        const iskonto = parseFloat(urun.iskonto) || 0;
        const paraBirimi = urun.paraBirimi;
        const rate = exchangeRates[paraBirimi];

        if (rate) {
          const toplamFiyat = birimFiyat * miktar;
          const iskontoFiyat = iskonto ? toplamFiyat * (1 - iskonto / 100) : toplamFiyat;
          const kdvliFiyat = kdv ? iskontoFiyat * (1 + kdv / 100) : iskontoFiyat;

          let tryTutar, usdTutar, eurTutar;

          if (paraBirimi === 'TRY') {
            tryTutar = toplamFiyat;
            usdTutar = tryTutar / exchangeRates.USD;
            eurTutar = tryTutar / exchangeRates.EUR;
          } else if (paraBirimi === 'USD') {
            usdTutar = toplamFiyat;
            tryTutar = usdTutar * exchangeRates.USD;
            eurTutar = tryTutar / exchangeRates.EUR;
          } else if (paraBirimi === 'EUR') {
            eurTutar = toplamFiyat;
            tryTutar = eurTutar * exchangeRates.EUR;
            usdTutar = tryTutar / exchangeRates.USD;
          }

          setValue(`urunler[${index}].toplamFiyat`, toplamFiyat.toFixed(2));
          setValue(`urunler[${index}].iskontoFiyat`, iskontoFiyat.toFixed(2));
          setValue(`urunler[${index}].kdvliFiyat`, kdvliFiyat.toFixed(2));
          setValue(`urunler[${index}].tryTutar`, tryTutar.toFixed(2));
          setValue(`urunler[${index}].usdTutar`, usdTutar.toFixed(2));
          setValue(`urunler[${index}].eurTutar`, eurTutar.toFixed(2));
          setValue(`urunler[${index}].tutar`, kdvliFiyat.toFixed(2));
        } else {
          console.log(`Döviz kuru bulunamadı: ${paraBirimi}`);
        }
      });
      setLoading(false);
    }, 2000);
  };

  const generateNextBelgeNo = async () => {
    const q = query(collection(db, 'proformaFaturalar'), orderBy('belgeNo', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return 'BRF-00001'; // Start with BRF
    } else {
      const lastBelgeNo = querySnapshot.docs[0].data().belgeNo;
      const lastNumber = parseInt(lastBelgeNo.split('-')[1]);
      const nextNumber = lastNumber + 1;
      return `BRF-${nextNumber.toString().padStart(5, '0')}`; // Increment BRF by 1
    }
  };


  useEffect(() => {
    const setInitialBelgeNo = async () => {
      const nextBelgeNo = await generateNextBelgeNo();
      setValue('belgeNo', nextBelgeNo);
    };

    setInitialBelgeNo();
  }, []);

  const removeUndefinedValues = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
          newObj[key] = removeUndefinedValues(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    });
    return newObj;
  };
  const handleEdit = (invoice) => {
    console.log(roles)
    // Set customer information
    setValue('belgeNo', invoice.belgeNo);
    setValue('cariAdi', invoice.cariAdi);
    setValue('cariKodu', invoice.cariKodu);
    setValue('siparisTarihi', invoice.siparisTarihi ? dayjs(invoice.siparisTarihi) : null);
    setValue('uretimTarihi', invoice.uretimTarihi ? dayjs(invoice.uretimTarihi) : null);
    setValue('sonKullanmaTarihi', invoice.sonKullanmaTarihi ? dayjs(invoice.sonKullanmaTarihi) : null);
    setValue('sevkTarihi', invoice.sevkTarihi ? dayjs(invoice.sevkTarihi) : null);
    setValue('faturaTarihi', invoice.faturaTarihi ? dayjs(invoice.faturaTarihi) : null);
    setValue('belgeTarihi', invoice.belgeTarihi ? dayjs(invoice.belgeTarihi) : null);

    // Set customer contact information
    setValue('vergiDairesi', invoice.vergiDairesi);
    setValue('vergiNumarasi', invoice.vergiNumarasi);
    setValue('adres', invoice.adres);
    setValue('email', invoice.email);
    setValue('telefon', invoice.telefon);
    setValue('ilgiliKisiAdi', invoice.ilgiliKisiAdi);
    setValue('ilgiliKisiTelefon', invoice.ilgiliKisiTelefon);
    setValue('ilgiliKisiEmail', invoice.ilgiliKisiEmail);
    fetchAllInvoices(); // Refresh the list after the edit

    // Set product details (assuming `urunler` is an array of products)
    setValue('urunler', invoice.urunler.map(urun => ({
      urunAdi: urun.urunAdi || '',
      urunKodu: urun.urunKodu || '',
      urunKategorisi: urun.urunKategorisi || '',
      miktar: urun.miktar || '',
      birim: urun.birim || '',
      birimFiyat: urun.birimFiyat || '',
      paraBirimi: urun.paraBirimi || '',
      iskonto: urun.iskonto || '',
      kdv: urun.kdv || '',
      tutar: urun.tutar || '',
      UnitAmount: urun.UnitAmount || '',
      toplamFiyat: urun.toplamFiyat || '',
      iskontoFiyat: urun.iskontoFiyat || '',
      kdvliFiyat: urun.kdvliFiyat || '',
      tryTutar: urun.tryTutar || '',
      usdTutar: urun.usdTutar || '',
      eurTutar: urun.eurTutar || '',
      MinimumStock: urun.MinimumStock || '',
      SafetyInfo: urun.SafetyInfo || [],
      FullStockCode: urun.FullStockCode || '',
      ShelfLifeStart: urun.ShelfLifeStart || '',
      SafetyStock: urun.SafetyStock || '',
      ImportExportInfo: urun.ImportExportInfo || '',
      StockNo: urun.StockNo || '',
      MaximumStock: urun.MaximumStock || '',
      ShelfLifeEnd: urun.ShelfLifeEnd || '',
      BatchNumber: urun.BatchNumber || '',
      LeadTime: urun.LeadTime || '',
      StorageConditions: urun.StorageConditions || '',
      GroupName: urun.GroupName || '',
      Certificates: urun.Certificates || [],
      StandardCost: urun.StandardCost || '',
      Types: urun.Types || '',
      SupplierInfo: urun.SupplierInfo || '',
      SubgroupCode: urun.SubgroupCode || '',
      auxiliaryUnits: urun.auxiliaryUnits || []
    })));

    // Set payment and delivery info
    setValue('odemeSekli', invoice.odemeSekli);
    setValue('faturaTipi', invoice.faturaTipi);
    setValue('faturaAdresi', invoice.faturaAdresi);
    setValue('faturaAciklamalari', invoice.faturaAciklamalari);
    setValue('teslimatAdresi', invoice.teslimatAdresi);
    setValue('sevkYontemi', invoice.sevkYontemi);
    setValue('istenenSevkAdresi', invoice.istenenSevkAdresi);
    setValue('nakliyeFirmasi', invoice.nakliyeFirmasi);
    setValue('lojistikUcreti', invoice.lojistikUcreti);
    setValue('teslimatNotlari', invoice.teslimatNotlari);
    setValue('ozelTalimatlar', invoice.ozelTalimatlar);
    setValue('siparisNotlari', invoice.siparisNotlari);

    // Set sales representative information
    setValue('formuHazirlayanAdi', invoice.formuHazirlayanAdi);
    setValue('formuHazirlayanTelefon', invoice.formuHazirlayanTelefon);
    setValue('formuHazirlayanEmail', invoice.formuHazirlayanEmail);

    setSelectedInvoiceId(invoice.id); // Store the ID of the invoice being edited
  };

  const onSubmit = async (data) => {
    if (isProductEdited) {
      Swal.fire({
        title: language === 'tr' ? 'Hata' : 'Error',
        text: language === 'tr' ? 'Lütfen fiyatları hesaplayın.' : 'Please calculate the prices.',
        icon: 'error',
        confirmButtonText: language === 'tr' ? 'Tamam' : 'OK'
      });
      return;
    }

    try {
      Modal.confirm({
        title: language === 'tr' ? 'Kaydı eklemek istediğinize emin misiniz?' : 'Are you sure you want to add the record?',
        okText: language === 'tr' ? 'Evet' : 'Yes',
        cancelText: language === 'tr' ? 'Hayır' : 'No',
        onOk: async () => {
          setLoading(true);

          // No need to convert dates, store them directly in the data as Date objects or strings
          data.siparisTarihi = data.siparisTarihi ? data.siparisTarihi.toString() : null;
          data.uretimTarihi = data.uretimTarihi ? data.uretimTarihi.toString() : null;
          data.sonKullanmaTarihi = data.sonKullanmaTarihi ? data.sonKullanmaTarihi.toString() : null;
          data.sevkTarihi = data.sevkTarihi ? data.sevkTarihi.toString() : null;
          data.faturaTarihi = data.faturaTarihi ? data.faturaTarihi.toString() : null;
          data.belgeTarihi = data.belgeTarihi ? data.belgeTarihi.toString() : null;

          // Clean up undefined values in the data
          const cleanedData = removeUndefinedValues(data);
          cleanedData.urunler = cleanedData.urunler.map(item => removeUndefinedValues(item));
          cleanedData.language = language;

          if (selectedInvoiceId) {
            // Update existing invoice without Timestamp
            await updateDoc(doc(db, 'proformaFaturalar', selectedInvoiceId), {
              ...cleanedData,
              belgeNo: cleanedData.belgeNo.startsWith('BRF') ? cleanedData.belgeNo : `BRF-${cleanedData.belgeNo}`,
            });
            Swal.fire({
              title: language === 'tr' ? 'Güncelleme Başarılı' : 'Update Successful',
              text: language === 'tr' ? 'Kayıt başarıyla güncellendi!' : 'The record has been updated successfully!',
              icon: 'success',
              confirmButtonText: language === 'tr' ? 'Tamam' : 'OK'
            });
          } else {
            // Generate a new `belgeNo`
            const nextBelgeNo = await generateNextBelgeNo();
            await addDoc(collection(db, 'proformaFaturalar'), {
              ...cleanedData,
              belgeNo: nextBelgeNo,
            });
            Swal.fire({
              title: language === 'tr' ? 'Başarılı' : 'Success',
              text: language === 'tr' ? 'Kayıt başarıyla eklendi!' : 'Record added successfully!',
              icon: 'success',
              confirmButtonText: language === 'tr' ? 'Tamam' : 'OK'
            });
          }
          await fetchAllInvoices(); // Refresh the list after adding or updating the order

          reset();
          const newBelgeNo = await generateNextBelgeNo();
          setValue('belgeNo', newBelgeNo);
          setLoading(false);
          setSelectedInvoiceId(null);
        }
      });
    } catch (error) {
      setLoading(false);
      Modal.error({
        title: language === 'tr' ? 'Hata' : 'Error',
        content: language === 'tr' ? 'Kayıt işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.' : 'An error occurred during the saving process. Please try again.'
      });
    }
  };





  const paymentMethods = {
    tr: [
      { value: "nakit", label: "Nakit" },
      { value: "krediKarti", label: "Kredi Kartı" },
      { value: "havale", label: "Havale" },
    ],
    en: [
      { value: "nakit", label: "Cash" },
      { value: "krediKarti", label: "Credit Card" },
      { value: "havale", label: "Wire Transfer" },
    ],
  };

  const invoiceTypes = {
    tr: [
      { value: "İhracat Faturası", label: "İhracat Faturası" },
      { value: "Yurtiçi Satış Faturası", label: "Yurtiçi Satış Faturası" },
      { value: "İhraç Kayıtlı Fatura", label: "İhraç Kayıtlı Fatura" },
    ],
    en: [
      { value: "İhracat Faturası", label: "Export Invoice" },
      { value: "Yurtiçi Satış Faturası", label: "Domestic Sales Invoice" },
      { value: "İhraç Kayıtlı Fatura", label: "Export Registered Invoice" },
    ],
  };
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null); // Track the ID of the invoice being edited



  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'proformaFaturalar', id));
      fetchAllInvoices(); // Refresh the list of invoices
      Modal.success({
        content: language === 'tr' ? 'Kayıt başarıyla silindi!' : 'Record deleted successfully!'
      });
    } catch (error) {
      Modal.error({
        title: language === 'tr' ? 'Hata' : 'Error',
        content: language === 'tr' ? 'Kayıt silinirken bir hata oluştu. Lütfen tekrar deneyin.' : 'An error occurred while deleting the record. Please try again.'
      });
    }
  };

  useEffect(() => {
    const currentValue = watch('sevkYontemi');
    const translatedValue = language === 'tr' ? translateValueToTurkish(currentValue) : translateValueToEnglish(currentValue);
    setValue('sevkYontemi', translatedValue);
  }, [language, watch, setValue]);
  const handleApprove = async (record) => {
    try {
      // Query the latest document in `approvedOrders` to get the last `belgeNo`
      const q = query(collection(db, 'approvedOrders'), orderBy('belgeNo', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);

      let nextBelgeNo = 'PRF-00001'; // Default belgeNo
      if (!querySnapshot.empty) {
        const lastBelgeNo = querySnapshot.docs[0].data().belgeNo;
        const lastNumber = parseInt(lastBelgeNo.split('-')[1]);
        nextBelgeNo = `PRF-${(lastNumber + 1).toString().padStart(5, '0')}`;
      }

      // Add the approved document to `approvedOrders` collection
      await addDoc(collection(db, 'pendingOrdersPRF'), {
        ...record, // Include all fields from the record
        belgeNo: nextBelgeNo, // Use the incremented belgeNo
      });

      // Delete the document from `proformaFaturalar` collection
      await deleteDoc(doc(db, 'proformaFaturalar', record.id));

      await fetchAllInvoices(); // Refresh the invoice list after approving

      Swal.fire({
        title: language === 'tr' ? 'Onay Başarılı' : 'Approval Successful',
        text: language === 'tr' ? 'Sipariş başarıyla onaylandı!' : 'The order has been approved successfully!',
        icon: 'success',
        confirmButtonText: language === 'tr' ? 'Tamam' : 'OK',
      });

    } catch (error) {
      console.error("Error approving the order: ", error);
      Swal.fire({
        title: language === 'tr' ? 'Hata' : 'Error',
        text: language === 'tr' ? 'Onay işlemi sırasında bir hata oluştu.' : 'An error occurred during the approval process.',
        icon: 'error',
        confirmButtonText: language === 'tr' ? 'Tamam' : 'OK',
      });
    }
  };


  const deliveryMethods = {
    tr: [
      { value: "Deniz Yolu", label: "Deniz Yolu" },
      { value: "Kara Yolu", label: "Kara Yolu" },
      { value: "Fabrikadan Depo Teslimatı", label: "Fabrikadan Depo Teslimatı" },
      { value: "Depo Teslimatı", label: "Depo Teslimatı" },
    ],
    en: [
      { value: "Sea", label: "Sea Route" },
      { value: "Land", label: "Land Route" },
      { value: "Factory to Warehouse Delivery", label: "Factory to Warehouse Delivery" },
      { value: "Warehouse Delivery", label: "Warehouse Delivery" },
    ],
  };

  const logisticsCosts = {
    tr: [
      { value: "Satıcıya aittir", label: "Satıcıya aittir" },
      { value: "Alıcıya aittir", label: "Alıcıya aittir" },
    ],
    en: [
      { value: "Seller Pays", label: "Seller Pays" },
      { value: "Buyer Pays", label: "Buyer Pays" },
    ],
  };
  const columns = [
    {
      title: language === 'tr' ? 'Belge No' : 'Document No',
      dataIndex: 'belgeNo',
      key: 'belgeNo',
    },
    {
      title: language === 'tr' ? 'Cari Adı' : 'Customer Name',
      dataIndex: 'cariAdi',
      key: 'cariAdi',
    },
    {
      title: language === 'tr' ? 'Sipariş Tarihi' : 'Order Date',
      dataIndex: 'siparisTarihi',
      key: 'siparisTarihi',
      render: (date) => (date ? dayjs(date).format('DD-MM-YYYY') : ''),
    },
    {
      title: language === 'tr' ? 'İşlemler' : 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEdit(record)}>
            {language === 'tr' ? 'Düzenle' : 'Edit'}
          </Button>
          {roles != 'Sistem Admini' ? null : <div>
            <Button type="link" danger onClick={() => handleDelete(record.id)}>
              {language === 'tr' ? 'Sil' : 'Delete'}
            </Button>
            {/* Approve Button */}
            <Button type="link" onClick={() => handleApprove(record)}>
              {language === 'tr' ? 'Onayla' : 'Approve'}
            </Button>
          </div>}


        </Space>
      ),
    },
  ];

  return (
    <div className='page-wrapper mt-5'>
      <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '10px', borderRadius: '15px' }}>
        <Table
          columns={columns}
          dataSource={allInvoices}
          rowKey="id"
          pagination={{ pageSize: 5 }}
        />
        <ReactToPrint
          trigger={() => (
            <Button variant="primary" style={{ marginBottom: '20px' }}>
              {language === 'tr' ? 'Yazdır' : 'Print'}
            </Button>
          )}
          content={() => componentRef.current}
        />
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Select defaultValue="tr" onChange={(value) => {
            localStorage.setItem('selectedLanguage', value);
            setLanguage(value);
          }}>
            <Option value="tr">Türkçe</Option>
            <Option value="en">English</Option>
          </Select>
        </div>
        <Title level={2} className="mb-4" style={{ textAlign: 'center', color: '#1890ff' }}>
          {language === 'tr' ? 'Proforma Fatura' : 'Order Form'}
        </Title>

        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Collapse defaultActiveKey={['1']} expandIconPosition="right">
            <Collapse.Panel header={language === 'tr' ? 'Müşteri Bilgileri' : 'Customer Information'} key="1">
              <Form.Item label={language === 'tr' ? 'Cari Adı' : 'Customer Name'} required>
                <Controller
                  name="cariAdi"
                  control={control}
                  rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                  render={({ field }) => (
                    <>
                      <Button
                        type="default"
                        onClick={() => setManualEntry(!manualEntry)} // Butona tıklayınca manuel giriş moduna geçiş yapar
                        style={{ marginBottom: '10px' }}
                      >
                        {manualEntry ? (language === 'tr' ? 'Seçim Yap' : 'Choose From List') : (language === 'tr' ? 'Elle Gir' : 'Enter Manually')}
                      </Button>

                      {manualEntry ? (
                        <Input
                          {...field}
                          placeholder={language === 'tr' ? 'Cari Adı Manuel Girin' : 'Enter Customer Name Manually'}
                          size="large"
                          onBlur={(e) => handleCariChange(e.target.value)} // Elle girilen ada göre bilgileri günceller
                        />
                      ) : (
                        <Select
                          {...field}
                          showSearch
                          placeholder={language === 'tr' ? 'Cari Adı Seçin' : 'Select Customer Name'}
                          optionFilterProp="children"
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          size="large"
                          allowClear
                          onClear={() => field.onChange('')} // Seçim kaldırılırsa cari adını boş yapar
                          onSelect={(value) => {
                            field.onChange(value);
                            handleCariChange(value); // Seçili cari adına göre diğer bilgileri günceller
                          }}
                        >
                          {allCaris.map(cari => (
                            <Option key={cari.KartAdi} value={cari.KartAdi}>
                              {cari.KartAdi}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </>
                  )}
                />
              </Form.Item>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Cari Kodu' : 'Customer Code'} required>
                    <Controller
                      name="cariKodu"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>

                  <Form.Item label="Email">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                  <Form.Item label={language === 'tr' ? 'Adres' : 'Address'}>
                    <Controller
                      name="adres"
                      control={control}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Vergi Dairesi' : 'Tax Office'}>
                    <Controller
                      name="vergiDairesi"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                  <Form.Item label={language === 'tr' ? 'Vergi Numarası' : 'Tax Number'}>
                    <Controller
                      name="vergiNumarasi"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'İlgili Kişi Adı' : 'Contact Person Name'}>
                    <Controller
                      name="ilgiliKisiAdi"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                  <Form.Item label={language === 'tr' ? 'Telefon' : 'Phone'}>
                    <Controller
                      name="telefon"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <div><br /></div>

          <Collapse expandIconPosition="right">
  <Collapse.Panel header={language === 'tr' ? 'Ürün Bilgileri' : 'Product Information'} key="1">
    {fields.map((field, index) => (
      <div key={field.id} style={getSideBySideStyles()}>
        <div style={{ flex: '2 1 100%', minWidth: '350px' }}>
          <Form.Item label={language === 'tr' ? 'Ürün Adı' : 'Product Name'} required>
            <Controller
              name={`urunler[${index}].urunAdi`}
              control={control}
              rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  placeholder={language === 'tr' ? 'Ürün Adı Seçin' : 'Select Product Name'}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  size="large"
                  onChange={(value) => {
                    field.onChange(value);
                    handleUrunAdiChange(value, index);
                    calculatePrices(index);
                  }}
                >
                  {allStockData.map(stock => (
                    <Option key={stock.StockName} value={stock.StockName}>
                      {stock.StockName}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '100px' }}>
          <Form.Item label={language === 'tr' ? 'Ürün Kodu' : 'Product Code'}>
            <Controller
              name={`urunler[${index}].urunKodu`}
              control={control}
              render={({ field }) => <Input {...field} size="large" readOnly />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '80px' }}>
          <Form.Item label={language === 'tr' ? 'Miktar' : 'Quantity'}>
            <Controller
              name={`urunler[${index}].miktar`}
              control={control}
              render={({ field }) => <Input {...field} size="large" onChange={(e) => {
                field.onChange(e);
                calculatePrices(index);
              }} />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '80px' }}>
          <Form.Item label={language === 'tr' ? 'Birim' : 'Unit'}>
            <Controller
              name={`urunler[${index}].birim`}
              control={control}
              render={({ field }) => <Input {...field} size="large" readOnly />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '100px' }}>
          <Form.Item label={language === 'tr' ? 'Birim Fiyat' : 'Unit Price'}>
            <Controller
              name={`urunler[${index}].birimFiyat`}
              control={control}
              render={({ field }) => <Input {...field} size="large" onChange={(e) => {
                field.onChange(e);
                calculatePrices(index);
              }} />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '100px' }}>
          <Form.Item label={language === 'tr' ? 'Para Birimi' : 'Currency'}>
            <Controller
              name={`urunler[${index}].paraBirimi`}
              control={control}
              render={({ field }) => (
                <Select {...field} size="large" onChange={(value) => {
                  field.onChange(value);
                  calculatePrices(index);
                }}>
                  <Option value="TRY">TRY</Option>
                  <Option value="USD">USD</Option>
                  <Option value="EUR">EUR</Option>
                </Select>
              )}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '80px' }}>
          <Form.Item label={`${language === 'tr' ? 'İsk' : 'Disc'} (%)`}>
            <Controller
              name={`urunler[${index}].iskonto`}
              control={control}
              render={({ field }) => <Input {...field} size="large" onChange={(e) => {
                field.onChange(e);
                calculatePrices(index);
              }} />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '80px' }}>
          <Form.Item label={`${language === 'tr' ? 'KDV' : 'VAT'} (%)`}>
            <Controller
              name={`urunler[${index}].kdv`}
              control={control}
              render={({ field }) => <Input {...field} size="large" onChange={(e) => {
                field.onChange(e);
                calculatePrices(index);
              }} />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '100px' }}>
          <Form.Item label={language === 'tr' ? 'Tutar' : 'Amount'}>
            <Controller
              name={`urunler[${index}].tutar`}
              control={control}
              render={({ field }) => <Input {...field} size="large" readOnly />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '1 1 100%', minWidth: '100px' }}>
          <Form.Item label={<span style={{ whiteSpace: 'nowrap' }}>{language === 'tr' ? 'Mevcut Stok' : 'Current Stock'}</span>}>
            <Controller
              name={`urunler[${index}].UnitAmount`}
              control={control}
              render={({ field }) => <Input {...field} size="large" readOnly />}
            />
          </Form.Item>
        </div>

        <div style={{ flex: '0 auto', minWidth: '80px', display: 'flex', alignItems: 'center' }}>
          <Button
            type="text"
            onClick={() => remove(index)}
            icon={<MinusCircleOutlined />}
            size="large"
            style={{ color: 'red' }}
          >
            {language === 'tr' ? 'Sil' : 'Delete'}
          </Button>
        </div>
      </div>
    ))}

    <Form.Item>
      <Button
        type="dashed"
        onClick={() => append({ urunAdi: '', urunKodu: '', urunKategorisi: '', miktar: '', birim: '', birimFiyat: '', paraBirimi: '', iskonto: '', kdv: '', tutar: '' })}
        block
        icon={<PlusOutlined />}
        size="large"
      >
        {language === 'tr' ? 'Ürün Ekle' : 'Add Product'}
      </Button>
    </Form.Item>
  </Collapse.Panel>
</Collapse>
          <div><br /></div>

          <Collapse defaultActiveKey={['3']} expandIconPosition="right">
            <Collapse.Panel header={language === 'tr' ? 'Sipariş Bilgileri' : 'Order Information'} key="3">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Sipariş Tarihi' : 'Order Date'} required>
                    <Controller
                      name="siparisTarihi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <DatePicker onChange={(date) => field.onChange(date ? dayjs(date) : null)} {...field} format="DD-MM-YYYY" size="large" />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Geçerlilik Tarihi' : 'Validity Date'} required >
                    <Controller
                      name="sevkTarihi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <DatePicker onChange={(date) => field.onChange(date ? dayjs(date) : null)} {...field} format="DD-MM-YYYY" size="large" />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Ödeme Şekli' : 'Payment Method'} required>
                    <Controller
                      name="odemeSekli"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="large"
                        >
                          {paymentMethods[language].map(method => (
                            <Option key={method.value} value={method.value}>{method.label}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Fatura Tipi' : 'Invoice Type'} required>
                    <Controller
                      name="faturaTipi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <Select {...field} size="large">
                          {invoiceTypes[language].map(type => (
                            <Option key={type.value} value={type.value}>{type.label}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Fatura Adresi' : 'Invoice Address'} required>
                    <Controller
                      name="faturaAdresi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Fatura Açıklamaları' : 'Invoice Notes'}>
                    <Controller
                      name="faturaAciklamalari"
                      control={control}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header={language === 'tr' ? 'Teslimat Bilgileri' : 'Delivery Information'} key="4">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Teslimat Adresi' : 'Delivery Address'} required>
                    <Controller
                      name="teslimatAdresi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Sevk Yöntemi' : 'Delivery Method'} required>
                    <Controller
                      name="sevkYontemi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="large"
                          placeholder={language === 'tr' ? 'Sevk Yöntemi Seçin' : 'Select Delivery Method'}
                          onChange={(value) => {
                            const translatedValue = language === 'tr' ? translateValueToTurkish(value) : translateValueToEnglish(value);
                            field.onChange(translatedValue);
                          }}
                          value={field.value}
                        >
                          {deliveryMethods[language].map(method => (
                            <Option key={method.value} value={method.value}>{method.label}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'İstenen Sevk Adresi' : 'Desired Delivery Address'} required>
                    <Controller
                      name="istenenSevkAdresi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Nakliye Firması' : 'Shipping Company'}>
                    <Controller
                      name="nakliyeFirmasi"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Lojistik Ücreti' : 'Logistics Cost'} required>
                    <Controller
                      name="lojistikUcreti"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="large"
                        >
                          {logisticsCosts[language].map(cost => (
                            <Option key={cost.value} value={cost.value}>{cost.label}</Option>
                          ))}
                        </Select>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={language === 'tr' ? 'Teslimat Notları' : 'Delivery Notes'}>
                <Controller
                  name="teslimatNotlari"
                  control={control}
                  render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header={language === 'tr' ? 'Diğer Bilgiler' : 'Other Information'} key="5">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Belge No' : 'Document No'}>
                    <Controller
                      name="belgeNo"
                      control={control}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Belge Tarihi' : 'Document Date'}>
                    <Controller
                      name="belgeTarihi"
                      control={control}
                      render={({ field }) => (
                        <DatePicker onChange={(date) => field.onChange(date ? dayjs(date) : null)} {...field} format="DD-MM-YYYY" size="large" />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Özel Talimatlar' : 'Special Instructions'}>
                    <Controller
                      name="ozelTalimatlar"
                      control={control}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Sipariş Notları' : 'Order Notes'}>
                    <Controller
                      name="siparisNotlari"
                      control={control}
                      render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Checkbox to toggle the visibility of the payment section */}
              <Form.Item>
                <Checkbox
                  checked={showPaymentInfo}
                  onChange={(e) => setShowPaymentInfo(e.target.checked)}
                >
                  {language === 'tr' ? 'Ön Ödeme Modu Açılsın mı?' : 'Enable Pre-Payment Mode?'}
                </Checkbox>
              </Form.Item>

              {/* Conditionally render the payment fields if the checkbox is checked */}
              {showPaymentInfo && (
                <>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label={language === 'tr' ? 'Ön Transfer Metni' : 'Pre-Transfer Text'}>
                        <Controller
                          name="paymentInfo.preTransferText"
                          control={control}
                          render={({ field }) => <Input {...field} size="large" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label={language === 'tr' ? 'Ön Transfer Yüzdesi' : 'Pre-Transfer Percentage'}>
                        <Controller
                          name="paymentInfo.preTransferPercentage"
                          control={control}
                          render={({ field }) => <Input {...field} type="number" size="large" />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label={language === 'tr' ? 'Yükleme Transferi Metni' : 'Loading Transfer Text'}>
                        <Controller
                          name="paymentInfo.loadingTransferText"
                          control={control}
                          render={({ field }) => <Input {...field} size="large" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label={language === 'tr' ? 'Yükleme Transferi Yüzdesi' : 'Loading Transfer Percentage'}>
                        <Controller
                          name="paymentInfo.loadingTransferPercentage"
                          control={control}
                          render={({ field }) => <Input {...field} type="number" size="large" />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

            </Collapse.Panel>
            <Collapse.Panel header={language === 'tr' ? 'Satış Temsilcisi Bilgileri' : 'Sales Representative Information'} key="6">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Formu Hazırlayan Adı' : 'Prepared By'} required>
                    <Controller
                      name="formuHazirlayanAdi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Formu Hazırlayan Telefon' : 'Prepared By Phone'} required>
                    <Controller
                      name="formuHazirlayanTelefon"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Formu Hazırlayan Email">
                    <Controller
                      name="formuHazirlayanEmail"
                      control={control}
                      rules={{
                        required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required',
                        pattern: { value: /^\S+@\S+$/i, message: language === 'tr' ? 'Geçerli bir email girin' : 'Enter a valid email' }
                      }}
                      render={({ field }) => <Input {...field} size="large" />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <Form.Item>
            <div><br /></div>

            <Button type="primary" htmlType="submit" block size="large">
              {language === 'tr' ? 'Siparişi Kaydet' : 'Save Order'}
            </Button>
          </Form.Item>

          <div style={{ display: "none" }}>
            <PrintComponent
              language={language}
              ref={componentRef}
              title={getTitle()}
              customerInfo={{
                cariKodu: watch("cariKodu"),
                cariAdi: watch("cariAdi"),
                adres: watch("adres"),
                vergiDairesi: watch("vergiDairesi"),
                vergiNumarasi: watch("vergiNumarasi"),
                email: watch("email"),
                telefon: watch("telefon"),
                ilgiliKisiAdi: watch("ilgiliKisiAdi"),
                ilgiliKisiTelefon: watch("ilgiliKisiTelefon"),
                ilgiliKisiEmail: watch("ilgiliKisiEmail"),
              }}
              productInfo={watch("urunler")}
              orderInfo={{
                siparisTarihi: watch("siparisTarihi")?.format("DD-MM-YYYY"),
                odemeSekli: watch("odemeSekli"),
                faturaTipi: (() => {
                  const type = invoiceTypes[language].find(t => t.value === watch("faturaTipi"));
                  return type ? type.label : watch("faturaTipi");
                })(),
                faturaAdresi: watch("faturaAdresi"),
                faturaAciklamalari: watch("faturaAciklamalari"),
              }}
              deliveryInfo={{
                teslimatAdresi: watch("teslimatAdresi"),
                sevkTarihi: watch("sevkTarihi")?.format("DD-MM-YYYY"),
                sevkYontemi: [watch("sevkYontemi")].flat().map(value => {
                  const method = deliveryMethods[language].find(m => m.value === value);
                  return method ? method.label : value;
                }),
                istenenSevkAdresi: watch("istenenSevkAdresi"),
                nakliyeFirmasi: watch("nakliyeFirmasi"),
                lojistikUcreti: (() => {
                  const type = logisticsCosts[language].find(t => t.value === watch("lojistikUcreti"));
                  return type ? type.label : watch("lojistikUcreti");
                })(),
                belgeNo: watch("belgeNo"),
                belgeTarihi: watch("belgeTarihi")?.format("DD-MM-YYYY"),
              }}
              otherInfo={{
                ozelTalimatlar: watch("ozelTalimatlar"),
                siparisNotlari: watch("siparisNotlari"),
              }}
              paymentInfo={showPaymentInfo ? watch("paymentInfo") : null}  // Pass payment info only if checkbox is checked

              salesRepInfo={{
                formuHazirlayanAdi: watch("formuHazirlayanAdi"),
                formuHazirlayanTelefon: watch("formuHazirlayanTelefon"),
                formuHazirlayanEmail: watch("formuHazirlayanEmail"),
              }}
              loading={loading}
            />
          </div>
        </Form>
      </Card>
    </div>
  );
};
const getSideBySideStyles = () => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '16px',
  marginBottom: '16px',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
  }
});
export default OrderForm;
