import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../environment";
import Swal from "sweetalert2";
import ChangeLogs from "../changelogs/changelogs";
import ShortcutSelector from "../shortcuts/shortcutselector";

export default function NormalUserPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { userId } = location.state || {};
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [shortcuts, setShortcuts] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            if (!userId) {
                console.log("No user ID found in local storage.");
                setLoading(false);
                return;
            }
            const userRef = doc(db, "users", userId);
            try {
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setCurrentUser(docSnap.data());
                } else {
                    console.log("No such user!");
                    alertUser();
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                alertUser();
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    useEffect(() => {
        const savedShortcuts = JSON.parse(localStorage.getItem('userShortcuts')) || [];
        setShortcuts(savedShortcuts);
    }, []);

    const alertUser = () => {
        Swal.fire({
            title: 'Kullanıcı Bulunamadı!',
            text: 'Lütfen tekrar giriş yapınız!',
            icon: 'error',
            confirmButtonText: 'Tamam'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/signin');
            }
        });
    };

    return (
        <div className="page-wrapper">
            <ChangeLogs />
            {loading ? (
                <div id="global-loader">
                    <div className="whirly-loader"></div>
                </div>
            ) : (
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <h1 className="display-4">Hoş Geldiniz!</h1>
                                    <p className="lead">{!loading && currentUser ? currentUser.username : "Hata!"} olarak giriş yaptınız</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <div className="accordion" id="shortcutAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingShortcuts">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseShortcuts" aria-expanded="true" aria-controls="collapseShortcuts">
                                                    İşlem Kısayolları
                                                </button>
                                            </h2>
                                            <div id="collapseShortcuts" className="accordion-collapse collapse show" aria-labelledby="headingShortcuts" data-bs-parent="#shortcutAccordion">
                                                <div className="accordion-body">
                                                    <ShortcutSelector onSave={setShortcuts} />
                                                    {shortcuts.length > 0 && (
                                                        <div className="mt-4">
                                                            <h4>Seçilen Kısayollar:</h4>
                                                            {shortcuts.map((shortcut, index) => (
                                                                <button
                                                                    className="btn btn-primary mb-2 m-1"
                                                                    key={index}  
                                                                    onClick={() => navigate(shortcut.value)}
                                                                >
                                                                    {shortcut.label}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <h4>Departmanlar</h4>
                                    {/* Departman listesi buraya gelecek */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
