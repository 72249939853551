import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { publicRoutes } from '../../Router/router.link';
import Swal from 'sweetalert2';

function ShortcutSelector({ onSave }) {
    const [selectedShortcuts, setSelectedShortcuts] = useState([]);

    useEffect(() => {
        const savedShortcuts = JSON.parse(localStorage.getItem('userShortcuts')) || [];
        const formattedShortcuts = savedShortcuts.map(shortcut => ({
            value: shortcut.value,
            label: shortcut.label
        }));
        setSelectedShortcuts(formattedShortcuts);
    }, []);

    // Assuming user's roles are stored in localStorage
    // This should ideally be obtained from a secure, authenticated source
    const userRoles = localStorage.getItem('role') || [];

    const options = publicRoutes
        .filter(route => route.allowedRoutes && route.allowedRoutes.some(role => userRoles.includes(role)))
        .map(route => ({
            value: route.path,
            label: route.name
        }));

    const handleChange = (selectedOptions) => {
        setSelectedShortcuts(selectedOptions || []);
    };

    const handleSave = () => {
        const paths = selectedShortcuts.map(shortcut => ({
            value: shortcut.value,
            label: shortcut.label
        }));
        localStorage.setItem('userShortcuts', JSON.stringify(paths));
        Swal.fire({
            icon: 'success',
            title: 'Shortcuts Saved!',
            text: 'Your shortcuts have been successfully saved.',
            confirmButtonText: 'OK',
            timer: 1500
        });
        onSave(paths);
    };

    return (
        <div>
            <Select
                className="text-dark"
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: 'orangered',
                        primary: 'black',
                    },
                })}
                value={selectedShortcuts}
                onChange={handleChange}
                options={options}
                isMulti={true}
                placeholder="Eklenecek kısayolları seçiniz."
            />
            <button className="btn btn-primary mt-3" onClick={handleSave}>Kısayollarımı Kaydet</button>
        </div>
    );
}

ShortcutSelector.propTypes = {
    onSave: PropTypes.func.isRequired
};

export default ShortcutSelector;
