import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import axios from "axios";
import { firebaseApiUrl } from "../../../environment";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleResetPassword = async () => {
    
    if (!email) {
      Swal.fire({
        icon: 'warning',
        title: 'Uyarı',
        text: 'Lütfen bir e-posta adresi girin.',
      });
      return; // Prevent further execution if email is not provided
    }
    setIsButtonDisabled(true);

    try {
      const response = await axios.post(`https://${firebaseApiUrl}/sendPasswordResetEmail-sendPasswordResetEmail`, { email });
      if (response.data.success) {
        setTimeout(() => setIsButtonDisabled(false), 300000); // 5 minutes timeout

        Swal.fire({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Şifre sıfırlama talimatları e-posta adresinize gönderildi.',
        });
      } else {
        throw new Error('Failed to send reset instructions');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: `Bir hata oluştu: ${error.message}`,
      });
    }
  };

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper forgot-pass-wrap bg-img">
          <div className="login-content">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/yclkimya.png" alt="img" />
                </div>
                <Link to={all_routes.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt="Logo" />
                </Link>
                <div className="login-userheading">
                  <h3>Şifremi Unuttum</h3>
                  <h4>E-posta adresinizi girin ve talimatları alın.</h4>
                </div>
                <div className="form-login">
                  <label>E-Posta Adresi</label>
                  <div className="form-addons">
                    <input type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="E-posta adresinizi giriniz" />
                    <ImageWithBasePath
                      src="assets/img/icons/mail.svg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="form-login">
                  <button 
                    onClick={handleResetPassword} 
                    className="btn btn-login"
                    disabled={isButtonDisabled}
                  >
                    Giriş Bilgilerimi Gönder
                  </button>
                </div>
                <div className="signinform text-center">
                  <h4>
                    <Link to={all_routes.signin} className="hover-a">Geri Dön</Link>
                  </h4>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
