import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Filter, Sliders, User, Globe, Edit, Trash2 } from "react-feather";
import Select from "react-select";
import { Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../environment";

const Suppliers = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    adres: "",
    aliciOrSatici: "",
    currency: "",
    kartAdi: "",
    muhHesapAdi: "",
    vergiDairesi: "",
    vergiNo: ""
  });

  useEffect(() => {
    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (selectedSupplier) {
      setFormData(selectedSupplier);
    }
  }, [selectedSupplier]);

  const fetchSuppliers = async () => {
    const querySnapshot = await getDocs(collection(db, 'materialBuyCaris'));
    const suppliersData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setData(suppliersData);
    setFilteredData(suppliersData);
  };

  const getNextCode = (currentCodes, prefix, length) => {
    const validCodes = currentCodes.filter(code => code && code.startsWith(prefix));
    const maxCode = validCodes.reduce((max, code) => {
      const num = parseInt(code.replace(prefix, ""), 10);
      return num > max ? num : max;
    }, 0);
    const nextCodeNum = (maxCode + 1).toString().padStart(length, '0');
    return `${prefix}${nextCodeNum}`;
  };

  const handleAddSupplier = async () => {
    try {
      const kartKodlari = data.map(item => item.kartKodu);
      const muhHesapKodlari = data.map(item => item.muhHesapKodu);
      const newKartKodu = getNextCode(kartKodlari, '01.', 4);
      const newMuhHesapKodu = getNextCode(muhHesapKodlari, '320.01.', 4);

      const newSupplier = {
        ...formData,
        kartKodu: newKartKodu,
        muhHesapKodu: newMuhHesapKodu
      };

      await addDoc(collection(db, 'materialBuyCaris'), newSupplier);
      fetchSuppliers();
      MySwal.fire({
        title: "Başarılı!",
        text: "Kayıt başarıyla eklendi.",
        icon: "success",
        confirmButtonText: "Tamam"
      });
    } catch (e) {
      console.error("Doküman eklenirken hata oluştu: ", e);
      MySwal.fire({
        title: "Hata!",
        text: "Kayıt eklenirken bir hata oluştu.",
        icon: "error",
        confirmButtonText: "Tamam"
      });
    }
  };

  const handleUpdateSupplier = async () => {
    try {
      await updateDoc(doc(db, 'materialBuyCaris', selectedSupplier.id), formData);
      fetchSuppliers();
      MySwal.fire({
        title: "Başarılı!",
        text: "Kayıt başarıyla güncellendi.",
        icon: "success",
        confirmButtonText: "Tamam"
      });
    } catch (e) {
      console.error("Doküman güncellenirken hata oluştu: ", e);
      MySwal.fire({
        title: "Hata!",
        text: "Kayıt güncellenirken bir hata oluştu.",
        icon: "error",
        confirmButtonText: "Tamam"
      });
    }
  };

  const handleDeleteSupplier = async (id) => {
    try {
      await deleteDoc(doc(db, 'materialBuyCaris', id));
      fetchSuppliers();
      MySwal.fire({
        title: "Silindi!",
        text: "Kayıt başarıyla silindi.",
        icon: "success",
        confirmButtonText: "Tamam"
      });
    } catch (e) {
      console.error("Doküman silinirken hata oluştu: ", e);
      MySwal.fire({
        title: "Hata!",
        text: "Kayıt silinirken bir hata oluştu.",
        icon: "error",
        confirmButtonText: "Tamam"
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    handleAddSupplier();
    setIsAddModalVisible(false);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    handleUpdateSupplier();
    setIsEditModalVisible(false);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const showAddSupplierModal = () => {
    setSelectedSupplier(null);
    setFormData({
      adres: "",
      aliciOrSatici: "",
      currency: "",
      kartAdi: "",
      muhHesapAdi: "",
      vergiDairesi: "",
      vergiNo: ""
    });
    setIsAddModalVisible(true);
  };

  const showEditSupplierModal = (supplier) => {
    setSelectedSupplier(supplier);
    setIsEditModalVisible(true);
  };

  const handleSortByDate = (selectedOption) => {
    const sortedData = [...filteredData].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return selectedOption.value === "ascending" ? dateA - dateB : dateB - dateA;
    });
    setFilteredData(sortedData);
  };

  const handleFilter = (selectedOption) => {
    const filteredData = data.filter(item => item.customerName === selectedOption.value);
    setFilteredData(filteredData);
  };

  const options = [
    { value: "ascending", label: "Artan" },
    { value: "descending", label: "Azalan" },
  ];

  const optionsTwo = [
    { label: "Müşteri Adını Seçin", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Ülke Seçin", value: "" },
    { label: "Hindistan", value: "India" },
    { label: "ABD", value: "USA" },
  ];

  const columns = [
    
    {
      title: "Kart Adı",
      
      dataIndex: "kartAdi",
      
      sorter: (a, b) => (a.kartAdi || '').length - (b.kartAdi || '').length,
    },
    {
      title: "Kart Kodu",
      dataIndex: "kartKodu",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.kartKodu || '').length - (b.kartKodu || '').length,
    },
    {
      title: "Muhasebe Hesap Adı",
      dataIndex: "muhHesapAdi",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.muhHesapAdi || '').length - (b.muhHesapAdi || '').length,
    },
    {
      title: "Muhasebe Hesap Kodu",
      dataIndex: "muhHesapKodu",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.muhHesapKodu || '').length - (b.muhHesapKodu || '').length,
    },
    {
      title: "Vergi Dairesi",
      dataIndex: "vergiDairesi",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.vergiDairesi || '').length - (b.vergiDairesi || '').length,
    },
    {
      title: "Vergi No",
      dataIndex: "vergiNo",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.vergiNo || '').length - (b.vergiNo || '').length,
    },
    {
      title: "Adres",
      dataIndex: "adres",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.adres || '').length - (b.adres || '').length,
    },
    {
      title: "Alıcı/Satıcı",
      dataIndex: "aliciOrSatici",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.aliciOrSatici || '').length - (b.aliciOrSatici || '').length,
    },
    {
      title: "Para Birimi",
      dataIndex: "currency",
      render: (text) => <span>{text || '-'}</span>,
      sorter: (a, b) => (a.currency || '').length - (b.currency || '').length,
    },
    {
      title: "İşlem",
      dataIndex: "action",
      fixed:'right',
      render: (_, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-target="#edit-units"
              onClick={() => showEditSupplierModal(record)}
            >
              <Edit className="feather-edit" />
            </Link>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(record.id)}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </td>
      ),
      sorter: (a, b) => (a.createdby || '').length - (b.createdby || '').length,
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Evet, sil!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteSupplier(id);
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="breadcrumb">
          <div className="breadcrumb-item">
            <Link to="/">Ana Sayfa</Link>
          </div>
          <div className="breadcrumb-item active">
            Tedarikçi Listesi
          </div>
        </div>
        <div className="page-title">
          <h4>Tedarikçilerinizi Yönetin</h4>
          <button className="btn btn-primary" style={{ marginBottom: 25 }} onClick={showAddSupplierModal}>
            Yeni Tedarikçi Ekle
          </button>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Ara"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <img
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  className="select"
                  options={options}
                  placeholder="Tarihe Göre Sırala"
                  onChange={handleSortByDate}
                />
              </div>
            </div>
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Müşteri Adını Seçin"
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Ülke Seçin"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        <i data-feather="search" className="feather-search" /> Ara
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <Table
              bordered  
              scroll={{ x: 1500, y: 300 }}
                columns={columns}
                dataSource={filteredData}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
      {isAddModalVisible && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
              <div className="modal-content">
                <div className="page-wrapper-new p-0">
                  <div className="content">
                    <div className="modal-header border-0 custom-modal-header">
                      <div className="page-title">
                        <h4>Yeni Tedarikçi Ekle</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setIsAddModalVisible(false)}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body custom-modal-body">
                      <form onSubmit={handleAddSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-blocks">
                              <label>Adres</label>
                              <input
                                type="text"
                                name="adres"
                                className="form-control"
                                value={formData.adres}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Alıcı/Satıcı</label>
                              <Select
                                className="select"
                                options={[
                                  { value: "Alıcı", label: "Alıcı" },
                                  { value: "Satıcı", label: "Satıcı" }
                                ]}
                                value={{ value: formData.aliciOrSatici, label: formData.aliciOrSatici }}
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  aliciOrSatici: selectedOption.value
                                }))}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Para Birimi</label>
                              <Select
                                className="select"
                                options={[
                                  { value: "TL", label: "TL" },
                                  { value: "USD", label: "USD" },
                                  { value: "EUR", label: "EUR" }
                                ]}
                                value={{ value: formData.currency, label: formData.currency }}
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  currency: selectedOption.value
                                }))}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Kart Adı</label>
                              <input
                                type="text"
                                name="kartAdi"
                                className="form-control"
                                value={formData.kartAdi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Muhasebe Hesap Adı</label>
                              <input
                                type="text"
                                name="muhHesapAdi"
                                className="form-control"
                                value={formData.muhHesapAdi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Vergi Dairesi</label>
                              <input
                                type="text"
                                name="vergiDairesi"
                                className="form-control"
                                value={formData.vergiDairesi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Vergi No</label>
                              <input
                                type="text"
                                name="vergiNo"
                                className="form-control"
                                value={formData.vergiNo}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                            onClick={() => setIsAddModalVisible(false)}
                          >
                            İptal
                          </button>
                          <button type="submit" className="btn btn-submit">
                            Kaydet
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isEditModalVisible && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
              <div className="modal-content">
                <div className="page-wrapper-new p-0">
                  <div className="content">
                    <div className="modal-header border-0 custom-modal-header">
                      <div className="page-title">
                        <h4>Tedarikçiyi Düzenle</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setIsEditModalVisible(false)}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body custom-modal-body">
                      <form onSubmit={handleEditSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-blocks">
                              <label>Adres</label>
                              <input
                                type="text"
                                name="adres"
                                className="form-control"
                                value={formData.adres}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Alıcı/Satıcı</label>
                              <Select
                                className="select"
                                options={[
                                  { value: "Alıcı", label: "Alıcı" },
                                  { value: "Satıcı", label: "Satıcı" }
                                ]}
                                value={{ value: formData.aliciOrSatici, label: formData.aliciOrSatici }}
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  aliciOrSatici: selectedOption.value
                                }))}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Para Birimi</label>
                              <Select
                                className="select"
                                options={[
                                  { value: "TL", label: "TL" },
                                  { value: "USD", label: "USD" },
                                  { value: "EUR", label: "EUR" }
                                ]}
                                value={{ value: formData.currency, label: formData.currency }}
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  currency: selectedOption.value
                                }))}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Kart Adı</label>
                              <input
                                type="text"
                                name="kartAdi"
                                className="form-control"
                                value={formData.kartAdi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Muhasebe Hesap Adı</label>
                              <input
                                type="text"
                                name="muhHesapAdi"
                                className="form-control"
                                value={formData.muhHesapAdi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Vergi Dairesi</label>
                              <input
                                type="text"
                                name="vergiDairesi"
                                className="form-control"
                                value={formData.vergiDairesi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Vergi No</label>
                              <input
                                type="text"
                                name="vergiNo"
                                className="form-control"
                                value={formData.vergiNo}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                            onClick={() => setIsEditModalVisible(false)}
                          >
                            İptal
                          </button>
                          <button type="submit" className="btn btn-submit">
                            Kaydet
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Suppliers;
