import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, posRoutes, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeSettings from "../InitialPage/themeSettings";
// import CollapsedSidebar from "../InitialPage/Sidebar/collapsedSidebar";
import Loader from "../feature-module/loader/loader";
import Signin from "../feature-module/pages/login/signin";
import { AuthProvider } from "../components/authcontext/authcontext";
import  PrivateRoute  from "../components/authcontext/privateroute";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import SpecialUserPage from "../components/pages/specialuserpage";
import NormalUserPage from "../components/pages/normaluserpage";
// import HorizontalSidebar from "../InitialPage/Sidebar/horizontalSidebar";
//import LoadingSpinner from "../InitialPage/Sidebar/LoadingSpinner";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  // const layoutStyles = useSelector((state) => state.layoutstyledata);
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Header />
      {/* {layoutStyles == "collapsed" ? (
        <CollapsedSidebar />
      ) : layoutStyles == "horizontal" ? (
        <HorizontalSidebar />
      ) : (
        <Sidebar />
      )} */}
      <Sidebar />
      <Outlet />
      <ThemeSettings />
      <Loader />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      <Loader />
      <ThemeSettings />
    </div>
  );

  const Pospages = () => (
    <div>
      <Header />
      <Outlet />
      <Loader />
      <ThemeSettings />
    </div>
  );

  // console.log(publicRoutes, "dashboard");

  return (
    <div>
            <AuthProvider>

      <Routes>

        <Route path="/" element={<Signin />} />
        <Route path="/reset-password/:token" element={<PrivateRoute><Resetpassword /></PrivateRoute>}/>

        <Route path="/pos" element={<Pospages />}>

          {posRoutes.map((route, id) => (

            <Route path={route.path} element={<PrivateRoute>{route.element}</PrivateRoute>} key={id} />
          ))}
        </Route>
        <Route path={"/"} element={<HeaderLayout />}>
        <Route path="/admin-anasayfa" element={<SpecialUserPage />} />
        <Route path="/kullanici-anasayfa" element={<NormalUserPage />} />

          {publicRoutes.map((route, id) => (
            <Route path={route.path} element={<PrivateRoute>{route.element}</PrivateRoute>} key={id} />
          ))}
        </Route>

        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>

        {/* <Route path={"/expenses/"} element={<HeaderLayout />}>
          {expensesRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route> */}
      </Routes>
      </AuthProvider>

    </div>
  );
};
export default AllRoutes;