import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Progress, message, Modal, Card, Row, Col, Tooltip } from 'antd';
import { UploadOutlined, DownloadOutlined, DeleteOutlined, EditOutlined, FilePdfOutlined, FileWordOutlined, FileExcelOutlined, FileImageOutlined, FileOutlined } from '@ant-design/icons';
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc, serverTimestamp, orderBy, query } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Swal from 'sweetalert2';
import 'antd/dist/antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { db, storage } from '../../environment';

const { Meta } = Card;

const SertifikaBelgeler = () => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [documents, setDocuments] = useState([]);
  const [editingDocument, setEditingDocument] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    const q = query(collection(db, 'certificatesdocuments'), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const docsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setDocuments(docsData);
  };

  const handleFileChange = ({ fileList }) => {
    setFiles(fileList);
  };

  const getFileIcon = (fileType) => {
    if (!fileType) return <FileOutlined style={{ color: '#6c757d', fontSize: '24px' }} />;
    if (fileType.includes('pdf')) return <FilePdfOutlined style={{ color: '#d9534f', fontSize: '24px' }} />;
    if (fileType.includes('word')) return <FileWordOutlined style={{ color: '#1e90ff', fontSize: '24px' }} />;
    if (fileType.includes('excel')) return <FileExcelOutlined style={{ color: '#28a745', fontSize: '24px' }} />;
    if (fileType.includes('image')) return <FileImageOutlined style={{ color: '#ffc107', fontSize: '24px' }} />;
    return <FileOutlined style={{ color: '#6c757d', fontSize: '24px' }} />;
  };

  const getFileBackgroundColor = (fileType) => {
    if (!fileType) return '#f8f9fa';
    if (fileType.includes('pdf')) return '#f8d7da';
    if (fileType.includes('word')) return '#d1ecf1';
    if (fileType.includes('excel')) return '#d4edda';
    if (fileType.includes('image')) return '#fff3cd';
    return '#f8f9fa';
  };

  const handleUpload = async values => {
    setUploading(true);
    const uploadPromises = files.map(file => {
      const storageRef = ref(storage, "/files/"+file.name);
      const uploadTask = uploadBytesResumable(storageRef, file.originFileObj);

      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({ ...prev, [file.uid]: progress }));
        }, error => {
          reject(error);
        }, async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await addDoc(collection(db, 'certificatesdocuments'), {
            title: values.title,
            description: values.description,
            fileUrl: downloadURL,
            fileType: file.type,
            createdAt: serverTimestamp()
          });
          resolve();
        });
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        message.success('Döküman başarıyla eklendi!');
        setFiles([]);
        setUploading(false);
        form.resetFields();
        fetchDocuments();
      })
      .catch(error => {
        message.error('Error adding documents: ' + error.message);
        setUploading(false);
      });
  };

  const handleEdit = (document) => {
    setEditingDocument(document);
    editForm.setFieldsValue(document);
  };

  const handleUpdate = async values => {
    try {
      if (editingDocument) {
        const docRef = doc(db, 'certificatesdocuments', editingDocument.id);
        await updateDoc(docRef, {
          title: values.title,
          description: values.description,
          updatedAt: serverTimestamp()
        });
        message.success('Döküman başarıyla güncellendi!');
        setEditingDocument(null);
        fetchDocuments();
      }
    } catch (error) {
      message.error('Error updating document: ' + error.message);
    }
  };

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: 'Emin misiniz?',
        text: "Bu dökümanı silmek istediğinizden emin misiniz?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d9534f',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Evet, sil!',
        cancelButtonText: 'Hayır, iptal et'
      });

      if (result.isConfirmed) {
        await deleteDoc(doc(db, 'certificatesdocuments', id));
        setDocuments(docs => docs.filter(doc => doc.id !== id));
        Swal.fire(
          'Silindi!',
          'Döküman başarıyla silindi.',
          'success'
        );
      }
    } catch (error) {
      message.error('Error deleting document: ' + error.message);
    }
  };

  return (
    <div className="container mt-5 page-wrapper mx-auto" style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '10px' }}>
      <h1 className="text-center mb-5" style={{ color: '#d9534f' }}>Sertifika ve Belge Yönetimi</h1>
      <Form form={form} onFinish={handleUpload} layout="vertical">
        <Form.Item name="title" label="Başlık" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Açıklama" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Karşıya Yükle">
          <Upload
            multiple
            onChange={handleFileChange}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />} style={{ backgroundColor: '#d9534f', borderColor: '#d9534f', color: 'white' }}>Döküman Seçiniz</Button>
          </Upload>
        </Form.Item>
        {files.map(file => (
          <Progress key={file.uid} percent={uploadProgress[file.uid] || 0} />
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={uploading} style={{ backgroundColor: '#d9534f', borderColor: '#d9534f', color: 'white' }}>Döküman Ekle</Button>
        </Form.Item>
      </Form>

      <Row gutter={[16, 16]}>
        {documents.map(item => (
          <Col span={8} key={item.id}>
            <Card
              actions={[
                <Tooltip title="İndir" key="download">
                  <Button icon={<DownloadOutlined />} href={item.fileUrl} target="_blank" />
                </Tooltip>,
                <Tooltip title="Düzenle" key="edit">
                  <Button icon={<EditOutlined />} onClick={() => handleEdit(item)} />
                </Tooltip>,
                <Tooltip title="Sil" key="delete">
                  <Button icon={<DeleteOutlined />} onClick={() => handleDelete(item.id)} />
                </Tooltip>
              ]}
              style={{ borderColor: '#d9534f', backgroundColor: getFileBackgroundColor(item.fileType) }}
            >
              <Meta
                avatar={getFileIcon(item.fileType)}
                title={item.title}
                description={`${item.description} (${item.fileType})`}
              />
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        visible={!!editingDocument}
        title="Dökümanı Düzenle"
        onCancel={() => setEditingDocument(null)}
        footer={null}
      >
        <Form form={editForm} onFinish={handleUpdate} layout="vertical">
          <Form.Item name="title" label="Başlık" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Açıklama" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#d9534f', borderColor: '#d9534f', color: 'white' }}>Dökümanı Güncelle</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SertifikaBelgeler;
