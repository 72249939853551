import React, { useEffect, useState } from "react";
import { db } from "../../environment";
import { collection, getDocs, doc, getDoc, setDoc, query, where, orderBy, limit } from "firebase/firestore";
import {Modal as AntModal, DatePicker,Input, Select as AntSelect } from "antd";
import moment from "moment";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
const AuxiliaryUnitModal = ({ visible, onOk, onCancel, auxUnit, index, onAuxiliaryUnitChange, baseUnit }) => {
  const unitOptions = [
    { label: 'Adet', value: 'adet' },
    { label: 'Kilogram', value: 'kilogram' },
    { label: 'Litre', value: 'litre' },
    { label: 'Metre', value: 'metre' },
    { label: 'Gram', value: 'gram' },
    { label: 'Koli', value: 'koli' }
  ];

  const calculateConversion = () => {
    const baseFactor = parseFloat(auxUnit.conversionFactor) || 1;
    return baseFactor > 0 ? 1 / baseFactor : 0;
  };

  const formatNumber = (number) => parseFloat(number).toFixed(3);

  return (
    <AntModal
      visible={visible}  // Ant Design'da visible kullanılır
      onCancel={onCancel}
      onOk={onOk}
      title="Birim Katsayıları"
    >
      <div className="form-group mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label style={{ flex: 1 }}>1 {baseUnit?.toUpperCase()} = </label>
        <Input
          type="number"
          value={auxUnit.conversionFactor}
          onChange={(e) => onAuxiliaryUnitChange(index, 'conversionFactor', e.target.value)}
          style={{ width: '50%', marginRight: '10px' }}
        />
        <AntSelect
          value={auxUnit.unit}
          onChange={(value) => onAuxiliaryUnitChange(index, 'unit', value)}
          style={{ width: '30%' }}
        >
          {unitOptions.map(option => (
            <AntSelect.Option key={option.value} value={option.value}>
              {option.label}
            </AntSelect.Option>
          ))}
        </AntSelect>
      </div>

      <div className="form-group mt-3">
        <label>
          1 {auxUnit.unit?.toUpperCase()} = {formatNumber(calculateConversion())} {baseUnit?.toUpperCase()}
        </label>
      </div>
    </AntModal>
  );
};












// PropTypes doğrulamaları
AuxiliaryUnitModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  baseUnit:PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
  auxUnit: PropTypes.shape({
    unit: PropTypes.string,
    conversionFactor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  index: PropTypes.number.isRequired,
  onAuxiliaryUnitChange: PropTypes.func.isRequired,
};

const StokGuncelleModal = ({ currentId, setShow }) => {
  const [stock, setStock] = useState({
    StockCode: '',
    StockNo: '',
    StockName: '',
    FullStockCode: '',
    StockCategory: '',
    Unit: 'kg',
    CurrentStock: '',
    MinimumStock: '',
    Types: '',
    MaximumStock: '',
    SafetyStock: '',
    SubgroupCode: '',
    Warehouse:'',
    GroupName: '',
    Currency: 'TRY',
    StandardCost: '',
    SupplierInfo: '',
    LeadTime: '',
    ShelfLifeEnd: '',
    StorageConditions: '',
    BatchNumber: '',
    Certificates: '',
    ImportExportInfo: '',
    TekAmbalajAgirlik:'',
    ToplamKG:'',
    BirBobinOrtalamaAgirligi:'',  // Kullanıcıdan alınacak bobin ağırlığı

    SafetyInfo: '',
    selectedCategories: {},
    selectedGroups: {},
    selectedTypes: {},
    auxiliaryUnits: [{ unit: '', amount: '',conversionFactor:1}]

  });

  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplierDetails, setSelectedSupplierDetails] = useState(null);
  const [, setDropdownOptions] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [types, setTypes] = useState([]);
  const [shelfLife, setShelfLife] = useState(null);
  const [warehouses, setWarehouses] = useState([]); // Depolar için state
  const fetchWarehouses = async () => {
    const warehouseRef = collection(db, 'depolar'); // Depoların bulunduğu koleksiyon
    const snapshot = await getDocs(warehouseRef);
    const warehouseList = snapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().depoAdi
    }));
    setWarehouses(warehouseList);
  };

  useEffect(() => {
    fetchWarehouses(); // Depoları çek
  }, []);
  const handleAddAuxiliaryUnit = () => {
    setStock(prevStock => ({
      ...prevStock,
      auxiliaryUnits: [...prevStock.auxiliaryUnits, { unit: '', conversionFactor: '' }]
    }));
  };

  // Yardımcı birim silme
  const handleRemoveAuxiliaryUnit = (index) => {
    setStock(prevStock => ({
      ...prevStock,
      auxiliaryUnits: prevStock.auxiliaryUnits.filter((_, i) => i !== index)
    }));
  };

  // Yardımcı birim değiştirme
  const handleAuxiliaryUnitChange = (index, field, value) => {
    setStock(prevStock => ({
      ...prevStock,
      auxiliaryUnits: prevStock.auxiliaryUnits.map((auxUnit, i) =>
        i === index ? { ...auxUnit, [field]: value } : auxUnit
      )
    }));
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(null); // Hangi birimin modal'ı açıldığını takip etmek için

  // Modal açma
  const handleOpenModal = (index) => {
    setSelectedUnitIndex(index); // Seçili birimi kaydet
    setModalVisible(true);
  };

  // Modal kapatma ve kaydetme
  const handleModalOk = () => {
    setModalVisible(false);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const categoriesRef = collection(db, 'stockManager');
      const snapshot = await getDocs(categoriesRef);
      const categoriesWithChildren = snapshot.docs.map(doc => ({
        label: doc.data().title,
        key: doc.id,
        children: doc.data().children || []
      }));
      setCategories(categoriesWithChildren);
  
      const groupsRef = collection(db, 'groupManager');
      const groupSnapshot = await getDocs(groupsRef);
      const groupsWithChildren = groupSnapshot.docs.map(doc => ({
        label: doc.data().title,
        key: doc.id,
        children: doc.data().children || []
      }));
      setGroups(groupsWithChildren);
  
      const typesRef = collection(db, 'typeManager');
      const typesSnapshot = await getDocs(typesRef);
      const typesWithChildren = typesSnapshot.docs.map(doc => ({
        label: doc.data().title,
        key: doc.id,
        children: doc.data().children || []
      }));
      setTypes(typesWithChildren);
  
      const stockUnitsCollection = collection(db, "stockUnits");
      const stockUnitsSnapshot = await getDocs(stockUnitsCollection);
      const dropdownOptions = stockUnitsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          label: data.name,
          value: data.value
        };
      });
      setDropdownOptions(dropdownOptions);
  
      const unitsCollection = collection(db, "units");
      const unitsSnapshot = await getDocs(unitsCollection);
      const unitList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUnits(unitList);
  
      const suppliersCollection = collection(db, "materialBuyCaris");
      const suppliersSnapshot = await getDocs(suppliersCollection);
      const suppliersList = suppliersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSuppliers(suppliersList);
    };
  
    fetchData();
  
    const fetchStockData = async () => {
      if (currentId) {
        const stockDoc = doc(db, "stocks", currentId);
        const stockData = await getDoc(stockDoc);
        if (stockData.exists()) {
          const fetchedStock = stockData.data();
          setStock(fetchedStock);
  
          if (fetchedStock.SupplierInfo) {
            const supplierDoc = doc(db, "materialBuyCaris", fetchedStock.SupplierInfo);
            const supplierDetails = await getDoc(supplierDoc);
            if (supplierDetails.exists()) {
              setSelectedSupplierDetails(supplierDetails.data());
            }
          }
  
          if (fetchedStock.ShelfLifeEnd) {
            const endDate = moment(fetchedStock.ShelfLifeEnd, "DD/MM/YYYY");
            const currentDate = moment();
            const diffDays = endDate.diff(currentDate, 'days');
            setShelfLife(diffDays);
          }
        }
      }
    };
  
    fetchStockData();
  }, [currentId]);
  

  const handleDateChange = (date, dateString) => {
    if (date) {
      const endDate = moment(dateString, 'DD/MM/YYYY');
      setStock(prev => ({
        ...prev,
        ShelfLifeEnd: dateString
      }));

      const currentDate = moment();
      const diffDays = endDate.diff(currentDate, 'days');
      setShelfLife(diffDays);
    } else {
      setStock(prev => ({
        ...prev,
        ShelfLifeEnd: ''
      }));
      setShelfLife(null);
    }
  };

  const handleSelectChange = (key, selectedKey, children) => {
    setStock(prev => ({
      ...prev,
      selectedCategories: {
        ...prev.selectedCategories,
        [key]: { selectedKey, children }
      }
    }));
  };

  const handleSelectChangeGroups = (key, selectedKey, children) => {
    setStock(prev => ({
      ...prev,
      selectedGroups: {
        ...prev.selectedGroups,
        [key]: { selectedKey, children }
      }
    }));
  };

  const handleSelectChangeTypes = (key, selectedKey, children) => {
    setStock(prev => ({
      ...prev,
      selectedTypes: {
        ...prev.selectedTypes,
        [key]: { selectedKey, children }
      }
    }));
  };

  const gatherSelectedCategories = (selectedCategories) => {
    let result = {};
  
    if (!selectedCategories || typeof selectedCategories !== 'object') {
      return result;
    }
  
    Object.keys(selectedCategories).forEach(key => {
      if (selectedCategories[key].selectedKey) {
        if (selectedCategories[key].title) {
          result[key] = {
            title: selectedCategories[key].title,
            key: selectedCategories[key].selectedKey
          };
        } else {
          result[key] = {
            key: selectedCategories[key].selectedKey
          };
        }
        const childResult = gatherSelectedCategories(selectedCategories[key].children || {});
        Object.assign(result, childResult);
      }
    });
  
    return result;
  };
  

  const gatherSelectedGroup = (selectedGroup) => {
    let result = {};
  
    if (!selectedGroup || typeof selectedGroup !== 'object') {
      return result;
    }
  
    Object.keys(selectedGroup).forEach(key => {
      if (selectedGroup[key].selectedKey) {
        if (selectedGroup[key].title) {
          result[key] = {
            title: selectedGroup[key].title,
            key: selectedGroup[key].selectedKey
          };
        } else {
          result[key] = {
            key: selectedGroup[key].selectedKey
          };
        }
        const childResult = gatherSelectedGroup(selectedGroup[key].children || {});
        Object.assign(result, childResult);
      }
    });
  
    return result;
  };
  

  const gatherSelectedType = (selectedType) => {
    let result = {};
    
    if (!selectedType || typeof selectedType !== 'object') {
      return result;
    }
  
    Object.keys(selectedType).forEach(key => {
      if (selectedType[key].selectedKey) {
        if (selectedType[key].title) {
          result[key] = {
            title: selectedType[key].title,
            key: selectedType[key].selectedKey
          };
        } else {
          result[key] = {
            key: selectedType[key].selectedKey
          };
        }
        const childResult = gatherSelectedType(selectedType[key].children || {});
        Object.assign(result, childResult);
      }
    });
    
    return result;
  };
  

  const renderSelectsForChildren = (categories) => {
    if (!categories || categories.length === 0) return null;

    return categories.map((category) => {
      if (!category.children || category.children.length === 0) {
        return null;
      }

      return (
        <div key={category.key} style={{ marginBottom: '15px' }}>
          <label>{category.title}</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <select
              className="form-control"
              style={{ flexGrow: 1, marginRight: '5px' }}
              value={stock.selectedCategories[category.key]?.selectedKey || ''}
              onChange={(event) => handleSelectChange(category.key, event.target.value, category.children)}
            >
              <option value="">Boş Bırakmak İstiyorum</option>
              {category.children.map((subCategory) => (
                <option key={subCategory.key} value={subCategory.key}>{subCategory.title}</option>
              ))}
            </select>
          </div>
          {category.children && category.children.length > 0 &&
            stock.selectedCategories[category.key] && stock.selectedCategories[category.key].selectedKey &&
            renderSelectsForChildren(category.children, category.key)}
        </div>
      );
    });
  };

  const renderSelectsForChildrenGroup = (categories) => {
    if (!categories || categories.length === 0) return null;

    return categories.map((category) => {
      if (!category.children || category.children.length === 0) {
        return null;
      }

      return (
        <div key={category.key} style={{ marginBottom: '15px' }}>
          <label>{category.title}</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <select
              className="form-control"
              style={{ flexGrow: 1, marginRight: '5px' }}
              value={stock.selectedGroups[category.key]?.selectedKey || ''}
              onChange={(event) => handleSelectChangeGroups(category.key, event.target.value, category.children)}
            >
              <option value="">Boş Bırakmak İstiyorum</option>
              {category.children.map((subCategory) => (
                <option key={subCategory.key} value={subCategory.key}>{subCategory.title}</option>
              ))}
            </select>
          </div>
          {category.children && category.children.length > 0 &&
            stock.selectedGroups[category.key] && stock.selectedGroups[category.key].selectedKey &&
            renderSelectsForChildrenGroup(category.children, category.key)}
        </div>
      );
    });
  };

  const renderSelectsForChildrenType = (categories) => {
    if (!categories || categories.length === 0) return null;

    return categories.map((category) => {
      if (!category.children || category.children.length === 0) {
        return null;
      }

      return (
        <div key={category.key} style={{ marginBottom: '15px' }}>
          <label>{category.title}</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <select
              className="form-control"
              style={{ flexGrow: 1, marginRight: '5px' }}
              value={stock.selectedTypes[category.key]?.selectedKey || ''}
              onChange={(event) => handleSelectChangeTypes(category.key, event.target.value, category.children)}
            >
              <option value="">Boş Bırakmak İstiyorum</option>
              {category.children.map((subCategory) => (
                <option key={subCategory.key} value={subCategory.key}>{subCategory.title}</option>
              ))}
            </select>
          </div>
          {category.children && category.children.length > 0 &&
            stock.selectedTypes[category.key] && stock.selectedTypes[category.key].selectedKey &&
            renderSelectsForChildrenType(category.children, category.key)}
        </div>
      );
    });
  };
  const fetchLatestStockNo = async (selectedTypeValue) => {
    if (!selectedTypeValue) return;
  
    try {
      const stockRef = collection(db, "stocks");
      const q = query(
        stockRef,
        where("FullStockCode", ">=", `${selectedTypeValue}.00.000`),
        where("FullStockCode", "<=", `${selectedTypeValue}.99.999`),
        orderBy("FullStockCode", "desc"),
        limit(1)
      );
  
      const querySnapshot = await getDocs(q);
      console.log('Query Snapshot Docs:', querySnapshot.docs);
  
      if (!querySnapshot.empty) {
        const latestStock = querySnapshot.docs[0].data();
        console.log('Latest Stock:', latestStock);
        const fullStockCodeParts = latestStock.FullStockCode.split('.');
        const latestStockNoPart = fullStockCodeParts[2];
        const stockCodePrefix = `${fullStockCodeParts[0]}.${fullStockCodeParts[1]}`;
  
        if (latestStockNoPart && !isNaN(latestStockNoPart)) {
          const nextStockNo = parseInt(latestStockNoPart, 10) + 1;
          setStock(prev => ({
            ...prev,
            StockCode: stockCodePrefix, // FullStockCode'un ilk iki kısmını StockCode olarak ayarla
            StockNo: String(nextStockNo).padStart(3, '0'), // sadece artırılmış sayıyı StockNo olarak tut
            FullStockCode: `${stockCodePrefix}.${String(nextStockNo).padStart(3, '0')}` // Formatı koruyarak yeni FullStockCode ayarla
          }));
        } else {
          setStock(prev => ({
            ...prev,
            StockCode: `${selectedTypeValue}.01`,
            StockNo: '001',
            FullStockCode: `${selectedTypeValue}.01.001`
          }));
        }
      } else {
        setStock(prev => ({
          ...prev,
          StockCode: `${selectedTypeValue}.01`,
          StockNo: '001',
          FullStockCode: `${selectedTypeValue}.01.001`
        }));
      }
    } catch (error) {
      console.error('Error fetching latest stock number:', error);
      setStock(prev => ({
        ...prev,
        StockCode: `${selectedTypeValue}.01`,
        StockNo: '001',
        FullStockCode: `${selectedTypeValue}.01.001`
      }));
    }
  };
  
  
  const handleChange =async (event) => {
    const { name, value } = event.target;
    if (name === "StockCode") {
      await fetchLatestStockNo(value); // Yeni StockNo'yu belirlemek için fetchLatestStockNo fonksiyonunu çağırın
      setStock(prevStock => ({
        ...prevStock,
        StockCode: value
      }));
      return; // setStock işlemi fetchLatestStockNo fonksiyonunda yapılıyor
    }
   
    if (name === "GroupName") {
      const selectedGroup = groups.find(group => group.key === value);
      if (selectedGroup) {
        handleSelectChangeGroups(selectedGroup.key, value, selectedGroup.children);
        setStock(prevStock => ({
          ...prevStock,
          GroupName: value,
          selectedGroups: {
            ...prevStock.selectedGroups,
            [selectedGroup.key]: {
              selectedKey: value,
              children: selectedGroup.children
            }
          }
        }));
      } else {
        setStock(prevStock => ({
          ...prevStock,
          GroupName: value,
          selectedGroups: {}
        }));
      }
    }

    if (name === "StockCategory") {
      const selectedCategory = categories.find(cat => cat.key === value);
      if (selectedCategory) {
        handleSelectChange(selectedCategory.key, value, selectedCategory.children);
        setStock(prevStock => ({
          ...prevStock,
          selectedCategories: {
            ...prevStock.selectedCategories,
            [selectedCategory.key]: {
              selectedKey: value,
              children: selectedCategory.children
            }
          }
        }));
      } else {
        setStock(prevStock => ({
          ...prevStock,
          selectedCategories: {}
        }));
      }
    }

    if (name === "Types") {
      const selectedTypes = types.find(cat => cat.key === value);
      if (selectedTypes) {
        setStock(prevStock => ({
          ...prevStock,
          selectedTypes: {
            ...prevStock.selectedTypes,
            [selectedTypes.key]: {
              selectedKey: value,
              children: selectedTypes.children
            }
          }
        }));
      } else {
        setStock(prevStock => ({
          ...prevStock,
          selectedTypes: {}
        }));
      }
    }

    if (name === "LeadTime") {
      setStock(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setStock(prevStock => ({
        ...prevStock,
        [name]: value
      }));
    }
  };
  
  

  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Seçilen kategoriler, gruplar ve tipler ile ilgili verileri topla
    const selectedCategoryKeys = gatherSelectedCategories(stock.selectedCategories);
    const selectedGroupKeys = gatherSelectedGroup(stock.selectedGroups);
    const selectedTypeKeys = gatherSelectedType(stock.selectedTypes);
  
    // Güncellenmiş stok verisi
    let updatedStockData = {
      ...stock,
      selectedCategoryKeys,
      selectedGroupKeys,
      selectedTypeKeys,
      
    };
  
    // Remove fields that are undefined
    updatedStockData = Object.fromEntries(
      Object.entries(updatedStockData).filter(([, value]) => value !== undefined)
    );
  
    // Firebase'e güncellenmiş stok verisini kaydet
    await setDoc(doc(db, "stocks", currentId), updatedStockData);
    console.log("Document updated with ID:", currentId);
  
    setShow(false);
    Swal.fire('Başarılı!', 'Kayıt başarılı!', 'success');
  };
  
  

  const handleSupplierChange = async (event) => {
    const supplierId = event.target.value;
    const supplierDoc = doc(db, "materialBuyCaris", supplierId);
    const supplierDetails = await getDoc(supplierDoc);

    if (supplierDetails.exists()) {
      setSelectedSupplierDetails(supplierDetails.data());
      setStock(prev => ({
        ...prev,
        SupplierInfo: supplierDetails.data().kartAdi,
      }));
    } else {
      console.log("No such supplier!");
      setSelectedSupplierDetails(null);
    }
  };

  return (
    <div>
     
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Stok Güncelle</h4>
                </div>
                
              </div>
              <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="accordion accordion-primary" id="accordionPrimaryExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingPrimaryOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#collapsePrimaryOne" aria-expanded="true"
                                  aria-controls="collapsePrimaryOne">
                                  Temel Bilgiler
                                </button>
                              </h2>
                              <div id="collapsePrimaryOne" className="accordion-collapse collapse show"
                                aria-labelledby="headingPrimaryOne" data-bs-parent="#accordionPrimaryExample">
                                <div className="accordion-body">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6">
                                      <div className="form-group mb-3">
  <label>Stok Kodu</label>
  <div className="input-group">
    <select
      name="StockCode"
      value={stock.FullStockCode}
      onChange={handleChange}
      className="form-control custom-select"
      style={{ width: '40%' }}
    >
      {stock.FullStockCode.startsWith("157") ? (
        <option value="Ambalaj Stok">Ambalaj Stok</option>
      ) : stock.FullStockCode.startsWith("150") ? (
        <option value="Alış Stok">Alış Stok</option>
      ) : (
        <option value="Satış Stok">Satış Stok</option>
      )}
    </select>
    <input
      type="text"
      name="FullStockCode"
      value={stock.FullStockCode || ""}
      readOnly
      className="form-control"
    />
  </div>
</div>




                                        <div className="form-group mb-3">
                                          <label>Kategori</label>
                                          <select
                                            name="StockCategory"
                                            value={stock.StockCategory}
                                            onChange={handleChange}
                                            className="form-control custom-select"
                                          >
                                            <option value="">Kategori seçiniz...</option>
                                            {categories.map(category => (
                                              <option key={category.key} value={category.key}>{category.label}</option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="form-group mb-3">
                                                        <label>Raf Ömrü</label>
                                                        <DatePicker
                                                          format="DD/MM/YYYY"
                                                          onChange={handleDateChange}
                                                          value={stock.ShelfLifeEnd ? moment(stock.ShelfLifeEnd, "DD/MM/YYYY") : null}
                                                          className="form-control"
                                                        />
                                                      </div>
                                                      <div className="form-group mb-3">
                                                        <label>Raf Ömrüne Kalan Gün</label>
                                                        <input type="text" value={shelfLife !== null ? shelfLife : ''} readOnly className="form-control" />
                                                      </div>
                                        {stock.StockCategory && categories.find(cat => cat.key === stock.StockCategory)?.children &&
                                          renderSelectsForChildren(categories.find(cat => cat.key === stock.StockCategory).children, stock.StockCategory)}
                                        <div className="form-group mb-3">
                                          <label>Grup</label>
                                          <select
                                            name="GroupName"
                                            value={stock.GroupName}
                                            onChange={handleChange}
                                            className="form-control custom-select"
                                          >
                                            <option value="">Grup seçiniz...</option>
                                            {groups.map(category => (
                                              <option key={category.key} value={category.key}>{category.label}</option>
                                            ))}
                                          </select>
                                          {stock.GroupName && groups.find(cat => cat.key === stock.GroupName)?.children &&
                                            renderSelectsForChildrenGroup(groups.find(cat => cat.key === stock.GroupName).children, stock.GroupName)}
                                        </div>
                                        <div className="form-group mb-3">
                                          <label>Tip</label>
                                          <select
                                            name="Types"
                                            value={stock.Types}
                                            onChange={handleChange}
                                            className="form-control custom-select"
                                          >
                                            <option value="">Tip seçiniz...</option>
                                            {types.map(category => (
                                              <option key={category.key} value={category.key}>{category.label}</option>
                                            ))}
                                          </select>
                                          {stock.Types && types.find(cat => cat.key === stock.Types)?.children &&
                                            renderSelectsForChildrenType(types.find(cat => cat.key === stock.Types).children, stock.Types)}
                                        </div>
                                      </div>
                                      
                                      <div className="col-md-6">
                                        <div className="form-group mb-3">
                                          <label>Stok Adı</label>
                                          <input  type="text" name="StockName" value={stock.StockName} onChange={handleChange} className="form-control" style={{ width: '100%' }} />
                                        </div>
                                        <label>Mevcut Stok </label>
                                        <div className="input-group form-group mb-3">
                                          <input  style={{ display: 'inline-block', width: '50%' }}  name="UnitAmount" value={stock.UnitAmount} onChange={handleChange} className="form-control" />
                                          <select  id="unitSelect" name="Unit" value={stock.Unit} onChange={handleChange} className="form-control custom-select">
                                            {units.map(unit => (
                                              <option key={unit.name} value={unit.name}>{unit.name}</option>
                                            ))}
                                          </select>
                                        </div>
                               
                                        <div className="form-group mb-3">
                                          <label>Minimum Stok Miktarı ({stock.Unit}) </label>
                                          <input  type="number" name="MinimumStock" value={stock.MinimumStock} onChange={handleChange} className="form-control" />
                                        </div>
                                        <div className="form-group mb-3">
                                          <label>Maksimum Stok Miktarı</label>
                                          <input  type="number" name="MaximumStock" value={stock.MaximumStock} onChange={handleChange} className="form-control" />
                                        </div>
                                        <div className="form-group mb-3">
                                          <label>Yeniden Sipariş Miktarı ({stock.Unit})</label>
                                          <input  type="number" name="LeadTime" value={stock.LeadTime} onChange={handleChange} className="form-control" />
                                        </div>
                                        <div>
                                          {stock.auxiliaryUnits.map((auxiliaryUnit, index) => (
  <div key={index} className="form-group mb-3">
    <label>Yardımcı Birim {index + 1}</label>
    <div className="input-group">
      <select
        value={auxiliaryUnit.unit}
        onChange={(e) => handleAuxiliaryUnitChange(index, 'unit', e.target.value)}  // Changed from value to e.target.value
        className="form-control custom-select"
      >
        <option value="adet">Adet</option>
        <option value="kilogram">Kilogram</option>
        <option value="litre">Litre</option>
        <option value="metre">Metre</option>
        <option value="gram">Gram</option>
        <option value="koli">Koli</option>
      </select>
      <input
        value={auxiliaryUnit.conversionFactor}
        onChange={(e) => handleAuxiliaryUnitChange(index, 'conversionFactor', e.target.value)}
        className="form-control"
        placeholder="Katsayı"
      />
      <button type="button" className="btn btn-danger" onClick={() => handleRemoveAuxiliaryUnit(index)}>Kaldır</button>
      <button type="button" className="btn btn-primary ml-2" onClick={() => handleOpenModal(index)}>Hesapla</button>
    </div>
  </div>
))}

{stock.auxiliaryUnits.length < 4 && (
  <button type="button" className="btn btn-primary" onClick={handleAddAuxiliaryUnit}>Yardımcı Birim Ekle</button>
)}

{selectedUnitIndex !== null && (
  <AuxiliaryUnitModal
    visible={modalVisible}
    onOk={handleModalOk}
    onCancel={handleModalCancel}
    auxUnit={stock.auxiliaryUnits[selectedUnitIndex]} // Seçili birimi modal'a gönder
    index={selectedUnitIndex}
    onAuxiliaryUnitChange={handleAuxiliaryUnitChange}
    baseUnit={stock.Unit} // Main unit as the base for conversions
  />
)}

</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="accordion accordion-primary" id="accordionDetailExample">
                                      <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingDetails">
                                          <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseDetails" aria-expanded="true"
                                            aria-controls="collapseDetails">
                                            Detaylar
                                          </button>
                                        </h2>
                                        <div id="collapseDetails" className="accordion-collapse collapse show"
                                          aria-labelledby="headingDetails" data-bs-parent="#accordionDetailExample">
                                          <div className="accordion-body">
                                            <div className="container mt-3">
                                              <div className="card">
                                                <div className="card-header">
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      
                                                      <div className="form-group mb-3">
                                                        <label>Depolama Koşulları</label>
                                                        <input type="text" name="StorageConditions" value={stock.StorageConditions} onChange={handleChange} className="form-control" />
                                                      </div>
                                                      <div className="form-group mb-3">
  <label>Depo Seçin</label>
  <select
    name="Warehouse"
    value={stock.Warehouse}  // Mevcut seçili depo
    onChange={handleChange}  // Depo değişimini yakalamak için
    className="form-control custom-select"
    required
  >
    <option value="">Depo Seçiniz...</option>
    {warehouses.map(warehouse => (
      <option key={warehouse.id} value={warehouse.id}>
        {warehouse.name}
      </option>
    ))}
  </select>
</div>

                                                      <div className="form-group mb-3">
                                                        <label>Güvenlik Stok Seviyesi</label>
                                                        <input type="number" name="SafetyStock" value={stock.SafetyStock} onChange={handleChange} className="form-control" />
                                                      </div>
                                                      <div className="form-group mb-3">
                                                        <label>Standart Maliyet</label>
                                                        <div className="input-group">
                                                          <input type="number" name="StandardCost" value={stock.StandardCost} onChange={handleChange} className="form-control" style={{ display: 'inline-block', width: '70%' }} />
                                                          <select name="Currency" value={stock.Currency} onChange={handleChange} className="form-control custom-select" style={{ width: '30%' }}>
                                                            <option value="TRY">₺</option>
                                                            <option value="USD">$</option>
                                                            <option value="EUR">€</option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="form-group mb-3">
                                                        <label>Taşıma Bilgileri</label>
                                                        <input type="text" name="ImportExportInfo" value={stock.ImportExportInfo} onChange={handleChange} className="form-control" />
                                                      </div>
                                                      <div className="form-group mb-3">
  <label>Tedarikçi Bilgileri</label>
  <select
    name="SupplierInfo"
    value={suppliers.find(supplier => supplier.kartAdi === stock.SupplierInfo)?.id || ""}
    onChange={handleSupplierChange}
    className="form-control custom-select"
  >
    <option value="">Tedarikçi seçiniz (opsiyonel)</option>
    {suppliers.map(supplier => (
      <option key={supplier.id} value={supplier.id}>{supplier.kartAdi}</option>
    ))}
  </select>
</div>

                                                      <div className="form-group mb-3">
                                                        <label>Sertifikalar/Uygunluk </label>
                                                        <input type="text" name="Certificates" value={stock.Certificates} onChange={handleChange} className="form-control" />
                                                      </div>
                                                      <div className="form-group mb-3">
                                                        <label>Güvenlik Bilgileri</label>
                                                        <input type="text" name="SafetyInfo" value={stock.SafetyInfo} onChange={handleChange} className="form-control" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {selectedSupplierDetails && (
                                                <div className="accordion mb-3" id="supplierDetailsAccordion">
                                                  <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingSupplier">
                                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupplier" aria-expanded="false" aria-controls="collapseSupplier">
                                                        Tedarikçi Detayları
                                                      </button>
                                                    </h2>
                                                    <div id="collapseSupplier" className="accordion-collapse collapse" aria-labelledby="headingSupplier" data-bs-parent="#supplierDetailsAccordion">
                                                      <div className="accordion-body">
                                                        <ul className="list-group list-group-flush">
                                                          <li className="list-group-item">Adres: {selectedSupplierDetails.adres}</li>
                                                          <li className="list-group-item">E-Posta: {selectedSupplierDetails.ePosta}</li>
                                                          <li className="list-group-item">Vergi Dairesi: {selectedSupplierDetails.vergiDairesi}</li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Güncelle</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
     
  );
};

StokGuncelleModal.propTypes = {
  currentId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

export default StokGuncelleModal;
