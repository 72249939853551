import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, notification, Table, Row, Col } from "antd";
import { collection, addDoc, getDocs } from "firebase/firestore";
import axios from "axios"; // Axios API istekleri için kullanacağız.
import { db } from "../../../environment";
import "./CreateWarehouse.css";

const { Option } = Select;

const CreateWarehouse = () => {
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]); // Depoları tutan state
  const [provinces, setProvinces] = useState([]); // İller
  const [districts, setDistricts] = useState([]); // İlçeler
  const [selectedProvince, setSelectedProvince] = useState(null); // Seçilen il

  // Firestore'dan depoları çekme
  const fetchWarehouses = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "depolar"));
      const warehouseList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWarehouses(warehouseList);
    } catch (error) {
      console.error("Depolar çekilirken bir hata oluştu:", error);
    }
  };

  // İlk render sırasında depoları çek ve illeri TürkiyeAPI'den yükle
  useEffect(() => {
    fetchWarehouses();
    fetchProvinces();
  }, []);

  // TürkiyeAPI ile illeri çek
  const fetchProvinces = async () => {
    try {
      const response = await axios.get("https://turkiyeapi.dev/api/v1/provinces");
      setProvinces(response.data.data); // İlleri state'e kaydet
    } catch (error) {
      console.error("İller çekilirken bir hata oluştu:", error);
    }
  };

  // TürkiyeAPI ile ilçeleri çek (seçilen ile göre)
  const fetchDistricts = async (provinceId) => {
    console.log(provinceId)
    try {
      const response = await axios.get(`https://turkiyeapi.dev/api/v1/provinces/${provinceId}`);
      console.log(response.data)
      setDistricts(response.data.data.districts); // İlçeleri state'e kaydet
    } catch (error) {
      console.error("İlçeler çekilirken bir hata oluştu:", error);
    }
  };

  // İl seçildiğinde ilçeleri çekme işlemi
  const handleProvinceChange = (provinceId) => {
    setSelectedProvince(provinceId);
    fetchDistricts(provinceId); // Seçilen il için ilgili ilçeleri getir
  };

  // Depo oluşturma işlemi
  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Firestore'a depo ekleme
      await addDoc(collection(db, "depolar"), values);
      notification.success({
        message: "Başarılı",
        description: "Depo başarıyla oluşturuldu!",
      });
      fetchWarehouses(); // Yeni depo eklendiğinde listeyi güncelle
    } catch (error) {
      notification.error({
        message: "Hata",
        description: "Depo oluşturulurken bir hata oluştu.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Ant Design Table için kolonlar
  const columns = [
    {
      title: "Depo Adı",
      dataIndex: "depoAdi",
      key: "depoAdi",
    },
    {
      title: "Yetkili Kişi",
      dataIndex: "yetkiliKisi",
      key: "yetkiliKisi",
    },
    {
      title: "Telefon Numarası",
      dataIndex: "telefonNumarasi",
      key: "telefonNumarasi",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Şehir",
      dataIndex: "sehir",
      key: "sehir",
    },
    {
      title: "İlçe",
      dataIndex: "ilce",
      key: "ilce",
    },
  ];

  return (
    <div className="page-wrapper mt-5">
      <Row gutter={16}>
        {/* Sol taraf: Depo Oluşturma Formu */}
        <Col xs={24} lg={8}>
          <h2>Depo Oluştur</h2>
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              depoAdi: "",
              yetkiliKisi: "",
              telefonNumarasi: "",
              isTelefonu: "",
              email: "",
              adres1: "",
              adres2: "",
              sehir: "",
              ilce: "",
              postaKodu: "",
            }}
            size="middle"
          >
            <Form.Item
              name="depoAdi"
              label="Depo Adı"
              rules={[{ required: true, message: "Lütfen depo adını girin!" }]}
            >
              <Input className="corporate-input" />
            </Form.Item>

            <Form.Item
              name="yetkiliKisi"
              label="Yetkili Kişi"
              rules={[{ required: true, message: "Lütfen yetkili kişiyi girin!" }]}
            >
              <Input className="corporate-input" />
            </Form.Item>

            <Form.Item
              name="telefonNumarasi"
              label="Telefon Numarası"
              rules={[{ required: true, message: "Lütfen telefon numarasını girin!" }]}
            >
              <Input className="corporate-input" />
            </Form.Item>

            <Form.Item
              name="sehir"
              label="Şehir"
              rules={[{ required: true, message: "Lütfen bir şehir seçin!" }]}
            >
             <Select
  className="corporate-input"
  placeholder="Şehir Seçin"
  showSearch
  optionFilterProp="children"
  onChange={handleProvinceChange}
  filterOption={(input, option) =>
    option?.children.toLowerCase().includes(input.toLowerCase())
  }
>
  {provinces.map((province) => (
    <Option key={province.id} value={province.id}>
      {province.name}
    </Option>
  ))}
</Select>

            </Form.Item>

            <Form.Item
              name="ilce"
              label="İlçe"
              rules={[{ required: true, message: "Lütfen bir ilçe seçin!" }]}
            >
              <Select className="corporate-input" placeholder="İlçe Seçin" disabled={!selectedProvince}>
                {districts.map((district) => (
                  <Option key={district.name} value={district.name}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="postaKodu" label="Posta Kodu">
              <Input className="corporate-input" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Depo Oluştur
              </Button>
            </Form.Item>
          </Form>
        </Col>

        {/* Sağ taraf: Oluşturulan Depolar Tablosu */}
        <Col xs={24} lg={16}>
          <h2>Oluşturulan Depolar</h2>
          <Table
            dataSource={warehouses}
            columns={columns}
            rowKey={(record) => record.id}
            scroll={{ x: "max-content" }} // Tablonun responsive olmasını sağlar
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateWarehouse;
