import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, DatePicker, Tabs, Row, Col, Select, Modal, notification, Table } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import 'animate.css';  // Animations
import './DepoTransferleri.css';  // Custom CSS
import { collection, query, where, getDocs, addDoc, updateDoc, doc, Timestamp } from "firebase/firestore"; // Firebase functions
import { db } from "../../../environment";
import ReactToPrint from "react-to-print";  // Import react-to-print
import PDFComponent from "./utils/PDFComponent";
import TextArea from "antd/es/input/TextArea";

const { TabPane } = Tabs;
const { Option } = Select;

const DepoTransferForm = () => {
  const [form] = Form.useForm();
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [stokMiktari, setStokMiktari] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(""); // Selected exit warehouse
  const [depolar, setDepolar] = useState([]);
  const [stokAdi, setStokAdi] = useState("");  // Stock name for state
  const [cariler, setCariler] = useState([]);
  const [girisIrsaliyeler, setGirisIrsaliyeler] = useState([]);
  const [cikisIrsaliyeler, setCikisIrsaliyeler] = useState([]);
  const [irsaliyeType, setIrsaliyeType] = useState(null); // Default to "GİRİŞ"
  const [, setSelectedIrsaliyeData] = useState(null);

  // Reference for printing the component
  const componentRef = useRef();

  // Fetch warehouses from Firebase
  useEffect(() => {
    const fetchDepolar = async () => {
      const depolarSnapshot = await getDocs(collection(db, "depolar"));
      const depolarData = depolarSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDepolar(depolarData);
    };

    fetchDepolar();
  }, []);

  // Fetch caris from Firebase
  useEffect(() => {
    const fetchCariler = async () => {
      const carilerSnapshot = await getDocs(collection(db, "allCaris"));
      const carilerData = carilerSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCariler(carilerData); // Set cariler data
    };
    fetchCariler();
  }, []);

  // Fetch Irsaliyeler from Firebase
  useEffect(() => {
    const fetchIrsaliyeler = async () => {
      const girisSnapshot = await getDocs(collection(db, "girisIrsaliyeler"));
      const cikisSnapshot = await getDocs(collection(db, "cikisIrsaliyeler"));
      
      const girisData = girisSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      const cikisData = cikisSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setGirisIrsaliyeler(girisData);
      setCikisIrsaliyeler(cikisData);
    };
    fetchIrsaliyeler();
  }, []);

  const handleIrsaliyeSelect = async (irsaliyeId, irsaliyeType) => {
    let selectedCollection = irsaliyeType === "GİRİŞ" ? "girisIrsaliyeler" : "cikisIrsaliyeler";
    const irsaliyeDoc = await getDocs(collection(db, selectedCollection), where("evrakNo", "==", irsaliyeId));
  
    if (irsaliyeDoc.docs.length > 0) {
      const data = irsaliyeDoc.docs[0].data();
      setSelectedIrsaliyeData(data);
      populateFormFields(data);
    }
  };
  
  const populateFormFields = (data) => {
    form.setFieldsValue({
      // Araç Bilgileri
      aracPlakasi1: data.aracBilgileri?.aracPlakasi || '',
      aracModeli: data.aracBilgileri?.aracModeli || '',
      aracMarkasi: data.aracBilgileri?.aracMarkasi || '',
      aracKapasitesi: data.aracBilgileri?.aracKapasitesi || '',
      
      // Dorse Bilgileri
      dorsePlakasi: data.dorseBilgileri?.dorsePlakasi || '',
      dorseKodu: data.dorseBilgileri?.dorseKodu || '',
      dorseTipi: data.dorseBilgileri?.dorseTipi || '',
      
      // Soför Bilgileri
      sevkiyatciAdi: data.soforBilgileri?.soforAdi || '',
      soforTelefon: data.soforBilgileri?.soforTelefon || '',
      soforEhliyetNo: data.soforBilgileri?.soforEhliyetNo || '',
      
      // Cari Bilgileri
      tasiyanFirma: data.cari?.KartAdi || '',
      sevkAdres: data.cari?.Adres || '',
      vergiNo: data.cari?.VergiNo || '',
      vergiDairesi: data.cari?.VergiDairesi || '',
      
      // Evrak Bilgileri
      evrakNo: data.evrakNo || '',
      evrakTarihi: data.evrakTarihi?.seconds ? new Date(data.evrakTarihi.seconds * 1000) : null,
      
      // Ekstra alanlar
      odemePlani: data.odemePlani || '',
      cekDetay: data.cekDetay || '',
    });
  
    // Stok verileri tabloya yerleştiriliyor
    const stockData = data.tableData.map(stock => ({
      id:stock.key,
      stokAdi: stock.stokAdi,
      miktar: stock.miktar,
      unit: stock.birim,
    }));
    setSelectedStocks(stockData);
  };
  

  const handleRemoveStock = (index) => {
    const updatedStocks = [...selectedStocks];
    updatedStocks.splice(index, 1); // Remove the stock entry at the specified index
    setSelectedStocks(updatedStocks); // Update the stock list
  };

  // Fetch stocks based on the selected warehouse
  useEffect(() => {
    if (selectedWarehouse) {
      const fetchStocks = async () => {
        const q = query(collection(db, "stocks"), where("Warehouse", "==", selectedWarehouse));
        const stockSnapshot = await getDocs(q);
        const stockData = stockSnapshot.docs.map(doc => ({
          id: doc.id,  // Firebase döküman ID'sini alıyoruz
          stokAdi: doc.data().StockName,
          unit: doc.data().Unit,  // Fetch unit data
          ...doc.data()
        }));
        setStocks(stockData); // Set stocks for the selected warehouse
      };
      fetchStocks();
    }
  }, [selectedWarehouse]);
  

  const handleAddStock = () => {
    if (stokAdi && stokMiktari > 0) {
      // Seçilen stok objesini bul
      const selectedStock = stocks.find(stock => stock.stokAdi === stokAdi);
  
      // Eğer stok bulunamazsa, hata ver ve eklemeyi engelle
      if (!selectedStock) {
        notification.error({
          message: "Hata",
          description: "Seçilen stok bulunamadı!",
          placement: "bottomRight",
        });
        return;
      }
  
      // Seçilen stok objesinden 'id' değerini kontrol et ve ekle
      const { id, unit } = selectedStock;
  
      setSelectedStocks(prevSelectedStocks => [
        ...prevSelectedStocks,
        { id, stokAdi, miktar: stokMiktari, unit }  // id alanı da ekleniyor
      ]);
  
      notification.success({
        message: "Stok Eklendi",
        description: `${stokAdi} - ${stokMiktari} ${unit} stok başarıyla eklendi!`,
        icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
        placement: "bottomRight"
      });
  
      setStokMiktari(1);
      setModalVisible(false);
    } else {
      notification.warning({
        message: "Eksik Bilgi",
        description: "Lütfen stok adını ve miktarını doğru giriniz!",
        placement: "bottomRight"
      });
    }
  };
  
  const handleSubmit = async (values) => {
    try {
      // selectedStocks'un tanımlı ve boş olmadığından emin olalım
      if (!selectedStocks || selectedStocks.length === 0) {
        throw new Error("Hiçbir stok seçilmedi.");
      }
  
      // Tarih alanlarının kontrolü ve Firebase'in Timestamp formatına dönüştürülmesi
      const formattedSevkTarihi = values.sevkTarihi ? Timestamp.fromDate(values.sevkTarihi.toDate()) : null;
      const formattedBaslamaZamani = values.baslamaZamani ? Timestamp.fromDate(values.baslamaZamani.toDate()) : null;
      const formattedBitisZamani = values.bitisZamani ? Timestamp.fromDate(values.bitisZamani.toDate()) : null;
      const formattedAsilTeslimTarihi = values.asilTeslimTarihi ? Timestamp.fromDate(values.asilTeslimTarihi.toDate()) : null;
  
      // Transfer verilerini oluştur
      const transferData = {
        ...values,
        sevkTarihi: formattedSevkTarihi,
        baslamaZamani: formattedBaslamaZamani,
        bitisZamani: formattedBitisZamani,
        asilTeslimTarihi: formattedAsilTeslimTarihi,
        selectedStocks, // Seçilen stoklar
        timestamp: Timestamp.now(), // Firebase zaman damgası
      };
  
      console.log("Gönderilen form verileri:", transferData);
  
      // Firebase'e transfer verilerini kaydetme
      const docRef = await addDoc(collection(db, "transfers"), transferData);
      console.log("Oluşturulan döküman ID'si: ", docRef.id);
  
      // Stokları güncelleme: Seçilen çıkış deposuna göre stokların Warehouse alanını güncelleme
      const varisDeposu = values.varisDeposu;
  
      if (!varisDeposu) {
        throw new Error("Çıkış deposu seçilmedi.");
      }
  
      // Seçilen stoklar arasında Warehouse alanını güncelle
      const updateStockPromises = selectedStocks.map(async (stock) => {
        if (!stock.id) {
          throw new Error(`Stok ID'si eksik: ${stock.stokAdi}`);
        }
  
        const stockRef = doc(db, "stocks", stock.id);  // Stock ID'sine göre referans oluştur
        return updateDoc(stockRef, {
          Warehouse: varisDeposu,  // Warehouse alanını seçilen çıkış deposuna göre güncelliyoruz
        });
      });
  
      // Tüm stok güncellemelerini bekliyoruz
      await Promise.all(updateStockPromises);
  
      notification.success({
        message: "Başarılı",
        description: "Sevk fişi başarıyla oluşturuldu ve stoklar güncellendi!",
        icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
        placement: "topRight",
      });
  
      // Formu ve seçilen stokları sıfırla
      form.resetFields();
      setSelectedStocks([]);
    } catch (error) {
      console.error("Döküman kaydedilirken hata oluştu: ", error.message);
  
      notification.error({
        message: "Hata",
        description: error.message || "Sevk fişi kaydedilirken veya stoklar güncellenirken hata oluştu!",
        placement: "topRight",
      });
    }
  };
  
  
  

  

  const columns = [
    {
      title: 'Stok Adı',
      dataIndex: 'stokAdi',
      key: 'stokAdi',
    },
    {
      title: 'Miktar',
      dataIndex: 'miktar',
      key: 'miktar',
    },
    {
      title: 'Birim',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (_, record, index) => (
        <Button danger onClick={() => handleRemoveStock(index)}>
          Sil
        </Button>
      ),
    }
  ];

  return (
    <div className="page-wrapper">
      <h2 className="animate__animated animate__fadeInDown">Depolar Arası Sevk Fişi</h2>
      {/* Wrapping the content you want to print in a div */}
      <div ref={componentRef}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Tabs defaultActiveKey="1" className="custom-tabs" animated>
                {/* Depolar Arası Sevk Fişi Sekmesi */}
                <TabPane tab="Depolar Arası Sevk Fişi" key="1">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="cikisDeposu" label="Çıkış Deposu" rules={[{ required: true, message: "Çıkış deposu seçiniz" }]}>
                        <Select placeholder="Çıkış deposu seçiniz" onChange={(value) => setSelectedWarehouse(value)}>
                          {depolar.map(depo => (
                            <Option key={depo.id} value={depo.depoAdi}>
                              {depo.depoAdi}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name="varisDeposu" label="Varış Deposu" rules={[{ required: true, message: "Varış deposu seçiniz" }]}>
                        <Select placeholder="Varış deposu seçiniz" onChange={(value) => setSelectedWarehouse(value)}>
                          {depolar.map(depo => (
                            <Option key={depo.id} value={depo.depoAdi}>
                              {depo.depoAdi}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="sevkAdres" label="Sevk Adresi">
                        <Input placeholder="Sevk adresi giriniz" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="sevkiyatciAdi" label="Sevk Yetkilisi Adı">
                        <Input placeholder="Yetkili adı giriniz" />
                      </Form.Item>
                    </Col>
                    
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="sevkTarihi" label="Sevk Tarihi">
                        <DatePicker showTime format="DD.MM.YYYY HH:mm:ss" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="tasiyanFirma" label="Taşıyan Firma" rules={[{ required: true, message: "Taşıyan firma seçiniz" }]}>
                        <Select placeholder="Firma seçiniz">
                          {cariler.map(cari => (
                            <Option key={cari.id} value={cari.KartAdi}>
                              {cari.KartAdi}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="bagliIrsaliyeType" label="İrsaliye Tipi" rules={[{ required: true, message: "İrsaliye tipini seçiniz" }]}>
                        <Select placeholder="İrsaliye tipi seçiniz" onChange={(value) => setIrsaliyeType(value)}>
                          <Option value="GİRİŞ">GİRİŞ İRSALİYESİ</Option>
                          <Option value="ÇIKIŞ">ÇIKIŞ İRSALİYESİ</Option>
                        </Select>
                      </Form.Item>

                      {irsaliyeType==null?null:<Form.Item name="bagliIrsaliye" label="Bağlı İrsaliye" rules={[{ required: true, message: "Bağlı irsaliye seçiniz" }]}>
  <Select
    placeholder="İrsaliye seçiniz"
    onChange={(value) => handleIrsaliyeSelect(value, irsaliyeType)} // Call handleIrsaliyeSelect on change
  >
    {irsaliyeType === "GİRİŞ" ? (
      girisIrsaliyeler.map(irsaliye => (
        console.log(irsaliye),
        <Option key={irsaliye.id} value={irsaliye.evrakNo}>
          {irsaliye.evrakNo}
        </Option>
      ))
    ) : (
      cikisIrsaliyeler.map(irsaliye => (
        <Option key={irsaliye.id} value={irsaliye.evrakNo}>
          {irsaliye.evrakNo}
        </Option>
      ))
    )}
  </Select>
</Form.Item>}

                    </Col>
                    <Col span={12}>
                      <Form.Item name="tasimaYontemi" label="Taşıma Yöntemi">
                        <Input placeholder="Taşıma yöntemi giriniz" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="baslamaZamani" label="Başlama Zamanı">
                        <DatePicker showTime format="DD.MM.YYYY HH:mm:ss" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="bitisZamani" label="Bitiş Zamanı">
                        <DatePicker showTime format="DD.MM.YYYY HH:mm:ss" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name={"sevkiyatciAdi"} label="Şoför Ad ">
                        <Input placeholder="Şoför ad  giriniz" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={"soforTelefon"} label="Şoför Tel">
                        <Input placeholder="Şoför  tel giriniz" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="aracPlakasi1" label="Araç Plakası">
                        <Input placeholder="Araç plakası giriniz" />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                
                {/* Evrak Detayları Sekmesi */}
                <TabPane tab="Evrak Detayları" key="2">
                  <Row gutter={16}>
                  <Col span={6}>
            <Form.Item name={"evrakNo"} label="Evrak Numarası" style={{ fontSize: '12px' }}>
            <Input placeholder="Evrak No Giriniz"></Input>
            </Form.Item>
          </Col>
                    <Col span={12}>
                      <Form.Item name="asilTeslimTarihi" label="Asıl Teslim Tarihi">
                        <DatePicker showTime format="DD.MM.YYYY HH:mm:ss" />
                      </Form.Item>
                    </Col>
                    
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="aracMarkasi" label="Araç Markası">
                        <Input placeholder="Araç Markası giriniz" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="aracModeli" label="Araç Model Bilgi">
                        <Input placeholder="Araç Modal bilgi giriniz" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="soforTCKN1" label="Şoför TCKN">
                        <Input placeholder="Şoför TCKN giriniz" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="detayBilgi" label="Detaylı Bilgi">
                        <TextArea placeholder="Detay bilgi giriniz" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="dorsePlakasi" label="Dorse Plaka ">
                        <Input placeholder="Dorse plakası giriniz" />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Col>
            <Modal
              title="Stok Ekle"
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              onOk={handleAddStock}
            >
              <Form.Item name="stokAdi" label="Stok Adı" rules={[{ required: true, message: "Stok adı giriniz" }]}>
                <Select
                  showSearch
                  placeholder="Stok seçiniz"
                  optionFilterProp="children"
                  onChange={(value) => setStokAdi(value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {stocks.map(stock => (
                    <Option key={stock.id} value={stock.stokAdi}>
                      {stock.stokAdi}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Miktar">
                <Input type="number" value={stokMiktari} onChange={(e) => setStokMiktari(e.target.value)} />
              </Form.Item>
            </Modal>

            <Col span={12}>
              {/* Stocks Table */}
              <Table dataSource={selectedStocks} columns={columns} rowKey="stokAdi" pagination={false} />
              <Button onClick={() => setModalVisible(true)}>Stok Ekle</Button>
            </Col>
          </Row>

          <Form.Item>
  <Button type="primary" htmlType="submit" className="animate__animated animate__zoomIn">
    Fiş Oluştur
  </Button>
</Form.Item>

        </Form>
      </div>

      <Form.Item>
        <ReactToPrint
          trigger={() => <Button type="primary">PDF Olarak Çıkart</Button>}
          content={() => componentRef.current}
        />
      </Form.Item>

      {/* Stok Ekleme Modal */}
      <Modal
        title="Stok Ekle"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleAddStock}
      >
        <Form.Item name="stokAdi" label="Stok Adı" rules={[{ required: true, message: "Stok adı giriniz" }]}>
          <Select
            showSearch
            placeholder="Stok seçiniz"
            optionFilterProp="children"
            onChange={(value) => setStokAdi(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {stocks.map(stock => (
              <Option key={stock.id} value={stock.stokAdi}>
                {stock.stokAdi}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Miktar">
          <Input type="number" value={stokMiktari} onChange={(e) => setStokMiktari(e.target.value)} />
        </Form.Item>
      </Modal>
      <div style={{ display: 'none' }}>
      {console.log("xgojh",form.getFieldsValue())}
        <PDFComponent ref={componentRef} formData={form.getFieldsValue()} selectedStocks={selectedStocks} />
      </div>
    </div>
  );
};

export default DepoTransferForm;
