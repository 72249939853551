import React, { useEffect, useState } from 'react';
import { List, Card, Spin, Collapse, Table, Typography, Button, Input, Form, message, Row, Col, Progress,Select, Modal  } from 'antd';
import { collection, getDocs, doc, updateDoc, addDoc, query, where, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../environment';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';
import './BatchSelectionTable.css';
dayjs.extend(customParseFormat);
const { Option } = Select;

const { Panel } = Collapse;
const { Title, Text } = Typography;

// BatchSelectionTable component
function BatchSelectionTable({ totalCells, onCounterUpdate, initialCounter, isProductionStarted, selectedKartId }) {
  const [counter, setCounter] = useState(initialCounter);

  useEffect(() => {
    if (!isProductionStarted) {
      setCounter(totalCells);
    } 
  }, [initialCounter, totalCells, isProductionStarted]);

  const handleDecrement = async () => {
    if (!isProductionStarted) {
      message.warning('Üretime başlamadan şarj adedi değiştiremezsiniz!');
      return;
    }

    if (counter > 0) {
      Swal.fire({
        title: 'Emin misiniz?',
        text: `Değeri azaltmak istiyor musunuz?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, azalt!',
        cancelButtonText: 'Hayır, iptal et!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
          console.log('Şarj adedi azaltıldı:', currentTime);

          setCounter((prevCounter) => {
            const newCounter = Math.max(prevCounter - 1, 0);
            onCounterUpdate(newCounter, currentTime);

            if (selectedKartId) {
              const kartRef = doc(db, 'onaylananUretimKartlari', selectedKartId);
              updateDoc(kartRef, {
                batchCounter: newCounter,
                lastBatchUpdateTime: currentTime,
              })
                .then(() => {
                  message.success('Değer azaltıldı!');
                })
                .catch((error) => {
                  console.error('Firebase güncelleme hatası:', error);
                  message.error('Firebase güncelleme hatası!');
                });
            }

            return newCounter;
          });
        }
      });
    } else {
      message.warning("Değer 0'da kalacak!");
    }
  };

  return (
    <div className="circle-container">
      <div
        className={`circle ${counter <= 0 ? 'red-circle' : ''}`}
        onClick={handleDecrement}
      >
        {counter}
      </div>
    </div>
  );
}

BatchSelectionTable.propTypes = {
  totalCells: PropTypes.number.isRequired,
  onCounterUpdate: PropTypes.func.isRequired,
  initialCounter: PropTypes.number.isRequired, // Firebase batchCounter
  isProductionStarted: PropTypes.bool.isRequired,
  selectedKartId: PropTypes.string.isRequired, // Seçilen kart ID'si (Firebase güncellemeleri için gerekli)
};




// TabletUretimKarti component
function TabletUretimKarti() {
  const [uretimKartlari, setUretimKartlari] = useState([]);
  const [selectedKart, setSelectedKart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [producedQuantity, setProducedQuantity] = useState(null);
  const [wasteQuantity, setWasteQuantity] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(null);
  const [batchCounter, setBatchCounter] = useState(0);
  const [initialCounter, setInitialCounter] = useState(null);
  const [kalanSarjAdedi, setKalanSarjAdedi] = useState(null);
  const [unwrapped_weight, setUnwrapped_weight] = useState(null);
  const [chargeRequestType, setChargeRequestType] = useState(null);
const [chargeRequestQuantity, setChargeRequestQuantity] = useState(null);
const [isChargeModalVisible, setIsChargeModalVisible] = useState(false);
  const [palletCount, setPalletCount] = useState(null);
  const [productsPerPallet, setProductsPerPallet] = useState(null);
  const [remainingStock, setRemainingStock] = useState(null);
  const [isProductionStarted, setIsProductionStarted] = useState(false); // Track if production has started
  const [productionStartTime, setProductionStartTime] = useState(null); // Store production start time
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [fillPercentage, setFillPercentage] = useState(0); // For Progress Bar
const [isOverfilled, setIsOverfilled] = useState(false); // Track if overfilled
const [chargeRequestDescription, setChargeRequestDescription] = useState(''); // State for optional description

  useEffect(() => {
    // Klavye açıldığında ekran boyutunu takip et
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const showChargeRequestModal = () => {
    setIsChargeModalVisible(true);
  };
  
  // Function to handle closing the charge request modal
  const handleChargeRequestCancel = () => {
    setIsChargeModalVisible(false);
    setChargeRequestType(null);
    setChargeRequestQuantity(null);
  };
  useEffect(() => {
    const fetchUretimKartlari = async () => {
      try {
        const uretimKartlariCollection = collection(db, 'onaylananUretimKartlari');
        const uretimKartlariSnapshot = await getDocs(uretimKartlariCollection);
        const uretimKartlariList = uretimKartlariSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const today = dayjs();
        const tenDaysAgo = today.subtract(30, 'day');

        const groupedByDate = {};
        uretimKartlariList.forEach(kart => {
          const date = dayjs(kart.approval_date, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD');
          if (dayjs(date).isAfter(tenDaysAgo)) {
            if (!groupedByDate[date]) {
              groupedByDate[date] = [];
            }
            groupedByDate[date].push(kart);
          }
        });

        setUretimKartlari(groupedByDate);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUretimKartlari();
  }, [uretimKartlari]);
  const handleChargeRequestSubmit = async () => {
    if (!chargeRequestType || !chargeRequestQuantity) {
      message.error('Lütfen şarj isteği türünü ve miktarını girin!');
      return;
    }
  
    try {
      const requestTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
  
      await addDoc(collection(db, 'sarjIstekleri'), {
        requestType: chargeRequestType,
        quantity: chargeRequestQuantity,
        description: chargeRequestDescription || null, // Optional description
        kartId: selectedKart.id,
        kartNumber: selectedKart.card_number, // Store the production card number
        requestTime,
      });
  
      message.success('Şarj isteği başarıyla oluşturuldu!');
      setIsChargeModalVisible(false); // Close the modal
      setChargeRequestType(null);
      setChargeRequestQuantity(null);
      setChargeRequestDescription(''); // Clear description
    } catch (error) {
      console.error('Şarj isteği oluşturulurken hata oluştu:', error);
      message.error('Şarj isteği oluşturulamadı!');
    }
  };
  
  // Updates total quantity when produced or waste quantity changes
  // Updates total quantity when produced or waste quantity changes
// Updates total quantity when produced or waste quantity changes
useEffect(() => {
  const totalProduction = parseFloat(selectedKart?.total_production_amount_pcs || 0);
  const produced = parseFloat(producedQuantity || 0);
  const unwrappedWeight = parseFloat(unwrapped_weight || 0); // Add unwrapped weight

  if (produced && totalProduction) {
    // Add unwrapped weight to the produced quantity
    const totalProducedWithUnwrapped = produced + unwrappedWeight;

    // Calculate percentage
    const percentage = (totalProducedWithUnwrapped / totalProduction) * 100;
    setFillPercentage(percentage);

    // Set overfill state
    setIsOverfilled(totalProducedWithUnwrapped > totalProduction);
  }

  setTotalQuantity(produced);
}, [producedQuantity, selectedKart,unwrapped_weight]);


  

  // Fetch initial batch counter and check if production has started from Firebase when a card is selected
  useEffect(() => {
    if (selectedKart) {
      const fetchInitialCounter = async () => {
        const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
        const kartSnapshot = await getDoc(kartRef);
        if (kartSnapshot.exists()) {
          const data = kartSnapshot.data();
          const savedCounter = data.batchCounter || 0;
          const savedProductionStart = data.productionStartTime || null;
          setInitialCounter(savedCounter);
          setBatchCounter(savedCounter);
          setIsProductionStarted(!!savedProductionStart); // Eğer productionStartTime varsa, üretim başlamıştır.
          setProductionStartTime(savedProductionStart);
        }
      };
      fetchInitialCounter();
    }
  }, [selectedKart]); // Sadece selectedKart değiştiğinde çalıştırılacak.
  

  // Save the updated counter to Firebase
  const updateBatchCounter = async (newCounter, currentTime) => {
    setBatchCounter(newCounter);
    if (selectedKart) {
      const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
      await updateDoc(kartRef, {
        batchCounter: newCounter,
        lastBatchUpdateTime: currentTime, // Store the time when the batch was updated
      });
    }
  };

  // Start production
  const startProduction = async () => {
    if (!selectedKart) {
      message.error('Lütfen bir kart seçiniz.');
      return;
    }

    const startTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    setProductionStartTime(startTime);
    setIsProductionStarted(true);

    // Update the production start time in Firebase
    const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
    await updateDoc(kartRef, { productionStartTime: startTime,batchCounter:selectedKart.totalCells });

    message.success('Üretim başlatıldı!');
  };

  // Handle finishing production
  // Handle finishing production
  // Handle finishing production
  const handleFinishProduction = async () => {
    try {
      const lowerBound = 99.99;
      const upperBound = 100.01;
  
      // Check if the fill percentage is within ±0.01%
      if (fillPercentage < lowerBound || fillPercentage > upperBound) {
        const result = await Swal.fire({
          title: 'Dolum yüzdesi geçerli aralıkta değil!',
          text: `Dolum yüzdesi ${lowerBound}% ile ${upperBound}% arasında olmalıdır. Devam etmek istiyor musunuz? Açıklama girmeniz gerekiyor.`,
          input: 'textarea',
          inputPlaceholder: 'Lütfen açıklamanızı girin...',
          showCancelButton: true,
          confirmButtonText: 'Evet, devam et!',
          cancelButtonText: 'Hayır, iptal et!',
          inputValidator: (value) => {
            if (!value) {
              return 'Açıklama girmelisiniz!';
            }
          },
        });
  
        // If the user cancels the action, return early
        if (!result.isConfirmed) return;
  
        // Get the explanation provided by the user
        const explanation = result.value;
  
        // Add a new document to the 'üretimiBitenKartlar' collection
        await addDoc(collection(db, 'üretimiBitenKartlar'), {
          ...selectedKart,
          production_end_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          totalQuantity,
          kalanSarjAdedi,
          unwrapped_weight,
          rawMaterialsUsed: selectedKart.rawMaterials.map((material) => ({
            ...material,
            totalUsedAmount: material.amount * selectedKart.totalCells,
          })),
          productionStartTime, // Save production start time
          explanation,
          // Add any additional fields you want to include here
          fillPercentage,
          batchCounter,
          producedQuantity,
          palletCount,
          productsPerPallet,
          remainingStock,
          wasteQuantity,
          createdAt: new Date(), // Optionally add a timestamp
        });
      }
  
      const totalBatches = selectedKart.batchRows * selectedKart.batchColumns;
  
      // Batch control
      if (batchCounter < totalBatches) {
        Swal.fire('Eksik Batch Seçimi!', 'Üretimi bitirmek için tüm batchlerin seçili olduğundan emin olun.', 'error');
        return;
      }
  
      // Input validation
      if (!producedQuantity || !palletCount || !productsPerPallet || !remainingStock || !wasteQuantity) {
        Swal.fire('Eksik Bilgi!', 'Lütfen tüm gerekli alanları doldurun.', 'error');
        return;
      }
  
      // Update raw material stocks in Firebase
      await updateRawMaterialStocks();
  
      // Update finished product stocks
      await updateFinishedProductStock();
  
      // Save the finished production to Firebase
      await saveFinishedProduction();
  
      // Remove the card from 'onaylananUretimKartlari' collection
      await removeSelectedKart();
  
      Swal.fire('Üretim Tamamlandı!', 'Kart başarıyla üretimi biten kartlar arasına kaydedildi ve stok güncellendi.', 'success');
  
      // Reset state variables
      resetState();
    } catch (error) {
      console.error('An error occurred during production:', error);
      Swal.fire('Hata!', error.message, 'error');
    }
  };

// Function to update raw material stocks in Firebase
const updateRawMaterialStocks = async () => {
  for (const material of selectedKart.rawMaterials) {
    const stockQuery = query(collection(db, 'stocks'), where('StockName', '==', material.materialName));
    const stockSnapshot = await getDocs(stockQuery);

    if (!stockSnapshot.empty) {
      const stockDoc = stockSnapshot.docs[0];
      const stockData = stockDoc.data();
      const updatedUnitAmount = (parseFloat(stockData.UnitAmount) || 0) - parseFloat(material.amount * selectedKart.totalCells);

      if (updatedUnitAmount < 0) {
        throw new Error(`Yetersiz stok miktarı: ${material.materialName}`);
      }

      // Update the stock in Firebase
      const stockRef = doc(db, 'stocks', stockDoc.id);
      await updateDoc(stockRef, { UnitAmount: updatedUnitAmount });
    } else {
      throw new Error(`Stok bulunamadı: ${material.materialName}`);
    }
  }
};

// Function to update the finished product stock
const updateFinishedProductStock = async () => {
  const mainStockQuery = query(collection(db, 'stocks'), where('StockName', '==', selectedKart.product_name));
  const mainStockSnapshot = await getDocs(mainStockQuery);

  if (!mainStockSnapshot.empty) {
    const mainStockDoc = mainStockSnapshot.docs[0];
    const mainStockData = mainStockDoc.data();
    const updatedMainUnitAmount = (parseFloat(mainStockData.UnitAmount) || 0) + parseFloat(producedQuantity) + parseFloat(remainingStock);

    // Update the finished product stock
    const mainStockRef = doc(db, 'stocks', mainStockDoc.id);
    await updateDoc(mainStockRef, { UnitAmount: updatedMainUnitAmount });
  } else {
    throw new Error(`Ana stok bulunamadı: ${selectedKart.product_name}`);
  }
};

// Function to save the finished production to Firebase
const saveFinishedProduction = async () => {
  const finishedProductionRef = collection(db, 'üretimiBitenKartlar');
  await addDoc(finishedProductionRef, {
    ...selectedKart,
    production_end_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    producedQuantity,
    palletCount,
    productsPerPallet,
    remainingStock,
    wasteQuantity,
    totalQuantity,
    kalanSarjAdedi,
    unwrapped_weight,
    rawMaterialsUsed: selectedKart.rawMaterials.map((material) => ({
      ...material,
      totalUsedAmount: material.amount * selectedKart.totalCells,
    })),
    productionStartTime, // Save production start time
  });
};

// Function to remove the selected card from 'onaylananUretimKartlari' collection
const removeSelectedKart = async () => {
  const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
  await deleteDoc(kartRef);
};

// Function to reset state variables after production completion
const resetState = () => {
  setSelectedKart(null);
  setProducedQuantity(0);
  setWasteQuantity(0);
  setBatchCounter(0);
  setPalletCount(0);
  setProductsPerPallet(0);
  setRemainingStock(0);
};

  
  
  


  const rawMaterialsColumns = [
    {
      title: 'Malzeme Adı',
      dataIndex: 'materialName',
      key: 'materialName',
      align: 'right',
    },
    {
      title: 'Tip',
      dataIndex: 'type',
      key: 'type',
      align: 'right',
    },
    {
      title: 'Miktar (KG)',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
    },
    {
      title: 'Toplam Kullanılacak Miktar (KG)',
      render: (_, material) => (material.amount * selectedKart.totalCells).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      key: 'totalUsedAmount',
      align: 'right',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      align: 'right',
    },
  ];

  return (
    <div className='page-wrapper mt-5' style={{ backgroundColor: '#f8f9fa', padding: '40px', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)' }}>
  {loading ? (
    <div style={{ textAlign: 'center', padding: '40px' }}>
      <Spin size="large" />
    </div>
  ) : (
    <Collapse accordion style={{ marginBottom: '20px', borderRadius: '8px', overflow: 'hidden' }}>
      {Object.keys(uretimKartlari).length > 0 ? (
        Object.keys(uretimKartlari).map((date) => (
          <Panel header={<strong style={{ fontSize: '16px' }}>{date}</strong>} key={date}>
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={uretimKartlari[date]}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    title={<Text strong style={{ fontSize: '14px' }}>{item.card_number}</Text>}
                    onClick={() => setSelectedKart(item)}
                    hoverable
                    style={{
                      border: item.id === selectedKart?.id ? '2px solid #1890ff' : '1px solid #e8e8e8',
                      backgroundColor: item.id === selectedKart?.id ? '#e6f7ff' : '#fff',
                      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08)',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                    }}
                  >
                    <Text style={{ fontSize: '14px', color: '#595959' }}>{item.product_name}</Text>
                  </Card>
                </List.Item>
              )}
            />
          </Panel>
        ))
      ) : (
        <p style={{ textAlign: 'center', color: '#888', fontStyle: 'italic' }}>Son 10 gün içinde üretim kartı bulunmamaktadır.</p>
      )}
    </Collapse>
  )}

  {selectedKart && (
    <div className="batch-section" style={{ marginTop: '40px', padding: '30px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.08)' }}>
      <Title level={4} style={{ marginBottom: '20px', color: '#003366' }}>Seçili Kart: {selectedKart.card_number}</Title>
      <div style={{ marginBottom: '20px', fontSize: '15px', color: '#666' }}>
        <p><strong>Ürün Adı:</strong> {selectedKart.product_name}</p>
        <p><strong>Üretim Durumu:</strong> {selectedKart.production_status}</p>
        <p><strong>Toplam Üretim Miktarı:</strong> {selectedKart.total_production_amount} KG</p>
        <p><strong>Toplam Üretim Miktarı Adeti:</strong> {selectedKart.total_production_amount_pcs} ADET</p>
        <p><strong>Ambalaj Ağırlığı:</strong> {selectedKart.packaging_weight} KG</p>
        <p><strong>Not:</strong> {selectedKart.notes}</p>
      </div>

      <h3 style={{ marginBottom: '15px', color: '#003366' }}>Batch Seçimi</h3>
      <BatchSelectionTable
        key={`${selectedKart.id}-${initialCounter}`}
        totalCells={selectedKart.totalCells}
        initialCounter={batchCounter}
        onCounterUpdate={updateBatchCounter}
        isProductionStarted={isProductionStarted}
        selectedKartId={selectedKart.id} // Kart ID'sini BatchSelectionTable bileşenine geçir
      />

      {!productionStartTime && (
        <Button type="primary" onClick={startProduction} style={{ marginTop: '30px', width: '100%', height: '50px', fontSize: '16px' }}>
          Üretime Başla
        </Button>
      )}

      <h3 style={{ marginTop: '40px', marginBottom: '15px', color: '#003366' }}>Hammaddeler</h3>
      <Table
        columns={rawMaterialsColumns}
        dataSource={selectedKart.rawMaterials}
        pagination={false}
        bordered
        rowKey="materialName"
        style={{ marginBottom: '30px' }}
      />

<div className='form-container' style={{ maxHeight: `${windowHeight - 150}px`, overflowY: 'auto', padding: '20px' }}>
  <Form layout="vertical" onFinish={handleFinishProduction}>
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item label="Üretimden Çıkan Ürün Adedi" rules={[{ required: true, message: 'Lütfen üretimden çıkan ürün adedini girin!' }]}>
          <Input value={producedQuantity} onChange={(e) => setProducedQuantity(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label="Çıkan Palet Adedi" rules={[{ required: true, message: 'Lütfen çıkan palet adedini girin!' }]}>
          <Input value={palletCount} onChange={(e) => setPalletCount(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label="1 Paletteki Ürün Adedi" rules={[{ required: true, message: 'Lütfen 1 paletteki ürün adedini girin!' }]}>
          <Input value={productsPerPallet} onChange={(e) => setProductsPerPallet(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={8}>
        <Form.Item label="Stoğa Kalan Ürün Adedi" rules={[{ required: true, message: 'Lütfen stoğa kalan ürün adedini girin!' }]}>
          <Input value={remainingStock} onChange={(e) => setRemainingStock(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label="Ambalajdan Çıkan Fire (kg)" rules={[{ required: true, message: 'Lütfen ambalajdan çıkan fire adedini girin!' }]}>
          <Input value={wasteQuantity} onChange={(e) => setWasteQuantity(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label="Kalan Şarj Adedi" rules={[{ required: true, message: 'Lütfen kalan şarj adedini girin!' }]}>
          <Input value={kalanSarjAdedi} onChange={(e) => setKalanSarjAdedi(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
    <Col span={8}>
        <Form.Item label="Ambalajlanmamış Bitmiş Ürün (kg)" rules={[{ required: true, message: 'Lütfen kalan şarj adedini girin!' }]}>
          <Input value={unwrapped_weight} onChange={(e) => setUnwrapped_weight(e.target.value)} type="number" size="large" required />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Toplam Adet">
          <Input value={totalQuantity} disabled size="large" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={8}>
      
        <div style={{ marginTop: '30px' }}>
  <h4 style={{ color: isOverfilled ? 'red' : '#003366' }}>
    {isOverfilled ? 'Fazla Üretim Yüzdesi:' : 'Üretim Yüzdesi:'} {fillPercentage.toFixed(2)}%
  </h4>
  <Progress
    percent={fillPercentage.toFixed(2)}
    status={isOverfilled ? 'exception' : 'active'}
    strokeColor={isOverfilled ? 'red' : '#1890ff'}
  />
</div>
      </Col>
    </Row>
    <Button type="primary" onClick={showChargeRequestModal} style={{ marginTop: '20px', width: '100%', height: '50px', fontSize: '16px' }}>
      Şarj İsteği Oluştur
    </Button>
    <Modal
  title="Şarj İsteği Oluştur"
  visible={isChargeModalVisible}
  onCancel={handleChargeRequestCancel}
  onOk={handleChargeRequestSubmit}
  okText="İsteği Gönder"
  cancelText="İptal"
>
  <Form layout="vertical">
    <Form.Item label="Şarj İsteği Türü" required>
      <Select
        value={chargeRequestType}
        onChange={setChargeRequestType}
        placeholder="Şarj isteği türü"
        size="large"
      >
        <Option value="increase">Şarj Yükselt</Option>
        <Option value="decrease">Şarj Azalt</Option>
      </Select>
    </Form.Item>

    <Form.Item label="Şarj Miktarı" required>
      <Input
        value={chargeRequestQuantity}
        onChange={(e) => setChargeRequestQuantity(e.target.value)}
        placeholder="Şarj Miktarı"
        type="number"
        size="large"
      />
    </Form.Item>

    {/* Optional Description Field */}
    <Form.Item label="Açıklama (Opsiyonel)">
      <Input.TextArea
        value={chargeRequestDescription}
        onChange={(e) => setChargeRequestDescription(e.target.value)}
        placeholder="İsteğe bağlı açıklama ekleyin"
        size="large"
        rows={3}
      />
    </Form.Item>
  </Form>
</Modal>
    <Button type="primary" htmlType="submit" style={{ marginTop: '20px', width: '100%', height: '50px', fontSize: '16px' }} disabled={!isProductionStarted}>
      Üretimi Bitir
    </Button>
  </Form>
</div>

    </div>
  )}
</div>
  
  );
}

export default TabletUretimKarti;
