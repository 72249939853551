import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { Table, Button, Modal as AntModal, message, Select, Input } from 'antd';
import { Modal } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { db } from '../../../environment';
import PrintComponent from './printcomponent'; // Adjust the import path as needed
import 'bootstrap/dist/css/bootstrap.min.css';

const { Option } = Select;
const { TextArea } = Input;

const ApprovedOrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [note, setNote] = useState('');
  const [dil,setDil]=useState("tr");
  const componentRef = useRef();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'approvedOrders'));
      const ordersData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setOrders(ordersData);
      setLoading(false);
    };

    fetchOrders();
  }, []);

  const handleShowPdf = (order) => {
    setSelectedOrder(order);
    setDil(order.language)
    setShowModal(true);
  };

  const handleDeliverOrder = (order) => {
    setSelectedOrder(order);
    setShowConfirmModal(true);
  };

  const confirmDelivery = async () => {
    if (!deliveryStatus) {
      message.error('Lütfen bir teslimat durumu seçin.');
      return;
    }
    if (['sorunlu', 'beklemede'].includes(deliveryStatus) && !note) {
      message.error('Lütfen bir not girin.');
      return;
    }
    try {
      setLoading(true);
      const orderDocRef = doc(db, 'approvedOrders', selectedOrder.id);
      const updatedOrder = { ...selectedOrder, deliveryStatus, note };
      await addDoc(collection(db, 'deliveredOrders'), updatedOrder);
      await deleteDoc(orderDocRef);
      setOrders(orders.filter(order => order.id !== selectedOrder.id));
      message.success('Seçiminiz Kaydedildi!');
    } catch (error) {
      message.error('Sipariş Teslim edilemedi hata!');
      console.error('Error delivering order:', error);
    } finally {
      setLoading(false);
      setShowConfirmModal(false);
      setSelectedOrder(null);
      setDeliveryStatus(null);
      setNote('');
    }
  };

  const getTitle = () => {
    if (selectedOrder?.belgeNo.startsWith('STS')) {
      return 'Proforma Fatura';
    } else if (selectedOrder?.belgeNo.startsWith('ALS')) {
      return 'Alış Sipariş Formu';
    } else if (selectedOrder?.belgeNo.startsWith('PRF')) {
      return 'Proforma Fatura';
    } else {
      return '';
    }
  };

  const columns = [
    { title: 'Belge No', dataIndex: 'belgeNo', key: 'belgeNo' },
    { title: 'Cari Kodu', dataIndex: 'cariKodu', key: 'cariKodu' },
    { title: 'Cari Adı', dataIndex: 'cariAdi', key: 'cariAdi' },
    { title: 'Sipariş Tarihi', dataIndex: 'siparisTarihi', key: 'siparisTarihi', render: date => date?.toDate().toLocaleDateString() },
    {
      title: ' ',
      key: 'action',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => handleShowPdf(record)} style={{ marginRight: '10px' }}>
            PDF Göster
          </Button>
          <Button type="danger" onClick={() => handleDeliverOrder(record)}>
            Teslim Et
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className='page-wrapper mt-5'>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="id"
      />

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detaylar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <ReactToPrint
              trigger={() => <Button variant="primary">Yazdır</Button>}
              content={() => componentRef.current}
            />
          )}
          <div style={{ display: 'none' }}>
            <PrintComponent
              ref={componentRef}
              title={getTitle()}
             language={dil}
              customerInfo={{
                cariKodu: selectedOrder?.cariKodu,
                cariAdi: selectedOrder?.cariAdi,
                adres: selectedOrder?.adres,
                vergiDairesi: selectedOrder?.vergiDairesi,
                vergiNumarasi: selectedOrder?.vergiNumarasi,
                email: selectedOrder?.email,
                telefon: selectedOrder?.telefon,
                ilgiliKisiAdi: selectedOrder?.ilgiliKisiAdi,
                ilgiliKisiTelefon: selectedOrder?.ilgiliKisiTelefon,
                ilgiliKisiEmail: selectedOrder?.ilgiliKisiEmail,
              }}
              productInfo={selectedOrder?.urunler || []}
              orderInfo={{
                siparisTarihi: selectedOrder?.siparisTarihi?.toDate().toLocaleDateString(),
                teslimatTarihi: selectedOrder?.teslimatTarihi?.toDate().toLocaleDateString(),
                odemeSekli: selectedOrder?.odemeSekli,
                faturaTipi: selectedOrder?.faturaTipi,
                faturaAdresi: selectedOrder?.faturaAdresi,
                faturaAciklamalari: selectedOrder?.faturaAciklamalari,
              }}
              deliveryInfo={{
                belgeNo: selectedOrder?.belgeNo,
                belgeTarihi: selectedOrder?.belgeTarihi?.toDate().toLocaleDateString(),
                sevkTarihi: selectedOrder?.sevkTarihi?.toDate().toLocaleDateString(),
                sevkYontemi: selectedOrder?.sevkYontemi,
                istenenSevkAdresi: selectedOrder?.istenenSevkAdresi,
              }}
              otherInfo={{
                faturaTarihi: selectedOrder?.faturaTarihi?.toDate().toLocaleDateString(),
                musteriSiparisNumarasi: selectedOrder?.musteriSiparisNumarasi,
                ozelTalimatlar: selectedOrder?.ozelTalimatlar,
                siparisNotlari: selectedOrder?.siparisNotlari,
              }}
              salesRepInfo={{
                formuHazirlayanAdi: selectedOrder?.formuHazirlayanAdi,
                formuHazirlayanTelefon: selectedOrder?.formuHazirlayanTelefon,
                formuHazirlayanEmail: selectedOrder?.formuHazirlayanEmail,
              }}
              loading={loading}
            />
          </div>
        </Modal.Body>
      </Modal>

      <AntModal
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onOk={confirmDelivery}
        title="Teslimatı Onayla"
        okText="Evet"
        cancelText="Hayır"
      >
        <p>Bu ürünü teslim ettiğinize emin misiniz? Bu işlem geri alınamayacaktır.</p>
        <Select
          placeholder="Teslimat Durumunu Seçin"
          onChange={setDeliveryStatus}
          style={{ width: '100%', marginBottom: '16px' }}
        >
          <Option value="basarili">Başarılı</Option>
          <Option value="sorunlu">Sorunlu</Option>
          <Option value="beklemede">Beklemede</Option>
        </Select>
        {['sorunlu', 'beklemede'].includes(deliveryStatus) && (
          <TextArea
            placeholder="Not Girin"
            onChange={(e) => setNote(e.target.value)}
            style={{ width: '100%' }}
            rows={4}
          />
        )}
      </AntModal>
    </div>
  );
};

export default ApprovedOrdersTable;
