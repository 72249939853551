import React from "react";

import ApprovedOrdersTable from "./utils/approvedorderstable";

const OnaylananSiparisler = () => {


  return (
    <div className="page-wrapper">
      <ApprovedOrdersTable />
    </div>
  );
};

export default OnaylananSiparisler;
