import React, { useEffect, useState } from 'react';
import { Table, Form, Input, Button, Row, Col, Tabs, Select, Modal } from 'antd';
import { collection, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../../environment';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import '../irsaliyeler/irsaliye.css';

const { TabPane } = Tabs;
const { Option } = Select;

const TumIrsaliyeler = () => {
  const [data, setData] = useState([]);
  const [girisData, setGirisData] = useState([]);
  const [allCaris, setAllCaris] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('giris');
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newStock, setNewStock] = useState({ stokAdi: '', birim: '', miktar: '' });

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'cikisIrsaliyeler'));
      const dataList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setData(dataList);

      const girisSnapshot = await getDocs(collection(db, 'girisIrsaliyeler'));
      const girisList = girisSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGirisData(girisList);

      const carisSnapshot = await getDocs(collection(db, 'allCaris'));
      const carisList = carisSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllCaris(carisList);

      const stocksSnapshot = await getDocs(collection(db, 'stocks'));
      const stocksList = stocksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStocks(stocksList);
    };
    fetchData();
  }, []);

  const onRowClick = (record) => {
    setSelectedRecord(record);
    form.setFieldsValue({
      ...record,
      evrakTarihi: record.evrakTarihi ? dayjs(record.evrakTarihi.toDate()) : null,
      stocks: record.stocks || [],
    });
    setSelectedStocks(record.stocks || []);
    setTableData(record.tableData || []); // Set tableData for editing
  };

  const handleMuhHesapAdiChange = (value) => {
    const cari = allCaris.find(c => c.MuhHesapAdi === value);
    form.setFieldsValue({
      cari: {
        KartKodu: cari ? cari.KartKodu : '',
        MuhHesapKodu: cari ? cari.MuhHesapKodu : '',
        MuhHesapAdi: value,
      },
    });
  };



  const onFinish = async (values) => {
    if (selectedRecord) {
      const collectionName = activeTab === 'giris' ? 'girisIrsaliyeler' : 'cikisIrsaliyeler';
      const docRef = doc(db, collectionName, selectedRecord.id);
      try {
        await updateDoc(docRef, { 
          ...values, 
          evrakTarihi: values.evrakTarihi ? values.evrakTarihi.toDate() : null,
          stocks: selectedStocks,
          tableData // Include the updated tableData
        });

        const updatedData = activeTab === 'giris' ? girisData : data;
        const newData = updatedData.map(item => 
          item.id === selectedRecord.id ? { ...item, ...values, stocks: selectedStocks, tableData } : item
        );

        activeTab === 'giris' ? setGirisData(newData) : setData(newData);
        setSelectedRecord(null);
        form.resetFields();
        setSelectedStocks([]);
        setTableData([]); // Reset tableData

        Swal.fire({
          title: 'Başarılı!',
          text: 'Kayıt güncellendi.',
          icon: 'success',
          confirmButtonText: 'Tamam',
        });
      } catch (error) {
        Swal.fire({
          title: 'Hata!',
          text: 'Kayıt güncellenirken bir hata oluştu.',
          icon: 'error',
          confirmButtonText: 'Tamam',
        });
      }
    }
  };

  const handleAddStock = async () => {
    try {
      const newStockRef = await addDoc(collection(db, 'stocks'), newStock);
      setStocks([...stocks, { id: newStockRef.id, ...newStock }]);
      setNewStock({ stokAdi: '', birim: '', miktar: '' });
      setIsModalVisible(false);
      Swal.fire({
        title: 'Başarılı!',
        text: 'Yeni stok eklendi.',
        icon: 'success',
        confirmButtonText: 'Tamam',
      });
    } catch (error) {
      Swal.fire({
        title: 'Hata!',
        text: 'Yeni stok eklenirken bir hata oluştu.',
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  };

  return (
    <div className='page-wrapper mt-5'>
      <h2>İrsaliyeler</h2>
      <Tabs defaultActiveKey="giris" onChange={onTabChange} tabBarStyle={{ background: '#f1f1f1' }}>
        <TabPane tab="Giriş İrsaliyesi" key="giris">
          <Table
            dataSource={girisData}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
            columns={[
              { title: 'Birim', dataIndex: 'tableData', render: (text) => Array.isArray(text) ? text[0]?.birim : 'N/A', key: 'birim' },
              { title: 'Miktar', dataIndex: 'tableData', render: (text) => Array.isArray(text) ? text[0]?.miktar : 'N/A', key: 'miktar' },
              { title: 'Stok Adı', dataIndex: 'tableData', render: (text) => Array.isArray(text) ? text[0]?.stokAdi : 'N/A', key: 'stokAdi' },
            ]}
          />
        </TabPane>
        <TabPane tab="Çıkış İrsaliyesi" key="cikis">
          <Table
            dataSource={data}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
            columns={[
              { title: 'Birim', dataIndex: 'tableData', render: (text) => Array.isArray(text) ? text[0]?.birim : 'N/A', key: 'birim' },
              { title: 'Miktar', dataIndex: 'tableData', render: (text) => Array.isArray(text) ? text[0]?.miktar : 'N/A', key: 'miktar' },
              { title: 'Stok Adı', dataIndex: 'tableData', render: (text) => Array.isArray(text) ? text[0]?.stokAdi : 'N/A', key: 'stokAdi' },
            ]}
          />
          <Form.Item name="stocks" label="Stoklar" rules={[{ required: true }]}>
            <Select mode="multiple" onChange={(value) => setSelectedStocks(value)}>
              {stocks.map(stock => (
                <Option key={stock.id} value={stock.stokAdi}>{stock.stokAdi}</Option>
              ))}
            </Select>
          </Form.Item>
        </TabPane>
      </Tabs>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <h3>Araç Bilgileri</h3>
            <Form.Item name={['aracBilgileri', 'aracMarkasi']} label="Marka" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['aracBilgileri', 'aracModeli']} label="Model" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['aracBilgileri', 'aracKapasitesi']} label="Kapasite" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['aracBilgileri', 'plakaNo']} label="Plaka No" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>İrsaliye Bilgileri</h3>
            <Form.Item name="evrakNo" label="Evrak No" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="evrakTarihi" label="Evrak Tarihi" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="muhHesap" label="Muhasebe Hesabı" rules={[{ required: true }]}>
              <Select onChange={handleMuhHesapAdiChange}>
                {allCaris.map(cari => (
                  <Option key={cari.id} value={cari.MuhHesapAdi}>{cari.MuhHesapAdi}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" style={{ marginBottom: '20px' }}>
          Güncelle
        </Button>
      </Form>

      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: '20px' }}>
        Yeni Stok Ekle
      </Button>

      <Modal
        title="Yeni Stok Ekle"
        visible={isModalVisible}
        onOk={handleAddStock}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Stok Adı">
            <Input value={newStock.stokAdi} onChange={(e) => setNewStock({ ...newStock, stokAdi: e.target.value })} />
          </Form.Item>
          <Form.Item label="Birim">
            <Input value={newStock.birim} onChange={(e) => setNewStock({ ...newStock, birim: e.target.value })} />
          </Form.Item>
          <Form.Item label="Miktar">
            <Input value={newStock.miktar} onChange={(e) => setNewStock({ ...newStock, miktar: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TumIrsaliyeler;
