export const rolesandpermission = [
	{
		"id": "1",
		"rolename": "Admin",
		"createdon": "25 May 2023",
		
	},
	{
		"id": "1",
		"rolename": "Customer",
		"createdon": "30 May 2023",
		
	},
	{
		"id": "1",
		"rolename": "Shop Owner",
		"createdon": "20 Apr 2023",
		
	}
]