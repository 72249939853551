// src/MessageComponent.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { collection, addDoc, query, orderBy, onSnapshot } from "firebase/firestore";
import { List, Input, Button, Form } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { db } from '../../environment';

const MessageComponent = ({ userId,username }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    const q = query(collection(db, "messages"), orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesData = [];
      querySnapshot.forEach((doc) => {
        messagesData.push({ id: doc.id, ...doc.data() });
      });
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, []);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      await addDoc(collection(db, "messages"), {
        text: newMessage,
        userId: userId,
        username:username,
        timestamp: new Date()
      });
      setNewMessage('');
    }
  };

  const handleEmojiSelect = (event) => {
    setNewMessage(prevMessage => prevMessage + event.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={message => (
          <List.Item>
            <List.Item.Meta
              title={message.username}
              description={message.text}
            />
          </List.Item>
        )}
        style={{ flex: 1, overflowY: 'auto' }}
      />
      <Form layout="inline" onFinish={handleSendMessage} style={{ marginTop: '10px' }}>
        <Form.Item style={{ flex: 1 }}>
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Mesaj yaz..."
            suffix={
              <Button type="link" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                😊
              </Button>
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Gönder
          </Button>
        </Form.Item>
      </Form>
      {showEmojiPicker && (
        <div style={{ position: 'absolute', bottom: '60px', right: '20px' }}>
          <EmojiPicker onEmojiClick={handleEmojiSelect} />
        </div>
      )}
    </div>
  );
};

MessageComponent.propTypes = {
  userId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,

};

export default MessageComponent;
