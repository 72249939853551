import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Row, Col, Divider, Modal, Card, Table, Select, Checkbox, message } from 'antd';
import { collection, addDoc, getDocs, query, orderBy, limit, where } from 'firebase/firestore';
import { PlusOutlined } from '@ant-design/icons';
import { db } from '../../environment';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import './utils/printablecomponent.css'; // Import the CSS file for styling
import { Delete, Edit } from 'react-feather';

const { TextArea } = Input;
const { Option } = Select;

const PrintableForm = React.forwardRef((props, ref) => {
  const { formValues, selectedBatch, } = props;


  return (
    <div ref={ref} className="printable-form">
      <h1 style={{ color: 'darkred', textAlign: 'center' }} className="form-title">Siparişten Üretim Formu Oluştur</h1>
      <section className="form-section">
        <h2 style={{ color: 'darkred', textAlign: 'center' }} className="section-title">Üretim Detayları</h2>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={6}><p><strong>Başlama Tarihi:</strong> {formValues.start_date}</p></Col>
          <Col span={6}><p><strong>Bitiş Tarihi:</strong> {formValues.end_date}</p></Col>
          <Col span={6}><p><strong>Kart Numarası:</strong> {formValues.card_number}</p></Col>
          <Col span={6}><p><strong>Şarj Sayacı:</strong> {Object.keys(selectedBatch).join(', ')}</p></Col>
        </Row>
        
      </section>
      <section className="form-section">
        <h2 style={{ color: 'darkred', textAlign: 'center' }} className="section-title">Sipariş Seçimi</h2>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={6}><p><strong>Ürün Adı:</strong> {formValues.product_name}</p></Col>
          <Col span={6}><p><strong>Mevcut Sipariş Miktarı:</strong> {formValues.packaging_weight}</p></Col>
          <Col span={6}><p><strong>Mevcut Stok Miktarı:</strong> {formValues.stock_amount}</p></Col>
          <Col span={6}><p><strong>Üretilecek Miktar:</strong> {formValues.total_production_amount}</p></Col>
        </Row>
      </section>
      <section className="form-section">
        <h2 style={{ color: 'darkred', textAlign: 'center' }} className="section-title">Reçete Detayları</h2>
        {Array.isArray(formValues.rawMaterials) && formValues.rawMaterials.map((material, index) => (
          <div key={index} className="material-item">         
            <Row justify="center" gutter={[16, 16]}>
              <Col span={6}><p><strong>Adı:</strong> {material?.materialName}</p></Col>
              <Col span={6}><p><strong>Cinsi:</strong> {material?.type}</p></Col>
              <Col span={6}><p><strong>Miktar (kg):</strong> {material?.amount}</p></Col>
              <Col span={6}><p><strong>Açıklama:</strong> {material?.description}</p></Col>
            </Row>
          </div>
        ))}
      </section>
      <section className="form-section">
        <h2 style={{ color: 'darkred', textAlign: 'center' }} className="section-title">Üretim Kartı Detayları</h2>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={6}><p><strong>Üretim Kartını Oluşturan:</strong> {formValues.creator_name}</p></Col>
          <Col span={6}><p><strong>Üretim Yapan Personel:</strong> {formValues.production_staff_name}</p></Col>
          <Col span={6}><p><strong>Ek Bilgi:</strong> {formValues.additional_info}</p></Col>
        </Row>
      </section>
      <section className="form-section">
        <h2 style={{ color: 'darkred', textAlign: 'center' }} className="section-title">Notlar</h2>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={12}><p><strong>Notlar:</strong> {formValues.notes}</p></Col>
          <Col span={12}><p><strong>Notu Yazan-Ad Soyad:</strong> {formValues.note_writer}</p></Col>
        </Row>
      </section>
      <section className="form-section">
        <h2 style={{ color: 'darkred', textAlign: 'center' }} className="section-title">Hesaplanan Değerler</h2>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={24}>
            <p><strong>{formValues.calculatedAuxiliaryUnits?.map((unit, index) => (
              <span key={index}>{unit.from} ↠ {unit.amount} {unit.to} <br /></span>
            ))}</strong></p>
          </Col>
        </Row>
      </section>
    </div>
  );
});

PrintableForm.displayName = 'PrintableForm';

PrintableForm.propTypes = {
  formValues: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    card_number: PropTypes.string,
    batch_counter: PropTypes.string,
    product_name: PropTypes.string,
    packaging_weight: PropTypes.number,
    batch_total_kg: PropTypes.number,
    total_production_amount: PropTypes.number,
    rawMaterials: PropTypes.arrayOf(
      PropTypes.shape({
        materialName: PropTypes.string,
        type: PropTypes.string,
        amount: PropTypes.number,
        description: PropTypes.string,
      })
    ),
    creator_name: PropTypes.string,
    production_staff_name: PropTypes.string,
    additional_info: PropTypes.string,
    signature: PropTypes.string,
    notes: PropTypes.string,
    note_writer: PropTypes.string,
    calculatedAuxiliaryUnits: PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        amount: PropTypes.number,
      })
    ),
    stock_amount: PropTypes.number,
  }).isRequired,
  selectedBatch: PropTypes.object.isRequired,
  batchRows: PropTypes.number.isRequired,
  batchColumns: PropTypes.number.isRequired,
};

const ProductionForm = () => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    rawMaterials: [],
  });

  const [, setChargeAmount] = useState(0);
  const [batchModalVisible, setBatchModalVisible] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [batchRows, setBatchRows] = useState(1);
  const [batchColumns, setBatchColumns] = useState(10);
  const [batchTotalKg,setBatchTotalKg ] = useState(0);
  const [nextCardNumber, setNextCardNumber] = useState('SUK-00001');
  const [approvedOrders, setApprovedOrders] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [editingMaterialIndex, setEditingMaterialIndex] = useState(null);
  const [stocks, setStocks] = useState([]);

  const printRef = useRef();

  useEffect(() => {
    const totalProductionAmount = formValues.packaging_weight - formValues.stock_amount;
    setFormValues((prevValues) => ({
      ...prevValues,
      total_production_amount: totalProductionAmount,
    }));
    form.setFieldsValue({ total_production_amount: totalProductionAmount });
  }, [formValues.packaging_weight, formValues.stock_amount]);

  useEffect(() => {
    const fetchApprovedOrders = async () => {
      const querySnapshot = await getDocs(collection(db, 'approvedOrders'));
      const ordersList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          siparisTarihi: data.siparisTarihi ? data.siparisTarihi.toDate() : null, // Ensure siparisTarihi exists before calling toDate
        };
      });
      setApprovedOrders(ordersList);
    };
    

    const fetchStocks = async () => {
      const querySnapshot = await getDocs(collection(db, 'stocks'));
      const stocksList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStocks(stocksList);
    };

    fetchApprovedOrders();
    fetchNextCardNumber();
    fetchStocks();
  }, []);

  const fetchNextCardNumber = async () => {
    try {
      const q = query(collection(db, 'productionData'), orderBy('card_number', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const lastCardNumber = querySnapshot.docs[0].data().card_number;
        const newCardNumber = incrementCardNumber(lastCardNumber);
        setNextCardNumber(newCardNumber);
        form.setFieldsValue({ card_number: newCardNumber });
      } else {
        setNextCardNumber('SUK-00001');
        form.setFieldsValue({ card_number: 'SUK-00001' });
      }
    } catch (error) {
      console.error('Error fetching the next card number:', error);
      setNextCardNumber('SUK-00001');
      form.setFieldsValue({ card_number: 'SUK-00001' });
    }
  };

  const incrementCardNumber = (lastCardNumber) => {
    const numberPart = lastCardNumber ? lastCardNumber.split('-')[1] : '00000';
    const incrementedNumber = (parseInt(numberPart, 10) + 1).toString().padStart(5, '0');
    return `SUK-${incrementedNumber}`;
  };

  const handleOrderChange = (orderId) => {
    // Formu ve seçilen ürünleri sıfırla
    setSelectedProducts([]);
    form.setFieldsValue({ product_name: '' });
  
    // Onaylı siparişler arasından seçilen siparişi bulun
    const selectedOrder = approvedOrders.find(order => order.id === orderId);
    
    if (selectedOrder) {
      // Yeni ürünler listesini ayarlayın
      setSelectedProducts(selectedOrder.urunler);
      
      const product = selectedOrder.urunler[0];
      const packagingLabel = product?.birim ? `Mevcut Sipariş Miktarı (${product.birim})` : 'Mevcut Sipariş Miktarı';
      console.log(selectedOrder);
      
      form.setFieldsValue({
        packaging_weight: product?.miktar || 0,
        packaging_label: packagingLabel,
      });
    }
  };
  
  const handleProductChange = async (checkedValue) => {
    // Seçilen ürünler için form alanlarını sıfırlayın
    form.setFieldsValue({ product_name: '', rawMaterials: [], total_production_amount: 0 });
    setSelectedProduct(null);
  
    if (checkedValue) {
      const selectedProductName = checkedValue;
      setSelectedProduct(selectedProductName);

      try {
        const q = query(collection(db, "approvedOrders"), orderBy("cariAdi", "asc"));
        const snapshot = await getDocs(q);
        const approvedOrders = snapshot.docs.map(doc => doc.data());
  
        const updatedApprovedOrders = await Promise.all(approvedOrders.map(async (order) => {
          const updatedUrunler = await Promise.all(order.urunler.map(async (urun) => {
            if (urun.urunAdi === selectedProductName) {
              form.setFieldsValue({ product_name: selectedProductName});

              try {
                const productRecipeSnapshot = await getDocs(query(collection(db, "productRecipe"), where("productName", "==", selectedProductName)));
                const productRecipe = productRecipeSnapshot.docs.map(doc => doc.data());
  
                if (productRecipe.length > 0) {
                  return { ...urun, rawMaterials: productRecipe[0].rawMaterials, auxiliaryUnits: productRecipe[0].auxiliaryUnits };
                } else {
                  return urun;
                }
              } catch (error) {
                console.error('Error fetching product recipe:', error);
                return urun;
              }
            }
            return urun;
          }));
  
          return { ...order, urunler: updatedUrunler };
        }));
  
        setApprovedOrders(updatedApprovedOrders);
  
        const selectedOrder = updatedApprovedOrders.find(order => {
          return order.urunler.some(urun => urun.urunAdi === selectedProductName);
        });
  
        if (selectedOrder) {
          const selectedUrun = selectedOrder.urunler.find(urun => urun.urunAdi === selectedProductName);
          if (selectedUrun) {
            setSelectedProduct(selectedUrun);
            const rawMaterials = selectedUrun.rawMaterials || [];
            const totalBatchKg = rawMaterials.reduce((acc, material) => acc + (material?.amount || 0), 0);
  
            const auxiliaryUnitCalculations = (unit, amount, totalBatchKg) => {
              switch(unit) {
                case 'kg':
                  return { from: `${amount} kg `, to: 'adet', amount: totalBatchKg / amount };
                case 'adet':
                  return { from: `${amount} adet `, to: 'kg', amount: totalBatchKg * amount };
                case 'lt':
                  return { from: `${amount} lt `, to: 'kg', amount: totalBatchKg / amount };
                case 'ton':
                  return { from: `${amount} ton `, to: 'kg', amount: totalBatchKg * 1000 };
                case 'g':
                  return { from: `${amount} g `, to: 'kg', amount: totalBatchKg / 1000 };
                case 'paket':
                  return { from: `${amount} paket `, to: 'adet', amount: totalBatchKg / amount };
                default:
                  return { from: `${unit} ${amount}`, to: unit, amount: totalBatchKg };
              }
            };
  
            const calculatedAuxiliaryUnits = selectedUrun.auxiliaryUnits 
              ? selectedUrun.auxiliaryUnits.map(unit => auxiliaryUnitCalculations(unit.unit, unit.amount, totalBatchKg)) 
              : [];
  
            const batchCount = batchRows * batchColumns;
            const totalProductionAmount = totalBatchKg * batchCount;
  
            const newFormValues = {
              ...form.getFieldsValue(),
              rawMaterials: selectedUrun.rawMaterials,
              packaging_weight: selectedUrun.miktar,
              batch_total_kg: totalBatchKg,
              total_production_amount: totalProductionAmount,
              stock_amount: selectedUrun?.CurrentStock || 0,
              calculatedAuxiliaryUnits: calculatedAuxiliaryUnits
            };
            setFormValues(newFormValues);
            form.setFieldsValue(newFormValues);
          } else {
            setSelectedProduct(null);
            setFormValues({
              ...form.getFieldsValue(),
              rawMaterials: [],
              packaging_weight: 0,
              batch_total_kg: 0,
              total_production_amount: 0,
              calculatedAuxiliaryUnits: []
            });
            form.setFieldsValue({ rawMaterials: [], packaging_weight: 0, batch_total_kg: 0, total_production_amount: 0, calculatedAuxiliaryUnits: [] });
          }
        } else {
          setSelectedProduct(null);
          setFormValues({
            ...form.getFieldsValue(),
            rawMaterials: [],
            packaging_weight: 0,
            batch_total_kg: 0,
            total_production_amount: 0,
            calculatedAuxiliaryUnits: []
          });
          form.setFieldsValue({ rawMaterials: [], packaging_weight: 0, batch_total_kg: 0, total_production_amount: 0, calculatedAuxiliaryUnits: [] });
          message.error('Seçilen ürün için reçete bulunamadı.');
        }
      } catch (error) {
        console.error('Error fetching approved orders:', error);
        message.error('Onaylı siparişler alınırken bir hata oluştu.');
      }
    } else {
      setSelectedProduct(null);
      setFormValues({
        ...form.getFieldsValue(),
        rawMaterials: [],
        packaging_weight: 0,
        batch_total_kg: 0,
        total_production_amount: 0,
        calculatedAuxiliaryUnits: []
      });
      form.setFieldsValue({ rawMaterials: [], packaging_weight: 0, batch_total_kg: 0, total_production_amount: 0, calculatedAuxiliaryUnits: [] });
    }
  };
  

  const handleMaterialChange = () => {
    const rawMaterials = form.getFieldValue('rawMaterials') || [];
    const totalBatchKg = rawMaterials.reduce((acc, material) => acc + (material?.amount || 0), 0);
    setBatchTotalKg(totalBatchKg);
    form.setFieldsValue({ rawMaterials });
  };

  const handleEditMaterial = (key) => {
    setEditingMaterialIndex(key);
  };

  useEffect(() => {
    setChargeAmount(batchRows * batchColumns);
  }, [batchRows, batchColumns]);

  const onValuesChange = (changedValues, allValues) => {
    const rawMaterials = allValues.rawMaterials || [];
    const totalBatchKg = rawMaterials.reduce((acc, material) => acc + (material?.amount || 0), 0);
  setBatchTotalKg(totalBatchKg)
    const auxiliaryUnitCalculations = (unit, amount, totalBatchKg) => {
      switch(unit) {
        case 'kg':
          return { from: `${amount} kg `, to: 'adet', amount: totalBatchKg / amount };
        case 'adet':
          return { from: `${amount} adet `, to: 'kg', amount: totalBatchKg * amount };
        case 'lt':
          return { from: `${amount} lt `, to: 'kg', amount: totalBatchKg / amount };
        case 'ton':
          return { from: `${amount} ton `, to: 'kg', amount: totalBatchKg * 1000 };
        case 'g':
          return { from: `${amount} g `, to: 'kg', amount: totalBatchKg / 1000 };
        case 'paket':
          return { from: `${amount} paket `, to: 'adet', amount: totalBatchKg / amount };
        default:
          return { from: `${unit} ${amount}`, to: unit, amount: totalBatchKg };
      }
    };
  
    const calculatedAuxiliaryUnits = selectedProduct && selectedProduct.auxiliaryUnits 
      ? selectedProduct.auxiliaryUnits.map(unit => auxiliaryUnitCalculations(unit.unit, unit.amount, totalBatchKg)) 
      : [];
  
  
    const formattedValues = {
      ...allValues,
      batch_total_kg: totalBatchKg > 0 ? totalBatchKg : 0,
      start_date: allValues.start_date ? allValues.start_date.format('DD-MM-YYYY') : '',
      end_date: allValues.end_date ? allValues.end_date.format('DD-MM-YYYY') : '',
      calculatedAuxiliaryUnits: calculatedAuxiliaryUnits,
    };
  
    setFormValues(formattedValues);
    form.setFieldsValue({ 
      batch_total_kg: formattedValues.batch_total_kg,
    });
  };

  const handleBatchModalOk = () => {
    setBatchModalVisible(false);
    form.setFieldsValue({ batch_counter: Object.keys(selectedBatch).join(', '), charge_amount: batchColumns * batchRows });
  };

  const handleBatchModalCancel = () => {
    setBatchModalVisible(false);
  };

  const onCellClick = (row, column) => {
    const key = `R${row}C${column}`;
    const newSelectedBatch = { ...selectedBatch };
    if (newSelectedBatch[key]) {
      delete newSelectedBatch[key];
    } else {
      newSelectedBatch[key] = true;
    }
    setSelectedBatch(newSelectedBatch);
  };

  const generateBatchData = (rows, columns) => {
    const data = [];
    for (let row = 1; row <= rows; row++) {
      const rowData = { key: row };
      for (let column = 1; column <= columns; column++) {
        const key = `R${row}C${column}`;
        rowData[key] = selectedBatch[key] ? '✓' : '';
      }
      data.push(rowData);
    }
    return data;
  };

  const tableColumns = Array.from({ length: batchColumns }, (_, index) => ({
    title: `C${index + 1}`,
    dataIndex: `C${index + 1}`,
    key: `C${index + 1}`,
    render: (_, record) => {
      const key = `R${record.key}C${index + 1}`;
      const isSelected = !!selectedBatch[key];
      return (
        <div
          style={{
            padding: '2px',
            cursor: 'pointer',
            border: '1px solid #ddd',
            position: 'relative',
            backgroundColor: isSelected ? '#e6ffed' : 'white',
            color: 'black',
            minHeight: '25px',
            textAlign: 'center',
          }}
          onClick={() => onCellClick(record.key, index + 1)}
        >
          {record[key]}
        </div>
      );
    },
  }));

  const onFinish = async (values) => {
    try {
      // Prepare the data to be saved in Firestore
      const dataToSave = {
        ...values,
        start_date: values.start_date ? values.start_date.format('DD-MM-YYYY') : '',
        end_date: values.end_date ? values.end_date.format('DD-MM-YYYY') : '',
        card_number: nextCardNumber,
        rawMaterials: values.rawMaterials || [],
        calculatedAuxiliaryUnits: values.calculatedAuxiliaryUnits || [],
        total_production_amount: values.total_production_amount || 0,
        batch_total_kg: batchTotalKg || 0,
      };
  
      // Save the data to the 'productionData' collection
      await addDoc(collection(db, "productionData"), dataToSave);
  
      // Reset the form fields and fetch the next card number
      form.resetFields();
      fetchNextCardNumber();
  
      // Show a success message
      message.success("Veri başarıyla kaydedildi!");
    } catch (e) {
      console.error("Veri ekleme hatası: ", e);
      message.error("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };
  

  return (
    <div className='page-wrapper'>
      <Card style={{ padding: '50px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1200px', margin: '0 auto', borderRadius: '10px' }}>
        <h1 style={{ textAlign: 'center', color: 'darkred' }}>SİPARİŞTEN ÜRETİM</h1>
        <Form
          form={form}
          name="production_form"
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onValuesChange}
        >
          <Divider>Sipariş Seç</Divider>
          <Col span={24}>
            <Form.Item label="Sipariş Seç">
            <Select placeholder="Sipariş Seçin" onChange={handleOrderChange} style={{ width: '100%' }}>
  {approvedOrders.map(order => (
    <Option key={order.id} value={order.id}>
      {`${order.cariAdi} - ${order.siparisTarihi ? order.siparisTarihi.toLocaleDateString('tr-TR') : 'Tarih Yok'} - ${order.belgeNo}`}
    </Option>
  ))}
</Select>

            </Form.Item>
          </Col>
          <Divider style={{ fontSize: 28 }}>Ürünler</Divider>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={24}>
            {selectedProducts.map(product => (
      <Checkbox
        key={product.urunAdi}
        value={product.urunAdi}
        onChange={(e) => handleProductChange(e.target.checked ? product.urunAdi : null)}
      >
        {product.urunAdi}
      </Checkbox>
    ))}            </Col>
          </Row>
          <Divider>Üretilecek Ürün</Divider>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item name="product_name" label="Adı" rules={[{ required: true, message: 'Lütfen ürün adını giriniz!' }]}>
                <Input value={selectedProduct} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="packaging_weight" label={form.getFieldValue('packaging_label') || 'Mevcut Sipariş Miktarı'} rules={[{ required: true, message: 'Lütfen ambalaj birimini giriniz!' }]}>
                <InputNumber disabled min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="stock_amount" label="Mevcut Stok Miktarı" rules={[{ required: true, message: 'Lütfen mevcut stok miktarını giriniz!' }]}>
                <InputNumber disabled min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="total_production_amount" label="Üretilecek Miktar">
                <InputNumber
                  style={{ width: '100%' }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Reçete Detayları</Divider>
          <Form.List name="rawMaterials">
  {(fields, { add, remove }) => (
    <>
      {fields.map(({ key, name, fieldKey, ...restField }) => (
        <Row justify="center" gutter={[10, 10]} key={key}>
          <Col span={6}>
            <Form.Item
              {...restField}
              name={[name, 'materialName']}
              fieldKey={[fieldKey, 'materialName']}
              label={key === 0 ? "Adı" : ""}
              rules={[{ required: true, message: 'Lütfen hammadde adını giriniz!' }]}
            >
              <Select
                showSearch
                disabled={editingMaterialIndex !== key}
                placeholder="Hammadde Seç"
                onChange={(value) => handleMaterialChange(value, key)}
                style={{ width: '100%' }}
              >
                {stocks.map(stock => (
                  <Option key={stock.StockName} value={stock.StockName}>
                    {stock.StockName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              {...restField}
              name={[name, 'type']}
              fieldKey={[fieldKey, 'type']}
              label={key === 0 ? "Cinsi" : ""}
              rules={[{ required: true, message: 'Lütfen cinsini giriniz!' }]}
            >
              <Input style={{ width: '100%' }} disabled={editingMaterialIndex !== key} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              {...restField}
              name={[name, 'amount']}
              fieldKey={[fieldKey, 'amount']}
              label={key === 0 ? "Miktar (kg)" : ""}
              rules={[{ required: true, message: 'Lütfen miktarı giriniz!' }]}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                disabled={editingMaterialIndex !== key}
                onChange={(value) => {
                  const rawMaterials = form.getFieldValue('rawMaterials') || [];
                  const updatedMaterials = rawMaterials.map((material, idx) => 
                    idx === key ? { ...material, amount: value } : material
                  );
                  const totalBatchKg = updatedMaterials.reduce((acc, material) => acc + (material?.amount || 0), 0);
                  setBatchTotalKg(totalBatchKg);  // Toplam batch kg burada güncelleniyor
                  form.setFieldsValue({ rawMaterials: updatedMaterials, batch_total_kg: totalBatchKg });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              {...restField}
              name={[name, 'description']}
              fieldKey={[fieldKey, 'description']}
              label={key === 0 ? `Açıklama ${key + 1}` : ""}
              rules={[{ required: true, message: 'Lütfen açıklamayı giriniz!' }]}
            >
              <Input style={{ width: '100%' }} disabled={editingMaterialIndex !== key} />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
            {editingMaterialIndex === key ? (
              <Button type="primary" onClick={() => setEditingMaterialIndex(null)}>Kaydet</Button>
            ) : (
              <>
                <Edit onClick={() => handleEditMaterial(key)} style={{ marginLeft: "10px", fontSize: "24px", cursor: "pointer" }} />
                <Delete onClick={() => remove(name)} style={{ marginLeft: "10px", fontSize: "24px", cursor: "pointer" }} />
              </>
            )}
          </Col>
        </Row>
      ))}
      <Form.Item style={{ marginLeft: '2%' }}>
        <Row justify="center">
          <Col>
            <Form.Item   label="1 Şarj Toplam Kg">
            {console.log("psdofg",batchTotalKg)}
              <InputNumber
              value={batchTotalKg.toString()}
                style={{ width: '90%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col>
          <Button
            type="dashed"
            onClick={() => {
              add();
              setEditingMaterialIndex(fields.length); // Yeni eklenen maddeyi düzenlenebilir yap
            }}
            icon={<PlusOutlined />}
            style={{
              width: '100%',
              marginBottom: '24px',
              borderColor: '#1890ff',
              color: '#1890ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Hammadde Ekle
          </Button>
        </Col>
      </Form.Item>
    </>
  )}
</Form.List>



          <Row justify="center" gutter={[16, 16]}>
          

          </Row>
          <Divider>Üretim Detayları</Divider>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={5}>
              <Form.Item name="start_date" label="Başlama Tarihi" rules={[{ required: true, message: 'Lütfen başlangıç tarihini giriniz!' }]}>
                <DatePicker format="DD-MM-YYYY" style={{ width: '70%' }} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="end_date" label="Bitiş Tarihi" rules={[{ required: true, message: 'Lütfen bitiş tarihini giriniz!' }]}>
                <DatePicker format="DD-MM-YYYY" style={{ width: '70%' }} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="card_number" label="Sipariş Numarası" rules={[{ required: true, message: 'Lütfen sipariş numarasını giriniz!' }]}>
                <Input value={nextCardNumber} readOnly style={{ width: '70%' }} />
              </Form.Item>
            </Col>
          </Row>
          
          <Divider>Üretim Kartı Detayları</Divider>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item name="creator_name" label="Üretim Kartını Oluşturan" initialValue="" rules={[{ required: true, message: 'Lütfen üretim kartını oluşturanın adını giriniz!' }]}>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="production_staff_name" label="Üretim Yapan Personel" rules={[{ required: true, message: 'Lütfen üretim yapan personelin adını giriniz!' }]}>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="additional_info" label="Ek Bilgi">
                <TextArea rows={2} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Notlar</Divider>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="notes" label="Notlar">
                <TextArea rows={2} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="note_writer" label="Notu Yazan-Ad Soyad" rules={[{ required: true, message: 'Lütfen notu yazan adı giriniz!' }]}>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Hesaplanan Değerler</Divider>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Hesaplanan Değerler">
                {formValues.calculatedAuxiliaryUnits?.map((unit, index) => (
                  <p key={index}><strong>{unit.from} ↠ {unit.amount} {unit.to}</strong> </p>
                ))}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row justify="center">
              <Col>
                <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#1890ff', borderColor: '#1890ff' }}>
                  Kaydet
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>

        <Modal
          title="Şarj Sayacı Girişi"
          visible={batchModalVisible}
          onOk={handleBatchModalOk}
          onCancel={handleBatchModalCancel}
          width={800}
        >
          <Form layout="vertical">
            <Form.Item label="Şarj Satır Sayısı">
              <InputNumber min={1} max={50} value={batchRows} onChange={setBatchRows} />
            </Form.Item>
            <Form.Item label="Şarj Sütun Sayısı">
              <InputNumber min={1} max={10} value={batchColumns} onChange={setBatchColumns} />
            </Form.Item>
            <Table
              dataSource={generateBatchData(batchRows, batchColumns)}
              columns={tableColumns}
              pagination={false}
              rowKey="key"
              bordered
            />
          </Form>
          <div>Toplam Şarj Sayısı: {batchRows * batchColumns}</div>
        </Modal>

        <ReactToPrint
          trigger={() => <Button type="primary" style={{ marginTop: '16px' }}>Yazdır</Button>}
          content={() => printRef.current}
        />

        <div style={{ display: 'none' }}>
          <PrintableForm
            ref={printRef}
            formValues={formValues}
            selectedBatch={selectedBatch}
            batchRows={batchRows}
            batchColumns={batchColumns}
          />
        </div>
      </Card>
    </div>
  );
};

export default ProductionForm;
