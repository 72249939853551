import React, { useState, useEffect, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { collection, doc, getDoc, getDocs, addDoc } from 'firebase/firestore'; // addDoc eklendi
import { Form, Input, Button, Row, Col, DatePicker, Select, Spin, Collapse, Typography, Card } from 'antd';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';
import { db } from '../../environment';
import { Container } from 'react-bootstrap';
import logo from '../../assets/yclkimya.png';

const { Option } = Select;
const { Title } = Typography;

const OrderForm = () => {
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      urunler: [{ urunAdi: '', urunKodu: '', miktar: '', birim: '', birimFiyat: '', paraBirimi: '', iskonto: '', kdv: '', tutar: '' }]
    }
  });

  const { fields } = useFieldArray({
    control,
    name: 'urunler',
  });

  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const [language, setLanguage] = useState('tr');

  const fetchAllOrders = async () => {
    setLoading(true);
    try {
      const ordersSnapshot = await getDocs(collection(db, 'pendingOrdersPRF'));
      const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllOrders(ordersList);
    } catch (error) {
      console.error('Sipariş verisi alınamadı: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const handleOrderChange = async (orderId) => {
    const selectedOrderDoc = doc(db, 'pendingOrdersPRF', orderId);
    const selectedOrderSnapshot = await getDoc(selectedOrderDoc);
  
    if (selectedOrderSnapshot.exists()) {
      const orderData = selectedOrderSnapshot.data();
      reset(orderData);
    } else {
      console.error('Sipariş bulunamadı.');
    }
  };

  const onSubmit = async (data) => {
    try {
      await addDoc(collection(db, 'commercialInvoice'), data); // commercialInvoice koleksiyonuna kayıt ekle
      console.log('Form data: ', data);
    } catch (error) {
      console.error('Veri eklenirken hata oluştu: ', error);
    }
  };

 

  return (
    <div className='page-wrapper'>
      <Container className="mt-5">
        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '30px', borderRadius: '15px' }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary" style={{ marginBottom: '20px' }} >
                {language === 'tr' ? 'Yazdır' : 'Print'}
              </Button>
            )}
            content={() => componentRef.current}
          />

          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Select defaultValue="tr" onChange={(value) => setLanguage(value)}>
              <Option value="tr">Türkçe</Option>
              <Option value="en">English</Option>
            </Select>
          </div>

          <Title level={2} className="mb-4" style={{ textAlign: 'center', color: '#1890ff' }}>
            {language === 'tr' ? 'Sipariş Seçin' : 'Select Order'}
          </Title>

          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">

            <Form.Item label={language === 'tr' ? 'Sipariş Seçimi' : 'Select Order'}>
              <Select
                showSearch
                placeholder={language === 'tr' ? 'Sipariş Seçin' : 'Select an Order'}
                optionFilterProp="children"
                onChange={handleOrderChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {allOrders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.belgeNo}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Collapse defaultActiveKey={['1']} expandIconPosition="right">
              <Collapse.Panel header={language === 'tr' ? 'Ürün Bilgileri' : 'Product Information'} key="1">
                <Spin spinning={loading}>
                  {fields.map((field, index) => (
                    <Card key={field.id} style={{ marginBottom: '24px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Ürün Adı' : 'Product Name'}>
                            <Controller
                              name={`urunler[${index}].urunAdi`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Ürün Kodu' : 'Product Code'}>
                            <Controller
                              name={`urunler[${index}].urunKodu`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Miktar' : 'Quantity'}>
                            <Controller
                              name={`urunler[${index}].miktar`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Birim' : 'Unit'}>
                            <Controller
                              name={`urunler[${index}].birim`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Birim Fiyat' : 'Unit Price'}>
                            <Controller
                              name={`urunler[${index}].birimFiyat`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Para Birimi' : 'Currency'}>
                            <Controller
                              name={`urunler[${index}].paraBirimi`}
                              control={control}
                              render={({ field }) => (
                                <Select {...field} size="large" disabled>
                                  <Option value="TRY">TRY</Option>
                                  <Option value="USD">USD</Option>
                                  <Option value="EUR">EUR</Option>
                                </Select>
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item label={`${language === 'tr' ? 'İskonto' : 'Discount'} (%)`}>
                            <Controller
                              name={`urunler[${index}].iskonto`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label={`${language === 'tr' ? 'KDV' : 'VAT'} (%)`}>
                            <Controller
                              name={`urunler[${index}].kdv`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label={language === 'tr' ? 'Tutar' : 'Total Amount'}>
                            <Controller
                              name={`urunler[${index}].tutar`}
                              control={control}
                              render={({ field }) => <Input {...field} size="large" readOnly />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Spin>
              </Collapse.Panel>

              <Collapse.Panel header={language === 'tr' ? 'Palet ve Ağırlık Bilgileri' : 'Pallet and Weight Information'} key="2">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Toplam Palet Sayısı' : 'Total Pallet Count'}>
                      <Controller
                        name="toplamPalletSayisi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Toplam Net Ağırlık' : 'Total Net Weight'}>
                      <Controller
                        name="toplamNetAgirlik"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Toplam Brüt Ağırlık' : 'Total Gross Weight'}>
                      <Controller
                        name="toplamBrutAgirlik"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>

              <Collapse.Panel header={language === 'tr' ? 'Fatura Bilgileri' : 'Invoice Information'} key="3">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={language === 'tr' ? 'Fatura Numarası' : 'Invoice Number'}>
                      <Controller
                        name="faturaNumarasi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label={language === 'tr' ? 'Fatura Tarihi' : 'Invoice Date'}>
                      <Controller
                        name="faturaTarihi"
                        control={control}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>

            <Form.Item>
              <Button type="primary" htmlType="submit" block size="large">
                {language === 'tr' ? 'Kaydet' : 'Save'}
              </Button>
            </Form.Item>
          </Form>
          
          {/* Print Component */}
          <div ref={componentRef} style={{ padding: '20px', fontFamily: 'Arial, sans-serif', fontSize: '12px', color: '#000' }}>
            {/* Header Section */}
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <h2 style={{ fontSize: '24px', margin: 0 }}>{language === 'tr' ? 'TİCARİ FATURA' : 'COMMERCIAL INVOICE'}</h2>
            </div>

            {/* Company Info */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div style={{ width: '60%' }}>
                <h4 style={{ margin: 0 }}>Yücel Kimya Temizlik Maddeleri San Tic Ltd Şti</h4>
                <p style={{ margin: 0 }}>Tepecik V.D: 992 000 5020 TIC SICIL NO : 31649</p>
                <p style={{ margin: 0 }}>Karadenizliler Mah Barbaros Cad No 66A/1</p>
                <p style={{ margin: 0 }}>Pk. 41140 Başiskele/KOCAELİ</p>
                <p style={{ margin: 0 }}>TEL : 0 541 485 70 79</p>
                <p style={{ margin: 0 }}>info@yucelkimya.com.tr</p>
              </div>
              <div style={{ width: '35%', textAlign: 'right' }}>
                <img src={logo} alt="Company Logo" style={{ width: '100px', height: 'auto' }} />
              </div>
            </div>

            {/* Table for Company and Importer Details */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px' }}><strong>{language === 'tr' ? 'ŞİRKET ADI' : 'COMPANY NAME'}</strong></td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{watch('cariAdi')}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}><strong>{language === 'tr' ? 'İTHALATÇI ADI' : 'IMPORTER NAME'}</strong></td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{watch('ilgiliKisiAdi')}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px' }}><strong>{language === 'tr' ? 'ŞİRKET ADRESİ' : 'COMPANY ADDRESS'}</strong></td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{watch('adres')}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}><strong>{language === 'tr' ? 'İTHALATÇI ADRESİ' : 'IMPORTER ADDRESS'}</strong></td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{watch('teslimatAdresi')}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '8px' }}><strong>{language === 'tr' ? 'FATURA TARİHİ' : 'INVOICE DATE'}</strong></td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{watch("faturaTarihi") ? dayjs(watch("faturaTarihi")).format("DD-MM-YYYY") : ''}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}><strong>{language === 'tr' ? 'FATURA NUMARASI' : 'INVOICE NUMBER'}</strong></td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{watch('faturaNumarasi')}</td>
                </tr>
              </tbody>
            </table>

            {/* Product Details Table */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{language === 'tr' ? 'NUMARA' : 'NUMBER'}</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{language === 'tr' ? 'ÜRÜN ADI' : 'PRODUCT NAME'}</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{language === 'tr' ? 'TOPLAM BİRİM' : 'TOTAL UNIT'}</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{language === 'tr' ? 'BİRİM FİYATI' : 'UNIT PRICE'}</th>
                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{language === 'tr' ? 'TOPLAM TUTAR' : 'TOTAL AMOUNT'}</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => {
                  const birim = watch(`urunler[${index}].paraBirimi`);
                  const miktar = parseFloat(watch(`urunler[${index}].miktar`) || 0);
                  const birimFiyat = parseFloat(watch(`urunler[${index}].birimFiyat`) || 0);
                  const tutar = miktar * birimFiyat;

                  return (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{watch(`urunler[${index}].urunAdi`)}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{miktar}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{birimFiyat.toFixed(2)} {birim}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{tutar.toFixed(2)} {birim}</td>
                    </tr>
                  );
                })}

                {/* USD Total */}
                {fields.some((field, index) => watch(`urunler[${index}].paraBirimi`) === 'USD') && (
                  <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} colSpan="2"><strong>{language === 'tr' ? 'TOPLAM USD' : 'TOTAL USD'}</strong></td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      {fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'USD' ? parseFloat(watch(`urunler[${index}].miktar`) || 0) : 0), 0)}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      ${fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'USD' ? parseFloat(watch(`urunler[${index}].birimFiyat`) || 0) : 0), 0).toFixed(2)}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      ${fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'USD' ? parseFloat(watch(`urunler[${index}].miktar`) * watch(`urunler[${index}].birimFiyat`) || 0) : 0), 0).toFixed(2)}
                    </td>
                  </tr>
                )}

                {/* EUR Total */}
                {fields.some((field, index) => watch(`urunler[${index}].paraBirimi`) === 'EUR') && (
                  <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} colSpan="2"><strong>{language === 'tr' ? 'TOPLAM EUR' : 'TOTAL EUR'}</strong></td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      {fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'EUR' ? parseFloat(watch(`urunler[${index}].miktar`) || 0) : 0), 0)}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      ${fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'EUR' ? parseFloat(watch(`urunler[${index}].birimFiyat`) || 0) : 0), 0).toFixed(2)}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      €{fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'EUR' ? parseFloat(watch(`urunler[${index}].miktar`) * watch(`urunler[${index}].birimFiyat`) || 0) : 0), 0).toFixed(2)}
                    </td>
                  </tr>
                )}

                {/* TRY Total */}
                {fields.some((field, index) => watch(`urunler[${index}].paraBirimi`) === 'TRY') && (
                  <tr>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} colSpan="2"><strong>{language === 'tr' ? 'TOPLAM TRY' : 'TOTAL TRY'}</strong></td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      {fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'TRY' ? parseFloat(watch(`urunler[${index}].miktar`) || 0) : 0), 0)}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      ${fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'TRY' ? parseFloat(watch(`urunler[${index}].birimFiyat`) || 0) : 0), 0).toFixed(2)}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                      ₺{fields.reduce((acc, field, index) => acc + (watch(`urunler[${index}].paraBirimi`) === 'TRY' ? parseFloat(watch(`urunler[${index}].miktar`) * watch(`urunler[${index}].birimFiyat`) || 0) : 0), 0).toFixed(2)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Summary Section */}
            <tr>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }} colSpan="5">
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                  <tbody>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'left', width: '25%' }}>
                        <strong>{language === 'tr' ? 'TOPLAM BİRİM' : 'TOTAL UNIT'}:</strong>
                      </td>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'right' }}>
                        {fields.reduce((acc, field, index) => acc + parseFloat(watch(`urunler[${index}].miktar`) || 0), 0)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'left', width: '25%' }}>
                        <strong>{language === 'tr' ? 'TOPLAM PALET' : 'TOTAL PALLET'}:</strong>
                      </td>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'right' }}>
                        {watch('toplamPalletSayisi')}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'left', width: '25%' }}>
                        <strong>{language === 'tr' ? 'TOPLAM NET AĞIRLIK' : 'TOTAL NET WEIGHT'}:</strong>
                      </td>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'right' }}>
                        {watch('toplamNetAgirlik')}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'left', width: '25%' }}>
                        <strong>{language === 'tr' ? 'TOPLAM BRÜT AĞIRLIK' : 'TOTAL GROSS WEIGHT'}:</strong>
                      </td>
                      <td style={{ border: '1px solid black', padding: '12px', textAlign: 'right' }}>
                        {watch('toplamBrutAgirlik')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default OrderForm;
