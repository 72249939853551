import React, { useState, useEffect, useRef } from 'react';
import { Form, Select, Button, DatePicker, Row, Col, Spin, Input, Table, Modal, message } from 'antd';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../environment';
import { useReactToPrint } from 'react-to-print';
import yucellogo from '../../yclkimya.png';

const { Option } = Select;

const GirisIrsaliyesiForm = () => {
  const [depolar, setDepolar] = useState([]);
  const [plasiyerler, setPlasiyerler] = useState([]);
  const [cariler, setCariler] = useState([]);
  const [stoklar, setStoklar] = useState([]);
  const [selectedDepo, setSelectedDepo] = useState(null);
  const [selectedStok, setSelectedStok] = useState(null);
  const [selectedStokUnit, setSelectedStokUnit] = useState('');
  const [miktar, setMiktar] = useState('');
  const [selectedCari, setSelectedCari] = useState(null);
  const [selectedPlasiyer, setSelectedPlasiyer] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [selectedOdemePlani, setSelectedOdemePlani] = useState('PEŞİN');
  const [cekDetay, setCekDetay] = useState('');
  const [evrakTarihi, setEvrakTarihi] = useState(null);
  const [stokMiktari, setStokMiktari] = useState('');
  const [soforAdi, setSoforAdi] = useState('');
  const [soforTelefon, setSoforTelefon] = useState('');
  const [soforEhliyetNo, setSoforEhliyetNo] = useState('');
  const [dorsePlakasi, setDorsePlakasi] = useState('');
  const [dorseKodu, setDorseKodu] = useState('');
  const [dorseTipi, setDorseTipi] = useState('');
  const [aracPlakasi, setAracPlakasi] = useState('');
  const [evrakNo, setEvrakNo] = useState('');

  const [aracMarkasi, setAracMarkasi] = useState('');
  const [aracModeli, setAracModeli] = useState('');
  const [aracKapasitesi, setAracKapasitesi] = useState('');
  const printRef = useRef();

  const fetchDepolar = async () => {
    const depoSnapshot = await getDocs(collection(db, 'depolar'));
    const depoList = depoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setDepolar(depoList);
  };

  const fetchPlasiyerler = async () => {
    const plasiyerSnapshot = await getDocs(collection(db, 'users'));
    const plasiyerList = plasiyerSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setPlasiyerler(plasiyerList);
  };

  const fetchCariler = async () => {
    const cariSnapshot = await getDocs(collection(db, 'allCaris'));
    const cariList = cariSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setCariler(cariList);
  };

  const fetchStoklarByDepo = async (depoId) => {
    const stokQuery = query(collection(db, 'stocks'), where('Warehouse', '==', depoId));
    const stokSnapshot = await getDocs(stokQuery);
    const stokList = stokSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setStoklar(stokList);
  };

  const handleDepoChange = (value) => {
    setSelectedDepo(value);
    fetchStoklarByDepo(value);
  };

  const handleStokChange = (value) => {
    const stok = stoklar.find((stok) => stok.StockName === value);
    if (stok) {
      setSelectedStok(stok);
      setSelectedStokUnit(stok.Unit);
      setStokMiktari(stok.UnitAmount);
    }
  };

  const handleCariChange = (value) => {
    const cari = cariler.find((cari) => cari.KartAdi === value);
    setSelectedCari(cari);  // Cari objesini state'e set ediyoruz.
  };
  

  const handlePlasiyerChange = (value) => {
    setSelectedPlasiyer(value);
  };

  const handleEvrakTarihiChange = (date) => {
    if (date) {
      setEvrakTarihi(date.toDate()); // Save the full Date object, including time
    }
  };
  
  useEffect(() => {
    const fetchEvrakNumbers = async () => {
      const girisSnapshot = await getDocs(collection(db, 'girisIrsaliyeler'));
      const existingEvrakNos = girisSnapshot.docs.map(doc => doc.data().evrakNo);
      
      // Generate the next Evrak Number
      const nextEvrakNo = generateNextEvrakNo(existingEvrakNos);
      setEvrakNo(nextEvrakNo);
    };

    fetchEvrakNumbers();
  }, []);
  const generateNextEvrakNo = (existingEvrakNos) => {
    // Extract numbers from existing evrak numbers
    const numbers = existingEvrakNos.map(no => {
      if (typeof no === 'string') {
        const match = no.match(/CIKS-(\d+)/);
        return match ? parseInt(match[1], 10) : 0;
      }
      return 0; // Return 0 for undefined or non-string values
    });
  
    // Find the maximum number and increment, start with 0 if no data
    const maxNumber = Math.max(0, ...numbers);
    const nextNumber = maxNumber + 1;
    return `CIKS-${String(nextNumber).padStart(5, '0')}`;
  };
  
  
  const handleAdd = () => {
    if (!selectedStok || !miktar || !selectedCari || !selectedPlasiyer) {
      message.error('Lütfen tüm alanları doldurun.');
      return;
    }

    const newData = {
      key: selectedStok.id,
      stokAdi: selectedStok.StockName,
      miktar,
      birim: selectedStokUnit,
      cari: selectedCari,
    };

    setTableData([...tableData, newData]);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current, 
  });

  const handleConfirmationOk = async () => {
    if (!tableData.length) {
      message.error('Tabloda güncellenecek stok yok.');
      return;
    }
  handlePrint()
    try {
      // Adding the irsaliye to girisIrsaliyeler collection
      await addDoc(collection(db, 'girisIrsaliyeler'), {
        evrakNo,
        tableData, // Array of added stocks
        odemePlani: selectedOdemePlani, // Payment Plan
        cekDetay, // Cheque details if applicable
        depo: selectedDepo, // Selected Depot
        evrakTarihi, // Use the full Date object (selected date and time)
        plasiyer: selectedPlasiyer, // Selected Sales Representative (Plasiyer)
        cari: selectedCari, // Selected Cari (Customer)
        soforBilgileri: { 
          soforAdi, 
          soforTelefon, 
          soforEhliyetNo 
        }, // Driver Information
        dorseBilgileri: { 
          dorsePlakasi, 
          dorseKodu, 
          dorseTipi 
        }, // Trailer Information
        aracBilgileri: { 
          aracPlakasi, 
          aracMarkasi, 
          aracModeli, 
          aracKapasitesi 
        }, // Vehicle Information
      });
  
      // Update stock quantities in the stocks collection
      await Promise.all(
        tableData.map(async (item) => {
          const stokRef = doc(db, 'stocks', item.key);
          const stokSnapshot = await getDoc(stokRef);
          const stokData = stokSnapshot.data();
  
          if (stokData) {
            const existingAmount = parseFloat(stokData.UnitAmount) || 0;
            const addedAmount = parseFloat(item.miktar) || 0;
  
            await updateDoc(stokRef, {
              UnitAmount: existingAmount-addedAmount,
            });
          }
        })
      );
  
      // Reset form and state after successful save
      setTableData([]);
      setMiktar('');
      setEvrakNo('')
      setSelectedStok(null);
      setSelectedStokUnit('');
      setSelectedCari(null);
      setSelectedPlasiyer(null);
      setSelectedDepo(null);
      setEvrakTarihi(null);
      setSelectedOdemePlani('PEŞİN');
      setCekDetay('');
      setIsConfirmationVisible(false);
      message.success('İrsaliye başarıyla kaydedildi ve stok miktarları güncellendi.');
    } catch (error) {
      console.error('Error during confirmation:', error);
      message.error('İrsaliye kaydedilirken hata oluştu.');
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      await fetchDepolar();
      await fetchPlasiyerler();
      await fetchCariler();
      setLoading(false);
    };
    fetchData();
  }, []);

  const columns = [
    { title: 'Stok Adı', dataIndex: 'stokAdi', key: 'stokAdi' },
    { title: 'Miktar', dataIndex: 'miktar', key: 'miktar' },
    { title: 'Birim', dataIndex: 'birim', key: 'birim' },
    { title: 'Cari', dataIndex: 'cari', key: 'cari' },
  ];

  if (loading) {
    return <Spin size="large" className="loading-spinner" />;
  }

  return (
    <div className="page-wrapper mt-5">
      <Form layout="vertical">
        {/* Evrak Details */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Evrak Tipi" style={{ fontSize: '12px' }}>
              <Select defaultValue="Toptan" style={{ width: '100%' }}>
                <Option value="Toptan">Toptan</Option>
                <Option value="Perakende">Perakende</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Evrak Tarihi" style={{ fontSize: '12px' }}>
            <DatePicker 
  format={'DD/MM/YYYY HH:mm'} 
  showTime 
  onChange={handleEvrakTarihiChange} 
  style={{ width: '100%' }} 
/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Evrak Numarası" style={{ fontSize: '12px' }}>
            <Input value={evrakNo}></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Ödeme Planı" style={{ fontSize: '12px' }}>
              <Select defaultValue="PEŞİN" onChange={(value) => setSelectedOdemePlani(value)} style={{ width: '100%' }}>
                <Option value="PEŞİN">Peşin</Option>
                <Option value="VADELİ">Vadeli</Option>
                <Option value="ÇEK">Çek</Option>
              </Select>
              {selectedOdemePlani === 'ÇEK' && (
                <Input
                  placeholder="Çek Detayları Girin"
                  value={cekDetay}
                  onChange={(e) => setCekDetay(e.target.value)}
                  style={{ marginTop: '10px' }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* Depo, Cari, Plasiyer Selections */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Plasiyer" style={{ fontSize: '12px' }}>
              <Select
                showSearch
                placeholder="Plasiyer seç"
                optionFilterProp="children"
                onChange={handlePlasiyerChange}
                style={{ width: '100%' }}
              >
                {plasiyerler.map((plasiyer) => (
                  <Option key={plasiyer.id} value={plasiyer.username}>
                    {plasiyer.username}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Depo" style={{ fontSize: '12px' }}>
              <Select
                showSearch
                placeholder="Depo seç"
                optionFilterProp="children"
                onChange={handleDepoChange}
                style={{ width: '100%' }}
              >
                {depolar.map((depo) => (
                  <Option key={depo.id} value={depo.id}>
                    {depo.depoAdi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Cari" style={{ fontSize: '12px' }}>
              <Select
                showSearch
                placeholder="Cari seç"
                optionFilterProp="children"
                onChange={handleCariChange}
                style={{ width: '100%' }}
              >
                {cariler.map((cari) => (
                  <Option key={cari.id} value={cari.KartAdi}>
                    {cari.KartAdi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Stok Seçimi ve Miktar */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Stok" style={{ fontSize: '12px' }}>
              <Select
                showSearch
                placeholder="Stok seç"
                optionFilterProp="children"
                onChange={handleStokChange}
                disabled={!selectedDepo}
                style={{ width: '100%' }}
              >
                {stoklar.map((stok) => (
                  <Option key={stok.id} value={stok.StockName}>
                    {stok.StockName}
                  </Option>
                ))}
              </Select>
              {selectedStok && <p>Mevcut Miktar: {stokMiktari} {selectedStokUnit}</p>}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Miktar" style={{ fontSize: '12px' }}>
              <Input
                placeholder={`Miktar (${selectedStokUnit})`}
                value={miktar}
                onChange={(e) => setMiktar(e.target.value)}
                disabled={!selectedStok}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Şoför, Araç, Dorse Bilgileri */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Şoför Adı" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Şoför Adını Girin"
                value={soforAdi}
                onChange={(e) => setSoforAdi(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Şoför Telefonu" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Şoför Telefonunu Girin"
                value={soforTelefon}
                onChange={(e) => setSoforTelefon(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Şoför Ehliyet No" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Ehliyet Numarasını Girin"
                value={soforEhliyetNo}
                onChange={(e) => setSoforEhliyetNo(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Araç Bilgileri */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Araç Plakası" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Araç Plakasını Girin"
                value={aracPlakasi}
                onChange={(e) => setAracPlakasi(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Araç Markası" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Araç Markasını Girin"
                value={aracMarkasi}
                onChange={(e) => setAracMarkasi(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Araç Modeli" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Araç Modelini Girin"
                value={aracModeli}
                onChange={(e) => setAracModeli(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Araç Kapasitesi" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Araç Kapasitesini Girin"
                value={aracKapasitesi}
                onChange={(e) => setAracKapasitesi(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Dorse Bilgileri */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Dorse Plakası" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Dorse Plakasını Girin"
                value={dorsePlakasi}
                onChange={(e) => setDorsePlakasi(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Dorse Kodu" style={{ fontSize: '12px' }}>
              <Input
                placeholder="Dorse Kodunu Girin"
                value={dorseKodu}
                onChange={(e) => setDorseKodu(e.target.value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Dorse Tipi" style={{ fontSize: '12px' }}>
              <Select
                showSearch
                placeholder="Dorse Tipi Seç"
                optionFilterProp="children"
                onChange={(value) => setDorseTipi(value)}
                style={{ width: '100%' }}
              >
                <Option value="Konteyner">Konteyner</Option>
                <Option value="Tenteli">Tenteli</Option>
                <Option value="Frigo">Frigo</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" onClick={handleAdd} disabled={!miktar || !selectedStok || !selectedCari}>
          Stok Ekle
        </Button>
        <Button type="primary" onClick={handlePrint}>
  PDF Yazdır
</Button>

        <Table columns={columns} dataSource={tableData} style={{ marginTop: '20px' }} />

        <Row gutter={16} style={{ marginTop: '20px' }}>
          <Button type="primary" block onClick={handleConfirmationOk}>
            İrsaliye Oluştur
          </Button>
        </Row>
      </Form>

      {/* PDF Görüntüsü */}
      
      <div ref={printRef} style={{ padding: '30px', border: '2px solid #000', borderRadius: '8px', backgroundColor: '#fff', fontFamily: 'Arial, sans-serif', color: '#000', maxWidth: '800px', margin: 'auto', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
  {/* Header */}
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
    <img src={yucellogo} alt="Logo" style={{ maxWidth: '120px' }} />
    <h1 style={{ fontWeight: 'normal', color: '#000', textAlign: 'right', fontSize: '18px' }}>Çıkış İrsaliye Belgesi</h1>
  </div>

  {/* Company and Customer Information */}
  <div style={{ borderBottom: '2px solid #e0e0e0', paddingBottom: '15px', marginBottom: '20px' }}>
    {evrakNo}
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '50%' }}>
        <p><strong>Gönderen Firma:</strong></p>
        <p style={{ fontSize: '12px' }}>{selectedCari?.KartAdi}</p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}><strong>Adres:</strong> {selectedCari?.Adres}</p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}><strong>Vergi Dairesi:</strong> {selectedCari?.VergiDairesi}</p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}><strong>VKN:</strong> {selectedCari?.VKN}</p>
      </div>
      <div style={{ width: '50%' }}>
        <p><strong>Alıcı Firma:</strong></p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}>YÜCEL KİMYA TEMİZLİK MADDELERİ SAN. T.C. LTD. ŞTİ.</p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}><strong>Adres:</strong> KARADENİZLİLER Mah. BARBAROS CAD No:66 A Başiskele/ Kocaeli</p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}><strong>Vergi Dairesi:</strong> TEPECİK VERGİ DAİRESİ</p>
        <p style={{ margin: '5px 0', fontSize: '12px' }}><strong>VKN:</strong> 9920005020</p>
      </div>
    </div>
  </div>

  {/* Vehicle and Trailer Information */}
  <div style={{ borderBottom: '2px solid #e0e0e0', paddingBottom: '15px', marginBottom: '20px' }}>
    <h3 style={{ color: '#d9534f', fontSize: '14px', borderBottom: '2px solid #000', paddingBottom: '5px', display: 'inline-block', marginBottom: '10px' }}>Araç ve Dorse Bilgileri</h3>
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
      <tbody>
        <tr>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Araç Plakası:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{aracPlakasi}</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Araç Markası:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{aracMarkasi}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Araç Modeli:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{aracModeli}</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Araç Kapasitesi:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{aracKapasitesi}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Dorse Plakası:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{dorsePlakasi}</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Dorse Kodu:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{dorseKodu}</td>
        </tr>
      </tbody>
    </table>
  </div>

  {/* Stock Information */}
  <div style={{ borderBottom: '2px solid #e0e0e0', paddingBottom: '15px', marginBottom: '20px' }}>
    <h3 style={{ color: '#d9534f', fontSize: '14px', borderBottom: '2px solid #000', paddingBottom: '5px', display: 'inline-block', marginBottom: '10px' }}>Stok Bilgileri</h3>
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #000', padding: '10px', backgroundColor: '#f0f0f0', fontWeight: 'bold', fontSize: '12px' }}>Ürün Adı</th>
          <th style={{ border: '1px solid #000', padding: '10px', backgroundColor: '#f0f0f0', fontWeight: 'bold', fontSize: '12px' }}>Miktar</th>
          <th style={{ border: '1px solid #000', padding: '10px', backgroundColor: '#f0f0f0', fontWeight: 'bold', fontSize: '12px' }}>Birim</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item) => (
          <tr key={item.key}>
            <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{item.stokAdi}</td>
            <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{item.miktar}</td>
            <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{item.birim}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  {/* Delivery Information */}
  <div style={{ borderBottom: '2px solid #e0e0e0', paddingBottom: '15px', marginBottom: '20px' }}>
    <h3 style={{ color: '#d9534f', fontSize: '14px', borderBottom: '2px solid #000', paddingBottom: '5px', display: 'inline-block', marginBottom: '10px' }}>Sevkiyat Bilgileri</h3>
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
      <tbody>
        <tr>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Evrak Tarihi:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{evrakTarihi ? evrakTarihi.toLocaleString('tr-TR', { dateStyle: 'short', timeStyle: 'short' }) : ''}</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Plasiyer:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{selectedPlasiyer}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Şoför Adı:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{soforAdi}</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontWeight: 'bold', fontSize: '12px' }}>Şoför Telefonu:</td>
          <td style={{ border: '1px solid #000', padding: '10px', fontSize: '12px' }}>{soforTelefon}</td>
        </tr>
      </tbody>
    </table>
  </div>

  {/* Footer with Signatures */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
    <div style={{ textAlign: 'center' }}>
      <p><strong>Teslim Eden:</strong></p>
      <div style={{ borderBottom: '2px solid #000', width: '200px', margin: '10px auto' }}></div>
      <p style={{ fontSize: '12px' }}>İmza</p>
    </div>
    <div style={{ textAlign: 'center' }}>
      <p><strong>Teslim Alan:</strong></p>
      <div style={{ borderBottom: '2px solid #000', width: '200px', margin: '10px auto' }}></div>
      <p style={{ fontSize: '12px' }}>İmza</p>
    </div>
  </div>
</div>






      {/* Onay Modalı */}
      <Modal
        visible={isConfirmationVisible}
        onOk={handleConfirmationOk}
        onCancel={() => setIsConfirmationVisible(false)}
        title="Onay"
      >
        <p>İrsaliyeyi kaydetmek istediğinize emin misiniz?</p>
      </Modal>
    </div>
  );
};

export default GirisIrsaliyesiForm;
