import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, notification, Space, Select } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  where,
  query,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../environment";

const { confirm } = Modal;
const { Option } = Select;

const AmbalajStokListesi = () => {
  const [ambalajList, setAmbalajList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [depolar, setDepolar] = useState([]); 
  const [searchTerm, setSearchTerm] = useState(""); 
  const [selectedDepot, setSelectedDepot] = useState("Hepsi"); 
  const [units, setUnits] = useState([]); 
  const [nextStockCode, setNextStockCode] = useState(""); 

  // Fetching units from Firestore
  useEffect(() => {
    const fetchUnits = async () => {
      const unitsSnapshot = await getDocs(collection(db, "units"));
      const unitsList = unitsSnapshot.docs.map((doc) => ({
        id: doc.id,
        unit: doc.data().name, 
      }));
      setUnits(unitsList);
    };
    fetchUnits();
  }, []);

  // Fetch data and calculate the next stock code starting with 157
  const fetchData = async () => {
    const depolarSnapshot = await getDocs(collection(db, "depolar"));
    const depolarList = depolarSnapshot.docs.map((doc) => ({
      id: doc.id,
      depoAdi: doc.data().depoAdi,
    }));
    setDepolar([{ id: "Hepsi", depoAdi: "Hepsi" }, ...depolarList]);

    // Fetch stocks collection where StockCode starts with 157 and sort by FullStockCode descending
    const stocksRef = collection(db, "stocks");
    const q = query(
      stocksRef,
      where("FullStockCode", ">=", "157"),
      where("FullStockCode", "<=", "157\uf8ff"),
      orderBy("FullStockCode", "desc")
    );

    const stocksSnapshot = await getDocs(q);
    const stocksList = stocksSnapshot.docs.map((doc) => {
      const stockData = doc.data();
      const depo = depolarList.find((depo) => depo.id === stockData.Warehouse);
      return {
        id: doc.id,
        ...stockData,
        depoAdi: depo ? depo.depoAdi : "Depo Adı Yok",
      };
    });

    setAmbalajList(stocksList);

    // Find the highest stock code that starts with 157 and increment it
    if (stocksList.length > 0) {
      const highestStockCode = stocksList[0].FullStockCode;
      const newStockCode = (parseInt(highestStockCode) + 1).toString();
      setNextStockCode(newStockCode);
    } else {
      setNextStockCode("157000");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter ambalajList based on search term and selected depot
  const filteredAmbalajList = ambalajList.filter((item) => {
    const matchesSearch =
      (item.StockName && item.StockName.toLowerCase().includes(searchTerm.toLowerCase())) || 
      (item.FullStockCode && item.FullStockCode.toLowerCase().includes(searchTerm.toLowerCase()));
    const matchesDepot = selectedDepot === "Hepsi" || item.Warehouse === selectedDepot;
    return matchesSearch && matchesDepot;
  });

  // Show notification
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  // Handle form submission for adding or updating ambalaj
  const handleSubmit = async (values) => {
    values.auxiliaryUnits = values.auxiliaryUnits || [];

    // Remove undefined values from all fields before sending to Firestore
    const cleanedValues = Object.fromEntries(
      Object.entries(values).filter(([, value]) => value !== undefined)
    );

    if (isEditing && currentId) {
      const ambalajDocRef = doc(db, "stocks", currentId);

      // Check if the document exists before updating
      const ambalajDocSnap = await getDoc(ambalajDocRef);

      if (ambalajDocSnap.exists()) {
        try {
          // Update the existing ambalaj
          await updateDoc(ambalajDocRef, cleanedValues);
          openNotificationWithIcon("success", "Başarılı!", "Ambalaj güncellendi.");
          fetchData(); // Re-fetch data after update
        } catch (error) {
          openNotificationWithIcon("error", "Hata!", `Güncelleme hatası: ${error.message}`);
        }
      } else {
        openNotificationWithIcon("error", "Hata!", "Bu ambalaj artık mevcut değil.");
      }
    } else {
      try {
        // Add a new ambalaj
        await addDoc(collection(db, "stocks"), {
          ...cleanedValues,
          FullStockCode: nextStockCode,
        });
        openNotificationWithIcon("success", "Başarılı!", "Yeni ambalaj eklendi.");
        fetchData(); // Re-fetch data after adding new entry
      } catch (error) {
        openNotificationWithIcon("error", "Hata!", `Ekleme hatası: ${error.message}`);
      }
    }

    setIsModalVisible(false);
    form.resetFields();
    setIsEditing(false);
    setCurrentId(null);
  };

  // Handle deletion of ambalaj
  const handleDelete = async (id) => {
    confirm({
      title: "Silmek istediğinize emin misiniz?",
      onOk: async () => {
        await deleteDoc(doc(db, "stocks", id));
        openNotificationWithIcon("success", "Başarılı!", "Ambalaj silindi.");
        fetchData(); // Re-fetch data after deletion
      },
    });
  };

  // Handle editing ambalaj
  const handleEdit = async (record) => {
    try {
      const ambalajDocRef = doc(db, "stocks", record.id);
      const ambalajDocSnap = await getDoc(ambalajDocRef);
      if (ambalajDocSnap.exists()) {
        setIsEditing(true);
        setIsModalVisible(true);

        const depo = depolar.find((depo) => depo.id === record.Warehouse);
        const formattedRecord = {
          ...record,
          Warehouse: depo ? depo.id : null,
        };
        
        form.setFieldsValue(formattedRecord);
        setCurrentId(record.id);
      } else {
        openNotificationWithIcon('error', 'Hata!', 'Bu ambalaj artık mevcut değil.');
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Hata!', 'Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  // Ant Design table columns
  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "FullStockCode",
      key: "FullStockCode",
    },
    {
      title: "Stok Adı",
      dataIndex: "StockName",
      key: "StockName",
    },
    {
      title: "Birim",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "Birim Miktarı",
      dataIndex: "UnitAmount",
      key: "UnitAmount",
      render: (text) =>
        text !== undefined
          ? parseFloat(text).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          : "N/A"
    },
    {
      title: "Minimum Stok",
      dataIndex: "MinimumStock",
      key: "MinimumStock",
      render: (text) =>
        text !== undefined
          ? parseFloat(text).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          : "N/A"
    },
    {
      title: "Maksimum Stok",
      dataIndex: "MaximumStock",
      key: "MaximumStock",
      render: (text) =>
        text !== undefined
          ? parseFloat(text).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          : "N/A"
    },
    {
      title: "Depo Adı",
      dataIndex: "depoAdi",
      key: "depoAdi",
    },
    {
      title: "Yardımcı Birimler",
      dataIndex: "auxiliaryUnits",
      key: "auxiliaryUnits",
      render: (auxiliaryUnits) =>
        auxiliaryUnits && auxiliaryUnits.length > 0
          ? auxiliaryUnits
              .map((unit) =>
                unit.amount
                  ? `${parseFloat(unit.amount).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${unit.unit}`
                  : "Miktar Yok"
              )
              .join(", ")
          : "N/A"
    },
    {
      title: "Aksiyonlar",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            type="primary"
          >
            Düzenle
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            danger
          >
            Sil
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-wrapper mt-5">
      <h2>Ambalaj Stok Listesi</h2>

      <Input
        placeholder="Stok kodu veya stok adı ara"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: 16, width: "300px" }}
      />

      <Select
        style={{ marginBottom: 16, width: "300px" }}
        value={selectedDepot}
        onChange={(value) => setSelectedDepot(value)}
      >
        {depolar.map((depo) => (
          <Option key={depo.id} value={depo.id}>
            {depo.depoAdi}
          </Option>
        ))}
      </Select>

      <Table columns={columns} dataSource={filteredAmbalajList} rowKey="id" />

      <Modal
        title={isEditing ? "Ambalaj Düzenle" : "Yeni Ambalaj Ekle"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            İptal
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {isEditing ? "Güncelle" : "Ekle"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="FullStockCode"
            label="Stok Kodu"
            initialValue={nextStockCode}
          >
            <Input disabled value={nextStockCode} />
          </Form.Item>

          <Form.Item
            name="StockName"
            label="Stok Adı"
            rules={[{ required: true, message: "Lütfen stok adını girin!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="Unit"
            label="Birim"
            rules={[{ required: true, message: "Lütfen birim seçin!" }]}
          >
            <Select placeholder="Birim Seç">
              {units.map((unit) => (
                <Option key={unit.id} value={unit.unit}>
                  {unit.unit}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="UnitAmount"
            label="Birim Miktarı"
            rules={[{ required: true, message: "Lütfen birim miktarını girin!" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="MinimumStock"
            label="Minimum Stok"
            rules={[{ required: true, message: "Lütfen minimum stoğu girin!" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="MaximumStock"
            label="Maksimum Stok"
            rules={[{ required: true, message: "Lütfen maksimum stoğu girin!" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="Warehouse"
            label="Depo"
            rules={[{ required: true, message: "Lütfen depo seçin!" }]}
          >
            <Select placeholder="Depo Seç">
              {depolar.map((depo) => (
                <Option key={depo.id} value={depo.id}>
                  {depo.depoAdi}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name="auxiliaryUnits">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "amount"]}
                      fieldKey={[fieldKey, "amount"]}
                    >
                      <Input placeholder="Miktar" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "unit"]}
                      fieldKey={[fieldKey, "unit"]}
                    >
                      <Select placeholder="Birim Seç">
                        {units.map((unit) => (
                          <Option key={unit.id} value={unit.unit}>
                            {unit.unit}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Yardımcı Birim Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default AmbalajStokListesi;
