import React, { createContext, useContext, useEffect, useState } from 'react';
import { db } from '../../environment';
import { doc, getDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const userId = localStorage.getItem('userId');
      if (userId) {
        const userRef = doc(db, "users", userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setCurrentUser(docSnap.data());
        } else {
          console.log("No such user!");
          setCurrentUser(null);
        }
      } else {
        console.log("No user ID found in local storage.");
        setCurrentUser(null);
      }
      setLoading(false);
    };

    fetchUser();

    return () => {
      // Optionally clean up any subscriptions or data here
    };
  }, []);

  const value = {
    currentUser,
    setCurrentUser // Optionally expose this if you need to update the currentUser from other components
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired // Ensuring that children prop is a React node
};
