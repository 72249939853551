import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, addDoc, updateDoc, query, where } from 'firebase/firestore';
import { Table, Button } from 'antd';
import { db } from '../../../environment';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const PendingOrdersTableSTA = ({ setSelectedOrderId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const MySwal = withReactContent(Swal);
  const userRole = localStorage.getItem('role'); // Ideally, fetch this more securely

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'pendingOrdersSTA'));
      const ordersData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setOrders(ordersData);
      setLoading(false);
    };

    fetchOrders();
  }, []);

  const handleApprove = async (order) => {
    const result = await MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Seçilen siparişi onaylamak istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, onayla!',
      cancelButtonText: 'Hayır, iptal et!',
    });

    if (result.isConfirmed) {
      try {
        await Promise.all(order.urunler.map(async (urun) => {
          const stockQuery = query(collection(db, 'stocks'), where('FullStockCode', '==', urun.urunKodu));
          const stockSnapshot = await getDocs(stockQuery);

          if (!stockSnapshot.empty) {
            const stockDoc = stockSnapshot.docs[0];
            const currentStock = parseFloat(stockDoc.data().UnitAmount) || 0;
            const urunMiktar = parseFloat(urun.miktar) || 0;

            const newStock = currentStock + urunMiktar;
            await updateDoc(stockDoc.ref, { UnitAmount: newStock });
          } else {
            throw new Error(`Stok bulunamadı: ${urun.urunAdi} (${urun.urunKodu})`);
          }
        }));

        await addDoc(collection(db, 'approvedOrdersSTA'), order);
        await deleteDoc(doc(db, 'pendingOrdersSTA', order.id));

        await axios.post('https://us-central1-yucelkimyaerpdb.cloudfunctions.net/sendOrderApprovalEmail-sendOrderApprovalEmail', {
          email: order.formuHazirlayanEmail,
          orderNumber: order.belgeNo,
        });

        setOrders(orders.filter(o => o.id !== order.id));

        MySwal.fire({
          title: 'Sipariş Onaylandı!',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Error approving order: ', error);
        MySwal.fire({
          title: 'Sipariş onaylanırken hata oluştu',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };

  const handleDelete = async (order) => {
    const result = await MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Seçilen siparişi silmek istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et!',
    });

    if (result.isConfirmed) {
      try {
        await deleteDoc(doc(db, 'pendingOrdersSTA', order.id));
        setOrders(orders.filter(o => o.id !== order.id));
        MySwal.fire({
          title: 'Sipariş Silindi!',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Error deleting order: ', error);
        MySwal.fire({
          title: 'Sipariş silinirken hata oluştu',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };

  const handleRowClick = (record) => {
    setSelectedOrder(record);
    console.log("kayitt", record);
    setSelectedOrderId(record.id);
  };

  const columns = [
    { title: 'Belge No', dataIndex: 'belgeNo', key: 'belgeNo' },
    { title: 'Cari Kodu', dataIndex: 'cariKodu', key: 'cariKodu' },
    { title: 'Cari Adı', dataIndex: 'cariAdi', key: 'cariAdi' },
    { title: 'Sipariş Tarihi', dataIndex: 'siparisTarihi', key: 'siparisTarihi', render: date => date?.toDate().toLocaleDateString() },
    {
      title: 'Eylem',
      key: 'action',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {userRole === "Satın Alma" ? null :
            <Button type="primary" onClick={() => handleApprove(record)} style={{ marginBottom: '5px' }}>
              Onayla
            </Button>
          }
          {userRole === "Satın Alma" ? null :
            <Button type="default" onClick={() => handleDelete(record)}>
              Sil
            </Button>
          }
        </div>
      ),
    },
  ];

  return (
    <div className='mb-3'>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="id"
        rowClassName={(record) => (record.id === selectedOrder?.id ? 'selected-row' : '')}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={{
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          showQuickJumper: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: true }} // Allow horizontal scrolling for narrow screens
      />
    </div>
  );
};

PendingOrdersTableSTA.propTypes = {
  setSelectedOrderId: PropTypes.func.isRequired,
};

export default PendingOrdersTableSTA;
