import {
    Box,
    ChevronUp,
    Edit,
    Eye,
    Filter,
    GitMerge,
    PlusCircle,
    RotateCcw,
    Sliders,
    StopCircle,
    Trash2,
  } from "feather-icons-react/build/IconComponents";
  import React, { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Link } from "react-router-dom";
  import Select from "react-select";
  import ImageWithBasePath from "../../../core/img/imagewithbasebath";
  import Brand from "../../../core/modals/inventory/brand";
  import withReactContent from "sweetalert2-react-content";
  import Swal from "sweetalert2";
  import { all_routes } from "../../../Router/all_routes";
  import { OverlayTrigger, Tooltip } from "react-bootstrap";
  import Table from "../../../core/pagination/datatable";
  import { setToogleHeader } from "../../../core/redux/action";
  import { Download } from "react-feather";
  
  const SonReceteler = () => {
    const dataSource = useSelector((state) => state.product_list);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
  
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
      setIsFilterVisible((prevVisibility) => !prevVisibility);
    };
    const route = all_routes;
    const options = [
      { value: "sortByDate", label: "Tarihe göre sırala" },
      { value: "140923", label: "14 09 23" },
      { value: "110923", label: "11 09 23" },
    ];
    const productlist = [
      { value: "choose", label: "Hammadde Seçiniz" },
      { value: "lenovo", label: "Lenovo 3rd Generation" },
      { value: "nike", label: "Nike Jordan" },
    ];
    const categorylist = [
      { value: "choose", label: "Kategori seçiniz" },
      { value: "laptop", label: "Laptop" },
      { value: "shoe", label: "Shoe" },
    ];
    const subcategorylist = [
      { value: "choose", label: "Alt kategori seçiniz" },
      { value: "computers", label: "Computers" },
      { value: "fruits", label: "Fruits" },
    ];
    const brandlist = [
      { value: "all", label: "Tüm Markalar" },
      { value: "lenovo", label: "Lenovo" },
      { value: "nike", label: "Nike" },
    ];
    const price = [
      { value: "price", label: "Fiyat" },
      { value: "12500", label: "$12,500.00" },
      { value: "13000", label: "$13,000.00" }, // Replace with your actual values
    ];
  
    const columns = [
      {
        title: "Reçeteler",
        dataIndex: "product",
        render: (text, record) => (
          <span className="productimgname">
            <Link to="/profile" className="product-img stock-img">
              <ImageWithBasePath alt="" src={record.productImage} />
            </Link>
            <Link to="/profile">{text}</Link>
          </span>
        ),
        sorter: (a, b) => a.product.length - b.product.length,
      },
      {
        title: "Referans No",
        dataIndex: "sku",
        sorter: (a, b) => a.sku.length - b.sku.length,
      },
  
      {
        title: "Kategori",
        dataIndex: "category",
        sorter: (a, b) => a.category.length - b.category.length,
      },
  
      {
        title: "Marka",
        dataIndex: "brand",
        sorter: (a, b) => a.brand.length - b.brand.length,
      },
      {
        title: "Fiyat",
        dataIndex: "price",
        sorter: (a, b) => a.price.length - b.price.length,
      },
      {
        title: "Birim",
        dataIndex: "unit",
        sorter: (a, b) => a.unit.length - b.unit.length,
      },
      {
        title: "Miktar",
        dataIndex: "qty",
        sorter: (a, b) => a.qty.length - b.qty.length,
      },
  
      {
        title: "Oluşturan",
        dataIndex: "createdby",
        render: (text, record) => (
          <span className="userimgname">
            <Link to="/profile" className="product-img">
              <ImageWithBasePath alt="" src={record.img} />
            </Link>
            <Link to="/profile">{text}</Link>
          </span>
        ),
        sorter: (a, b) => a.createdby.length - b.createdby.length,
      },
      {
        title: " ",
        dataIndex: "action",
        render: () => (
          <td className="action-table-data">
            <div className="edit-delete-action">
              <div className="input-block add-lists"></div>
              <Link className="me-2 p-2" to={route.productdetails}>
                <Eye className="feather-view" />
              </Link>
              <Link className="me-2 p-2" to={route.editproduct}>
                <Edit className="feather-edit" />
              </Link>
              <Link
                className="confirm-text p-2"
                to="#"
                onClick={showConfirmationAlert}
              >
                <Trash2 className="feather-trash-2" />
              </Link>
            </div>
          </td>
        ),
        sorter: (a, b) => a.createdby.length - b.createdby.length,
      },
    ];
    const MySwal = withReactContent(Swal);
  
    const showConfirmationAlert = () => {
      MySwal.fire({
        title: "Emin misiniz?",
        text: "Bu işlem geri alınamayacak!",
        showCancelButton: true,
        confirmButtonColor: "#00ff00",
        confirmButtonText: "Evet, sil!",
        cancelButtonColor: "#ff0000",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: "Silindi!",
            text: "Kayıt silindi!",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          MySwal.close();
        }
      });
    };
  
    const renderTooltip = (props) => (
      <Tooltip id="pdf-tooltip" {...props}>
        Pdf
      </Tooltip>
    );
    const renderExcelTooltip = (props) => (
      <Tooltip id="excel-tooltip" {...props}>
        Excel
      </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
      <Tooltip id="printer-tooltip" {...props}>
        Printer
      </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
      <Tooltip id="refresh-tooltip" {...props}>
        Refresh
      </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
      <Tooltip id="refresh-tooltip" {...props}>
        Daralt
      </Tooltip>
    );
    return (
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Son Reçeteler Listesi</h4>
                <h6>Son Reçetelerinizi yönetin.</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link to={route.sonreceteekle} className="btn btn-added">
                <PlusCircle className="me-2 iconsize" />
                Yeni Reçete Ekle
              </Link>
            </div>
            <div className="page-btn import">
              <Link
                to="#"
                className="btn btn-added color"
                data-bs-toggle="modal"
                data-bs-target="#view-notes"
              >
                <Download className="me-2" />
                Reçete Verisi Ekle
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Ara"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="select"
                    options={options}
                    placeholder="14 09 23"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <Box className="info-img" />
                            <Select
                              className="select"
                              options={productlist}
                              placeholder="Hammadde Seçiniz"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <StopCircle className="info-img" />
                            <Select
                              className="select"
                              options={categorylist}
                              placeholder="Kategori Seçiniz"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <GitMerge className="info-img" />
                            <Select
                              className="select"
                              options={subcategorylist}
                              placeholder="Alt kategori seçiniz."
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <StopCircle className="info-img" />
                            <Select
                              className="select"
                              options={brandlist}
                              placeholder="Nike"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <i className="fas fa-money-bill info-img" />
  
                            <Select
                              className="select"
                              options={price}
                              placeholder="Ücret"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <Link className="btn btn-filters ms-auto">
                              {" "}
                              <i
                                data-feather="search"
                                className="feather-search"
                              />{" "}
                              Ara{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={dataSource} />
              </div>
            </div>
          </div>
          {/* /product list */}
          <Brand />
        </div>
      </div>
    );
  };
  
  export default SonReceteler;
  