// src/feature-module/Application/participantview.jsx

import { useMeeting, useParticipant } from '@videosdk.live/react-sdk';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Card, Avatar, Tooltip, Button } from 'antd';
import { 
  AudioMutedOutlined, 
  AudioOutlined, 
  VideoCameraOutlined, 
  VideoCameraAddOutlined, 
  ShareAltOutlined,
  YoutubeOutlined,
  StopOutlined,
} from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../environment';

const { Meta } = Card;

function ParticipantView({ participantId, focused }) {
  const micRef = useRef(null);
  const videoRef = useRef(null);
  const [username, setUsername] = useState('');
  const [recording, setRecording] = useState(false);
  const [screenShareWindow, setScreenShareWindow] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const userDocRef = doc(db, 'users', userId);
      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setUsername(docSnap.data().username || '');
        }
      }).catch((error) => {
        console.error("Error fetching user data: ", error);
      });
    }
  }, []);

  const {
    webcamStream,
    micStream,
    screenShareStream,
    webcamOn,
    micOn,
    screenShareOn,
    networkQuality,
  } = useParticipant(participantId);

  const {
    toggleMic,
    toggleWebcam,
    toggleScreenShare,
    startRecording,
    stopRecording
  } = useMeeting();

  const videoStream = useMemo(() => {
    if (screenShareOn && screenShareStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(screenShareStream.track);
      return mediaStream;
    }
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [screenShareStream, screenShareOn, webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) => console.error("audioElem.current.play() failed", error));
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  const handleRecording = () => {
    if (recording) {
      stopRecording();
    } else {
      startRecording();
    }
    setRecording(!recording);
  };

  useEffect(() => {
    if (screenShareOn && screenShareStream) {
      const screenShareMediaStream = new MediaStream();
      screenShareMediaStream.addTrack(screenShareStream.track);
      const newWindow = window.open("", "ScreenShare", "width=800,height=600");
      if (newWindow) {
        const video = newWindow.document.createElement("video");
        video.srcObject = screenShareMediaStream;
        video.autoplay = true;
        newWindow.document.body.appendChild(video);
        setScreenShareWindow(newWindow);
      }
    } else if (screenShareWindow) {
      screenShareWindow.close();
      setScreenShareWindow(null);
    }
  }, [screenShareOn, screenShareStream]);

  return (
    <div>
      <Card
        className={`mb-3 ${focused ? 'focused' : ''}`}
        style={{ borderRadius: '10px', width: '300px', margin: '5px', border: focused ? '2px solid #1890ff' : 'none' }}
        cover={
          <div ref={videoRef}>
            {videoStream ? (
              <ReactPlayer
                playsinline
                pip={false}
                light={false}
                controls={false}
                muted={true}
                playing={true}
                url={videoStream}
                width="100%"
                height="200px"
                onError={(err) => console.log(err, "participant video error")}
              />
            ) : (
              <div className="d-flex align-items-center justify-content-center" style={{ height: '200px', backgroundColor: '#f0f0f0' }}>
                <span style={{ fontSize: '20px', color: '#999' }}>Kamera Kapalı</span>
              </div>
            )}
          </div>
        }
        actions={[
          <Tooltip title={micOn ? "Mikrofonu Kapat" : "Mikrofonu Aç"} key="mic">
            <Button icon={micOn ? <AudioOutlined /> : <AudioMutedOutlined />} onClick={()=>toggleMic()} />
          </Tooltip>,
          <Tooltip title={webcamOn ? "Kamerayı Kapat" : "Kamerayı Aç"} key="webcam">
            <Button icon={webcamOn ? <VideoCameraOutlined /> : <VideoCameraAddOutlined />} onClick={()=>toggleWebcam()} />
          </Tooltip>,
          <Tooltip title={screenShareOn ? "Ekran Paylaşımını Durdur" : "Ekran Paylaşımını Başlat"} key="screenShare">
            <Button icon={<ShareAltOutlined />} onClick={()=>toggleScreenShare()} />
          </Tooltip>,
          <Tooltip title={recording ? "Kaydı Durdur" : "Kaydı Başlat"} key="recording">
            <Button icon={recording ? <StopOutlined /> : <YoutubeOutlined />} onClick={handleRecording} />
          </Tooltip>,
         
        ]}
      >
        <Meta
          avatar={<Avatar style={{ backgroundColor: '#87d068' }}>{username ? username.charAt(0).toUpperCase() : 'K'}</Avatar>}
          title={username}
          description={networkQuality ? `Ağ Kalitesi: ${networkQuality}` : 'Ağ Kalitesi: Bilinmiyor'}
        />
        <audio ref={micRef} autoPlay muted={false} />
        {/* {showMessages && <MessageComponent userId={localStorage.getItem('userId')} />} */}
      </Card>
    </div>
  );
}

ParticipantView.propTypes = {
  participantId: PropTypes.string.isRequired,
  focused: PropTypes.bool,
};

export default ParticipantView;
