import React, { useEffect, useState } from 'react';
import ImageWithBasePath from '../../../core/img/imagewithbasebath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../Router/all_routes';
import { db } from '../../../environment';
import Swal from 'sweetalert2';
import { doc, setDoc,getDocs,collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // To generate unique IDs

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [roles, setRoles] = useState([]); // State to store roles

  useEffect(() => {
    const fetchRoles = async () => {
      const snapshot = await getDocs(collection(db, "roles"));
      const rolesData = snapshot.docs.map(doc => ({ label: doc.data().name, value: doc.id }));
      setRoles(rolesData);
    };
    fetchRoles();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      Swal.fire('Hata', 'Şifreler eşleşmiyor', 'error');
      return;
    }
    if (!agreeToTerms) {
      Swal.fire('Hata', 'Şartları ve koşulları kabul etmelisiniz.', 'error');
      return;
    }

    const defaultRole = roles.find(role => role.label === 'Kullanıcı') || roles[0]; // Find 'Kullanıcı' role or default to first role

    const userId = uuidv4(); // Generate a unique user ID

    try {
      await setDoc(doc(db, 'users', userId), {
        uid: userId,
        username: username,
        email: email,
        emailVerified: false,
        password: password, // Note: Storing passwords in plain text is insecure
        createdOn: new Date().toISOString(),
        role: {label:defaultRole.label,value:defaultRole.value}, // Set the default role value
        description: "",
        showModal:true
      });

      Swal.fire('Başarılı', 'Kayıt işlemi başarılı! Hesabınızın onaylanmasını bekleyiniz.', 'success');
    } catch (error) {
      let errorMessage = error.message;
      Swal.fire('Kayıt Başarısız', errorMessage, 'error');
    }
  };

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper register-wrap bg-img">
          <div className="login-content">
            <form onSubmit={handleSubmit}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/yclkimya.png" alt="img" />
                </div>
                <Link to={all_routes.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt="logo" />
                </Link>
                <div className="login-userheading">
                  <h3>Kayıt Ol</h3>
                  <h4 style={{width:400}}>Yeni Hesap Oluştur</h4>
                </div>
                <div className="form-login">
                  <label>Kullanıcı Adı</label>
                  <div className="form-addons">
                    <input type="text" className="form-control" value={username} onChange={e => setUsername(e.target.value)} />
                    <ImageWithBasePath src="assets/img/icons/user-icon.svg" alt="img" />
                  </div>
                </div>
                <div className="form-login">
                  <label>E-posta Adresi</label>
                  <div className="form-addons">
                    <input type="text" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                    <ImageWithBasePath src="assets/img/icons/mail.svg" alt="img" />
                  </div>
                </div>
                <div className="form-login">
                  <label>Şifre</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input" value={password} onChange={e => setPassword(e.target.value)} />
                    <span className="fas toggle-password fa-eye-slash" />
                  </div>
                </div>
                <div className="form-login">
                  <label>Şifreyi tekrar girin</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                    <span className="fas toggle-passwords fa-eye-slash" />
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="custom-control custom-checkbox justify-content-start">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" checked={agreeToTerms} onChange={() => setAgreeToTerms(!agreeToTerms)} />
                            <span className="checkmarks" />Şartları ve Koşulları{" "}
                            <Link style={{color:'red'}} to="#" className="hover-a">
                              Kabul Ettim
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <button type="submit" className="btn btn-login">
                    Kayıt Ol
                  </button>
                </div>
                <div className="signinform">
                  <h4>
                    Zaten hesabınız var mı ?{" "}
                    <Link to={all_routes.signin} className="hover-a">
                      Giriş Yapın
                    </Link>
                  </h4>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
