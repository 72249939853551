import React, { useState, useEffect, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { collection, addDoc, query, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { Form, Input, Button, Row, Col, DatePicker, Select, Spin, Modal, Collapse, Typography, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { db } from '../../environment';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import PrintComponent from './utils/printcomponent';
import dayjs from 'dayjs';
import { Container } from 'react-bootstrap';

const API_KEY = '025f1d2d3e0b4217bfed93c3';
const BASE_URL = 'https://v6.exchangerate-api.com/v6';
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const OrderForm = () => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      urunler: [{ urunAdi: '', urunKodu: '', miktar: '', birim: '', birimFiyat: '', paraBirimi: '', iskonto: '', kdv: '', tutar: '' }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "urunler"
  });
  const componentRef = useRef();
  const [allCaris, setAllCaris] = useState([]);
  const [, setSelectedCari] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(false);
  const [allStockData, setAllStockData] = useState([]);
  const [language, setLanguage] = useState('tr');

  const watchBelgeNo = watch('belgeNo', '');
  const watchUrunler = watch('urunler', []);
  
  const getTitle = () => {
    if (watchBelgeNo.startsWith('STS')) {
      return language === 'tr' ? 'Satış Sipariş Formu' : 'Sales Order Form';
    } else if (watchBelgeNo.startsWith('ALS')) {
      return language === 'tr' ? 'Alış Sipariş Formu' : 'Purchase Order Form';
    } else if (watchBelgeNo.startsWith('PRF')) {
      return language === 'tr' ? 'Proforma Fatura' : 'Proforma Invoice';
    } else {
      return '';
    }
  };
  
  const fetchAllCaris = async () => {
    try {
      const carisSnapshot = await getDocs(collection(db, 'allCaris'));
      const carisList = carisSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllCaris(carisList);
    } catch (error) {
      console.error("Cari verisi alınamadı: ", error);
    }
  };

  const handleUrunAdiChange = async (urunAdi, index) => {
    const selectedStock = allStockData.find(stock => stock.StockName === urunAdi);
    if (selectedStock) {
      try {
        const stockManagerSnapshot = await getDocs(collection(db, 'stockManager'));
        const stockManagerData = stockManagerSnapshot.docs.map(doc => doc.data());
        const category = stockManagerData.find(item => item.id === selectedStock.categoryId)?.title || '';
        setValue(`urunler[${index}].urunKodu`, selectedStock.FullStockCode);
        setValue(`urunler[${index}].urunKategorisi`, category);
        setValue(`urunler[${index}].birim`, selectedStock.Unit);
        setValue(`urunler[${index}].birimFiyat`, selectedStock.StandardCost);
        setValue(`urunler[${index}].paraBirimi`, selectedStock.Currency);
        setValue(`urunler[${index}].CurrentStock`, selectedStock.CurrentStock);
      } catch (error) {
        console.error("Kategori bilgisi alınamadı: ", error);
      }
    }
  };

  const fetchAllStockData = async () => {
    setLoading(true);
    try {
      const stockSnapshot = await getDocs(collection(db, 'stocks'));
      const stockList = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllStockData(stockList.filter(stock => stock.StockCode.startsWith('152')));
    } catch (error) {
      console.error("Stok verisi alınamadı: ", error);
    }
    setLoading(false);
  };

  const fetchExchangeRates = async () => {
    setLoading(true);
    try {
      const responseUSD = await axios.get(`${BASE_URL}/${API_KEY}/latest/USD`);
      const responseEUR = await axios.get(`${BASE_URL}/${API_KEY}/latest/EUR`);

      const rates = {
        USD: responseUSD.data.conversion_rates.TRY,
        EUR: responseEUR.data.conversion_rates.TRY,
        TRY: 1 // TRY'nin kendisi 1 olarak alınır
      };
      setExchangeRates(rates);
    } catch (error) {
      console.error("Döviz kuru alınamadı: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllCaris()
    fetchAllStockData();
    fetchExchangeRates();
  }, []);

  const handleCariChange = async (cariAdi) => {
    const selectedCari = allCaris.find(cari => cari.KartAdi === cariAdi);

    const handleNullValue = (value) => {
      return value == null ? "" : value;
    };

    if (selectedCari) {
      setValue('cariKodu', handleNullValue(selectedCari.KartKodu));
      setValue('cariAdi', handleNullValue(selectedCari.KartAdi));
      setValue('vergiDairesi', handleNullValue(selectedCari.VergiDairesi));
      setValue('vergiNumarasi', handleNullValue(selectedCari.VergiNo));
      setValue('adres', handleNullValue(selectedCari.Adres));
      setValue('email', handleNullValue(selectedCari.EPosta));
      setValue('muhHesapKodu', handleNullValue(selectedCari.MuhHesapKodu));
      setValue('muhHesapAdi', handleNullValue(selectedCari.MuhHesapAdi));
      setSelectedCari(selectedCari);
    }
  };

  const calculatePrices = () => {
    setLoading(true);
    setTimeout(() => {
      watchUrunler.forEach((urun, index) => {
        const birimFiyat = parseFloat(urun.birimFiyat) || 0;
        const miktar = parseFloat(urun.miktar) || 0;
        const kdv = parseFloat(urun.kdv) || 0;
        const iskonto = parseFloat(urun.iskonto) || 0;
        const paraBirimi = urun.paraBirimi;
        const rate = exchangeRates[paraBirimi];

        if (rate) {
          const toplamFiyat = birimFiyat * miktar;
          const iskontoFiyat = iskonto ? toplamFiyat * (1 - iskonto / 100) : toplamFiyat;
          const kdvliFiyat = kdv ? iskontoFiyat * (1 + kdv / 100) : iskontoFiyat;

          let tryTutar, usdTutar, eurTutar;

          if (paraBirimi === 'TRY') {
            tryTutar = toplamFiyat;
            usdTutar = tryTutar / exchangeRates.USD;
            eurTutar = tryTutar / exchangeRates.EUR;
          } else if (paraBirimi === 'USD') {
            usdTutar = toplamFiyat;
            tryTutar = usdTutar * exchangeRates.USD;
            eurTutar = tryTutar / exchangeRates.EUR;
          } else if (paraBirimi === 'EUR') {
            eurTutar = toplamFiyat;
            tryTutar = eurTutar * exchangeRates.EUR;
            usdTutar = tryTutar / exchangeRates.USD;
          }

          setValue(`urunler[${index}].toplamFiyat`, toplamFiyat.toFixed(2));
          setValue(`urunler[${index}].iskontoFiyat`, iskontoFiyat.toFixed(2));
          setValue(`urunler[${index}].kdvliFiyat`, kdvliFiyat.toFixed(2));
          setValue(`urunler[${index}].tryTutar`, tryTutar.toFixed(2));
          setValue(`urunler[${index}].usdTutar`, usdTutar.toFixed(2));
          setValue(`urunler[${index}].eurTutar`, eurTutar.toFixed(2));

          setValue(`urunler[${index}].tutar`, toplamFiyat.toFixed(2));
        } else {
          console.log(`Döviz kuru bulunamadı: ${paraBirimi}`);
        }
      });
      setLoading(false);
    }, 2000);
  };

  const generateNextBelgeNo = async () => {
    const q = query(collection(db, 'pendingOrders'), orderBy('belgeNo', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return 'PRF-00001';
    } else {
      const lastBelgeNo = querySnapshot.docs[0].data().belgeNo;
      const lastNumber = parseInt(lastBelgeNo.split('-')[1]);
      const nextNumber = lastNumber + 1;
      return `PRF-${nextNumber.toString().padStart(5, '0')}`;
    }
  };

  useEffect(() => {
    const setInitialBelgeNo = async () => {
      const nextBelgeNo = await generateNextBelgeNo();
      setValue('belgeNo', nextBelgeNo);
    };

    setInitialBelgeNo();
  }, []);

  const removeUndefinedValues = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
          newObj[key] = removeUndefinedValues(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    });
    return newObj;
  };

  const onSubmit = async (data) => {
    try {
      Modal.confirm({
        title: language === 'tr' ? 'Kaydı eklemek istediğinize emin misiniz?' : 'Are you sure you want to add the record?',
        okText: language === 'tr' ? 'Evet' : 'Yes',
        cancelText: language === 'tr' ? 'Hayır' : 'No',
        onOk: async () => {
          setLoading(true);

          // Convert dayjs dates to valid Date objects before converting to Timestamps
          const convertToDate = (date) => {
            return date ? date.toDate() : null;
          };

          data.siparisTarihi = convertToDate(data.siparisTarihi);
          data.sevkTarihi = convertToDate(data.sevkTarihi);
          data.uretimTarihi = convertToDate(data.uretimTarihi);
          data.sonKullanmaTarihi = convertToDate(data.sonKullanmaTarihi);
          data.faturaTarihi = convertToDate(data.faturaTarihi); // Ekledim
          data.belgeTarihi = convertToDate(data.belgeTarihi); // Ekledim

          // Remove undefined values from data
          const cleanedData = removeUndefinedValues(data);

          await addDoc(collection(db, 'pendingOrders'), {
            ...cleanedData,
            siparisTarihi: data.siparisTarihi ? Timestamp.fromDate(data.siparisTarihi) : null,
            sevkTarihi: data.sevkTarihi ? Timestamp.fromDate(data.sevkTarihi) : null,
            uretimTarihi: data.uretimTarihi ? Timestamp.fromDate(data.uretimTarihi) : null,
            sonKullanmaTarihi: data.sonKullanmaTarihi ? Timestamp.fromDate(data.sonKullanmaTarihi) : null,
            faturaTarihi: data.faturaTarihi ? Timestamp.fromDate(data.faturaTarihi) : null, // Ekledim
            belgeTarihi: data.belgeTarihi ? Timestamp.fromDate(data.belgeTarihi) : null // Ekledim
          });

          reset();
          const nextBelgeNo = await generateNextBelgeNo();
          setValue('belgeNo', nextBelgeNo);
          setLoading(false);
          Modal.success({
            content: language === 'tr' ? 'Kayıt başarıyla eklendi!' : 'Record added successfully!'
          });
        }
      });
    } catch (error) {
      setLoading(false);
      Modal.error({
        title: language === 'tr' ? 'Hata' : 'Error',
        content: language === 'tr' ? 'Kayıt eklenirken bir hata oluştu. Lütfen tekrar deneyin.' : 'An error occurred while adding the record. Please try again.'
      });
    }
  };

  return (
    <div className='page-wrapper'>
      <Container className="mt-5">
        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '30px', borderRadius: '15px' }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary" style={{ marginBottom: '20px' }}>
                {language === 'tr' ? 'Yazdır' : 'Print'}
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Select defaultValue="tr" onChange={(value) => {
              setLanguage(value);
              console.log('Selected language:', value);
            }}>
              <Option value="tr">Türkçe</Option>
              <Option value="en">English</Option>
            </Select>
          </div>
          <Title level={2} className="mb-4" style={{ textAlign: 'center', color: '#1890ff' }}>
            {language === 'tr' ? 'Satış Sipariş Formu' : 'Order Form'}
          </Title>

          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
            <Collapse defaultActiveKey={['1']} expandIconPosition="right">
              <Collapse.Panel header={language === 'tr' ? 'Müşteri Bilgileri' : 'Customer Information'} key="1">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Cari Kodu' : 'Customer Code'} required>
                      <Controller
                        name="cariKodu"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Cari Adı' : 'Customer Name'} required>
                      <Controller
                        name="cariAdi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            showSearch
                            placeholder={language === 'tr' ? 'Cari Adı Seçin' : 'Select Customer Name'}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            size="large"
                            onChange={(value) => {
                              field.onChange(value);
                              handleCariChange(value);
                            }}
                          >
                            {allCaris.map(cari => (
                              <Option key={cari.KartAdi} value={cari.KartAdi}>
                                {cari.KartAdi}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>

                    <Form.Item label="Email">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Adres' : 'Address'}>
                      <Controller
                        name="adres"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Vergi Dairesi' : 'Tax Office'}>
                      <Controller
                        name="vergiDairesi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Vergi Numarası' : 'Tax Number'}>
                      <Controller
                        name="vergiNumarasi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Telefon' : 'Phone'}>
                      <Controller
                        name="telefon"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'İlgili Kişi Adı' : 'Contact Person Name'}>
                      <Controller
                        name="ilgiliKisiAdi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'İlgili Kişi Telefon' : 'Contact Person Phone'}>
                      <Controller
                        name="ilgiliKisiTelefon"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'İlgili Kişi Email' : 'Contact Person Email'}>
                      <Controller
                        name="ilgiliKisiEmail"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
            <Spin style={{ alignItems: 'center' }} spinning={loading} tip={language === 'tr' ? 'Hesaplanıyor...' : 'Calculating...'}>
              {fields.map((field, index) => (
                <div key={field.id}>
                  <Row gutter={16}>
                    <Col span={3}>
                      <Form.Item label={language === 'tr' ? 'Ürün Adı' : 'Product Name'} required>
                        <Controller
                          name={`urunler[${index}].urunAdi`}
                          control={control}
                          rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              showSearch
                              placeholder={language === 'tr' ? 'Ürün Adı Seçin' : 'Select Product Name'}
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                if (option && option.children) {
                                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                              }}
                              size="large"
                              onChange={(value) => {
                                field.onChange(value);
                                handleUrunAdiChange(value, index);
                              }}
                            >
                              {allStockData.map(stock => (
                                <Option key={stock.StockName} value={stock.StockName}>
                                  {stock.StockName}
                                </Option>
                              ))}
                            </Select>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'Ürün Kodu' : 'Product Code'}>
                        <Controller
                          name={`urunler[${index}].urunKodu`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" readOnly />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'Miktar' : 'Quantity'}>
                        <Controller
                          name={`urunler[${index}].miktar`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'Birim' : 'Unit'}>
                        <Controller
                          name={`urunler[${index}].birim`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" readOnly />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'Birim Fiyat' : 'Unit Price'}>
                        <Controller
                          name={`urunler[${index}].birimFiyat`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'Para Birimi' : 'Currency'}>
                        <Controller
                          name={`urunler[${index}].paraBirimi`}
                          control={control}
                          render={({ field }) => (
                            <Select {...field} size="large">
                              <Option value="TRY">TRY</Option>
                              <Option value="USD">USD</Option>
                              <Option value="EUR">EUR</Option>
                            </Select>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'İskonto (%)' : 'Discount (%)'}>
                        <Controller
                          name={`urunler[${index}].iskonto`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={language === 'tr' ? 'KDV (%)' : 'VAT (%)'}>
                        <Controller
                          name={`urunler[${index}].kdv`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label={language === 'tr' ? 'Tutar' : 'Amount'}>
                        <Controller
                          name={`urunler[${index}].tutar`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" readOnly />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label={language === 'tr' ? 'Mevcut Stok' : 'Current Stock'}>
                        <Controller
                          name={`urunler[${index}].CurrentStock`}
                          control={control}
                          render={({ field }) => <Input {...field} size="large" readOnly />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Button
                        type="text"
                        onClick={() => remove(index)}
                        icon={<MinusCircleOutlined />}
                        size="large"
                        style={{ marginTop: '30px', color: 'red' }}
                      >
                        {language === 'tr' ? 'Sil' : 'Delete'}
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => append({ urunAdi: '', urunKodu: '', urunKategorisi: '', miktar: '', birim: '', birimFiyat: '', paraBirimi: '', iskonto: '', kdv: '', tutar: '' })}
                  block
                  icon={<PlusOutlined />}
                  size="large"
                >
                  {language === 'tr' ? 'Ürün Ekle' : 'Add Product'}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="default" onClick={calculatePrices} block size="large">
                  {language === 'tr' ? 'Fiyatları Hesapla' : 'Calculate Prices'}
                </Button>
              </Form.Item>
            </Spin>

            <Collapse defaultActiveKey={['3']} expandIconPosition="right">
              <Collapse.Panel header={language === 'tr' ? 'Sipariş Bilgileri' : 'Order Information'} key="3">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Sipariş Tarihi' : 'Order Date'} required>
                      <Controller
                        name="siparisTarihi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Geçerlilik Tarihi' : 'Validity Date'} required >
                      <Controller
                        name="sevkTarihi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Ödeme Şekli' : 'Payment Method'} required>
                      <Controller
                        name="odemeSekli"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="large"
                          >
                            <Option value="nakit">{language === 'tr' ? 'Nakit' : 'Cash'}</Option>
                            <Option value="krediKarti">{language === 'tr' ? 'Kredi Kartı' : 'Credit Card'}</Option>
                            <Option value="havale">{language === 'tr' ? 'Havale' : 'Wire Transfer'}</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Fatura Tipi' : 'Invoice Type'} required>
                      <Controller
                        name="faturaTipi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="large"
                          >
                            <Option value="İhracat Faturası">{language === 'tr' ? 'İhracat Faturası' : 'Export Invoice'}</Option>
                            <Option value="Yurtiçi Satış Faturası">{language === 'tr' ? 'Yurtiçi Satış Faturası' : 'Domestic Sales Invoice'}</Option>
                            <Option value="İhraç Kayıtlı Fatura">{language === 'tr' ? 'İhraç Kayıtlı Fatura' : 'Export Registered Invoice'}</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Fatura Adresi' : 'Invoice Address'} required>
                      <Controller
                        name="faturaAdresi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Fatura Açıklamaları' : 'Invoice Notes'}>
                      <Controller
                        name="faturaAciklamalari"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel header={language === 'tr' ? 'Teslimat Bilgileri' : 'Delivery Information'} key="4">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Teslimat Adresi' : 'Delivery Address'} required>
                      <Controller
                        name="teslimatAdresi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                 
                  <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Sevk Yöntemi' : 'Delivery Method'} required>
                    <Controller
                      name="sevkYontemi"
                      control={control}
                      rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="large"
                          mode="multiple"
                          placeholder={language === 'tr' ? 'Sevk Yöntemi Seçin' : 'Select Delivery Method'}
                        >
                          <Option value="Deniz Yolu,">{language === 'tr' ? 'Deniz yolu' : 'Sea Route'}</Option>
                          <Option value="Kara yolu,">{language === 'tr' ? 'Kara yolu' : 'Land Route'}</Option>
                          <Option value="Fabrikadan depo teslimatı,">{language === 'tr' ? 'Fabrikadan depo teslimatı' : 'Factory to Warehouse Delivery'}</Option>
                          <Option value="Depo teslimatı,">{language === 'tr' ? 'Depo teslimatı' : 'Warehouse Delivery'}</Option>
                        </Select>
                      )}
                    />
                  </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'İstenen Sevk Adresi' : 'Desired Delivery Address'} required>
                      <Controller
                        name="istenenSevkAdresi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Nakliye Firması' : 'Shipping Company'}>
                      <Controller
                        name="nakliyeFirmasi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Lojistik Ücreti' : 'Logistics Cost'} required>
                      <Controller
                        name="lojistikUcreti"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="large"
                          >
                            <Option value="Satıcıya aittir">{language === 'tr' ? 'Satıcıya aittir' : 'Seller Pays'}</Option>
                            <Option value="Alıcıya aittir">{language === 'tr' ? 'Alıcıya aittir' : 'Buyer Pays'}</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label={language === 'tr' ? 'Teslimat Notları' : 'Delivery Notes'}>
                  <Controller
                    name="teslimatNotlari"
                    control={control}
                    render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                  />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel header={language === 'tr' ? 'Diğer Bilgiler' : 'Other Information'} key="5">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Belge No' : 'Document No'}>
                      <Controller
                        name="belgeNo"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Belge Tarihi' : 'Document Date'}>
                      <Controller
                        name="belgeTarihi"
                        control={control}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Özel Talimatlar' : 'Special Instructions'}>
                      <Controller
                        name="ozelTalimatlar"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Sipariş Notları' : 'Order Notes'}>
                      <Controller
                        name="siparisNotlari"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel header={language === 'tr' ? 'Satış Temsilcisi Bilgileri' : 'Sales Representative Information'} key="6">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Formu Hazırlayan Adı' : 'Prepared By'} required>
                      <Controller
                        name="formuHazirlayanAdi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Formu Hazırlayan Telefon' : 'Prepared By Phone'} required>
                      <Controller
                        name="formuHazirlayanTelefon"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Formu Hazırlayan Email">
                      <Controller
                        name="formuHazirlayanEmail"
                        control={control}
                        rules={{
                          required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required',
                          pattern: { value: /^\S+@\S+$/i, message: language === 'tr' ? 'Geçerli bir email girin' : 'Enter a valid email' }
                        }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
            <Form.Item>
              <Button type="primary" htmlType="submit" block size="large">
                {language === 'tr' ? 'Siparişi Kaydet' : 'Save Order'}
              </Button>
            </Form.Item>

            <div style={{ display: "none" }}>
              <PrintComponent
              language={
                language
              }                ref={componentRef}
                title={getTitle()}
                customerInfo={{
                  cariKodu: watch("cariKodu"),
                  cariAdi: watch("cariAdi"),
                  adres: watch("adres"),
                  vergiDairesi: watch("vergiDairesi"),
                  vergiNumarasi: watch("vergiNumarasi"),
                  email: watch("email"),
                  telefon: watch("telefon"),
                  ilgiliKisiAdi: watch("ilgiliKisiAdi"),
                  ilgiliKisiTelefon: watch("ilgiliKisiTelefon"),
                  ilgiliKisiEmail: watch("ilgiliKisiEmail"),
                }}
                productInfo={watch("urunler")}
                orderInfo={{
                  siparisTarihi: watch("siparisTarihi")?.format("DD-MM-YYYY"),
                  sevkTarihi: watch("sevkTarihi")?.format("DD-MM-YYYY"),
                  odemeSekli: watch("odemeSekli"),
                  faturaTipi: watch("faturaTipi"),
                  faturaAdresi: watch("faturaAdresi"),
                  faturaAciklamalari: watch("faturaAciklamalari"),
                }}
                deliveryInfo={{
                  teslimatAdresi: watch("teslimatAdresi"),
                  sevkYontemi: watch("sevkYontemi"),
                  istenenSevkAdresi: watch("istenenSevkAdresi"),
                  nakliyeFirmasi: watch("nakliyeFirmasi"),
                  lojistikUcreti: watch("lojistikUcreti"),
                  belgeNo: watch("belgeNo"),
                  belgeTarihi: watch("belgeTarihi")?.format("DD-MM-YYYY"),
                }}
                otherInfo={{
                  ozelTalimatlar: watch("ozelTalimatlar"),
                  siparisNotlari: watch("siparisNotlari"),
                }}
                salesRepInfo={{
                  formuHazirlayanAdi: watch("formuHazirlayanAdi"),
                  formuHazirlayanTelefon: watch("formuHazirlayanTelefon"),
                  formuHazirlayanEmail: watch("formuHazirlayanEmail"),
                }}
                loading={loading}
              />
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default OrderForm;
