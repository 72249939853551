import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, addDoc,updateDoc, query, where } from 'firebase/firestore';
import { Table, Button } from 'antd';
import { db } from '../../../environment';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const PendingOrdersTable = ({ setSelectedOrderId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const MySwal = withReactContent(Swal);
  const userRole = localStorage.getItem('role'); // Ideally, fetch this more securely

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'pendingOrdersPRF'));
      const ordersData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setOrders(ordersData);
      setLoading(false);
    };

    fetchOrders();
  }, []);
  const handleApprove = async (order) => {
    const result = await MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Seçilen siparişi onaylamak istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, onayla!',
      cancelButtonText: 'Hayır, iptal et!',
    });
  
    if (result.isConfirmed) {
      try {
        // Update stock amounts in the `stocks` collection
        await Promise.all(order.urunler.map(async (urun) => {
          // Fetch the stock document based on urunKodu or other identifier
          const stockDocSnapshot = await getStockDocumentByUrunKodu(urun.urunKodu); // Assuming `urunKodu` is a field in stock documents
          const stockDocRef = stockDocSnapshot.ref;
  
          const stockData = stockDocSnapshot.data();
          const currentStock = stockData.UnitAmount;
          const newStock = currentStock - urun.miktar;
  
          if (newStock >= 0) {
            await updateDoc(stockDocRef, { UnitAmount: newStock });
          } else {
            throw new Error(`Yetersiz stok: ${urun.urunAdi}`);
          }
        }));
  
        // Move the order to `approvedOrders` collection
        await addDoc(collection(db, 'approvedOrders'), order);
  
        // Delete the order from `pendingOrdersPRF` collection
        await deleteDoc(doc(db, 'pendingOrdersPRF', order.id));
  
        // Update UI by removing the approved order from the state
        setOrders(orders.filter(o => o.id !== order.id));
  
        // Show success message
        MySwal.fire({
          title: 'Sipariş Onaylandı!',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Error approving order: ', error);
  
        // Show error message
        MySwal.fire({
          title: error.message,
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };
  
  // Function to query stock document by urunKodu
  const getStockDocumentByUrunKodu = async (urunKodu) => {
    const stockQuery = query(collection(db, 'stocks'), where('FullStockCode', '==', urunKodu));
    const querySnapshot = await getDocs(stockQuery);
    
    if (querySnapshot.empty) {
      throw new Error(`Stok bulunamadı: ${urunKodu}`);
    }
  
    // Return the first matching document
    return querySnapshot.docs[0];
  };
  
 
  
  // Function to query stock document by urunKodu
  
  
  
  

  const handleDelete = async (order) => {
    const result = await MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Seçilen siparişi silmek istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et!',
    });

    if (result.isConfirmed) {
      try {
        await deleteDoc(doc(db, 'pendingOrdersPRF', order.id));
        setOrders(orders.filter(o => o.id !== order.id));
        MySwal.fire({
          title: 'Sipariş Silindi!',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Error deleting order: ', error);
        MySwal.fire({
          title: 'Sipariş silinirken hata oluştu',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };

  const handleRowClick = (record) => {
    setSelectedOrder(record);
    setSelectedOrderId(record.id); // Pass selected order ID to parent
  };

  const columns = [
    { title: 'Belge No', dataIndex: 'belgeNo', key: 'belgeNo' },
    { title: 'Cari Kodu', dataIndex: 'cariKodu', key: 'cariKodu' },
    { title: 'Cari Adı', dataIndex: 'cariAdi', key: 'cariAdi' },
    {
      title: 'Sipariş Tarihi',
      dataIndex: 'siparisTarihi',
      key: 'siparisTarihi',
      render: (timestamp) => {
        if (timestamp && timestamp.seconds) {
          const date = new Date(timestamp.seconds * 1000); // Timestamp'ı Date nesnesine çevirme
          return date.toLocaleDateString(); // Tarihi okunabilir forma çevirme
        }
        return ''; // Eğer timestamp yoksa boş string döndür
      },
    },
    {
      title: ' ',
      key: 'action',
      render: (text, record) => (
        <div>
          {userRole === "Satış" ? null : (
            <Button
              type="primary"
              onClick={() => handleApprove(record)}
              style={{ marginRight: '10px' }}
            >
              Onayla
            </Button>
          )}
          {userRole === "Satış" ? null : (
            <Button type="default" onClick={() => handleDelete(record)}>
              Sil
            </Button>
          )}
        </div>
      ),
    },
  ];
  

  return (
    <div className='mb-3'>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="id"
        rowClassName={(record) => (record.id === selectedOrder?.id ? 'selected-row' : '')}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

PendingOrdersTable.propTypes = {
  setSelectedOrderId: PropTypes.func.isRequired,
};

export default PendingOrdersTable;
