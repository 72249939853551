import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {db, storage } from '../../../environment'
import { addDoc, collection, getDocs } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import PropTypes from "prop-types"; // Import PropTypes
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal);

const AddUsers = ({onChangeUser}) => {
    const [roles, setRoles] = useState([]);
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [userData, setUserData] = useState({
        username: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        confirmPassword: '',
        description: '',
        emailVerified: false,
        showModal:true
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const fetchRoles = async () => {
            const response = collection(db, 'roles');
            const data = await getDocs(response);
            setRoles(data.docs.map(doc => ({
                value: doc.data().name, // Use doc.id if it is unique and needed for reference
                label: doc.data().name,
            })));
        };
        fetchRoles();
    }, []);
    const handleTogglePassword = () => {
        setShowPassword(prevShow => !prevShow);
    };
    
    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(prevShow => !prevShow);
    };
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleSelectChange = (selectedOption) => {
        setUserData({ ...userData, role: selectedOption.value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => setPreviewUrl(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const validateForm = () => {
        const { username, phone, email, role, password, confirmPassword } = userData;
        if (!username.trim() || !phone.trim() || !email.trim() || !role.trim() || !password.trim()) {
            MySwal.fire({
                icon: 'error',
                title: 'Gerekli alanlar boş!',
                text: 'Lütfen tüm alanların dolu olduğundan emin olun!',
            });
            return false;
        }
        if (password !== confirmPassword) {
            MySwal.fire({
                icon: 'error',
                title: 'Şifre eşleşmiyor!',
                text: 'Şifre eşleşmiyor lütfen tekrar deneyin!',
            });
            return false;
        }
        return true;
    };
    
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setUserData(prevState => ({
            ...prevState,
            emailVerified: checked
        }));
    };
    const handleShowModalChange = (event) => {
        const { checked } = event.target;
        setUserData(prevState => ({
            ...prevState,
            showModal: checked
        }));
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return; // Stops submission if validation fails
    
        if (file) {
            const fileRef = ref(storage, `user_images/${file.name}`);
            await uploadBytes(fileRef, file);
            const photoURL = await getDownloadURL(fileRef);
            const finalUserData = { ...userData, img: photoURL };
    
            try {
                const docRef = await addDoc(collection(db, 'users'), finalUserData);  // Create document in Firestore
                onChangeUser({ ...finalUserData, id: docRef.id });  // Use docRef.id here within the same block
                MySwal.fire({
                    icon: 'success',
                    title: 'Kullanıcı başarıyla eklendi!',
                    timer: 1500,
                    showConfirmButton: false,
                });
                // Reset form after successful submission
                setUserData({
                    username: '',
                    phone: '',
                    email: '',
                    role: '',
                    password: '',
                    confirmPassword: '',
                    description: '',
                    emailVerified: false,
                    showModal:true
                });
                setPreviewUrl('');
                setFile(null);
            } catch (error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Kullanıcı eklenirken hata oluştu!',
                    text: error.message,
                });
            }
        } else {
            MySwal.fire({
                icon: 'error',
                title: 'Fotoğraf bulunamadı!',
                text: 'Lütfen fotoğraf ekleyiniz!',
            });
        }
    };
    
    
    return (
        <div>
            {/* Add User */}
            <div className="modal fade" id="add-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Kullanıcı Ekle</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="new-employee-field">
                                                    <span>Profil Fotoğrafı</span>
                                                    <div className="profile-pic-upload mb-2">
                                                        <div >
                                                            <span>
                                                                {previewUrl && (
                                                                    <div className="profile-pic">
                                                                        <img src={previewUrl} alt="Profile Preview" style={{ width: '100px', height: '100px' }} />
                                                                    </div>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="input-blocks mb-0">
                                                            <div className="image-upload mb-0">
                                                                <input onChange={handleFileChange} type="file" />
                                                                <div className="image-uploads">
                                                                    <h4>Resmi Değiştir</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Kullanıcı Adı</label>
                                                    <input name='username' onChange={handleInputChange} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Telefon No</label>
                                                    <input name='phone' onChange={handleInputChange} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>E-posta</label>
                                                    <input name='email' onChange={handleInputChange} type="email" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Rol</label>

                                                    <Select
                                                        className="select"
                                                        options={roles}
                                                        placeholder="Rol seçiniz"
                                                        onChange={handleSelectChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Şifre</label>
                                                    <div className="pass-group">
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="pass-input"
                                                            onChange={handleInputChange}
                                                            name='password'
                                                        />
                                                        <span
                                                            className={`fas toggle-password ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                            onClick={handleTogglePassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Şifre onayla</label>
                                                    <div className="pass-group">
                                                        <input
                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                            className="pass-input"
                                                            name='confirmPassword'
                                                            onChange={handleInputChange}

                                                        />
                                                        <span
                                                            className={`fas toggle-password ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                            onClick={handleToggleConfirmPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-blocks m-0">
                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                    <p>Doğrulanma Durumu:</p>
                                                    <input
                                                        type="checkbox"
                                                        id="emailVerified"
                                                        className="check"
                                                        checked={userData.emailVerified}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="emailVerified" className="checktoggle mb-0"> </label>
                                                </div>
                                            </div>
                                            <div className="input-blocks m-0">
                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                    <p>Güncellemeleri Göster:</p>
                                                    <input
                                                        type="checkbox"
                                                        id="emailVerified2"
                                                        className="check"
                                                        checked={userData.showModal}
                                                        onChange={handleShowModalChange}
                                                    />
                                                    <label htmlFor="emailVerified2" className="checktoggle mb-0"> </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-0 input-blocks">
                                                    <label className="form-label">Açıklama</label>
                                                    <textarea
                                                        className="form-control mb-1"
                                                        defaultValue={"Type Message"}
                                                    />
                                                    <p>Maksimum 600 Karakter</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                İptal
                                            </button>
                                            <Link onClick={handleSubmit} className="btn btn-submit">
                                                Ekle
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add User */}
        </div>
    )
}

export default AddUsers
AddUsers.propTypes = {
    onChangeUser: PropTypes.func,
    
  };