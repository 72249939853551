import React, { useEffect } from 'react';
import { notification } from 'antd';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../environment';
import { useLocation } from 'react-router-dom'; // React Router'dan useLocation import edilir
import { ExclamationCircleOutlined } from '@ant-design/icons'; // Uyarı ikonu ekliyoruz

const StocksNotification = () => {
  const location = useLocation(); // Ekran değişikliklerini izlemek için kullanıyoruz
  let userRole=localStorage.getItem('role');
  useEffect(() => {
    const stocksCollectionRef = collection(db, 'stocks'); // 'stocks' koleksiyonunu referans al
    console.log("slkdfg",userRole)
    const unsubscribe = onSnapshot(stocksCollectionRef, (snapshot) => {
      snapshot.forEach((doc) => {
        const stockData = doc.data();
        if (stockData.UnitAmount <= stockData.SafetyStock) {
          // Eğer stok eşitlenmişse bildirimi göster
          showNotification(stockData);
        }
      });
    });

    return () => unsubscribe(); // Cleanup için unsubscribe
  }, [location]); // URL değişikliklerinde tekrar çalışır

  const showNotification = (stockData) => {
    {userRole=="Sistem Admini"||userRole=="Tablet"||userRole=="Satış"?null:notification.open({
      message: 'Stok Uyarısı',
      description: `${stockData.StockName} ürünü için stok, güvenlik stok seviyesine ulaştı!`,
      placement: 'topRight',
      duration: 3, // Bildirimin 3 saniye sonra kaybolmasını sağlar
      className: 'custom-notification', // Özel sınıf ekliyoruz
      icon: <ExclamationCircleOutlined style={{ color: 'white' }} />, // Beyaz uyarı ikonu
    });}
    
  };

  return null;
};

export default StocksNotification;
