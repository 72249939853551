import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { updateDoc, doc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { db } from '../../../environment';
import { Link } from 'react-router-dom';

const EditRole = ({ role }) => {
    const [roleName, setRoleName] = useState("");
    const handleRoleNameChange = (event) => {
        setRoleName(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const roleRef = doc(db, "roles", role.id); // Reference to the specific role in the 'roles' collection

        try {
            await updateDoc(roleRef, {
                name: roleName
            });
            Swal.fire({
                title: "Başarılı",
                text: "Kayıt Güncellendi",
                type: "success",
                timer: 1000
            
            },

            );
        } catch (error) {
            Swal.fire({
                title: "Hata",
                text: "Rol Güncellenirken Hata Oluştu!",
                type: "error",
                timer: 1000
            
            
            })
        }
    };

    return (
        <div>
            {/* Add Role */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Rol Düzenle</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="mb-0">
                                            <label className="form-label">Rol</label>
                                            <input defaultValue={role.name}  onChange={handleRoleNameChange} type="text" className="form-control" />
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                İptal
                                            </button>
                                            <Link onClick={handleSubmit} className="btn btn-submit">
                                                Değişiklikleri Kaydet
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Role */}
        </div>
    );
};

// Define propTypes
EditRole.propTypes = {
    role: PropTypes.string.isRequired // Specifies that roleId is a required string
};

export default EditRole;
