import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, DatePicker, Divider, Space, message, Modal, List, Select } from "antd";
import { PlusOutlined, MinusCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import moment from "moment";
import { collection, query, orderBy, where, getDocs, addDoc, updateDoc, doc, arrayUnion, limit } from "firebase/firestore";
import { db } from "../../environment";

const { Option } = Select;

const ReceteOlustur = () => {
  const [form] = Form.useForm();
  const [recipeNumber, setRecipeNumber] = useState("");
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentRecipeId, setCurrentRecipeId] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState({});

  useEffect(() => {
    const fetchLastRecipeNumber = async () => {
      const currentYearMonth = moment().format("YYMM");
  
      // Veritabanından bu yıl ve ay ile başlayan en son reçete numarasını al
      const q = query(
        collection(db, "productRecipe"),
        where("recipeNumber", ">=", `RCT/${currentYearMonth}-001`),
        where("recipeNumber", "<=", `RCT/${currentYearMonth}-999`),
        orderBy("recipeNumber", "desc"),
        limit(1)
      );
  
      const snapshot = await getDocs(q);
  
      if (!snapshot.empty) {
        const lastRecipe = snapshot.docs[0].data();
        if (lastRecipe.recipeNumber.startsWith(`RCT/${currentYearMonth}`)) {
          const lastNumber = parseInt(lastRecipe.recipeNumber.split('-')[1], 10);
          const newRecipeNumber = `RCT/${currentYearMonth}-${String(lastNumber + 1).padStart(3, '0')}`;
          setRecipeNumber(newRecipeNumber);
        } else {
          setRecipeNumber(`RCT/${currentYearMonth}-001`);
        }
      } else {
        setRecipeNumber(`RCT/${currentYearMonth}-001`);
      }
    };
  
    const fetchStocks = async () => {
      const stockSnapshot = await getDocs(collection(db, "stocks"));
      const stocksData = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStocks(stocksData);
    };
  
    fetchLastRecipeNumber();
    fetchStocks();
  }, []);
  
  

  const handleMaterialChange = (value, key) => {
    const selectedStock = stocks.find(stock => stock.StockName === value);
    setSelectedMaterials(prev => ({
      ...prev,
      [key]: selectedStock
    }));
  };

  const onFinish = async (values) => {
    const currentTime = moment().format("DD-MM-YYYY HH:mm:ss");
    const recipeData = {
      ...values,
      recipeNumber,
      creationDate: moment(values.creationDate).format("DD-MM-YYYY"),
      lastUpdateDate: currentTime,
      history: arrayUnion({
        updateDate: currentTime,
        updater: values.lastUpdater,
      }),
    };

    try {
      if (isUpdate && currentRecipeId) {
        await updateDoc(doc(db, "productRecipe", currentRecipeId), recipeData);
        message.success("Reçete başarıyla güncellendi!");
      } else {
        await addDoc(collection(db, "productRecipe"), recipeData);
        message.success("Reçete başarıyla oluşturuldu!");
      }

      // Stok güncellemesi
      for (const material of values.rawMaterials) {
        const selectedStock = selectedMaterials[material.key];
        if (selectedStock) {
          const updatedStock = {
            ...selectedStock,
            CurrentStock: selectedStock.CurrentStock - material.amount,
          };
          await updateDoc(doc(db, "stocks", selectedStock.id), updatedStock);
        }
      }

      form.resetFields();
      setIsUpdate(false);
      setCurrentRecipeId(null);
    } catch (error) {
      message.error("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  const showHistory = async () => {
    const q = query(collection(db, "productRecipe"), orderBy("recipeNumber", "desc"));
    const snapshot = await getDocs(q);
    const history = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setHistoryData(history);
    setHistoryModalVisible(true);
  };

  return (
    <>
      <Card title="Reçete Oluştur" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: '10px' }}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="creationDate" label="Reçete Oluşturma Tarihi" rules={[{ required: true, message: "Lütfen tarih seçiniz!" }]}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Reçete Numarası">
            <Input value={recipeNumber} disabled />
          </Form.Item>
          <Form.Item name="productCode" label="Ürün Kodu" rules={[{ required: true, message: "Lütfen ürün kodunu giriniz!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="productCategory" label="Ürün Kategorisi" rules={[{ required: true, message: "Lütfen ürün kategorisini giriniz!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="subCategory" label="Alt Kategori" rules={[{ required: true, message: "Lütfen alt kategoriyi giriniz!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="qualityCode" label="Kalite Kodu" rules={[{ required: true, message: "Lütfen kalite kodunu giriniz!" }]}>
            <Input />
          </Form.Item>
          <Divider>Hammadde Girişi</Divider>
          <Form.List name="rawMaterials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item {...restField} name={[name, 'code']} fieldKey={[fieldKey, 'code']} rules={[{ required: true, message: 'Lütfen kodu giriniz!' }]}>
                      <Select
                        placeholder="Hammadde Seç"
                        onChange={(value) => handleMaterialChange(value, key)}
                        style={{ width: 200 }}
                      >
                        {stocks.map(stock => (
                          <Option key={stock.StockName} value={stock.StockName}>
                            {stock.StockName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'amount']} fieldKey={[fieldKey, 'amount']} rules={[{ required: true, message: 'Lütfen miktarı giriniz!' }]}>
                      <Input placeholder="Miktar (kg)" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'structuralFeature']} fieldKey={[fieldKey, 'structuralFeature']} rules={[{ required: true, message: 'Lütfen yapısal özelliği giriniz!' }]}>
                      <Input placeholder="Yapısal Özellik" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'unit']} fieldKey={[fieldKey, 'unit']}>
                      <Input placeholder="Birim" value={selectedMaterials[key]?.Unit} disabled />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Hammadde Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item name="totalBatchKg" label="1 Şarj Toplam Kg" rules={[{ required: true, message: "Lütfen toplam kg giriniz!" }]}>
            <Input />
          </Form.Item>
          <Divider>Üretim Rotası</Divider>
          <Form.List name="productionRoute">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item {...restField} name={[name, 'step']} fieldKey={[fieldKey, 'step']} rules={[{ required: true, message: 'Lütfen adımı giriniz!' }]}>
                      <Input placeholder={`Adım ${key + 1}`} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Adım Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item name="creator" label="Reçeteyi Oluşturan Ad Soyad" rules={[{ required: true, message: "Lütfen ad soyad giriniz!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="lastUpdater" label="Son Güncelleştirmeyi Yapan Ad Soyad" rules={[{ required: true, message: "Lütfen ad soyad giriniz!" }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Reçete {isUpdate ? 'Güncelle' : 'Oluştur'}</Button>
          </Form.Item>
        </Form>
        <Button type="default" icon={<HistoryOutlined />} onClick={showHistory}>Geçmiş Güncellemeler</Button>
      </Card>

      <Modal
        title="Geçmiş Güncellemeler"
        visible={historyModalVisible}
        footer={null}
        onCancel={() => setHistoryModalVisible(false)}
      >
        <List
          dataSource={historyData}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={`Reçete Numarası: ${item.recipeNumber}`}
                description={
                  <>
                    <p>Oluşturma Tarihi: {item.creationDate}</p>
                    <p>Son Güncelleme: {item.lastUpdateDate}</p>
                    <p>Güncelleyenler: {item.history.map((h, index) => (
                      <span key={index}>{h.updateDate} - {h.updater}<br /></span>
                    ))}</p>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ReceteOlustur;
