import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, doc, addDoc, query, where } from 'firebase/firestore';
import { db } from '../../environment';
import { Table, Button, message, Spin, Modal } from 'antd';
import dayjs from 'dayjs';

function SarjIstekleri() {
  const [requests, setRequests] = useState([]);
  const [archivedRequests, setArchivedRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the 'şarjIstekleri' and 'sarjIstekleriArsiv' collections
  const fetchSarjIstekleri = async () => {
    try {
      // Fetch current requests
      const requestsCollection = collection(db, 'sarjIstekleri');
      const requestsSnapshot = await getDocs(requestsCollection);
      const requestList = requestsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch archived requests
      const archivedCollection = collection(db, 'sarjIstekleriArsiv');
      const archivedSnapshot = await getDocs(archivedCollection);
      const archivedList = archivedSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setRequests(requestList);
      setArchivedRequests(archivedList);
    } catch (error) {
      console.error('Error fetching şarj istekleri:', error);
      message.error('Şarj istekleri alınamadı!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSarjIstekleri();
  }, []);

  // Confirm Action Modal
  const showConfirm = (action, record, callback) => {
    Modal.confirm({
      title: 'Emin misiniz?',
      content: `Bu isteği ${action} etmek istediğinizden emin misiniz?`,
      onOk() {
        callback(record);
      },
    });
  };

  // Remove request from state
  const removeRequestFromState = (requestId) => {
    setRequests((prevRequests) => prevRequests.filter((req) => req.id !== requestId));
  };

  // Approve a charge request and update the batchCounter
  const handleApprove = async (request) => {
    try {
      // Fetch the related kart by card_number
      const kartQuery = query(collection(db, 'onaylananUretimKartlari'), where('card_number', '==', request.kartNumber));
      const kartSnapshot = await getDocs(kartQuery);

      if (!kartSnapshot.empty) {
        const kartDoc = kartSnapshot.docs[0];
        const kartRef = doc(db, 'onaylananUretimKartlari', kartDoc.id);
        let newBatchCounter = kartDoc.data().batchCounter || 0;

        // Adjust batchCounter based on the request type
        if (request.requestType === 'increase') {
          newBatchCounter += parseInt(request.quantity, 10);
        } else if (request.requestType === 'decrease') {
          newBatchCounter -= parseInt(request.quantity, 10);
        }

        // Update the batchCounter in the corresponding kart
        await updateDoc(kartRef, { batchCounter: newBatchCounter });

        // Archive the approved request
        await addDoc(collection(db, 'sarjIstekleriArsiv'), {
          ...request,
          status: 'Onaylandı',
          processedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });

        // Delete the approved request from 'şarjIstekleri' collection
        await deleteDoc(doc(db, 'sarjIstekleri', request.id));

        // Remove from the UI table
        removeRequestFromState(request.id);

        message.success('Şarj isteği onaylandı ve batchCounter güncellendi!');
      } else {
        message.error(`Kart ${request.kartNumber} bulunamadı!`);
      }
    } catch (error) {
      console.error('Error approving şarj isteği:', error);
      message.error('Şarj isteği onaylanamadı!');
    }
  };

  // Reject a charge request and move it to the 'sarjIstekleriArsiv' collection
  const handleReject = async (request) => {
    try {
      // Archive the rejected request
      await addDoc(collection(db, 'sarjIstekleriArsiv'), {
        ...request,
        status: 'Reddedildi',
        rejectedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });

      // Delete the rejected request from 'şarjIstekleri' collection
      await deleteDoc(doc(db, 'sarjIstekleri', request.id));

      // Remove from the UI table
      removeRequestFromState(request.id);

      message.success('Şarj isteği reddedildi ve arşivlendi!');
    } catch (error) {
      console.error('Error rejecting şarj isteği:', error);
      message.error('Şarj isteği reddedilemedi!');
    }
  };

  // Archive a charge request directly
  const handleArchive = async (request) => {
    try {
      // Archive the request
      await addDoc(collection(db, 'sarjIstekleriArsiv'), {
        ...request,
        status: 'Arşivlendi',
        archivedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });

      // Delete the request from 'şarjIstekleri' collection
      await deleteDoc(doc(db, 'sarjIstekleri', request.id));

      // Remove from the UI table
      removeRequestFromState(request.id);

      message.success('Şarj isteği arşivlendi!');
    } catch (error) {
      console.error('Error archiving şarj isteği:', error);
      message.error('Şarj isteği arşivlenemedi!');
    }
  };

  // Restore archived request back to 'şarjIstekleri'
  const handleRestore = async (request) => {
    try {
      // Add the request back to 'şarjIstekleri' collection
      await addDoc(collection(db, 'sarjIstekleri'), {
        ...request,
        restoredAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });

      // Delete the request from the 'sarjIstekleriArsiv' collection
      await deleteDoc(doc(db, 'sarjIstekleriArsiv', request.id));

      // Refresh the archived list
      fetchSarjIstekleri();

      message.success('Şarj isteği tekrar aktif hale getirildi!');
    } catch (error) {
      console.error('Error restoring şarj isteği:', error);
      message.error('Şarj isteği tekrar aktif hale getirilemedi!');
    }
  };

  // Table columns for displaying şarj istekleri
  const columns = [
    {
      title: 'Kart Numarası',
      dataIndex: 'kartNumber',
      key: 'kartNumber',
    },
    {
      title: 'Şarj İsteği Türü',
      dataIndex: 'requestType',
      key: 'requestType',
      render: (text) => (text === 'increase' ? 'Şarj Yükselt' : 'Şarj Azalt'),
    },
    {
      title: 'Şarj Miktarı',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'İstek Zamanı',
      dataIndex: 'requestTime',
      key: 'requestTime',
      render: (text) => dayjs(text).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => showConfirm('onaylamak', record, handleApprove)} style={{ marginRight: 8 }}>
            Onayla
          </Button>
          <Button danger onClick={() => showConfirm('reddetmek', record, handleReject)} style={{ marginRight: 8 }}>
            Reddet
          </Button>
          <Button onClick={() => showConfirm('arşivlemek', record, handleArchive)}>
            Arşivle
          </Button>
        </>
      ),
    },
  ];

  // Table columns for displaying archived şarj istekleri
  const archivedColumns = [
    {
      title: 'Kart Numarası',
      dataIndex: 'kartNumber',
      key: 'kartNumber',
    },
    {
      title: 'Şarj İsteği Türü',
      dataIndex: 'requestType',
      key: 'requestType',
      render: (text) => (text === 'increase' ? 'Şarj Yükselt' : 'Şarj Azalt'),
    },
    {
      title: 'Şarj Miktarı',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Arşivlenme Zamanı',
      dataIndex: 'archivedAt',
      key: 'archivedAt',
      render: (text) => dayjs(text).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (text, record) => (
        <Button onClick={() => showConfirm('tekrar aktif hale getirmek', record, handleRestore)}>
          Geri Yükle
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '40px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="page-wrapper mt-5">
      <h2>Şarj İstekleri</h2>
      <Table
        columns={columns}
        dataSource={requests}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
        style={{ margin: '20px 0' }}
      />

      <h2>Arşivlenmiş Şarj İstekleri</h2>
      <Table
        columns={archivedColumns}
        dataSource={archivedRequests}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
        style={{ margin: '20px 0' }}
      />
    </div>
  );
}

export default SarjIstekleri;
