
import React from "react";
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { base_path } from "./environment.jsx";
import '../src/style/css/feather.css'
import '../src/style/css/line-awesome.min.css'
import "../src/style/scss/main.scss";
import '../src/style/icons/fontawesome/css/fontawesome.min.css'
import '../src/style/icons/fontawesome/css/all.min.css'


import { Provider } from "react-redux";
import store from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
import { ConfigProvider } from "antd";

const rootElement = document.getElementById('root');
import locale from 'antd/locale/tr_TR';
import dayjs from 'dayjs';
import { MeetingProvider } from "@videosdk.live/react-sdk";
import ResizableTitle from "./style/ResizableTitle";
import StocksNotification from "./components/stok/stokalarm.jsx";

dayjs.locale('tr-tr');
function MeetingView() {
  return null
}


if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
<ConfigProvider locale={locale}  theme={{
    components: {
      Input:{
        activeBorderColor:'#a61919',
        hoverBorderColor:"#e62525",
        
      },
      Header:{
        Cell:ResizableTitle
      },
      Button:{
        colorPrimary: '#e62525', // Butonların ana rengini yeşil yapıyoruz
    colorPrimaryHover: '#a61919', // Buton üzerine gelindiğindeki rengi
      },
      Select:{
        zIndexPopup:999999999,
        colorPrimary:'#e62525',
        colorPrimaryHover: '#e62525'
      },
      DatePicker: {
        colorPrimary:'#e62525',

        colorPrimaryHover:"#e62525",
        zIndexPopup:999999
      },
    },
  }}>
    <MeetingProvider
    config={{
      meetingId: "czpt-shox-jk5n",
      micEnabled: true,
      webcamEnabled: true,
      name: "Katılımcı",    }}
    token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJjM2Q2NTFiYy0wZWM2LTRmODEtYmFhMi0wNzc4NjUxZDcyMjYiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcxNTk0NzI4MCwiZXhwIjoxODczNzM1MjgwfQ.qb8zCEGGB6IgFw9oYLfdLvexg3tskjJHa_74-NNFTXM"
  >
    <React.StrictMode>
    <Provider store={store} >
      <BrowserRouter basename={base_path}>
      <AllRoutes />
      <StocksNotification/>

      </BrowserRouter>
    </Provider>
  </React.StrictMode>
  <MeetingView/>
  </MeetingProvider>
  </ConfigProvider>
  
  );
} else {
  console.error("Element with id 'root' not found.");
}
