import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { ChevronUp, RotateCcw } from 'feather-icons-react/build/IconComponents';
import { setToogleHeader } from '../../core/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, PlusCircle, Sliders, StopCircle, User, Zap } from 'react-feather';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Table from '../../core/pagination/datatable'
import AddUsers from '../../core/modals/usermanagement/addusers';
import EditUser from '../../core/modals/usermanagement/edituser';
import { db } from '../../environment';
import { getDocs,collection,doc, deleteDoc } from 'firebase/firestore';
import moment from 'moment';
import 'moment/locale/tr';  // Türkçe dil desteği yükler

moment.locale('tr');  // Locale ayarını Türkçe yapar

const Users = () => {
    const [selectedUserId, setSelectedUserId] = useState(null);
    const handleEditClick = (userId) => {
        setSelectedUserId(userId);
    };
    const [refreshFlag, setRefreshFlag] = useState(false);

  const triggerRefresh = () => {
    setRefreshFlag(!refreshFlag); // Toggle the flag to force refresh
  };
    const [users, setUsers] = useState([]);
    const handleUserAdded = (newUser) => {
        setUsers((prevUsers) => [...prevUsers, newUser]);
    };
   
    const oldandlatestvalue = [
        { value: 'date', label: 'Sort by Date' },
        { value: 'newest', label: 'Newest' },
        { value: 'oldest', label: 'Oldest' },
    ];
    
    const status = [
        { value: 'Choose Name', label: 'Choose Status' },
        { value: 'Active', label: 'Active' },
        { value: 'InActive', label: 'InActive' },
    ];
    const role = [
        { value: 'Choose Role', label: 'Choose Role' },
        { value: 'AcStore Keeper', label: 'Store Keeper' },
        { value: 'Salesman', label: 'Salesman' },
    ];
    const getUsers = async () => {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersArray = querySnapshot.docs.map(doc => {
            const data = doc.data();
            const formattedCreatedOn = data.createdOn ? moment(data.createdOn).format('LLLL') : 'Tarih Yok';
            return {
                ...data,
                id: doc.id,
                createdOn: formattedCreatedOn ,
                role:data.role.label // formattedCreatedOn ile createdOn'u günceller
            };
        });
        setUsers(usersArray);
    };
    useEffect(() => {
       
    
        getUsers();
    
      return () => {
        
      }
    }, [refreshFlag])
    

    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Yazıcı
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip onClick={()=>window.location.reload()} id="refresh-tooltip" {...props}>
            Yenile
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Daralt
        </Tooltip>
    )

    const columns = [
        {
            title: "Kullanıcı Adı",
            dataIndex: "username",
            sorter: (a, b) => a.username.length - b.username.length,
        },
        {
            title: "Fotoğrafı",
            dataIndex: "",
            render: (record) => (
                <span className="userimgname">
                    <Link to="#" className="userslist-img bg-img">
                        <ImageWithBasePath alt="" src={record} />
                    </Link>
                    <div>
                    <img style={{width:50,height:50,borderRadius:55}} src={record.img==undefined||record.img==null?"assets/img/customer/no-imagecustomer.png":record.img}/>

                    
                    </div>
                </span>
                
            ),
            sorter: (a, b) => a.username.length - b.username.length,
        },

        {
            title: "Telefon Numarası",
            dataIndex: "phone",
            sorter: (a, b) => a.phone.length - b.phone.length,
        },
        {
            title: "E-posta",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Rol",
            dataIndex: "role",
            sorter: (a, b) => a.role.length - b.role.length,
        },
        {
            title: "Oluşturulma Tarihi",
            dataIndex: "createdOn",
            sorter: (a, b) => a.createdOn.localeCompare(b.createdOn), // Doğru sıralama için localeCompare kullanın
        },
        {
            title: "Onay Durumu",
            dataIndex: "emailVerified",
            render: (text) => (
                
                <div>
                    {console.log("oızxfjg",text)}
                  {text === true && (
                    <span className="badge badge-linesuccess">{"Onaylandı"}</span>
                  )}
                  {text === false && (
                    <span className="badge badge-linedanger">{"Onaylanmadı"}</span>
                  )}
                  
                </div>
              ),
            sorter: (a, b) => a.status.length - b.status.length,
        },
        {
            title: ' ',
            dataIndex: 'actions',
            key: 'actions',
            render: (text,record) => (
                <td className="action-table-data">
                    <div className="edit-delete-action">
                        
                        <Link className="me-2 p-2" to="#">
                            <i data-feather="eye" className="feather feather-eye action-eye"></i>
                        </Link>
                        <Link  onClick={() => handleEditClick(record.id)}className="me-2 p-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-units">
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                        <Link className="confirm-text p-2" to="#"  >
                            <i data-feather="trash-2" className="feather-trash-2" onClick={()=>showConfirmationAlert(record.id)}></i>
                        </Link>
                    </div>
                </td>
            )
        },
    ]
    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (record) => {
        console.log("xdflohıjg",record)
        MySwal.fire({
            title: 'Emin misiniz?',
            text: 'Bu işlem geri alınamayacaktır!',
            showCancelButton: true,
            confirmButtonColor: '#00ff00',
            confirmButtonText: 'Evet, sil!',
            cancelButtonColor: '#ff0000',
            cancelButtonText: 'İptal',
        }).then((result) => {
            if (result.isConfirmed) {
                 deleteDoc(doc(db, "users", record)).then(res=>{
                    console.log(res)
                    getUsers()

                 })

                MySwal.fire({
                    title: 'Silindi!',
                    text: 'Kullanıcı silindi!',
                    className: "btn btn-success",
                    confirmButtonText: 'Tamam',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
            } else {
                MySwal.close();
            }

        });
    };
    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Kullanıcı Listesi</h4>
                                <h6>Kullanıcılarınızı yönetin.</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link>
                                        <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <i data-feather="printer" className="feather-printer" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        <div className="page-btn">
                            <a
                                to="#"
                                className="btn btn-added"
                                data-bs-toggle="modal"
                                data-bs-target="#add-units"
                            >
                                <PlusCircle className="me-2" />
                                Yeni kullanıcı ekle
                            </a>
                        </div>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Ara"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                
                                <div className="search-path">
                                    <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                        <Filter
                                            className="filter-icon"
                                            onClick={toggleFilterVisibility}
                                        />
                                        <span onClick={toggleFilterVisibility}>
                                            <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                                <div className="form-sort">
                                    <Sliders className="info-img" />
                                    <Select
                                        className="select"
                                        options={oldandlatestvalue}
                                        placeholder="En yeni"
                                    />
                                </div>
                            </div>
                            {/* /Filter */}
                            <div
                                className={`card${isFilterVisible ? ' visible' : ''}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? 'block' : 'none' }}
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <User className="info-img" />

                                                <Select
                                                    className="select"
                                                    options={users}
                                                    placeholder="En yeni"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <StopCircle className="info-img" />

                                                <Select
                                                    className="select"
                                                    options={status}
                                                    placeholder="Durum seçiniz"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <Zap className="info-img" />

                                                <Select
                                                    className="select"
                                                    options={role}
                                                    placeholder="Rol seçiniz"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <a className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Ara{" "}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Filter */}
                            <div className="table-responsive">
                            <Table columns={columns} dataSource={users} /> {/* Pass the fetched users here */}

                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
        <AddUsers onChangeUser={handleUserAdded}/>
        <EditUser onUserUpdated={triggerRefresh} uid={selectedUserId} />
        </div>
    )
}

export default Users
