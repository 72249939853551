export const leavetypedata = [
	{
		"id":1,
		"name": "Sick Leave",
		"leavequota": "05",
		"createdon": "02 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":2,
		"name": "Maternity",
		"leavequota": "05",
		"createdon": "03 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":3,
		"name": "Paternity",
		"leavequota": "05",
		"createdon": "04 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":4,
		"name": "Casual Leave",
		"leavequota": "05",
		"createdon": "07 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":5,
		"name": "Emergency",
		"leavequota": "05",
		"createdon": "08 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":1,
		"name": "Vacation",
		"leavequota": "05",
		"createdon": "10 Aug 2023",
		"status": "Active",
		
	}
]