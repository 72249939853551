import React, { useState, useEffect } from 'react';
import { Tree, Dropdown, Menu, Modal, Input } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { db } from '../../environment';
import { doc, deleteDoc, collection, writeBatch, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid'; 
import { Button, Card } from 'react-bootstrap';

const TreeView = () => {
    const [treeData, setTreeData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState('');
    const [currentNode, setCurrentNode] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuInfo, setContextMenuInfo] = useState({ x: 0, y: 0 });
    const [lastUpdate, setLastUpdate] = useState(Date.now());
    const [categoryCodeSuffix, setCategoryCodeSuffix] = useState('');

    useEffect(() => {
        const fetchData = async () => {
          const snapshot = await getDocs(collection(db, 'stockManager'));
            const loadedData = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    title: data.title,
                    key: doc.id,
                    children: data.children || [],
                    isLeaf: !data.children?.length,
                    isMainCategory: true,
                    categoryCode: data.categoryCode // Veritabanından çekilen kategori kodu
                };
            });
            // Veri yüklendikten sonra state güncellenir
            setTreeData(loadedData.sort((a, b) => a.title.localeCompare(b.title)));
        };
        fetchData();
    }, [lastUpdate]);

    const updateTreeDataOnDrop = (treeData, info) => {
        const dragKey = info.dragNode.key;
        const dropKey = info.node.key;
        const dropToGap = info.dropToGap;
        const dropPosition = info.dropPosition;
        const dragNodeData = findNode(treeData, info.dragNode.key);
        if (!dragNodeData || dragNodeData.isLeaf === false) {
            console.log("Dragging main categories is not allowed.");
            return;
        }      
        const dropNodeData = findNode(treeData, dropKey);

        if (!dragNodeData || !dropNodeData) {
            console.error("Dragged or dropped node data not found.");
            return treeData;
        }

        const newData = deepRemoveNode(treeData, dragKey);

        if (!dropToGap) {
            return deepInsertNode(newData, dragNodeData, dropKey, true);
        } else {
            return deepInsertNode(newData, dragNodeData, dropKey, false, dropPosition);
        }
    };

    

    const handleAddMainCategory = async () => {
      if (categoryCodeSuffix.trim() === 'KTG-') {
          Swal.fire('Hata!', 'Kategori kodu "KTG-" ile başlamalıdır ve boş bırakılamaz.', 'error');
          return;
      }
      const categoryCode = `KTG-${categoryCodeSuffix}`;
      console.log("oızxfjg",categoryCode)
      const newKey = uuidv4();
      const newCategory = {
          title: inputValue,
          key: newKey,
          children: [],
          isLeaf: true,
          isMainCategory: true,
          categoryCode: categoryCode
      };

      const docRef = doc(collection(db, 'stockManager'), newKey);
      await setDoc(docRef, newCategory);
      setTreeData([...treeData, newCategory]);
      setLastUpdate(Date.now());

      Swal.fire('Başarılı!', 'Ana kategori eklemesi başarılı', 'success');
      setModalVisible(false);
      setInputValue('');
  };

    const renderTreeNodeTitle = (node) => {
      // Ana kategoriler için özel stil
      console.log("soıhg",node)
      if (node.isMainCategory) {
        return (
          <span style={{
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#003366',
            color: '#ffffff',
            justifyContent:'center',
            padding: '5px 10px',
            borderRadius: '5px',
            display: 'inline-block',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            margin: '2px 0'
          }}>
           Kod:{node.categoryCode}<br/> Başlık:{node.title}
          </span>
        );
      } else {
        // Diğer düğümler için daha basit bir stil
        return (
          <span style={{
            fontSize: '14px',
            fontStyle: 'italic',
            color: '#333',
            padding: '3px 6px',
            borderRadius: '3px',
            display: 'inline-block',
            margin: '2px 0'
          }}>
            {node.title}
          </span>
        );
      }
    };
    
  

    const deepRemoveNode = (nodes, key) => {
        let newNodes = [];
        nodes.forEach(node => {
            if (node.key !== key) {
                if (node.children) {
                    node.children = deepRemoveNode(node.children, key);
                }
                newNodes.push(node);
            }
        });
        return newNodes;
    };

    const deepInsertNode = (nodes, newNode, targetKey, asChild, position = 0) => {
        const newNodes = [];
        nodes.forEach(node => {
            if (node.key === targetKey) {
                if (asChild) {
                    node.children = [...(node.children || []), newNode];
                } else {
                    const index = position === -1 ? 0 : 1;
                    newNodes.splice(newNodes.indexOf(node) + index, 0, newNode);
                }
            }
            if (node.children) {
                node.children = deepInsertNode(node.children, newNode, targetKey, asChild, position);
            }
            newNodes.push(node);
        });
        return newNodes;
    };

    const onDrop = async (info) => {
        const dragKey = info.dragNode.key;
        const dropKey = info.node.key;
        const dropPos = info.node.pos.split('-');
        const dropIndex = dropPos[dropPos.length - 1];
        const dragNodeData = findNode(treeData, dragKey);
        const dropNodeData = findNode(treeData, dropKey);

        if (!dragNodeData || !dropNodeData) {
            console.error("Cannot find either the drop node or the drag node. Drag Key:", dragKey, "Drop Key:", dropKey);
            return;
        }

        if (!dragNodeData.isLeaf) {
            console.log("Dragging main categories is not allowed.");
            return;
        }

        let newTreeData = [...treeData];

        const dragParent = findParentNode(treeData, dragKey);
        const dropParent = findParentNode(treeData, dropKey);

        if (dragParent) {
            dragParent.children = dragParent.children.filter(child => child.key !== dragKey);
        } else {
            newTreeData = newTreeData.filter(node => node.key !== dragKey);
        }

        if (!info.dropToGap) {
            dropNodeData.children = dropNodeData.children || [];
            dropNodeData.children.push(dragNodeData);
        } else {
            if (dropParent) {
                const index = dropParent.children.findIndex(child => child.key === dropKey);
                dropParent.children.splice(index + (info.dropPosition > 0 ? 1 : 0), 0, dragNodeData);
            } else {
                newTreeData.splice(parseInt(dropIndex) + (info.dropPosition > 0 ? 1 : 0), 0, dragNodeData);
            }
        }
        const updatedTreeData = updateTreeDataOnDrop(treeData, info); 
        setTreeData(updatedTreeData);
        await updateWholeCollection(updatedTreeData);
    };

    const updateWholeCollection = async (treeData) => {
        const batch = writeBatch(db);  
        const collectionRef = collection(db, "stockManager");

        const snapshot = await getDocs(collectionRef);
        snapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
        });

        treeData.forEach((node) => {
            const docRef = doc(collectionRef, node.key);  
            batch.set(docRef, {
                title: node.title,
                isLeaf: node.isLeaf,
                children: node.children ? node.children.map(child => ({ title: child.title, key: child.key, isLeaf: child.isLeaf })) : []
            });
        });

        await batch.commit();
    };

    const findNode = (nodes, key) => {
        for (const node of nodes) {
            if (node.key === key) {
                return node;
            }
            if (node.children) {
                const found = findNode(node.children, key);
                if (found) return found;
            }
        }
        return null;
    };

    const handleMenuClick = async ({ key }) => {
        setContextMenuVisible(false);
        setModalType(key);
        setLastUpdate(Date.now());

        setInputValue('');
        if (key === 'delete') {
            const result = await Swal.fire({
                title: 'Emin misiniz?',
                text: "Bu işlem geri alınamayacaktır!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, sil!'
            });
            if (result.isConfirmed) {
              console.log("oıdzfjg",currentNode.key)
              // Silinecek düğümü yerel state'den kaldır
              const updatedTreeData = removeNodeByKey(treeData, currentNode.key);
              setTreeData(updatedTreeData);
      
              // Firestore'daki ilgili dokümanı sil
              const ref = doc(db, 'stockManager', currentNode.key);
              await deleteDoc(ref);
      
              Swal.fire('Silindi!', 'Silme işlemi tamamlandı.', 'success');
          }
        } else {
            setModalVisible(true);
        }
    };
    const removeNodeByKey = (nodes, keyToRemove) => {
      return nodes.reduce((acc, node) => {
          if (node.key === keyToRemove) {
              // Eğer hedef node bulunursa, alt düğümleri ile birlikte kaldır
              return acc;
          }
          if (node.children) {
              // Eğer alt düğümler varsa, recursive olarak kontrol et
              node.children = removeNodeByKey(node.children, keyToRemove);
          }
          acc.push(node); // Anahtar eşleşmiyorsa, düğümü yeni listeye ekle
          return acc;
      }, []);
  };
  
    const addChildToSubCategory = async (parentKey, newChildTitle) => {
        const collectionRef = collection(db, "stockManager");
        const snapshot = await getDocs(collectionRef);
        let found = false;
        setLastUpdate(Date.now());

        snapshot.docs.forEach(async (doc) => {
            const data = doc.data();
            const newData = addNewChild(data, parentKey, {
                title: newChildTitle,
                key: uuidv4(),
                children: [],
                isLeaf: false,
                isMainCategory:false  // Eğer renk bilgisi varsa kullan, yoksa default bir değer atar

            });

            if (newData) {
                found = true;
                await setDoc(doc.ref, newData); 
            }
        });

        if (!found) {
            console.error("No parent key found in any document.");
        }
    };

    const addNewChild = (node, parentKey, newChild) => {
        if (node.key === parentKey) {
            node.children = [...(node.children || []), newChild];
            return node;
        } else if (node.children) {
            for (let child of node.children) {
                let updatedChild = addNewChild(child, parentKey, newChild);
                if (updatedChild) return node;  
            }
        }
        return null;  
    };

    const handleEditCategory = async (key, newTitle) => {
      const collectionRef = collection(db, "stockManager");
      const snapshot = await getDocs(collectionRef);
      let found = false;
      setLastUpdate(Date.now());
      for (const doc of snapshot.docs) {
          const data = doc.data();
          if (data.children) {
              const index = data.children.findIndex(child => child.key === key);
              if (index !== -1) {
                  const updatedChildren = [...data.children];
                  updatedChildren[index].title = newTitle;  
  
                  // Renk bilgisini de güncelle
  
                  await updateDoc(doc.ref, { children: updatedChildren });
                  found = true;
                  Swal.fire('Başarılı!', 'Kategori başarıyla güncellendi.', 'success');
                  setTreeData(prev => deepUpdateNode(prev, key, newTitle));  
                  break;
              }
          }
      }
  
      if (!found) {
          console.error("No child with given key found in any document.");
          Swal.fire('Hata!', 'Belirtilen anahtarla bir öğe bulunamadı.', 'error');
      }
  };
  

    const handleAddSubCategory = async () => {
        if (currentNode) {
            await addChildToSubCategory(currentNode.key, inputValue);
            Swal.fire('Başarılı!', 'Alt kategori ekleme işlemi başarılı', 'success');
            setInputValue('');  
        } else {
            Swal.fire('Hata!', 'Hata düğüm seçilmedi', 'error');
        }
    };

    const deepUpdateNode = (nodes, key, newTitle) => {
        return nodes.map(node => {
            if (node.key === key) {
                return { ...node, title: newTitle }; 
            } else if (node.children) {
                return { ...node, children: deepUpdateNode(node.children, key, newTitle) };
            }
            return node;
        });
    };

    const menu = (
        <Menu onClick={handleMenuClick} items={[
            { key: 'add', icon: <PlusOutlined />, label: 'Alt kategori ekle' },
            { key: 'edit', icon: <EditOutlined />, label: 'Düzenle' },
            { key: 'delete', icon: <DeleteOutlined />, label: 'Sil' }
        ]}/>
    );

    const findParentNode = (nodes, key) => {
        for (let node of nodes) {
            if (node.children && node.children.some(child => child.key === key)) {
                return node;  
            }
            if (node.children) {
                const result = findParentNode(node.children, key);
                if (result) return result;  
            }
        }
        return null;  
    };
  
    return (
        <div className='page-wrapper m-5' >
            <Card style={{marginLeft:300,maxHeight:500,minHeight:500}}>
                <Button onClick={() => {setModalType('addMain'), setModalVisible(true) }} type="primary" style={{ marginBottom: 16 }}>
                    Ana kategori ekle
                </Button>
                <Tree
                    
                    onDrop={onDrop}
                    draggable={(node) => !node.isLeaf}
                    
                    onRightClick={({ event, node }) => {
                        setCurrentNode(node);
                        setContextMenuInfo({ x: event.clientX, y: event.clientY });
                        setContextMenuVisible(true);
                    }}
                    treeData={treeData}
                    titleRender={(e)=>renderTreeNodeTitle(e)} 

                />
                {contextMenuVisible && (
                    <div style={{ position: 'fixed', left: contextMenuInfo.x, top: contextMenuInfo.y }}>
                        <Dropdown overlay={menu} visible={true} onVisibleChange={(visible) => setContextMenuVisible(visible)}>
                            <div />
                        </Dropdown>
                    </div>
                )}
                <Modal
                    title={modalType === 'addMain' ? 'Ana Kategori Ekle' : modalType === 'edit' ? "Kategori Düzenle" : "Alt kategori ekle"}
                    visible={modalVisible}
                    onOk={() => {
                        if (modalType === 'addMain') {
                            handleAddMainCategory();
                        } else if (modalType === 'edit') {
                            handleEditCategory(currentNode.key, inputValue);
                        } else {
                            handleAddSubCategory();
                        }
                    }}
                    onCancel={() => setModalVisible(false)}
                    okText="Tamam"
                    cancelText="İptal"
                >
                  {modalType === 'addMain' && (
                        <Input
                            style={{ marginTop: '16px',marginBottom:5 }}
                            inputMode='numeric'
                            value={`KTG-${categoryCodeSuffix}`}
                            onChange={e => setCategoryCodeSuffix(e.target.value.slice(4))}
                            placeholder="Kategori Kodu (KTG- ile başlamalı)"
                        />
                    )}
                                   <Input value={inputValue} onChange={e => setInputValue(e.target.value)} placeholder="Kategori adı giriniz" />

                </Modal>
            </Card>
        </div>
    );
};

export default TreeView;
