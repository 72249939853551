import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Card, Alert, Upload, Collapse, Input, DatePicker, Checkbox } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { collection, onSnapshot, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import moment from 'moment';
import { db, storage } from '../../environment';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print';
import { Printer } from 'react-feather';
const { Dragger } = Upload;
const { Panel } = Collapse;

const ProductionData = () => {
  const [data, setData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const printRef = useRef();
  const [selectedCharges, setSelectedCharges] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewingRecord, setViewingRecord] = useState(null);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [isPhotoModalVisible, setIsPhotoModalVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (editingRecord) {
      setSelectedCharges(editingRecord.selectedCharges || []);
      form.setFieldsValue({
        ...editingRecord,
        startDate: editingRecord.startDate ? moment(editingRecord.startDate).toDate() : null,
        endDate: editingRecord.endDate ? moment(editingRecord.endDate).toDate() : null,
        selectedCharges: editingRecord.selectedCharges || []
      });
    }
  }, [editingRecord, form]);
  
  
  

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'productionData'), (snapshot) => {
      const productionData = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      setData(productionData);
    });

    return () => unsubscribe();
  }, []);

  const calculateTotals = (rawMaterials, chargeCount) => {
    const rawMaterialTotal = rawMaterials.reduce((sum, material) => sum + parseFloat(material.amount || 0), 0);
    const totalProduction = rawMaterialTotal * (chargeCount || 0);
    form.setFieldsValue({
      chargeWeight: rawMaterialTotal,
      totalProduction
    });
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    setIsModalVisible(true);
    const rawMaterialTotal = record.rawMaterials?.reduce((sum, material) => sum + parseFloat(material.amount || 0), 0) || 0;
    const totalProduction = rawMaterialTotal * (record.chargeCount || 0);
    form.setFieldsValue({
      ...record,
      startDate: record.startDate ? moment(record.startDate.seconds * 1000) : null,
      endDate: record.endDate ? moment(record.endDate.seconds * 1000) : null,
      photos: Array.isArray(record.photos) ? record.photos.map((url, index) => ({
        uid: index,
        name: `photo-${index}`,
        status: 'done',
        url
      })) : [],
      rawMaterials: record.rawMaterials || [],
      chargeWeight: rawMaterialTotal,
      totalProduction
    });
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu kaydı silmek üzeresiniz!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, 'productionData', id));
          setAlertMessage('Kayıt başarıyla silindi');
          Swal.fire('Silindi!', 'Kayıt başarıyla silindi.', 'success');
        } catch (error) {
          console.error('Error deleting document: ', error);
          setAlertMessage('Kayıt silinirken hata oluştu');
          Swal.fire('Hata!', 'Kayıt silinirken hata oluştu.', 'error');
        }
      }
    });
  };

  const cleanObject = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(cleanObject).filter(v => v !== undefined && v !== null);
    } else if (obj !== null && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([k, v]) => [k, cleanObject(v)])
          .filter(([, v]) => v !== undefined && v !== null && !(Array.isArray(v) && v.length === 0))
      );
    }
    return obj;
  };

  const handleUpdate = async (values) => {
    try {
      const photosArray = Array.isArray(values.photos) ? values.photos.map(file => file.url || file.response || file.thumbUrl) : [];
  
      // Tarihleri `Date` nesnesine dönüştürme
      const startDate = values.startDate ? moment(values.startDate).toDate() : null;
      const endDate = values.endDate ? moment(values.endDate).toDate() : null;
  
      const filteredValues = {
        ...values,
        startDate: startDate,
        endDate: endDate,
        photos: photosArray,
        selectedCharges: selectedCharges || [],
      };
  
      const cleanedValues = cleanObject(filteredValues);
  
      await updateDoc(doc(db, 'productionData', editingRecord.id), cleanedValues);
      setIsModalVisible(false);
      setEditingRecord(null);
      setAlertMessage('Kayıt başarıyla güncellendi');
    } catch (error) {
      console.error('Error updating document: ', error);
      setAlertMessage('Kayıt güncellenirken hata oluştu');
    }
  };
  
  
  
  

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      setUploading(true);
      const storageRef = ref(storage, `photos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Error uploading file:', error);
          setAlertMessage('Yükleme başarısız oldu.');
          setUploading(false);
          onError(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const existingPhotos = form.getFieldValue('photos') || [];
            const updatedPhotos = Array.isArray(existingPhotos) ? [...existingPhotos, { uid: file.uid, url: downloadURL, name: file.name, status: 'done' }] : [{ uid: file.uid, url: downloadURL, name: file.name, status: 'done' }];
            form.setFieldsValue({ photos: updatedPhotos });
            setUploading(false);
            setAlertMessage('Fotoğraf başarıyla yüklendi');
            onSuccess(null, file);
          } catch (error) {
            console.error('Error getting download URL:', error);
            setAlertMessage('Yükleme sırasında hata oluştu.');
            setUploading(false);
            onError(error);
          }
        }
      );
    } catch (error) {
      console.error('Error during file upload:', error);
      setAlertMessage('Yükleme sırasında hata oluştu.');
      setUploading(false);
    }
  };

  const handleView = (record) => {
    setViewingRecord(record);
    setIsViewModalVisible(true);
  };

  const handleViewPhoto = (photo) => {
    setSelectedPhoto(photo);
    setIsPhotoModalVisible(true);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Production Data'
  });

  const handleChargeChange = (e) => {
    const { value, checked } = e.target;
    const newSelectedCharges = checked
      ? [...selectedCharges, value]
      : selectedCharges.filter((charge) => charge !== value);
    setSelectedCharges(newSelectedCharges);
    form.setFieldsValue({ selectedCharges: newSelectedCharges });
  };

  const renderChargeOptions = () => {
    const chargeCount = form.getFieldValue('chargeCount') || 0;
    const chargeOptions = [];
    for (let i = 1; i <= chargeCount; i++) {
      chargeOptions.push(
        <Checkbox key={i} value={i} checked={selectedCharges.includes(i)} onChange={handleChargeChange}>
          {i}
        </Checkbox>
      );
    }
    return chargeOptions;
  };

  return (
    <div className='page-wrapper' style={{ padding: '20px', marginTop: 50 }}>
      {alertMessage && <Alert message={alertMessage} type="info" closable afterClose={() => setAlertMessage('')} />}
      <Row gutter={[16, 16]}>
        {data.map((record) => (
          <Col span={8} key={record.id}>
            <Card
              title={<div style={{ whiteSpace: 'pre-wrap' }}>{record.productName}</div>}
              extra={
                <>
                  <Button type="link" icon={<EyeOutlined />} onClick={() => handleView(record)} />
                  <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                  <Button type="link" icon={<DeleteOutlined />} danger onClick={() => handleDelete(record.id)} />
                </>
              }
              style={{ marginBottom: '20px', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
            >
              <p><strong>Kart Numarası:</strong> {record.cardNumber}</p>
              <p><strong>Şarj Sayısı:</strong> {record.chargeCount}</p>
              <p><strong>Kg Başına Fiyat:</strong> {record.pricePerKg}</p>
              <p><strong>Toplam Üretim:</strong> {record.totalProduction}</p>
              {record.photos && record.photos.length > 0 && (
                <div>
                  <p><strong>Fotoğraflar:</strong></p>
                  {record.photos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo}
                      alt={`photo-${index}`}
                      style={{ width: '100px', marginRight: '10px', cursor: 'pointer' }}
                      onClick={() => handleViewPhoto(photo)}
                    />
                  ))}
                </div>
              )}
              {record.rawMaterials && record.rawMaterials.length > 0 && (
                <Collapse accordion>
                  <Panel header="Ham Maddeler" key="1">
                    {record.rawMaterials.map((material, index) => (
                      <div key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #f0f0f0', borderRadius: '5px' }}>
                        <p><strong>İsim:</strong> {material.name}</p>
                        <p><strong>Miktar:</strong> {material.amount}</p>
                        <p><strong>Açıklama:</strong> {material.description}</p>
                        <p><strong>Sıra Numarası:</strong> {material.sequenceNumber}</p>
                      </div>
                    ))}
                  </Panel>
                </Collapse>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Kaydı Düzenle"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose={true}
      >
        <Form form={form} onFinish={handleUpdate} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="startDate" label="Başlangıç Tarihi">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="endDate" label="Bitiş Tarihi">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="cardNumber" label="Kart Numarası">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="orderNumber" label="Sipariş Numarası">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="productName" label="Ürün Adı">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="productCategory" label="Ürün Kategorisi">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="packageWeight" label="Paket Ağırlığı">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="pricePerKg" label="Kg Başına Fiyat">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="chargeWeight" label="Bir Şarj Miktarı">
                <Input type="number" readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="totalProduction" label="Toplam Üretim Miktarı">
                <Input type="number" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="chargeCount" label="Şarj Sayısı">
                <Input
                  type="number"
                  onChange={(e) => {
                    calculateTotals(form.getFieldValue('rawMaterials'), e.target.value);
                    form.setFieldsValue({ chargeCount: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="additionalInfo" label="Ek Bilgiler">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="unpackagedExcess" label="Paketlenmemiş Fazlalık">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="remainingStock" label="Kalan Stok">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="totalProducedProduct" label="Toplam Üretilen Ürün">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="productionCardQuantity" label="Üretim Kartı Miktarı">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="difference" label="Fark">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="photos" label="Fotoğraflar" valuePropName="fileList" getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}>
                <Dragger
                  customRequest={handleUpload}
                  listType="picture"
                  multiple
                  fileList={form.getFieldValue('photos') || []}
                  onChange={({ fileList }) => form.setFieldsValue({ photos: fileList })}
                  onRemove={(file) => {
                    const updatedPhotos = form.getFieldValue('photos').filter((item) => item.uid !== file.uid);
                    form.setFieldsValue({ photos: updatedPhotos });
                  }}
                >
                  <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <UploadOutlined />}</p>
                  <p className="ant-upload-text">Dosyaları buraya sürükleyin veya yüklemek için tıklayın</p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Collapse accordion>
            <Panel header="Ham Maddeler" key="1">
              <Form.List name="rawMaterials">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div key={field.key} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #f0f0f0', borderRadius: '5px' }}>
                        <Form.Item {...field} name={[field.name, 'name']} fieldKey={[field.fieldKey, 'name']} label="İsim">
                          <Input />
                        </Form.Item>
                        <Form.Item {...field} name={[field.name, 'amount']} fieldKey={[field.fieldKey, 'amount']} label="Miktar">
                          <Input />
                        </Form.Item>
                        <Form.Item {...field} name={[field.name, 'description']} fieldKey={[field.fieldKey, 'description']} label="Açıklama">
                          <Input />
                        </Form.Item>
                        <Form.Item {...field} name={[field.name, 'sequenceNumber']} fieldKey={[field.fieldKey, 'sequenceNumber']} label="Sıra Numarası">
                          <Input type="number" />
                        </Form.Item>
                        <Button type="link" onClick={() => remove(field.name)} icon={<DeleteOutlined />}>
                          Sil
                        </Button>
                      </div>
                    ))}
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Ham Madde Ekle
                    </Button>
                  </>
                )}
              </Form.List>
            </Panel>
          </Collapse>
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Form.Item label="Şarjları Seçin">
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>{renderChargeOptions()}</div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={uploading}>
              Güncelle
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Kaydı Görüntüle"
        visible={isViewModalVisible}
        onCancel={() => setIsViewModalVisible(false)}
        footer={null}
      >
        {viewingRecord && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button style={{ alignSelf: 'flex-end' }} type="link" onClick={handlePrint}><Printer /></Button>
            <p><strong>Kart Numarası:</strong> {viewingRecord.cardNumber}</p>
            <p><strong>Şarj Sayısı:</strong> {viewingRecord.chargeCount}</p>
            <p><strong>Kg Başına Fiyat:</strong> {viewingRecord.pricePerKg}</p>
            <p><strong>Toplam Üretim:</strong> {viewingRecord.totalProduction}</p>
            {viewingRecord.photos && viewingRecord.photos.length > 0 && (
              <div>
                <p><strong>Fotoğraflar:</strong></p>
                {viewingRecord.photos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    alt={`photo-${index}`}
                    style={{ objectFit: 'contain', width: '100px', height: '100px', marginRight: '10px', cursor: 'pointer' }}
                    onClick={() => handleViewPhoto(photo)}
                  />
                ))}
              </div>
            )}
            {viewingRecord.rawMaterials && viewingRecord.rawMaterials.length > 0 && (
              <Collapse accordion>
                <Panel header="Ham Maddeler" key="1">
                  {viewingRecord.rawMaterials.map((material, index) => (
                    <div key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #f0f0f0', borderRadius: '5px' }}>
                      <p><strong>İsim:</strong> {material.name}</p>
                      <p><strong>Miktar:</strong> {material.amount}</p>
                      <p><strong>Açıklama:</strong> {material.description}</p>
                      <p><strong>Sıra Numarası:</strong> {material.sequenceNumber}</p>
                    </div>
                  ))}
                </Panel>
              </Collapse>
            )}
          </div>
        )}
      </Modal>

      <Modal
        visible={isPhotoModalVisible}
        footer={null}
        onCancel={() => setIsPhotoModalVisible(false)}
      >
        {selectedPhoto && (
          <img
            src={selectedPhoto}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: '80vh', maxWidth: '100%' }}
            alt="Selected"
          />
        )}
      </Modal>
      <div style={{ display: 'none' }}>
        <CustomPrintComponent ref={printRef} data={viewingRecord || {}} />
      </div>
    </div>
  );
};


const CustomPrintComponent = React.forwardRef(({ data }, ref) => {
  const renderChargeTable = () => {
    const table = [];
    for (let i = 0; i < Math.ceil((data.chargeCount || 0) / 8); i++) {
      const row = [];
      for (let j = 0; j < 8; j++) {
        const chargeNumber = i * 8 + j + 1;
        if (chargeNumber <= (data.chargeCount || 0)) {
          row.push(
            <td
              key={chargeNumber}
              style={{
                border: '1px solid #333',
                padding: '1.5px',
                textAlign: 'center',
                backgroundColor: data.selectedCharges?.includes(chargeNumber) ? '#90ee90' : '#fff',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {chargeNumber}
            </td>
          );
        } else {
          row.push(<td key={chargeNumber} style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px' }}></td>);
        }
      }
      table.push(<tr key={i}>{row}</tr>);
    }
    return table;
  };

  const renderRawMaterialsTable = () => (
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '12px' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px', backgroundColor: '#f2f2f2' }}>Sıra No</th>
          <th style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px', backgroundColor: '#f2f2f2' }}>Adı</th>
          <th style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px', backgroundColor: '#f2f2f2' }}>Miktar (KG)</th>
          <th style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px', backgroundColor: '#f2f2f2' }}>Açıklama</th>
        </tr>
      </thead>
      <tbody>
        {data.rawMaterials?.map((material, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px' }}>{material.sequenceNumber}</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px' }}>{material.name}</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px' }}>{material.amount}</td>
            {material.description == null ? null : (
              <td style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px' }}>{material.description}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const calculateTotals = () => {
    const packageWeight = parseFloat(data.packageWeight) || 0;
    const totalProducedProduct = parseFloat(data.totalProducedProduct) || 0;
    const unpackagedExcess = parseFloat(data.unpackagedExcess) || 0;
    const remainingStock = parseFloat(data.remainingStock) || 0;

    // Sum the quantities of the raw materials
    const rawMaterialTotal = data.rawMaterials?.reduce((sum, material) => sum + parseFloat(material.amount || 0), 0) || 0;

    const totalAmount = totalProducedProduct + unpackagedExcess;
    const difference = rawMaterialTotal - totalAmount;

    return { packageWeight, totalProducedProduct, rawMaterialTotal, unpackagedExcess, remainingStock, totalAmount, difference };
  };

  const totals = calculateTotals();

  return (
    <div ref={ref} style={{ padding: '12px', fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.2' }}>
      <h3 style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', marginBottom: '12px' }}>Üretim Reçetesi</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Kart No:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.cardNumber}</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Sipariş No:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.orderNumber}</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Başlangıç Tarihi:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.startDate ? moment(data.startDate.seconds * 1000).format('DD-MM-YYYY') : ''}</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Bitiş Tarihi:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.endDate ? moment(data.endDate.seconds * 1000).format('DD-MM-YYYY') : ''}</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Ürün Adı:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.productName}</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Ürün Kategorisi:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.productCategory}</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Ambalaj Ağırlığı:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{totals.packageWeight} KGS</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>KG Başına Fiyat:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{data.pricePerKg}</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px', fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>Toplam Üretim Miktarı:</td>
            <td style={{ border: '1px solid #333', padding: '1.5px', fontSize: '12px' }}>{totals.totalProducedProduct}</td>
          </tr>
          <tr>
            <td colSpan="4">
              <div style={{ marginTop: '5px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th colSpan="8" style={{ border: '1px solid #333', padding: '1.5px', textAlign: 'center', fontSize: '12px', backgroundColor: '#f2f2f2' }}>Şarj No</th>
                    </tr>
                  </thead>
                  <tbody>{renderChargeTable()}</tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h4 style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '15px' }}>Hammadde Listesi</h4>
      {renderRawMaterialsTable()}

      

      <Row style={{ justifyContent: 'space-between', marginTop: '20px' }}>
        <Col>
          <h4 style={{ fontSize: '16px', fontWeight: 'bold' }}>Üretim Sorumlusu:</h4>
          <div>
            <label>
              <span>İsim Soyisim: </span>
            </label>
          </div>
          <div>
            <label>
              <span>İmza: </span>
            </label>
          </div>
        </Col>

        <Col>
          <h4 style={{ fontSize: '16px', fontWeight: 'bold' }}>Üreten Kişi:</h4>
          <div>
            <label>
              <span>İsim Soyisim: </span>
            </label>
          </div>
          <div>
            <label>
              <span>İmza: </span>
            </label>
          </div>
        </Col>
      </Row>
    </div>
  );
});



CustomPrintComponent.displayName = 'CustomPrintComponent';
CustomPrintComponent.propTypes = {
  data: PropTypes.shape({
    cardNumber: PropTypes.string,
    orderNumber: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    productName: PropTypes.string,
    productCategory: PropTypes.string,
    packageWeight: PropTypes.number,
    pricePerKg: PropTypes.number,
    totalProduction: PropTypes.number,
    chargeCount: PropTypes.number,
    selectedCharges: PropTypes.arrayOf(PropTypes.number),
    rawMaterials: PropTypes.arrayOf(PropTypes.shape({
      sequenceNumber: PropTypes.number,
      name: PropTypes.string,
      amount: PropTypes.number,
      description: PropTypes.string,
    })),
    photos: PropTypes.arrayOf(PropTypes.string),
    additionalInfo: PropTypes.string,
    totalProducedProduct: PropTypes.number,
    unpackagedExcess: PropTypes.number,
    remainingStock: PropTypes.number,
  }),
};




CustomPrintComponent.displayName = 'CustomPrintComponent';
CustomPrintComponent.propTypes = {
  data: PropTypes.shape({
    cardNumber: PropTypes.string,
    orderNumber: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    productName: PropTypes.string,
    productCategory: PropTypes.string,
    packageWeight: PropTypes.number,
    pricePerKg: PropTypes.number,
    totalProduction: PropTypes.number,
    chargeCount: PropTypes.number,
    selectedCharges: PropTypes.arrayOf(PropTypes.number),
    rawMaterials: PropTypes.arrayOf(PropTypes.shape({
      sequenceNumber: PropTypes.number,
      name: PropTypes.string,
      amount: PropTypes.number,
      description: PropTypes.string,
    })),
    photos: PropTypes.arrayOf(PropTypes.string),
    additionalInfo: PropTypes.string,
    totalProducedProduct: PropTypes.number,
    productionCardQuantity: PropTypes.number,
    unpackagedExcess: PropTypes.number,
    remainingStock: PropTypes.number,
  }),
};





export default ProductionData;

