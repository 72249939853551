export const categorylist = [
	{
		"id":1,
		"category": "Laptop",
		"categoryslug": "laptop",
		"createdon": "25 May 2023",
		"status": "Aktif",
		
	},
	{
		"id":2,
		"category": "Electronics",
		"categoryslug": "electronics",
		"createdon": "24 Jun 2023",
		"status": "Aktif",
		
	},
	{
		"id":3,
		"category": "Shoe",
		"categoryslug": "shoe",
		"createdon": "23 Jul 2023",
		"status": "Aktif",
		
	},
	{
		"id":4,
		"category": "Speaker",
		"categoryslug": "speaker",
		"createdon": "22 Aug 2023",
		"status": "Aktif",
		
	},
	{
		"id":5,
		"category": "Furniture",
		"categoryslug": "furniture",
		"createdon": "21 Sep 2023",
		"status": "Aktif",
		
	},
	{
		"id":6,
		"category": "Bags",
		"categoryslug": "bags",
		"createdon": "20 Sep 2023",
		"status": "Aktif",
		
	},
	{
		"id":7,
		"category": "Phone",
		"categoryslug": "phone",
		"createdon": "20 Sep 2023",
		"status": "Aktif",
		
	},
	{
		"id":8,
		"category": "Chairs",
		"categoryslug": "chairs",
		"createdon": "20 Sep 2023",
		"status": "Aktif",
		
	}
]