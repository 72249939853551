import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../core/pagination/datatable';
import KritikStokDuzenle from './kritikstokduzenle';
import { getDocs, collection, query, where } from 'firebase/firestore'; // Firebase'den veri çekmek için gerekli fonksiyonlar
import { db } from '../../../environment'; // Firebase yapılandırma dosyanızı buradan import edin

const KritikStokListesi = () => {
    const [allData, setAllData] = useState([]); // Tüm verileri tutmak için
    const [filteredData, setFilteredData] = useState([]); // Filtrelenmiş veriyi tutmak için
    const [searchText, setSearchText] = useState(""); // Arama kutusu için

    // Firebase'den verileri çekmek ve filtrelemek için useEffect
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Depoları getir
                const depolarSnapshot = await getDocs(collection(db, 'depolar'));
                const depolar = depolarSnapshot.docs.reduce((acc, doc) => {
                    acc[doc.id] = doc.data().depoAdi;
                    return acc;
                }, {});

                // Firebase'den 'stocks' koleksiyonunu çekiyoruz
                const q = query(collection(db, 'stocks'), where('UnitAmount', '<', 'SafetyStock'));
                const querySnapshot = await getDocs(q);

                // Verileri çekip uygun formatta saklıyoruz
                const stocks = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    depoAdi: depolar[doc.data().Warehouse] || 'Bilinmiyor', // Depo ID'yi depo adına çevir
                }));

                // Verileri tabloya yüklemek için state'e atıyoruz
                setAllData(stocks); // Tüm verileri saklıyoruz
                setFilteredData(stocks); // Başlangıçta tüm veriler gösteriliyor
            } catch (error) {
                console.error("Veriler alınırken bir hata oluştu: ", error);
            }
        };

        fetchData();
    }, []);

    // Arama fonksiyonu
    const handleSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchText(searchValue);

        if (searchValue === "") {
            // Eğer arama kutusu boşsa tüm verileri geri yükle
            setFilteredData(allData);
        } else {
            // Stokları arama kriterine göre filtrele
            const filtered = allData.filter(item => 
                (item.StockName && item.StockName.toLowerCase().includes(searchValue)) ||
                (item.depoAdi && item.depoAdi.toLowerCase().includes(searchValue))
            );
            setFilteredData(filtered);
        }
    };

    const columns = [
        {
            title: "Depo",
            dataIndex: "depoAdi",
            sorter: (a, b) => a.depoAdi.length - b.depoAdi.length,
            width: "5%",
        },
        {
            title: "Stok",
            dataIndex: "StockName",
            sorter: (a, b) => a.StockName.length - b.StockName.length,
        },
        {
            title: "Miktar",
            dataIndex: "UnitAmount",
            sorter: (a, b) => a.UnitAmount - b.UnitAmount,
        },
        {
            title: "Miktar Alarmı",
            dataIndex: "SafetyStock",
            sorter: (a, b) => a.SafetyStock - b.SafetyStock,
        },
    ];

    return (
        <div>
            <div className="page-wrapper mt-5">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title me-auto">
                            <h4>Kritik Stoklar</h4>
                            <h6>Kritik Stoklarınızı Yönetin</h6>
                        </div>
                    </div>
                    <div className="table-tab">
                        <div className="table-responsive">
                            <div className="table-top">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Ara"
                                        value={searchText}
                                        onChange={handleSearch}
                                        className="form-control form-control-sm formsearch"
                                    />
                                    <Link to="#" className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                            <Table columns={columns} dataSource={filteredData} />
                        </div>
                    </div>
                </div>
            </div>
            <KritikStokDuzenle />
        </div>
    );
};

export default KritikStokListesi;
