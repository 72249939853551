import React, { useEffect, useState } from "react";
import { updateDoc, doc, getDocs, collection, getDoc } from "firebase/firestore"; 
import { db } from "../../../environment";
import PropTypes from 'prop-types'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { DatePicker } from "antd";
import moment from "moment";
const HammaddeDuzenle = ({ materialId = null }) => {
  const [material, setMaterial] = useState({
    MaterialCode: '',
    MaterialName: '',
    MaterialCategory: '',
    Unit: 'kg',
    CurrentStock: '',
    MinimumStock: '',
    MaximumStock: '',
    SafetyStock: '',
    Currency:'TRY',
    StandardCost: '',
    SupplierInfo: '',
    LeadTime: '',
    ShelfLife: '',
    StorageConditions: '',
    BatchNumber: '',
    Certificates: '',
    ImportExportInfo: '',
    SafetyInfo: ''
  });
  const MySwal = withReactContent(Swal);
  const [units, setUnits] = useState([]);  
  const [categories,setCategories]=useState([]);
  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const unitsCollection = collection(db, "units");
        const unitSnapshot = await getDocs(unitsCollection);
        const unitList = unitSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUnits(unitList);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };
    const fetchCategory = async () => {
      try {
        const categoryCollection = collection(db, "materialCategories");
        const categorySnapshot = await getDocs(categoryCollection);
        const categoryList = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCategories(categoryList);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };
    fetchCategory()

    const fetchMaterial = async () => {
      
  
     
      if (materialId) {
        const docRef = doc(db, "materials", materialId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setMaterial(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } else {
        // Reset material state to default values for adding a new material
        setMaterial({
          MaterialCode: '',
          MaterialName: '',
          MaterialCategory: '',
          Unit: '',
          CurrentStock: '',
          MinimumStock: '',
          MaximumStock: '',
          SafetyStock: '',
          StandardCost: '',
          SupplierInfo: '',
          LeadTime: '',
          ShelfLife: '',
          StorageConditions: '',
          BatchNumber: '',
          Certificates: '',
          ImportExportInfo: '',
          SafetyInfo: ''
        });
      }
    };
    fetchUnits()
    fetchMaterial();
  }, [materialId]);
  const handleDateChange = (date, dateString) => {
    setMaterial(prev => ({
      ...prev,
      ShelfLife: dateString // or date.toISOString() if you want ISO format
    }));
  };
  const handleDateChange2 = (date, dateString) => {
    setMaterial(prev => ({
      ...prev,
      LeadTime: dateString // or date.toISOString() if you want ISO format
    }));
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMaterial(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmitUpdate = async (event) => {
    event.preventDefault();
    const result = await MySwal.fire({
      title: "Kaydetmek istediğinize emin misiniz?",
      text: "Bu kayıt veritabanına eklenecektir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Kaydet!",
      cancelButtonText: "İptal"
    });

    if (result.isConfirmed) {
      const docRef = doc(db, "materials", materialId);
      await updateDoc(docRef, material);
      console.log("Document updated with ID:", materialId);
      MySwal.fire(
        'Kaydedildi!',
        'Kayıt başarıyla veritabanına eklendi.',
        'success'
      );
    }
  };
  
  return (
    <div>
      {/* Add Adjustment */}
      <div >
        <div >
          <div >
            <div className="page-wrapper-new p-0">
              <div className="content">
                 
                <div >
                  <form onSubmit={handleSubmitUpdate}>
                    <div className="container">
                      {/* Row 1 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hammadde Kodu</label>
                            <input type="text" name="MaterialCode" value={material.MaterialCode} onChange={handleChange} className="form-control" readOnly />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hammadde Adı</label>
                            <input type="text" name="MaterialName" value={material.MaterialName} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Row 2 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hammadde Tipi/Kategorisi</label>
                            <select
                              name="MaterialCategory"
                              value={material.MaterialCategory}
                              onChange={handleChange}
                              className="form-control"
                            >
                              {categories.map(unit => (
                                <option key={unit.name} value={unit.name}>{unit.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Birim</label>
                            <select
                              name="Unit"
                              value={material.Unit}
                              onChange={handleChange}
                              className="form-control"
                            >
                              {units.map(unit => (
                                <option key={unit.name} value={unit.name}>{unit.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Row 3 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Mevcut Stok Miktarı</label>
                            <input type="number" name="CurrentStock" value={material.CurrentStock} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Minimum Stok Miktarı</label>
                            <input type="number" name="MinimumStock" value={material.MinimumStock} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Row 4 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Maksimum Stok Miktarı</label>
                            <input type="number" name="MaximumStock" value={material.MaximumStock} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Güvenlik Stok Seviyesi</label>
                            <input type="number" name="SafetyStock" value={material.SafetyStock} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Row 5 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Standart Maliyet</label>
                            <input
                              type="number"
                              name="StandardCost"
                              value={material.StandardCost}
                              onChange={handleChange}
                              className="form-control"
                              style={{ display: 'inline-block', width: 'calc(70% - 12px)' }}
                            />
                            <select
                              name="Currency"
                              value={material.Currency}
                              onChange={handleChange}
                              className="form-control"
                              style={{ display: 'inline-block', width: '30%', marginLeft: '10px' }}
                            >
                              <option value="TRY">₺</option>
                              <option value="USD">$</option>
                              <option value="EUR">€</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tedarikçi Bilgileri</label>
                            <input type="text" name="SupplierInfo" value={material.SupplierInfo} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Row 6 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Yeniden Sipariş Süresi</label>
                            <DatePicker
                            
                            format="DD/MM/YYYY"
                            onChange={handleDateChange2}
                            value={material.LeadTime ? moment(material.LeadTime, "DD/MM/YYYY") : null}
                            className="form-control"
                          />        </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Raf Ömrü</label>
                            <DatePicker
                            
                            format="DD/MM/YYYY"
                            onChange={handleDateChange}
                            value={material.ShelfLife ? moment(material.ShelfLife, "DD/MM/YYYY") : null}
                            className="form-control"
                          />
                          </div>
                        </div>
                      </div>

                      {/* Row 7 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Depolama Koşulları</label>
                            <input type="text" name="StorageConditions" value={material.StorageConditions} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Parti/Seri Numarası</label>
                            <input type="text" name="BatchNumber" value={material.BatchNumber} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Row 8 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Sertifikalar ve Uygunluk Belgeleri</label>
                            <input type="text" name="Certificates" value={material.Certificates} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>İthalat ve İhracat Bilgileri</label>
                            <input type="text" name="ImportExportInfo" value={material.ImportExportInfo} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Row 9 */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Güvenlik Bilgileri</label>
                            <input type="text" name="SafetyInfo" value={material.SafetyInfo} onChange={handleChange} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="row">
                        <div className="col-12 text-center mt-4">
                          <button data-bs-dismiss="modal"
                            type="submit" className="btn btn-primary">Kaydet</button>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Adjustment */}
      {/* Edit Adjustment */}

      {/* /View Notes */}
    </div>
  );
};

export default HammaddeDuzenle;
HammaddeDuzenle.propTypes = {
  materialId: PropTypes.string // Define prop type for materialId
};