import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Axios kütüphanesini yüklemeniz gerekecek.
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import { firebaseApiUrl } from "../../../environment";
import Swal from "sweetalert2";

const Resetpassword = () => {
  const route = all_routes;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const email = params.get('email');
  const [loading,setLoading]=useState(false)
  const navigate=useNavigate()
  useEffect(() => {
    if(!token){
      navigate(all_routes.signin)
    }
    return () => {
      
    }
  }, [])
  
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true)

    if (password !== confirmPassword) {
      setError('Şifreler eşleşmiyor!');
      return;
    }
    
    // Burada API endpointinize token ve yeni şifre ile bir istek gönderilir
   await axios.post(`https://${firebaseApiUrl}/validateResetToken-validateResetToken`, {
      email:email,
      token: token,
      newPassword: password
    }).then(response => {
      console.log("sızklog",response.data)
    
      if (response.data.success) {
        Swal.fire({
          title: "Şifre Yenileme Başarılı!",
          
          icon: "success",
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Tamam'
        });
        navigate(route.signin)
      } else {
        setError('Failed to reset password: ' + response.data.error);
      }
    })
    .catch(err => {
      setError('An error occurred: ' + err.message);
    }).finally(()=>{
      setLoading(false)

    })
    console.log("sfdpgj",token,password)
  };

  return (
    <div className="main-wrapper">
      {loading==true?<div id="global-loader">
        <div className="whirly-loader"></div>
      </div>:null}
      
      <div className="account-content">
        <div className="login-wrapper reset-pass-wrap bg-img">
          <div className="login-content">
            <form onSubmit={handlePasswordReset}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/yclkimya.png" alt="Logo" />
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt="White Logo" />
                </Link>
                <div className="login-userheading">
                  <h3>Şifre Yenile</h3>
                  <h4>Yeni şifrenizi giriniz.</h4>
                </div>
                <div className="form-login">
                  <label>Yeni Şifre</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                </div>
                <div className="form-login">
                  <label>Yeni Şifre Onayla</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  </div>
                </div>
                <div className="form-login">
                  <button type="submit" className="btn btn-login">Şifreyi Değiştir</button>
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="signinform text-center">
                  <h4>
                    <Link to={route.signin} className="hover-a">Giriş Yap</Link>
                  </h4>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
