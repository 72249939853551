import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "../../core/json/siderbar_data";
import CollapsedSidebar from "./collapsedSidebar";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../environment";
import { Input } from "antd"; // Import Ant Design Input component

const { Search } = Input; // Destructure Search from Input

const Sidebar = () => {
  const userId = localStorage.getItem("userId");
  const Location = useLocation();

  const [, setCurrentUser] = useState(null);
  const [subOpen, setSubopen] = useState("");
  const [, setSubsidebar] = useState("");
  const [sidebarData, setSidebarData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [filteredData, setFilteredData] = useState([]); // Filtered menu state

  const toggleSidebar = (title) => {
    setSubopen((prev) => (prev === title ? "" : title));
  };

  const toggleSubsidebar = (subitem) => {
    setSubsidebar((prev) => (prev === subitem ? "" : subitem));
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        const userRef = doc(db, "users", userId);
        try {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            let data = SidebarData;
            setCurrentUser(docSnap.data());

            // Filter based on user role
            const role = docSnap.data()?.role?.label;
            if (role === "Kullanıcı") {
              data = [];
            } else if (role === "Muhasebe") {
              data = data.filter((item) => item.label === "Raporlar" || item.label === "Uygulama");
            } else if (role === "Satın Alma") {
              data = data.filter(
                (item) =>
                  item.label === "Sipariş Yönetimi" ||
                  item.label === "Hızlı Ekle" ||
                  item.label === "Müşteri Yönetimi" ||
                  item.label === "Stok"
              );
              data.forEach((item) => {
                if (item.label === "Sipariş Yönetimi") {
                  item.submenuItems = item.submenuItems.filter((subItem) => subItem.label === "Satın Alma");
                }
                if (item.label === "Müşteri Yönetimi") {
                  item.submenuItems = item.submenuItems.filter(
                    (subItem) =>
                      subItem.label === "Tedarikçiler" ||
                      subItem.label === "Stok Kartı Oluştur" ||
                      subItem.label === "Stok Düzenle/Güncelle"
                  );
                }
              });
            } else if (role === "Satış") {
              data = data.filter(
                (item) =>
                  item.label === "Sipariş Yönetimi" ||
                  item.label === "Hızlı Ekle" ||
                  item.label === "Stok" ||
                  item.label === "Müşteri Yönetimi" ||
                  item.label === "Envanter"||
                  item.label === "Üretim Yönetimi"
              );
              data.forEach((item) => {
                if (item.label === "Sipariş Yönetimi") {
                  item.submenuItems = item.submenuItems.filter((subItem) => subItem.label === "Satış");
                }
                if (item.label === "Stok") {
                  item.submenuItems = item.submenuItems.filter(
                    (subItem) =>
                      subItem.label === "Stok Kartı Oluştur" || subItem.label === "Stok Düzenle/Güncelle"
                  );
                }
                if (item.label === "Müşteri Yönetimi") {
                  item.submenuItems = item.submenuItems.filter((subItem) => subItem.label === "Tedarikçiler");
                }
              });
            } else if (role === "Yönetim") {
              data = data.filter(
                (item) => item.label === "Envanter" || item.label === "Sertifikalar&Belgeler" || item.label === "Uygulama" || item.label === "Stok"
              );
            } else if (role === "Admin") {
              data = data.filter(
                (item) =>
                  item.label === "Stok" ||
                  item.label === "Sipariş Yönetimi" ||
                  item.label === "Sertifikalar&Belgeler" ||
                  item.label === "Uygulama" ||
                  item.label === "İnsan Kaynakları" ||
                  item.label === "Müşteri Yönetimi" ||
                  item.label === "Kullanıcı Yönetimi" ||
                  item.label === "Raporlar" ||
                  item.label === "Mali İşler & Hesaplar" ||
                  item.label === "Envanter"
              );
            } else if (role === "İnsan Kaynakları") {
              data = data.filter(
                (item) =>
                  item.label === "Stok" ||
                  item.label === "Uygulama" ||
                  item.label === "İnsan Kaynakları" ||
                  item.label === "Sertifikalar&Belgeler" ||
                  item.label === "Müşteri Yönetimi" ||
                  item.label === "Kullanıcı Yönetimi" ||
                  item.label === "Sipariş Yönetimi"
              );
            } else if (role === "Sistem Admini") {
              data = data.filter(
                (item) =>
                  item.label === "Stok" ||
                  item.label === "Sipariş Yönetimi" ||
                  item.label === "Sertifikalar&Belgeler" ||
                  item.label === "Uygulama" ||
                  item.label === "İnsan Kaynakları" ||
                  item.label === "Üretim Yönetimi" ||
                  item.label === "Hızlı Ekle" ||
                  item.label === "Müşteri Yönetimi" ||
                  item.label === "Kullanıcı Yönetimi" ||
                  item.label === "Raporlar" ||
                  item.label === "Mali İşler & Hesaplar" ||
                  item.label === "Envanter" ||
                  item.label === "Tablet"
              );
            } else if (role === "Tablet") {
              data = data.filter((item) => item.label === "Tablet");
            }

            setSidebarData(data);
            setFilteredData(data); // Set the filtered data initially as the complete data
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUser();
  }, [userId]);

  // Filter menu items based on search term
  useEffect(() => {
    if (searchTerm.trim()) {
      const filtered = sidebarData.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.submenuItems?.some((subItem) =>
          subItem.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(sidebarData);
    }
  }, [searchTerm, sidebarData]);

  return (
    <div>
      <div style={{ zIndex: 1 }} className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              {/* Add Search Input */}
              <div className="sidebar-search">
                <Search
                  placeholder="Menü Ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  enterButton
                  allowClear
                />
              </div>
<br></br>
              <ul>
                {filteredData?.map((mainLabel, index) => (
                  <li className="submenu-open" key={index}>
                    <h6 className="submenu-hdr">{mainLabel?.label}</h6>

                    <ul>
                      {mainLabel?.submenuItems?.map((title, i) => {
                        return (
                          <li className="submenu" key={i}>
                            <Link
                              to={title?.link}
                              onClick={() => toggleSidebar(title?.label)}
                              className={`${subOpen === title?.label ? "subdrop" : ""} ${
                                title?.submenuItems?.some((subItem) =>
                                  subItem.link.includes(Location.pathname)
                                )
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {title?.icon}
                              <span>{title?.label}</span>
                              <span className={title?.submenu ? "menu-arrow" : ""} />
                            </Link>
                            {/* Submenu */}
                            {title?.submenuItems?.length > 0 && (
                              <ul
                                style={{
                                  display: subOpen === title?.label ? "block" : "none",
                                }}
                              >
                                {title?.submenuItems?.map((item, titleIndex) => (
                                  <li className="submenu submenu-two" key={titleIndex}>
                                    <Link
                                      to={item?.link}
                                      className={`${item?.link === Location.pathname ? "active" : ""}`}
                                      onClick={() => toggleSubsidebar(item?.label)}
                                    >
                                      {item?.label}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
      <CollapsedSidebar />
    </div>
  );
};

export default Sidebar;
