import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../environment";
import Swal from "sweetalert2";

const Profile = () => {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phone: "",
    role: { label: "" },
    img: "",
    password: ""
  });
  const [file, setFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  let userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUserData = async () => {
      const userDocRef = doc(db, "users", userId);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchUserData();
  }, [userId]); // Changed to depend on userId instead of userData.img to prevent infinite loop

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserData(prevUserData => ({ ...prevUserData, img: reader.result }));
      };
      reader.readAsDataURL(file);
    }
    setFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDocRef = doc(db, "users", userId);

    if (file) {
      const fileRef = ref(storage, `profileImages/${userDocRef.id}`);
      await uploadBytes(fileRef, file);
      const img = await getDownloadURL(fileRef);
      userData.img = img;
    }

    await updateDoc(userDocRef, userData);
    Swal.fire({
      title: "Başarılı!",
      text: "Profil başarıyla kaydedildi.",
      icon: "success"
    });
  };

  return (
    <div className="page-wrapper">
      <form onSubmit={handleSubmit}>
        <div className="content">
          <div className="page-header">
            
          </div>
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-top">
                  <div className="profile-content">
                    <div className="profile-contentimg">
                      <img
                        src={userData.img || "assets/img/customer/no-imagecustomer.png"}
                        alt="img"
                        id="blah"
                        style={{ borderRadius: 55, width: 150, height: 110, objectFit: "cover" }}
                      />
                      <div className="profileupload">
                        <input onChange={handleFileChange} type="file" id="imgInp" />
                        <Link to="#">
                          <ImageWithBasePath src="assets/img/icons/edit-set.svg" alt="img" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>{userData.username}</h2>
                      <h4>{userData.role ? userData.role.label : "Loading role..."}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Kullanıcı Adı</label>
                    <input
                      type="text"
                      name="username"
                      onChange={handleInputChange}
                      className="form-control"
                      value={userData.username}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label>E-posta</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleInputChange}
                      className="form-control"
                      defaultValue={userData.email}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Telefon Numarası</label>
                    <input
                      name="phone"
                      type="text"
                      onChange={handleInputChange}
                      defaultValue={userData.phone}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Şifre</label>
                    <div className="pass-group">
                      <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={handleInputChange}
                        value={userData.password}
                        className="pass-input form-control"
                      />
                      <span
                        className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"} toggle-password`}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-submit">Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Profile;
