import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Layout, notification, Tag, DatePicker } from 'antd';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, Timestamp } from "firebase/firestore";
import { TransitionGroup } from 'react-transition-group';
import dayjs from 'dayjs';
import './styles.css'; // Animasyonlar için CSS
import { db } from '../../../environment';

const { Content } = Layout;

const SampleTrackingApp = () => {
  const [samples, setSamples] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentSample, setCurrentSample] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'numunelerSTS'));
      setSamples(querySnapshot.docs.map(doc => {
        const data = doc.data();
        return { ...data, id: doc.id, tarih: data.tarih.toDate() };
      }));
    };

    fetchData();
  }, []);

  const handleAdd = () => {
    form.resetFields();
    setCurrentSample(null);
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    form.setFieldsValue({ ...record, tarih: dayjs(record.tarih) });
    setCurrentSample(record.id);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'numunelerSTS', id));
    setSamples(samples.filter(sample => sample.id !== id));
    notification.success({ message: 'Numune başarıyla silindi' });
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    values.tarih = Timestamp.fromDate(values.tarih.toDate());
    if (currentSample) {
      await updateDoc(doc(db, 'numunelerSTS', currentSample), values);
      setSamples(samples.map(sample => sample.id === currentSample ? { ...values, id: currentSample, tarih: values.tarih.toDate() } : sample));
      notification.success({ message: 'Numune başarıyla güncellendi' });
    } else {
      const docRef = await addDoc(collection(db, 'numunelerSTS'), values);
      setSamples([...samples, { ...values, id: docRef.id, tarih: values.tarih.toDate() }]);
      notification.success({ message: 'Numune başarıyla eklendi' });
    }
    setIsModalVisible(false);
  };

  const getStatusTag = (status) => {
    if (status === "TESLİM EDİLDİ") {
      return <Tag color="green">{status}</Tag>;
    } else {
      return <Tag color="red">{status}</Tag>;
    }
  };

  return (
    <div className='page-wrapper'>
      <Layout style={{ margin: 25 }} className="layout">
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div style={{ padding: 24, background: '#fff', textAlign: 'left' }}>
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>Numune Ekle</Button>
            <TransitionGroup>
              <Table 
                dataSource={samples} 
                columns={[
                  { title: 'Numune No', dataIndex: 'numuneNo', key: 'numuneNo' },
                  { title: 'Tarih', dataIndex: 'tarih', key: 'tarih', render: (text) => dayjs(text).format('YYYY-MM-DD') },
                  { title: 'Unvan', dataIndex: 'unvan', key: 'unvan' },
                  { title: 'Üretim Ref. No', dataIndex: 'uretimRefNo', key: 'uretimRefNo' },
                  { title: 'Personel Adı', dataIndex: 'personelAdi', key: 'personelAdi' },
                  { title: 'Şahit Numune mi?', dataIndex: 'sahitNumune', key: 'sahitNumune' },
                  { title: 'Teslim Şekli', dataIndex: 'teslimSekli', key: 'teslimSekli' },
                  { title: 'Ürün Açıklaması', dataIndex: 'urunAciklamasi', key: 'urunAciklamasi' },
                  { title: 'Verilen Miktar', dataIndex: 'verilenMiktar', key: 'verilenMiktar' },
                  { title: 'Kargo Takip No', dataIndex: 'kargoTakipNo', key: 'kargoTakipNo' },
                  { title: 'Son Durum', dataIndex: 'sonDurum', key: 'sonDurum', render: (text) => getStatusTag(text) },
                  {
                    title: ' ',
                    key: 'actions',
                    render: (_, record) => (
                      <span>
                        <Button onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>Düzenle</Button>
                        <Button onClick={() => handleDelete(record.id)} danger>Sil</Button>
                      </span>
                    )
                  }
                ]}
                rowClassName="editable-row"
              />
            </TransitionGroup>

            <Modal title={currentSample ? "Numune Düzenle" : "Numune Ekle"} visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
              <Form form={form} layout="vertical">
                <Form.Item name="tarih" label="Tarih" rules={[{ required: true, message: 'Lütfen tarih giriniz' }]}>
                  <DatePicker />
                </Form.Item>
                <Form.Item name="unvan" label="Unvan" rules={[{ required: true, message: 'Lütfen unvan giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="numuneNo" label="Numune No" rules={[{ required: true, message: 'Lütfen numune no giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="uretimRefNo" label="Üretim Ref. No" rules={[{ required: true, message: 'Lütfen üretim ref. no giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="personelAdi" label="Personel Adı" rules={[{ required: true, message: 'Lütfen personel adı giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="sahitNumune" label="Şahit Numune mi?" rules={[{ required: true, message: 'Lütfen şahit numune mi giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="teslimSekli" label="Teslim Şekli" rules={[{ required: true, message: 'Lütfen teslim şekli giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="urunAciklamasi" label="Ürün Açıklaması" rules={[{ required: true, message: 'Lütfen ürün açıklaması giriniz' }]}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item name="verilenMiktar" label="Verilen Miktar" rules={[{ required: true, message: 'Lütfen verilen miktar giriniz' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="kargoTakipNo" label="Kargo Takip No">
                  <Input />
                </Form.Item>
                <Form.Item name="sonDurum" label="Son Durum">
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default SampleTrackingApp;
