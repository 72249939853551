import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Edit, Trash2 } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import { collection, deleteDoc, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../environment";
import * as XLSX from 'xlsx';
import StokGuncelleModal from "./stokguncellemodal";
import moment from "moment";
import './stokguncelle.css'
const StokGuncelle = () => {
  const [currentId, setCurrentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);

  const fetchCategoryName = async (categoryId, collectionName) => {
    if (!categoryId) return "";
    const categoryDoc = await getDoc(doc(db, collectionName, categoryId));
    if (categoryDoc.exists()) {
      return categoryDoc.data().title;
    } else {
      return "";
    }
  };

  const fetchStockData = async () => {
    const unsubscribe = onSnapshot(collection(db, "stocks"), async (snapshot) => {
      const stocks = await Promise.all(snapshot.docs.map(async (doc) => {
        const stockData = doc.data();
        const stockCategoryName = await fetchCategoryName(stockData.StockCategory, 'stockManager');
        const groupName = await fetchCategoryName(stockData.GroupName, 'groupManager');
        const typeName = await fetchCategoryName(stockData.Types, 'typeManager');

        const currentDate = moment();
        const endDate = moment(stockData.ShelfLifeEnd, "DD/MM/YYYY");
        const diffDays = endDate.diff(currentDate, 'days');
        return {
          id: doc.id,
          ...stockData,
          StockCategoryName: stockCategoryName,
          GroupName: groupName,
          TypeName: typeName,
          RemainingDays: diffDays
        };
      }));
      setData(stocks);
      setFilteredData(stocks);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    fetchStockData();
  }, []);
  
  // Arama çubuğunda girilen terime göre filtreleme işlemi
  useEffect(() => {
    const filtered = data.filter(stock => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        stock.FullStockCode?.toLowerCase().includes(searchTermLower) ||
        stock.StockName?.toLowerCase().includes(searchTermLower) ||
        stock.StockCategoryName?.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredData(filtered);
  }, [searchTerm, data]);

  const openModal = (id) => {
    setCurrentId(id);
    setShowModal(true);
  };

  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "FullStockCode",
      sorter: (a, b) => (a.FullStockCode || "").localeCompare(b.FullStockCode || ""),
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.FullStockCode}
        </span>
      ),
      
    },

    {
      title: "Stok Adı",
      dataIndex: "StockName",
      sorter: (a, b) => (a.StockName || "").localeCompare(b.StockName || ""),
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.StockName}
        </span>
      ),

    },
    {
      title: "Stok Tipi/Kategorisi",
      dataIndex: "StockCategoryName",
      sorter: (a, b) => (a.StockCategoryName || "").localeCompare(b.StockCategoryName || ""),
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.StockCategoryName}
        </span>
      ),
    },
    
    {
      title: "Birim",
      dataIndex: "Unit",
      sorter: (a, b) => (a.Unit || "").localeCompare(b.Unit || ""),
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.Unit}
        </span>
      ),

    },
    {
      title: "Mevcut Stok Miktarı",
      dataIndex: "UnitAmount",
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.UnitAmount}
        </span>
      ),

      sorter: (a, b) => a.UnitAmount - b.UnitAmount
    },
    {
      title: "Minimum Stok Miktarı",
      dataIndex: "MinimumStock",
       align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.MinimumStock}
        </span>
      ),
      sorter: (a, b) => a.MinimumStock - b.MinimumStock
    },
    {
      title: "Maksimum Stok Miktarı",
      dataIndex: "MaximumStock",
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.MaximumStock}
        </span>
      ),
      sorter: (a, b) => a.MaximumStock - b.MaximumStock
    },
    {
      title: "Güvenlik Stok Seviyesi",
      dataIndex: "SafetyStock",
      align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.SafetyStock}
        </span>
      ),
      sorter: (a, b) => a.SafetyStock - b.SafetyStock
    },
    {
      title: "Standart Maliyet",
      dataIndex: "StandardCost",align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.StandardCost}
        </span>
      ),
      sorter: (a, b) => a.StandardCost - b.StandardCost
    },
    {
      title: "Tedarikçi Bilgileri",
      dataIndex: "SupplierInfo",align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.SupplierInfo}
        </span>
      ),
      sorter: (a, b) => (a.SupplierInfo || "").localeCompare(b.SupplierInfo || "")
    },
    {
      title: "Yeniden Sipariş Süresi",
      dataIndex: "LeadTime",align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.LeadTime}
        </span>
      ),
      sorter: (a, b) => a.LeadTime - b.LeadTime
    },
    
    {
      title: "Raf Ömrü Bitiş",
      dataIndex: "ShelfLifeEnd",align:'right',
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.ShelfLifeEnd}
        </span>
      ),
      sorter: (a, b) => new Date(a.ShelfLifeEnd) - new Date(b.ShelfLifeEnd)
    },
    {
      title: "Kalan Gün",
      dataIndex: "RemainingDays",
      sorter: (a, b) => a.RemainingDays - b.RemainingDays,
      render: (text, record) => (
        <span>{record.RemainingDays} gün</span>
      )
    },
    {
      title: "Depolama Koşulları",
      dataIndex: "StorageConditions",align:'right',
      sorter: (a, b) => (a.StorageConditions || "").localeCompare(b.StorageConditions || "")
      ,render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.StorageConditions}
        </span>
      ),
    },
    {
      title: "Parti/Seri Numarası",
      dataIndex: "BatchNumber",align:'right',
      sorter: (a, b) => (a.BatchNumber || "").localeCompare(b.BatchNumber || ""),
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.BatchNumber}
        </span>
      ),
    },
    {
      title: "Sertifikalar ve Uygunluk Belgeleri",
      dataIndex: "Certificates",align:'right',
      sorter: (a, b) => (a.Certificates || "").localeCompare(b.Certificates || ""),
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.Certificates}
        </span>
      ),
    },
    {
      title: "İthalat ve İhracat Bilgileri",
      dataIndex: "ImportExportInfo",align:'right',
      sorter: (a, b) => (a.ImportExportInfo || "").localeCompare(b.ImportExportInfo || ""),
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.ImportExportInfo}
        </span>
      ),
    },
    {
      title: "Güvenlik Bilgileri",
      dataIndex: "SafetyInfo",align:'right',
      sorter: (a, b) => (a.SafetyInfo || "").localeCompare(b.SafetyInfo || ""),
      render: (text, record) => (
        <span
          style={{
            color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue"
          }}
        >
          {record.SafetyInfo}
        </span>
      ),
    },
    {
      title: " ",
      dataIndex: "action",
      fixed:'right',
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" data-id={record.id} to="#" onClick={() => openModal(record.id)}>
              <Edit className="feather-edit" />
            </Link>
            <Link className="confirm-text p-2" to="#" onClick={() => showConfirmationAlert(record)}>
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </td>
      )
    }
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (record) => {
    MySwal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "Hayır, iptal et!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteDoc(doc(db, "stocks", record.id));
        MySwal.fire("Silindi!", "Kayıt başarıyla silindi.", "success");
      }
    });
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData.length > 0 ? filteredData : data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stok Verileri");
    XLSX.writeFile(workbook, "StokVerileri.xlsx");
  };

  return (
    <div className="page-wrapper mt-5">
      <div className="row ms-3 me-3 align-items-center">
  <div className="col-md-3">
    {/* Arama Çubuğu */}
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        placeholder="Stok Ara"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  </div>
  
  
  
  <div className="col-md-3 d-flex align-items-center">
    <span style={{ color: "green" }}>Yeşiller yeni depo,</span>
    <span style={{ color: "blue", marginLeft: '5px' }}>maviler fabrika verileridir.</span>
  </div>
  <div className="col-md-6 d-flex justify-content-end align-items-center">
    <button className="btn btn-primary ms-2" onClick={handleDownloadExcel}>Excel İndir</button>
  </div>
</div>


      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <Table

                    columns={columns}
                    dataSource={filteredData.length > 0 ? filteredData : data} // Verilerin gösterilmesi
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <StokGuncelleModal show={showModal} setShow={setShowModal} currentId={currentId} />
      )}
    </div>
  );
};

export default StokGuncelle;
