import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../environment";
import Swal from "sweetalert2";
import ChangeLogs from "../changelogs/changelogs";
import ShortcutSelector from "../shortcuts/shortcutselector";
import { OpenAI } from "openai";  // Güncellenmiş kullanım

export default function SpecialUserPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { userId } = location.state || {};
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [shortcuts, setShortcuts] = useState([]);
    const [aiResponse, setAiResponse] = useState(""); // AI cevabı için state
    const [userQuestion, setUserQuestion] = useState(""); // Kullanıcı sorusu için state

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            if (!userId) {
                console.log("No user ID found.");
                setLoading(false);
                return;
            }
            const userRef = doc(db, "users", userId);
            try {
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setCurrentUser(docSnap.data());
                } else {
                    console.log("No such user!");
                    alertUser();
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                alertUser();
            } finally {
                setLoading(false);
            }
        };
        fetchUser();
    }, [userId]);

    useEffect(() => {
        const savedShortcuts = JSON.parse(localStorage.getItem('userShortcuts')) || [];
        setShortcuts(savedShortcuts);
    }, []);

    const alertUser = () => {
        Swal.fire({
            title: 'Kullanıcı Bulunamadı!',
            text: 'Lütfen tekrar giriş yapınız!',
            icon: 'error',
            confirmButtonText: 'Tamam'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/signin');
            }
        });
    };

    // OpenAI Entegrasyonu
    const fetchModelResponse = async () => {
        const openai = new OpenAI({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser:true,
        });

        try {
            const completion = await openai.chat.completions.create({
                model: "ft:gpt-3.5-turbo-1106:personal:my-experiment3:ADSWkfoQ",
                messages: [
                    { role: "user", content: userQuestion }
                ]
            });
            setAiResponse(completion.choices[0].message.content);
            console.log(completion.choices[0].message.content);
        } catch (error) {
            console.error("Error fetching completion:", error);
        }
    };

    return (
        <div className="page-wrapper">
            <ChangeLogs />
            {loading ? (
                <div id="global-loader">
                    <div className="whirly-loader"></div>
                </div>
            ) : (
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <h1 className="display-4">Hoş Geldiniz!</h1>
                                    <p className="lead">{!loading && currentUser ? currentUser.username : "Hata!"} olarak giriş yaptınız</p>
                                </div>
                                {/* Kullanıcı Sorusunu Almak İçin Text Input */}
                                <input 
                                    type="text" 
                                    className="form-control mb-3" 
                                    placeholder="Sorunuzu buraya yazın..." 
                                    value={userQuestion} 
                                    onChange={(e) => setUserQuestion(e.target.value)} 
                                />
                                <button className="btn btn-primary" onClick={fetchModelResponse}>AI dan Cevap Al</button>
                                {aiResponse && (
                                    <div className="mt-3">
                                        <h4>AI dan Cevap:</h4>
                                        <p>{aiResponse}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <div className="accordion" id="shortcutAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingShortcuts">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseShortcuts" aria-expanded="true" aria-controls="collapseShortcuts">
                                                    <h4><i className="fa fa-sticky-note m-2" aria-hidden="true"></i>
                                                        İşlem Kısayolları</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseShortcuts" className="accordion-collapse collapse show" aria-labelledby="headingShortcuts" data-bs-parent="#shortcutAccordion">
                                                <div className="accordion-body">
                                                    <ShortcutSelector onSave={setShortcuts} />
                                                </div>
                                            </div>
                                            {shortcuts.length > 0 && (
                                                <div className="d-grid gap-2">
                                                    <div className="d-grid gap-2 justify-content-center">
                                                        {shortcuts.map((shortcut, index) => (
                                                            <button
                                                                style={{ width: 150 }}
                                                                className="btn btn-primary mb-2 m-1"
                                                                key={index}
                                                                onClick={() => navigate(shortcut.value)}
                                                            >
                                                                {shortcut.label}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <h4><i className="fa fa-bell m-2"></i>Bildirimler</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bg-dark-gray p-2 rounded-lg m-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
