import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Row, Col , Card, Select, message, Table } from 'antd';
import { collection, addDoc, getDocs, doc, getDoc, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../../environment';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import './utils/printablecomponent.css'; // Import the CSS file for styling
import logo from '../../assets/yclkimya.png'
const { TextArea } = Input;
const { Option } = Select;



const PrintableForm = React.forwardRef((props, ref) => {
  const { formValues, totalCells, recipes, total_production_amount_pcs, total_production_amount } = props;

  // Style definitions
  const headerStyle = {
    textAlign: 'center',
    marginBottom: '2px',
    fontSize: '10px',
  };

  const sectionStyle = {
    marginBottom: '8px',
    padding: '6px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
    fontSize: '8px',
  };

  const rowStyle = {
    gutter: [3, 3],
    fontSize: '8px',
  };

  return (
    <div
      ref={ref}
      className="printable-form"
      style={{ fontSize: '8px', padding: '5px', lineHeight: '1.2', fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f4f4' }}
    >
      <header style={headerStyle}>
        <img src={logo} alt="Logo" style={{ width: '80px', marginBottom: '-10px', marginTop: '-10px' }} />
        <h1 style={{ color: '#333', fontSize: '12px', margin: 0 }}>Reçeteden Üretim Kartı Oluşturma Formu</h1>
      </header>

      <section className="form-section" style={sectionStyle}>
        <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Üretim Detayları</h2>
        <Row gutter={[1, 1]} style={rowStyle}>
          <Col span={8}>
            <p style={{ color: 'red' }}>
              <strong>Reçete Numarası:</strong> {recipes}
            </p>
          </Col>

          <Col span={8}>
            <p>
              <strong>Başlama Tarihi:</strong> {formValues.start_date}
            </p>
          </Col>

          <Col span={8}>
            <p>
              <strong>Bitiş Tarihi:</strong> {formValues.end_date}
            </p>
          </Col>

          <Col span={8}>
            <p style={{ color: 'red' }}>
              <strong>Sipariş Numarası:</strong> {formValues.card_number}
            </p>
          </Col>
        </Row>
      </section>

      <section className="form-section" style={sectionStyle}>
        <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Reçete Seçimi</h2>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={8}>
            <p>
              <strong>Şarj Sayısı:</strong> {totalCells}
            </p>
          </Col>
          <Col span={8}>
            <p>
              <strong>Ürün Adı:</strong> {formValues.product_name}
            </p>
          </Col>
          <Col span={8}>
            <p>
              <strong>Ambalaj Kilogramı:</strong> {formValues.packaging_weight?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
          </Col>
        </Row>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={8}>
            <p>
              <strong>1 Şarj Toplam Kg:</strong> {formValues.batch_total_kg?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
          </Col>

          <Col span={8}>
            <p>
              <strong>Toplam Üretim Miktarı:</strong> {total_production_amount?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
          </Col>

          <Col span={8}>
            <p>
              <strong>Toplam Üretim Miktarı Adedi:</strong> {total_production_amount_pcs?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
          </Col>
        </Row>
      </section>

      <section className="form-section" style={sectionStyle}>
  <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Üretimde Kullanılan Hammaddeler</h2>
  <Table
  dataSource={formValues.rawMaterials}
  columns={[
    { title: 'Adı', dataIndex: 'materialName', key: 'materialName', align: 'right' },
    { title: 'Cinsi', dataIndex: 'type', key: 'type', align: 'right' },
    {
      title: 'Miktar (kg)',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record) => {
        const amount = record.amount || 0;
        return <span>{amount.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>;
      },
    },
    {
      title: 'Toplam Kullanılacak Miktar (kg)',
      key: 'totalAmount',
      align: 'right',
      render: (text, record) => {
        // Şarj sayısı ve miktarı çarpıyoruz
        const totalCells = formValues.totalCells || 1;
        const amount = record.amount || 0;
        return <span>{(totalCells * amount).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>;
      },
    },
    { title: 'Açıklama', dataIndex: 'description', key: 'description', align: 'right' },
  ]}
  pagination={false}
  rowKey={(record) => record.materialName + record.type}
  bordered
  size="small"
/>

</section>

      <section className="form-section" style={sectionStyle}>
        <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Üretim Kartı Detayları</h2>
        <Row gutter={[3, 3]} style={rowStyle}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <p>
              <strong>Üretim Kartını Oluşturan</strong><br /> {formValues.creator_name}
            </p>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <p>
              <strong>Üretim Sorumlusu</strong><br /> {formValues.production_staff_name}
              <div style={{ height: '45px' }}></div>
              __________________________________
            </p>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <p>
              <strong>Üretim Sorumlusu İmzası</strong>
              <div style={{ height: '45px' }}></div>
              __________________________________
            </p>
          </Col>

        </Row>
      </section>

      <section className="form-section" style={sectionStyle}>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={12}>
            <p style={{ whiteSpace: 'pre-wrap' }}>
              <strong>Notlar:</strong> <br />{formValues.notes}
            </p>
          </Col>
        </Row>
      </section>


    </div>
  );
});


PrintableForm.displayName = 'PrintableForm';

// PropTypes doğrulaması
PrintableForm.propTypes = {
  recipes: PropTypes.string,
  total_production_amount_pcs: PropTypes.number,
  total_production_amount: PropTypes.number,
  formValues: PropTypes.shape({
    totalCells:PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    card_number: PropTypes.string,
    batch_counter: PropTypes.string,
    product_name: PropTypes.string,
    packaging_weight: PropTypes.number,
    batch_total_kg: PropTypes.number,
    total_production_amount: PropTypes.number,
    total_production_amount_pcs: PropTypes.number,
    recipe_number: PropTypes.string,
    rawMaterials: PropTypes.arrayOf(
      PropTypes.shape({
        materialName: PropTypes.string,
        type: PropTypes.string,
        amount: PropTypes.number,
        description: PropTypes.string,
      })
    ),
    creator_name: PropTypes.string,
    production_staff_name: PropTypes.string,
    additional_info: PropTypes.string,
    signature: PropTypes.string,
    notes: PropTypes.string,
    note_writer: PropTypes.string,
  }).isRequired,
  selectedBatch: PropTypes.object.isRequired,
  totalCells: PropTypes.number.isRequired, // totalCells eklenmiştir
};



const ProductionForm = () => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    rawMaterials: [],
  });
  const [selectedBatch,] = useState({});
  const [totalCells, setTotalCells] = useState(10);
  const [recipes, setRecipes] = useState([]);
  const [batchTotalKg, setBatchTotalKg] = useState(0);
  const [nextCardNumber, setNextCardNumber] = useState('RUK-00001');
  const [isPrintDisabled, setIsPrintDisabled] = useState(true); // New state to control the print button

  const printRef = useRef();

  useEffect(() => {
    const fetchRecipes = async () => {
      const querySnapshot = await getDocs(collection(db, 'productRecipe'));
      const recipeList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRecipes(recipeList);
    };
    form.validateFields().then(() => {
      setIsPrintDisabled(false);
    }).catch(() => {
      setIsPrintDisabled(true);
    });
    fetchRecipes();
    fetchNextCardNumber();
  }, [form]);

  const fetchNextCardNumber = async () => {
    try {
      const q = query(collection(db, 'onaylananUretimKartlari'), orderBy('card_number', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const lastCardNumber = querySnapshot.docs[0].data().card_number;
        const newCardNumber = incrementCardNumber(lastCardNumber);
        console.log(newCardNumber)
        setNextCardNumber(newCardNumber);
        form.setFieldsValue({ card_number: newCardNumber });
      } else {
        setNextCardNumber('RUK-00001');
        form.setFieldsValue({ card_number: 'RUK-00001' });
      }
    } catch (error) {
      console.error('Error fetching the next card number:', error);
      setNextCardNumber('RUK-00001');
      form.setFieldsValue({ card_number: 'RUK-00001' });
    }
  };
  const handlePrint = async () => {
    try {
      // Validate fields before printing
      await form.validateFields();
      printRef.current.print();
    } catch (error) {
      message.error('Lütfen tüm zorunlu alanları doldurun!'); // Show a warning message
    }
  };
  const incrementCardNumber = (lastCardNumber) => {
    const numberPart = lastCardNumber ? lastCardNumber.split('-')[1] : '00000';
    const incrementedNumber = (parseInt(numberPart, 10) + 1).toString().padStart(5, '0');
    return `RUK-${incrementedNumber}`;
  };

  const handleRecipeChange = async (recipeId) => {
    try {
      const recipeDoc = await getDoc(doc(db, 'productRecipe', recipeId));
      const recipeData = recipeDoc.data();
      if (!recipeData) {
        console.error('No recipe data found');
        return;
      }

      const totalBatchKg = parseFloat(recipeData.totalBatchKg);
      const packagingWeight = parseFloat(recipeData.packagingWeight || 0);
      const product_name = recipeData.productName;
      const rawMaterials = recipeData.rawMaterials.map(material => ({
        materialName: material.materialName,
        amount: material.amount,
        type: material.type,
        description: material.description,



      }));

      // Şarj sayısının girilmesini bekle, eğer şarj sayısı boşsa uyarı gösterme.
      if (!totalCells || totalCells <= 0) {
        alert("Lütfen geçerli bir şarj sayısı girin!");
        form.setFieldsValue({ total_production_amount: 0, total_production_amount_pcs: 0 });
        return;
      }

      // Toplam üretim miktarını ve adedini hesapla
      const totalProductionAmount = totalCells;
      const totalProductionAmountPcs = totalProductionAmount / packagingWeight;
      // Tüm form değerlerini güncelle
      const updatedFormValues = {
        recipe_number: recipeData.recipeNumber,
        product_name: product_name,
        packaging_weight: packagingWeight,
        card_number: nextCardNumber,
        notes: form.getFieldValue('notes'),  // Kullanıcının yazdığı notları koruyun
        total_production_amount: totalProductionAmount,
        total_production_amount_pcs: totalProductionAmountPcs,
        additional_info: recipeData.productionRoute,  // Ek bilgileri doldur
        creator_name: recipeData.creator,
        rawMaterials,  // Hammadde bilgileri güncelleniyor
        batch_total_kg: totalBatchKg,
      };

      // Form alanlarını güncelle
      form.setFieldsValue(updatedFormValues);

      // PDF'e aktarılan formValues'u güncelle
      setFormValues(updatedFormValues);

    } catch (error) {
      console.error('Error fetching recipe data:', error);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    const rawMaterials = allValues.rawMaterials || [];
    const totalBatchKg = rawMaterials.reduce((acc, material) => acc + (material?.amount || 0), 0);
    const packagingWeight = allValues.packaging_weight || 0;
    form.validateFields().then(() => {
      setIsPrintDisabled(false);
    }).catch(() => {
      setIsPrintDisabled(true);
    });
    // Şarj sayısı kontrolü
    let currentTotalCells = allValues.totalCells;
    if (!currentTotalCells || currentTotalCells <= 0) {
      form.setFieldsValue({ total_production_amount: 0, total_production_amount_pcs: 0 });
      return;
    }

    const totalProductionAmount = totalBatchKg * currentTotalCells;
    const totalProductionAmountPcs = totalProductionAmount / packagingWeight;
    form.setFieldsValue({
      total_production_amount_pcs: totalProductionAmountPcs
    });


    console.log(totalProductionAmountPcs)
    form.setFieldsValue({
      product_name: allValues.product_name,
      batch_total_kg: totalBatchKg.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      total_production_amount: totalProductionAmount.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      total_production_amount_pcs: totalProductionAmountPcs.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    });

    setBatchTotalKg(totalBatchKg);

    const formattedValues = {
      ...allValues,
      start_date: allValues.start_date ? allValues.start_date.format('DD-MM-YYYY') : '',
      end_date: allValues.end_date ? allValues.end_date.format('DD-MM-YYYY') : '',
      notes: allValues.notes || '',
      card_number: nextCardNumber,
    };

    setFormValues(formattedValues);
  };

  const onFinish = async (values) => {
    try {
      const dataToSave = {
        ...values,
        start_date: values.start_date ? values.start_date.format('DD-MM-YYYY') : '',
        end_date: values.end_date ? values.end_date.format('DD-MM-YYYY') : '',
        card_number: nextCardNumber,
        rawMaterials: values.rawMaterials || [],
        batch_total_kg: batchTotalKg || 0,
        total_production_amount: values.total_production_amount || 0,
        production_status: "Hazırlık Aşamasında",
        recipe_number: form.getFieldValue('recipe_number'),
        selectedBatch,
        totalCells,
        notes: values.notes || '',
        note_writer: values.note_writer || '',
        additional_info: values.additional_info || ''
      };

      await addDoc(collection(db, "productionData"), dataToSave);

      form.resetFields();
      fetchNextCardNumber();

      alert("Veri başarıyla kaydedildi!");
    } catch (e) {
      console.error("Veri ekleme hatası: ", e);
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  return (
    <div className='page-wrapper'>
      <Card style={{ padding: '30px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', maxWidth: '900px', margin: '0 auto', borderRadius: '8px' }}>
  <h2 style={{ textAlign: 'center', color: '#d9534f', marginBottom: '20px' }}>Üretim Kartı Formu</h2>
  <Form
    form={form}
    name="production_form"
    onFinish={onFinish}
    layout="vertical"
    onValuesChange={onValuesChange}
  >
    <Row gutter={16}>
      <Col span={6}>
        <Form.Item name="start_date" label="Başlama Tarihi" rules={[{ required: true, message: 'Lütfen başlangıç tarihini giriniz!' }]}>
          <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="end_date" label="Bitiş Tarihi">
          <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="card_number" label="Kart Numarası" rules={[{ required: true, message: 'Lütfen kart numarasını giriniz!' }]}>
          <Input value={nextCardNumber} readOnly />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Reçete Seçimi">
          <Select placeholder="Reçete Seçin" onChange={handleRecipeChange}>
            {recipes.map(recipe => (
              <Option key={recipe.id} value={recipe.id}>
                {recipe.recipeNumber}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={6}>
        <Form.Item name="totalCells" label="Şarj Sayısı" rules={[{ required: true, message: 'Lütfen şarj sayısını giriniz!' }]}>
          <InputNumber min={1} max={500} value={totalCells} onChange={(value) => setTotalCells(value)} style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="packaging_weight" label="Ambalaj Kg" rules={[{ required: true, message: 'Lütfen ambalaj kilogramını giriniz!' }]}>
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="batch_total_kg" label="1 Şarj Toplam Kg">
          <InputNumber value={batchTotalKg} style={{ width: '100%' }} disabled />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="total_production_amount" label="Toplam Üretim Miktarı">
          <InputNumber decimalSeparator='.' style={{ width: '100%' }} disabled />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={6}>
        <Form.Item name="product_name" label="Ürün Adı">
          <Input style={{ width: '100%' }} disabled />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="total_production_amount_pcs" label="Toplam Üretim Adedi">
          <InputNumber style={{ width: '100%' }} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="creator_name" label="Oluşturan" rules={[{ required: true, message: 'Lütfen adını giriniz!' }]}>
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Form.List name="rawMaterials">

  {(fields,) => (
    <>

      <Row gutter={16}>
        <Col span={5}>
          <Form.Item label="Adı" />
        </Col>
        <Col span={5}>
          <Form.Item label="Cinsi" />
        </Col>
        <Col span={5}>
          <Form.Item label="Miktar (kg)" />
        </Col>
        <Col span={8}>
          <Form.Item label="Açıklama" />
        </Col>
      </Row>
      <div style={{margin:-45}}></div>

      {fields.map(({ key, name, fieldKey, ...restField }) => (
        <Row gutter={16} key={key}>
          <Col span={5}>
            <Form.Item
              {...restField}
              name={[name, 'materialName']}
              fieldKey={[fieldKey, 'materialName']}
              rules={[{ required: true, message: 'Lütfen hammadde adını giriniz!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              {...restField}
              name={[name, 'type']}
              fieldKey={[fieldKey, 'type']}
              rules={[{ required: true, message: 'Lütfen cinsini giriniz!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              {...restField}
              name={[name, 'amount']}
              fieldKey={[fieldKey, 'amount']}
              rules={[{ required: true, message: 'Lütfen miktarı giriniz!' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...restField}
              name={[name, 'description']}
              fieldKey={[fieldKey, 'description']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </>
  )}

</Form.List>


    <Row gutter={16}>
      <Col span={24}>
        <Form.Item name="notes" label="Notlar">
          <TextArea style={{ height: '80px' }} />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#1890ff', borderColor: '#1890ff' }}>
        Kaydet
      </Button>
    </Form.Item>
  </Form>

  <ReactToPrint
    trigger={() => <Button disabled={isPrintDisabled} onClick={handlePrint} type="primary" style={{ marginTop: '16px' }}>Yazdır</Button>}
    content={() => printRef.current}
  />

  <div style={{ display: 'none' }}>
    <PrintableForm
      ref={printRef}
      recipes={form.getFieldValue('recipe_number')}
      formValues={formValues}
      total_production_amount_pcs={form.getFieldValue('total_production_amount_pcs')}
      total_production_amount={form.getFieldValue('total_production_amount')}
      selectedBatch={selectedBatch}
      totalCells={totalCells}
    />
  </div>
</Card>

    </div>
  );
};



export default ProductionForm;
