export const base_path = "/";
export const firebaseApiUrl="us-central1-yucelkimyaerpdb.cloudfunctions.net"
// export const _serverUrl="https://hepatechlabs.com"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";



// export const base_path = "/";
// export const _serverUrl="http://localhost:8080"




// Import the functions you need from the SDKs you need
// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Firebase yapılandırma bilgilerinizi buraya ekleyin
const firebaseConfig = {
  apiKey: "AIzaSyC-pm9RV0lfmRmwUuLW1JJHTGH03GRSMzo",
  authDomain: "yucelkimyaerpdb.firebaseapp.com",
  projectId: "yucelkimyaerpdb",
  storageBucket: "yucelkimyaerpdb.appspot.com",
  messagingSenderId: "907313149216",
  appId: "1:907313149216:web:ae061d207d06322d261345",
  measurementId: "G-ELQZX37QHS"
};
export const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Le0CkwqAAAAAKGFlYClhdhsybwDRP5pegLSMtAB'), // Replace with your actual reCAPTCHA v3 site key
  isTokenAutoRefreshEnabled: true, // Automatically refresh tokens
});
// Firebase'i başlatma
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);




