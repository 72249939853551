import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronUp, PlusCircle, RotateCcw, Sliders, StopCircle, User } from 'feather-icons-react/build/IconComponents';
import { setToogleHeader } from '../../core/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, Zap } from 'react-feather';
import Select from 'react-select';
import Table from '../../core/pagination/datatable'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AddSalesReturns from '../../core/modals/sales/addsalesreturns';
import EditSalesRetuens from '../../core/modals/sales/editsalesretuens';
const SalesReturn = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const dataSource = useSelector((state) => state.salesreturns_data);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const oldandlatestvalue = [
    { value: 'date', label: 'Tarihe Göre Sırala' },
    { value: 'newest', label: 'En yeni' },
    { value: 'oldest', label: 'En eski' },
  ];
  const customer = [
    { value: 'Choose Customer', label: 'Müşteri Seçiniz' },
    { value: 'Thomas', label: 'Thomas' },
    { value: 'James', label: 'James' },
    { value: 'Beverly', label: 'Beverly' },
  ];
  const status = [
    { value: 'Choose Status', label: 'Durum Seçiniz' },
    { value: 'Teslim Alındı', label: 'Teslim Alındı' },
    { value: 'Pending', label: 'Ödeme Bekleniyor' },
  ];
  const paymentstatus = [
    { value: 'Choose Payment Status', label: 'Ödeme Yöntemi Seçiniz' },
    { value: 'Unpaid', label: 'Ödenmedi' },
    { value: 'Paids', label: 'Ücretler' },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  )
  const columns = [

    {
      title: "Ürün Adı",
      dataIndex: "productname",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img" />
          <ImageWithBasePath alt="img" src={record.img} />
          <Link to="#" className='ms-2'>{text}</Link>

        </div>
      ),
      sorter: (a, b) => a.productname.length - b.productname.length,
    },
    {
      title: "Tarih",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Müşteri",
      dataIndex: "customer",
      sorter: (a, b) => a.customer.length - b.customer.length,
    },
    {
      title: "Durum",
      dataIndex: "status",
      render: (text) => (
        <div>
          {text === "Ödeme Alındı" && (
            <span className="badges bg-lightgreen">{text}</span>
          )}
          {text === "Ödeme Bekleniyor" && (
            <span className="badges bg-lightred">{text}</span>
          )}
          {text === "Sipariş Verildi" && (
            <span className="badges bg-lightyellow">{text}</span>
          )}
        </div>
      ), 
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Genel Toplam ($)",
      dataIndex: "grandtotal",
      sorter: (a, b) => a.grandtotal.length - b.grandtotal.length,
    },
    {
      title: "Ödeme",
      dataIndex: "paid",
      sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title: "Vade ($)",
      dataIndex: "due",
      sorter: (a, b) => a.due.length - b.due.length,
    },
    {
      title: "Ödeme Durumu",
      dataIndex: "paymentstatus",
      render: (text) => (
        <div>
          {text === "Ödeme Yapıldı" && (
            <span className="badges bg-lightgreen">{text}</span>
          )}
          {text === "Ödeme Alınmadı" && (
            <span className="badges bg-lightred">{text}</span>
          )}
          {text === "Taksitli" && (
            <span className="badges bg-lightyellow">{text}</span>
          )}
        </div>
      ),
      sorter: (a, b) => a.paymentstatus.length - b.paymentstatus.length,
    },
    {
      title: ' ',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-sales-new">
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2" to="#"  >
              <i data-feather="trash-2" className="feather-trash-2" onClick={showConfirmationAlert}></i>
            </Link>
          </div>
        </td>
      )
    },
  ]
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Bu işlem geri alınamaz!',
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Evet, Sil!',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.isConfirmed) {

        MySwal.fire({
          title: 'Silindi!',
          text: 'Kayıt silindi!',
          className: "btn btn-success",
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      } else {
        MySwal.close();
      }

    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>
                  Satış İade Listesi
                </h4>
                <h6>İade yönetimi ekranı</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>

                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => { dispatch(setToogleHeader(!data)) }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-sales-new"
              >
                <PlusCircle className="me-2" />
                Yeni İade Ekle
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Ara"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                    </span>
                  </Link>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="select"
                    options={oldandlatestvalue}
                    placeholder="En yeni"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? ' visible' : ''}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? 'block' : 'none' }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={customer}
                          placeholder="Marka"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Zap className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Marka"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">

                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={paymentstatus}
                          placeholder="Marka"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i data-feather="search" className="feather-search" />{" "}
                          Ara{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={dataSource} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddSalesReturns />
      <EditSalesRetuens />
    </div>
  )
}

export default SalesReturn
