export const designationdata= [
	{
		"id":1,
		"designation": "Designer",
		"members": "+3",
		"createdon": "07",
		"totalmembers": "25 May 2023",
		"status": "Active",
		users: [
            'assets/img/users/user-08.jpg',
            'assets/img/users/user-13.jpg',
            'assets/img/users/user-09.jpg',
            'assets/img/users/user-11.jpg',
          ],
          additionalCount: 0,
	},
	{
		"id":2,
		"designation": "Curator",
		"members": "+5",
		"createdon": "08",
		"totalmembers": "27 June 2023",
		"status": "Active",
        // users: [
        //     'assets/img/users/user-08.jpg',
        //     'assets/img/users/user-13.jpg',
        //     'assets/img/users/user-09.jpg',
        //     'assets/img/users/user-11.jpg',
        //   ],
        //   additionalCount: 0,
		
	},
	{
		"id":3,
		"designation": "System Administrator",
		"members": "+2",
		"createdon": "06",
		"totalmembers": "29 June 2023",
		"status": "Active",
		
	},
	{
		"id":4,
		"designation": "Administrative Officer",
		"members": "+1",
		"createdon": "03",
		"totalmembers": "15 July 2023",
		"status": "Active",
		
	},
	{
		"id":5,
		"designation": "Technician",
		"members": "+2",
		"createdon": "05",
		"totalmembers": "19 July 2023",
		"status": "Active",
		
	},
	{
		"id":6,
		"designation": "Office Support Secretary",
		"members": "+5",
		"createdon": "09",
		"totalmembers": "04 August 2023",
		"status": "Active",
		
	},
	{
		"id":7,
		"designation": "Tech Lead",
		"members": "+6",
		"createdon": "10",
		"totalmembers": "13 August 2023",
		"status": "Active",
		
	},
	{
		"id":8,
		"designation": "Database administrator",
		"members": "+1",
		"createdon": "04",
		"totalmembers": "24 August 2023",
		"status": "Active",
		
	}
]