import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Breadcrumbs from '../../core/breadcrumbs';
import { Link } from 'react-router-dom';
import { Filter, Sliders, StopCircle, User, Calendar } from 'react-feather';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { db } from '../../environment';

const TeslimEdilenSiparisler = () => {
  const [orders, setOrders] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  useEffect(() => {
    fetchOrders();
  }, [selectedName, selectedStatus, dateRange]);

  const fetchOrders = async () => {
    const ordersRef = collection(db, 'deliveredOrders');
    let q = ordersRef;

    if (selectedName) {
      q = query(q, where('cariAdi', '==', selectedName.value));
    }

    if (selectedStatus) {
      q = query(q, where('deliveryStatus', '==', selectedStatus.value));
    }

    if (dateRange.startDate && dateRange.endDate) {
      q = query(q, where('sevkTarihi', '>=', dateRange.startDate), where('sevkTarihi', '<=', dateRange.endDate));
    }

    const querySnapshot = await getDocs(q);
    const ordersData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setOrders(ordersData);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(prevVisibility => !prevVisibility);
  };

  const handleDateRange = (event, picker) => {
    setDateRange({
      startDate: picker.startDate.toDate(),
      endDate: picker.endDate.toDate(),
    });
  };

  const nameOptions = [
    { value: 'Rose', label: 'Rose' },
    { value: 'Kaitlin', label: 'Kaitlin' },
  ];

  const statusOptions = [
    { value: 'basarili', label: 'Başarılı' },
    { value: 'sorunlu', label: 'Sorunlu' },
    { value: 'beklemede', label: 'Beklemede' },
  ];

  const initialSettings = {
    startDate: new Date(), // default start date
    endDate: new Date(), // default end date
    timePicker: false,
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'basarili':
        return <span className="badge badge-linesuccess">Başarılı</span>;
      case 'sorunlu':
        return <span className="badge badge-linedanger">Sorunlu</span>;
      case 'beklemede':
        return <span className="badge badge-warning">Beklemede</span>;
      default:
        return <span className="badge badge-secondary">Bilinmiyor</span>;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Teslim Edilen Siparişler"
          subtitle="Teslim Edilen Siparişleri Yönet"
        />
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Ara"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${isFilterVisible ? 'setclose' : ''}`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  className="select"
                  options={statusOptions}
                  placeholder="Duruma göre sırala"
                  onChange={setSelectedStatus}
                />
              </div>
            </div>
            <div
              className={`card${isFilterVisible ? ' visible' : ''}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? 'block' : 'none' }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        className="select"
                        options={nameOptions}
                        placeholder="İsim Seçiniz"
                        onChange={setSelectedName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <StopCircle className="info-img" />
                      <Select
                        className="select"
                        options={statusOptions}
                        placeholder="Durum Seçiniz"
                        onChange={setSelectedStatus}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <div className="position-relative daterange-wraper">
                        <Calendar className="feather-14 info-img" />
                        <DateRangePicker initialSettings={initialSettings} onApply={handleDateRange}>
                          <input
                            className="form-control col-4 input-range"
                            type="text"
                            style={{ border: 'none' }}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto" onClick={fetchOrders}>
                        <i data-feather="search" className="feather-search" /> Ara
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th className="no-sort">
                      <label className="checkboxs">
                        <input type="checkbox" id="select-all" />
                        <span className="checkmarks" />
                      </label>
                    </th>
                    <th>Onay No</th>
                    <th>Müşteri</th>
                    <th>Vade Tarihi</th>
                    
                    <th>Durum</th>
                    <th>Not</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(order => (
                    <tr key={order.id}>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                        </label>
                      </td>
                      <td>{order.belgeNo}</td>
                      <td>{order.cariAdi}</td>
                      <td>{new Date(order.sevkTarihi.toDate()).toLocaleDateString()}</td>
                      
                      <td>{getStatusBadge(order.deliveryStatus)}</td>
                      <td>{order.note}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeslimEdilenSiparisler;
