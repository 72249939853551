import React, { useEffect, useState } from "react";
import { Card, Descriptions, Table, Typography, Spin, Input, Row, Col } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import { db } from "../../environment";
import './utils/tamamlananuretimkartlari.css'; // CSS dosyasını burada import ediyoruz

const { Search } = Input;

const TamamlananUretimKartlari = () => {
    const [productionCards, setProductionCards] = useState([]);
    const [filteredCards, setFilteredCards] = useState([]);
    const [materialsWithStocks, setMaterialsWithStocks] = useState({}); // Her kart için stok verilerini ayrı tutacağız
    const [loading, setLoading] = useState(true);
    const [hoveredCardId, setHoveredCardId] = useState(null);

    // Üretimi biten kartları Firestore'dan çekiyoruz
    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "üretimiBitenKartlar"));
                const cards = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setProductionCards(cards);
                setFilteredCards(cards); // Başlangıçta tüm kartları gösteriyoruz
            } catch (error) {
                console.error("Veri çekme hatası: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Stok bilgilerini Firestore'dan çekiyoruz
    const fetchStockUnitAmount = async (materialName) => {
        const stocksQuery = query(collection(db, "stocks"), where("StockName", "==", materialName));
        const querySnapshot = await getDocs(stocksQuery);

        if (!querySnapshot.empty) {
            const stockData = querySnapshot.docs[0].data(); // İlk uygun sonucu alıyoruz
            return stockData.UnitAmount;
        }
        return "Bilinmiyor"; // Eğer stok bulunmazsa
    };

    // Malzemeler ve stok bilgilerini eşleştiriyoruz
    const handleFetchStockAmounts = async () => {
        const allMaterialsWithStocks = {};
        for (const card of filteredCards) {
            // Check if rawMaterials exists and is an array
            if (card.rawMaterials && Array.isArray(card.rawMaterials)) {
                const materialsWithStockData = await Promise.all(
                    card.rawMaterials.map(async (material) => {
                        const stockAmount = await fetchStockUnitAmount(material.materialName);
                        return { ...material, stockAmount }; // Add stock amount to the material
                    })
                );
                allMaterialsWithStocks[card.id] = materialsWithStockData; // Save stock data for each card
            } else {
                allMaterialsWithStocks[card.id] = []; // If no rawMaterials, save an empty array
            }
        }
        setMaterialsWithStocks(allMaterialsWithStocks);
    };
    

    // Kartlar filtrelendikten sonra stok bilgilerini çekiyoruz
    useEffect(() => {
        if (filteredCards.length > 0) {
            handleFetchStockAmounts();
        }
    }, [filteredCards]);

    // Arama çubuğunda girilen değere göre kartları filtreliyoruz
    const handleSearch = (value) => {
        const filtered = productionCards.filter((card) =>
            card.product_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCards(filtered);
    };

    if (loading) return <Spin size="large" />;

    if (!filteredCards.length) return <p>Veri bulunamadı.</p>;

    return (
        <div className="page-wrapper mt-5 production-summary-container" style={{ padding: "20px" }}>
            {/* Arama Çubuğu */}
            <Search
                placeholder="Ürün adı ile arayın..."
                onSearch={handleSearch}
                enterButton
                style={{ marginBottom: "20px", maxWidth: "400px" }}
            />

            <Row gutter={[16, 16]}>
                {filteredCards.map((productionData) => {
                    const {
                        id,
                        product_name,
                        productionStartTime,
                        production_end_date,
                        batch_total_kg,
                        palletCount,
                        totalCells,
                        productsPerPallet,
                        producedQuantity,
                        wasteQuantity,
                        total_production_amount,
                        notes,
                        card_number,
                        batchCounter,
                    } = productionData;

                    // Raw Materials tablosunda yeni bir kolon ekleme (Toplam Kullanılan Miktar ve Kalan Stok)
                    const columns = [
                        { title: "Malzeme Adı", dataIndex: "materialName", key: "materialName", align: 'right' },
                        { title: "Miktar (KG)", dataIndex: "amount", key: "amount", align: 'right' },
                        { title: "Tip", dataIndex: "type", key: "type", align: 'right' },
                        {
                            title: "Toplam Kullanılan Miktar (KG)",
                            key: "totalUsed",
                            align: 'right',
                            render: (_, record) => {
                                const totalUsed = record.amount * totalCells;
                                return totalUsed.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // 2 ondalıklı formatlama
                            }
                        },
                        {
                            title: "Kalan Stok (KG)",
                            key: "UnitAmount",
                            align: 'right',
                            render: (_, record) => record.stockAmount ? record.stockAmount.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "Bilinmiyor"
                        }
                    ];

                    const formattedStartDate = moment(productionStartTime).format("DD-MM-YYYY HH:mm");
                    const formattedEndDate = moment(production_end_date).format("DD-MM-YYYY HH:mm");

                    return (
                        <Col key={productionData.id} xs={24} sm={24} md={12} lg={12}>
                            <Card
                                className={`production-card ${hoveredCardId === productionData.id ? 'expanded' : ''}`}
                                hoverable
                                title={<Typography.Text style={{ color: '#fff' }}>{`${product_name} --- ${card_number} - Üretim Özeti`}</Typography.Text>}
                                bordered={false}
                                style={{ marginBottom: "20px", background: "#D32F2F", borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
                                onMouseEnter={() => setHoveredCardId(productionData.id)}
                                onMouseLeave={() => setHoveredCardId(null)}
                                extra={<Typography.Text style={{ color: '#fff' }}>{formattedStartDate} - {formattedEndDate}</Typography.Text>}
                            >
                                {hoveredCardId === productionData.id && (
                                    <div className="animated-content">
                                        <Typography.Title level={3} style={{ color: '#fff' }}>Üretim Bilgileri</Typography.Title>
                                        <Descriptions bordered column={1} style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '10px' }}>
                                            <Descriptions.Item label="Toplam Üretim Miktarı (KG)">
                                                {batch_total_kg}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Toplam Üretilen Ürün Miktarı">
                                                {producedQuantity}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Fire Miktarı">{wasteQuantity}</Descriptions.Item>
                                            <Descriptions.Item label="Palet Sayısı">{palletCount}</Descriptions.Item>
                                            <Descriptions.Item label="Şarj Sayısı">{totalCells}</Descriptions.Item>
                                            <Descriptions.Item label="Kalan Şarj Sayısı">{batchCounter}</Descriptions.Item>

                                            <Descriptions.Item label="Palet Başına Ürün">
                                                {productsPerPallet}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Toplam Üretim Miktarı (Adet)">
                                                {total_production_amount}
                                            </Descriptions.Item>
                                        </Descriptions>

                                        <Typography.Title level={3} style={{ marginTop: "20px", color: '#fff' }}>
                                            Kullanılan Hammaddeler
                                        </Typography.Title>

                                        {/* Her kart için kendi material verilerini getiriyoruz */}
                                        <Table
                                            dataSource={materialsWithStocks[id]}  // Her kart için stok verisi
                                            columns={columns}
                                            pagination={false}
                                            scroll={{ y: 240 }}  // Tabloya kaydırma özelliği ekliyoruz
                                        />

                                        

                                    </div>
                                    
                                )}
                                <Typography.Title level={3} style={{ marginTop: "20px", color: '#fff', }}>
    Notlar
</Typography.Title>
<Card style={{ maxHeight: '900px', overflowY: 'auto' }}>
    {notes}
</Card>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default TamamlananUretimKartlari;
