import React from 'react';
import PropTypes from 'prop-types';

const PrintLayout = React.forwardRef(({
  selectedOrder,
  containerNumbers, // Updated to handle an array of container numbers
  palletCounts,
  productWeights,
  language,
  invoiceNumber,
  invoiceDate,
  logoUrl,
}, ref) => {

  const translations = {
    tr: {
      packingList: "PAKET LİSTESİ",
      companyName: "ŞİRKET ADI",
      companyAddress: "ŞİRKET ADRESİ",
      telefonMail: "TELEFON & MAIL",
      importerName: "İTHALATÇI ADI",
      importerAddress: "İTHALATÇI ADRESİ",
      containerNumber: "KONTEYNER NUMARASI",
      productName: "ÜRÜN ADI",
      totalUnit: "TOPLAM BİRİM",
      totalPalet: "TOPLAM PALET",
      totalNetWeight: "TOPLAM NET AĞIRLIK",
      totalGrossWeight: "TOPLAM BRÜT AĞIRLIK",
      totalPallets: "TOPLAM PALET SAYISI",
      invoiceNumber: "FATURA NUMARASI",
      invoiceDate: "FATURA TARİHİ"
    },
    en: {
      packingList: "PACKING LIST",
      companyName: "COMPANY NAME",
      companyAddress: "COMPANY ADDRESS",
      telefonMail: "TELEFON & MAIL",
      importerName: "IMPORTER NAME",
      importerAddress: "IMPORTER ADDRESS",
      containerNumber: "CONTAINER NUMBER",
      productName: "PRODUCT NAME",
      totalUnit: "TOTAL UNIT",
      totalPalet: "TOTAL PALET",
      totalNetWeight: "TOTAL NET WEIGHT",
      totalGrossWeight: "TOTAL GROSS WEIGHT",
      totalPallets: "TOTAL PALLET COUNT",
      invoiceNumber: "INVOICE NUMBER",
      invoiceDate: "INVOICE DATE"
    },
    ar: {
      packingList: "قائمة التعبئة",
      companyName: "اسم الشركة",
      companyAddress: "عنوان الشركة",
      telefonMail: "الهاتف والبريد الإلكتروني",
      importerName: "اسم المستورد",
      importerAddress: "عنوان المستورد",
      containerNumber: "رقم الحاوية",
      productName: "اسم المنتج",
      totalUnit: "إجمالي الوحدة",
      totalPalet: "إجمالي البليت",
      totalNetWeight: "إجمالي الوزن الصافي",
      totalGrossWeight: "إجمالي الوزن الإجمالي",
      totalPallets: "إجمالي عدد البليت",
      invoiceNumber: "رقم الفاتورة",
      invoiceDate: "تاريخ الفاتورة"
    }
  };

  return (
    <div ref={ref} style={{ padding: '20px', fontSize: '12px' }}>
      {/* Logo Section */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src={logoUrl} alt="Company Logo" style={{ maxWidth: '200px' }} />
      </div>

      <h2 style={{ textAlign: 'center' }}>{translations[language].packingList}</h2>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <strong>{translations[language].companyName}:</strong>
          <p>YÜCEL KİMYA TEMİZLİK MADDELER SAN TİC LTD ŞTİ</p>
          <strong>{translations[language].companyAddress}:</strong>
          <p>Karadenizliler Mah Barbaros Cad No 66A/1 Pk. 41140 Başiskele/KOCAELİ</p>
          <strong>{translations[language].telefonMail}:</strong>
          <p>0 541 485 70 79, info@yucelkimya.com.tr</p>
          <strong>{translations[language].invoiceNumber}:</strong>
          <p>{invoiceNumber}</p>
          <strong>{translations[language].invoiceDate}:</strong>
          <p>{invoiceDate}</p>
        </div>
        <div>
          <strong>{translations[language].importerName}:</strong>
          <p>{selectedOrder.muhHesapAdi}</p>
          <strong>{translations[language].importerAddress}:</strong>
          <p>{selectedOrder.faturaAdresi}</p>
          <strong>{translations[language].telefonMail}:</strong>
          <p>{selectedOrder.telefon}</p>
        </div>
      </div>

      <hr />

      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #000', padding: '8px' }}>{translations[language].containerNumber}</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>{translations[language].productName}</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>{translations[language].totalUnit}</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>{translations[language].totalPalet}</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>{translations[language].totalNetWeight}</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>{translations[language].totalGrossWeight}</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrder.urunler.map((urun, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>{containerNumbers[index]}</td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>{urun.urunAdi}</td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>{urun.miktar}</td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {palletCounts[index]}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {urun.miktar * productWeights[index]} KG
              </td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {palletCounts[index] * 20 + urun.miktar * productWeights[index]} KG
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr />

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <div>
          <strong>{translations[language].totalPallets}:</strong>
          <p>{palletCounts.reduce((acc, pallet) => acc + pallet, 0)}</p>
        </div>
        <div>
          <strong>{translations[language].totalNetWeight}:</strong>
          <p>{selectedOrder.urunler.reduce((acc, urun, index) => acc + urun.miktar * productWeights[index], 0)} KG</p>
        </div>
        <div>
          <strong>{translations[language].totalGrossWeight}:</strong>
          <p>{palletCounts.reduce((acc, pallet, index) => acc + pallet * 20 + selectedOrder.urunler[index].miktar * productWeights[index], 0)} KG</p>
        </div>
      </div>
    </div>
  );
});

// Add a display name for better debugging
PrintLayout.displayName = 'PrintLayout';

PrintLayout.propTypes = {
  selectedOrder: PropTypes.object.isRequired,
  containerNumbers: PropTypes.arrayOf(PropTypes.string).isRequired, // Updated to handle an array of container numbers
  palletCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  productWeights: PropTypes.arrayOf(PropTypes.number).isRequired,
  language: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.string.isRequired,
  invoiceDate: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
};

export default PrintLayout;
