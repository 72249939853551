import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from "firebase/firestore"; 
import { useReactToPrint } from 'react-to-print';
import { db } from '../../environment';
import { Select, Button, Typography, Row, Col, Input, DatePicker, Card, Divider, InputNumber } from 'antd';
import PrintLayout from './PrintLayout';
import moment from 'moment';
import logo from '../../yclkimya.png'
const { Option } = Select;
const { Text } = Typography;

function PackageList() {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [language, setLanguage] = useState('tr'); 
  const [containerNumbers, setContainerNumbers] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [palletCounts, setPalletCounts] = useState([]);
  const [productWeights, setProductWeights] = useState([]);
  const componentRef = useRef();

  const translations = {
    tr: {
      containerNumberInput: "Konteyner Numarası",
      invoiceDateInput: "Fatura Tarihi",
      invoiceNumberInput: "Fatura Numarası",
      print: "Yazdır",
      productWeight: "Ürün Ağırlığı",
      palletCount: "Palet Sayısı"
    },
    en: {
      containerNumberInput: "Container Number",
      invoiceDateInput: "Invoice Date",
      invoiceNumberInput: "Invoice Number",
      print: "Print",
      productWeight: "Product Weight",
      palletCount: "Pallet Count"
    },
    ar: {
      containerNumberInput: "رقم الحاوية",
      invoiceDateInput: "تاريخ الفاتورة",
      invoiceNumberInput: "رقم الفاتورة",
      print: "طباعة",
      productWeight: "وزن المنتج",
      palletCount: "عدد البليتات"
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const querySnapshot = await getDocs(collection(db, "approvedOrders"));
      const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOrders(ordersData);
    };
    fetchOrders();
  }, []);

  useEffect(() => {
    if (selectedOrder) {
      const initialContainerNumbers = selectedOrder.urunler.map(() => '');
      setContainerNumbers(initialContainerNumbers);

      const initialPalletCounts = selectedOrder.urunler.map(() => 0);
      setPalletCounts(initialPalletCounts);

      const initialWeights = selectedOrder.urunler.map(() => 0); 
      setProductWeights(initialWeights);
    }
  }, [selectedOrder]);

  const handleContainerNumberChange = (value, index) => {
    const newContainerNumbers = [...containerNumbers];
    newContainerNumbers[index] = value;
    setContainerNumbers(newContainerNumbers);
  };

  const handlePalletCountChange = (value, index) => {
    const newPalletCounts = [...palletCounts];
    newPalletCounts[index] = value;
    setPalletCounts(newPalletCounts);
  };

  const handleWeightChange = (value, index) => {
    const newWeights = [...productWeights];
    newWeights[index] = value;
    setProductWeights(newWeights);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const formattedInvoiceDate = invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : '';

  return (
    <div className='page-wrapper'>
      <div style={{ marginBottom: '20px' }}>
        <Text strong>Dil seçimi: </Text>
        <Select 
          onChange={setLanguage} 
          value={language} 
          style={{ width: 150 }}
        >
          <Option value="tr">Türkçe</Option>
          <Option value="en">English</Option>
          <Option value="ar">العربية</Option>
        </Select>
      </div>

      <Select 
        placeholder="Sipariş Seçiniz" 
        onChange={(value) => setSelectedOrder(orders.find(order => order.belgeNo === value))} 
        style={{ width: '100%', marginBottom: '20px' }}
      >
        {orders.map(order => (
          <Option key={order.belgeNo} value={order.belgeNo}>{order.belgeNo}</Option>
        ))}
      </Select>

      {selectedOrder && (
        <>
          <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '20px', padding: '20px' }}>
            <Row gutter={16} style={{ marginBottom: '20px' }}>
              <Col span={8}>
                <Text strong>{translations[language].invoiceDateInput}:</Text>
                <DatePicker 
                  value={invoiceDate ? moment(invoiceDate) : null} 
                  onChange={(date) => setInvoiceDate(date)} 
                  style={{ width: '100%' }} 
                />
              </Col>
              <Col span={8}>
                <Text strong>{translations[language].invoiceNumberInput}:</Text>
                <Input 
                  value={invoiceNumber} 
                  onChange={(e) => setInvoiceNumber(e.target.value)} 
                  style={{ width: '100%' }} 
                />
              </Col>
            </Row>

            {selectedOrder.urunler.map((urun, index) => (
              <div key={index}>
                <Divider />
                <Row gutter={16} style={{ marginBottom: '20px' }}>
                  <Col span={6}>
                    <Text strong>{urun.urunAdi}</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>{translations[language].containerNumberInput}:</Text>
                    <Input 
                      value={containerNumbers[index]} 
                      onChange={(e) => handleContainerNumberChange(e.target.value, index)}
                      style={{ width: '100%' }} 
                    />
                  </Col>
                  <Col span={6}>
                    <Text strong>{translations[language].palletCount}:</Text>
                    <InputNumber 
                      min={0}
                      value={palletCounts[index]} 
                      onChange={(value) => handlePalletCountChange(value, index)}
                      style={{ width: '100%' }} 
                    />
                  </Col>
                  <Col span={6}>
                    <Text strong>{translations[language].productWeight} (kg):</Text>
                    <InputNumber 
                      min={0}
                      value={productWeights[index]} 
                      onChange={(value) => handleWeightChange(value, index)}
                      style={{ width: '100%' }} 
                    />
                  </Col>
                </Row>
              </div>
            ))}

            <PrintLayout
              ref={componentRef}
              selectedOrder={selectedOrder}
              logoUrl={logo}
              containerNumbers={containerNumbers} // Pass the container numbers array
              palletCounts={palletCounts}
              productWeights={productWeights}
              language={language}
              invoiceNumber={invoiceNumber}
              invoiceDate={formattedInvoiceDate} // Pass the formatted date
            />
          </Card>
        </>
      )}

      <Button 
        type="primary" 
        onClick={handlePrint} 
        style={{ marginTop: '20px' }}
      >
        {translations[language].print}
      </Button>
    </div>
  );
}

export default PackageList;
