import React from "react";
import PropTypes from "prop-types";
import yclkimyaLogo from '../../../yclkimya.png'; // Adjust the path accordingly

const PrintComponent = React.forwardRef((props, ref) => {
  const {
    customerInfo,
    productInfo,
    orderInfo,
    otherInfo,
    language,
    deliveryInfo,
    salesRepInfo,
  } = props;

  // Ensure productInfo is an array
  const validProductInfo = Array.isArray(productInfo) ? productInfo : [];

  // Calculate summary data
  const calculateSummaryData = (productInfo) => {
    const currencySummary = {
      TRY: { totalAmount: 0, calculatedVAT: 0, totalIncludingTaxes: 0 },
      USD: { totalAmount: 0, calculatedVAT: 0, totalIncludingTaxes: 0 },
      EUR: { totalAmount: 0, calculatedVAT: 0, totalIncludingTaxes: 0 },
    };

    productInfo.forEach((item) => {
      const totalAmount = parseFloat(item.miktar) * parseFloat(item.birimFiyat);
      const vatAmount = totalAmount * (parseFloat(item.kdv) / 100);
      const totalIncludingTaxes = totalAmount + vatAmount;

      if (currencySummary[item.paraBirimi]) {
        currencySummary[item.paraBirimi].totalAmount += totalAmount;
        currencySummary[item.paraBirimi].calculatedVAT += vatAmount;
        currencySummary[item.paraBirimi].totalIncludingTaxes += totalIncludingTaxes;
      }
    });

    return Object.keys(currencySummary).map((currency) => ({
      currency,
      totalAmount: currencySummary[currency].totalAmount.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      calculatedVAT: currencySummary[currency].calculatedVAT.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      totalIncludingTaxes: currencySummary[currency].totalIncludingTaxes.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    }));
  };

  const summaryData = calculateSummaryData(validProductInfo);

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'TRY':
        return '₺';
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  };

  return (
    <div ref={ref} style={{ width: '205mm', height: '293mm', padding: '15px', border: '1px solid black', borderColor: 'darkred', margin: '5px', marginLeft: '10px', fontFamily: 'Helvetica, Arial, sans-serif', boxSizing: 'border-box' }}>
      <div style={{ fontSize: '13px', padding: '15px', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={yclkimyaLogo} alt="Logo" style={{ width: 150, height: 150 }} />
            <div style={{ textAlign: 'left', marginLeft: '15px' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{language === 'tr' ? 'TEMİZLİK MADDELERİ' : 'TEMİZLİK MADDELERİ'}</div>
              <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{language === 'tr' ? 'SANAYİ VE TİCARET LTD. ŞTİ.' : 'SANAYİ VE TİCARET LTD. ŞTİ.'}</div>
            </div>
          </div>
          <div style={{ textAlign: 'left', marginTop: '10px' }}>
            <div>{language === 'tr' ? 'Karadenizliler Mh. Barbaros Cd. No.66-66A-66A1' : 'Karadenizliler Mh. Barbaros Cd. No.66-66A-66A1'}</div>
            <div>{language === 'tr' ? 'Başiskele/KOCAELİ' : 'Başiskele/KOCAELİ'}</div>
            <div>{language === 'tr' ? 'Tel: +90 212 485 70 79' : 'Phone: +90 212 485 70 79'}</div>
            <div>{language === 'tr' ? 'GSM: +90 541 485 70 79' : 'Mobile: +90 541 485 70 79'}</div>
            <div>{language === 'tr' ? 'E-Mail: satis@yucelkimya.com.tr' : 'E-Mail: satis@yucelkimya.com.tr'}</div>
          </div>
        </div>
        <div style={{ textAlign: 'center', fontSize: '19px', fontWeight: 'bold', color: 'darkred', margin: '5px 0' }}>{language=="tr"?"Alış Sipariş Formu":"Purchase Order Form"}</div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
          <tr>
            <td style={{ width: '50%', verticalAlign: 'top' }}>
              <div style={{ color: 'darkred', fontWeight: 'bold', width: '60%', borderBottom: '1px solid #000', paddingBottom: '3px' }}>{language === 'tr' ? 'Gönderen Firma Bilgileri' : 'Sender Company Information'}</div>
              <div><strong>{language === 'tr' ? 'Cari Adı:' : 'Customer Name:'}</strong> {customerInfo.cariAdi}</div>
              <div><strong>{language === 'tr' ? 'Adres:' : 'Address:'}</strong> {customerInfo.adres}</div>
              <div><strong>{language === 'tr' ? 'Vergi Dairesi:' : 'Tax Office:'}</strong> {customerInfo.vergiDairesi}</div>
              <div><strong>{language === 'tr' ? 'Vergi Numarası:' : 'Tax Number:'}</strong> {customerInfo.vergiNumarasi}</div>
              <div><strong>Email:</strong> {customerInfo.email}</div>
              <div>
                <strong>{language === 'tr' ? 'İlgili Kişi Adı:' : 'Contact Person Name:'}</strong> {customerInfo.ilgiliKisiAdi} / <strong>{language === 'tr' ? 'Telefon:' : 'Phone:'}</strong> {customerInfo.telefon}
                {customerInfo.ilgiliKisiTelefon}
              </div>
            </td>
            <td style={{ width: '50%', verticalAlign: 'top' }}>
              <div style={{ color: 'darkred', fontWeight: 'bold', width: '60%', borderBottom: '1px solid #000', paddingBottom: '3px' }}>{language === 'tr' ? 'Belge Detayları' : 'Document Details'}</div>
              <div><strong>{language === 'tr' ? 'Belge No:' : 'Document No:'}</strong> {deliveryInfo.belgeNo}</div>
              <div><strong>{language === 'tr' ? 'Belge Tarihi:' : 'Document Date:'}</strong> {deliveryInfo.belgeTarihi}</div>
              <div><strong>{language === 'tr' ? 'Fatura Tipi:' : 'Invoice Type:'}</strong> {orderInfo.faturaTipi}</div>
              <div><strong>{language === 'tr' ? 'Geçerlilik Tarihi:' : 'Validity Date:'}</strong> {deliveryInfo.sevkTarihi == undefined || deliveryInfo.sevkTarihi === "Invalid Date" ? null : deliveryInfo.sevkTarihi}</div>
              <div><strong>{language === 'tr' ? 'Sevk Yöntemi:' : 'Delivery Method:'}</strong> {deliveryInfo.sevkYontemi}</div>
              <div><strong>{language === 'tr' ? 'İstenen Sevk Adresi:' : 'Desired Delivery Address:'}</strong> {deliveryInfo.istenenSevkAdresi}</div>
            </td>
          </tr>
        </table>
        <div style={{ color: 'darkred', textAlign: 'center', fontWeight: 'bold', paddingBottom: '3px', marginTop: '5px' }}>{language === 'tr' ? 'Ürün Bilgileri' : 'Product Information'}</div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'Ürün Kodu' : 'Product Code'}</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'Malzeme/ Hizmet Açıklaması' : 'Product/Service Description'}</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'Miktar' : 'Quantity'}</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'Birim' : 'Unit'}</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'Birim Fiyat' : 'Unit Price'}</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'P.B.' : 'Currency'}</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'İsk' : 'Disc'}<br />(%)</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'KDV' : 'VAT'}<br />(%)</th>
              <th style={{ border: '1px solid #000', textAlign: 'center' }}>{language === 'tr' ? 'Tutar' : 'Amount'}</th>
            </tr>
          </thead>
          <tbody>
            {validProductInfo.map((item, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #000', textAlign: 'center', padding: '5px' }}>{item.urunKodu}</td>
                <td style={{ border: '1px solid #000', textAlign: 'left', padding: '5px' }}>{item.urunAdi}</td>
                <td style={{ border: '1px solid #000', textAlign: 'right', padding: '5px' }}>{parseFloat(item.miktar).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td style={{ border: '1px solid #000', textAlign: 'center', padding: '5px' }}>{item.birim}</td>
                <td style={{ border: '1px solid #000', textAlign: 'right', padding: '5px' }}>{parseFloat(item.birimFiyat).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {getCurrencySymbol(item.paraBirimi)}</td>
                <td style={{ border: '1px solid #000', textAlign: 'center', padding: '5px' }}>{item.paraBirimi}</td>
                <td style={{ border: '1px solid #000', textAlign: 'right', padding: '5px' }}>{parseFloat(item.iskonto).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td style={{ border: '1px solid #000', textAlign: 'right', padding: '5px' }}>{parseFloat(item.kdv).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td style={{ border: '1px solid #000', textAlign: 'right', padding: '5px' }}>{parseFloat(item.tutar).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {getCurrencySymbol(item.paraBirimi)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', marginTop: '5px' }}>
        <div style={{ fontSize: '11px',  padding: '10px', width: 'calc(100% - 280px)', marginRight: '10px' }}>
  <strong>NOT:</strong>
  <div style={{ margin: '5px 0', wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
    {!otherInfo.siparisNotlari||otherInfo.siparisNotlari.split('\n').map((line, index, array) => (
      <div key={index} style={{ borderBottom: '1px dashed black', paddingBottom: '2px' }}>
        {line}
        {index !== array.length - 1 && <br />}
      </div>
    ))}
  </div>
</div>




          <table style={{ width: '260px', height: 'auto', borderCollapse: 'collapse', marginBottom: 20, fontSize: '11px' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', fontWeight: 'bold', padding: '2px' }}>{language === 'tr' ? 'P.B.' : 'Currency'}</th>
                {summaryData.map((item, index) => (
                  <td key={index} style={{ border: '1px solid #000', textAlign: 'center', whiteSpace: 'nowrap', padding: '5px' }}>{item.currency}</td>
                ))}
              </tr>
              <tr>
                <th style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', fontWeight: 'bold', padding: '2px' }}>{language === 'tr' ? 'Mal Hizmet Toplam Tutarı' : 'Total Product/Service Amount'}</th>
                {summaryData.map((item, index) => (
                  <td key={index} style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', padding: '5px' }}>{item.totalAmount.toLocaleString('tr-TR', { minimumFractionDigits: 3 })} {getCurrencySymbol(item.currency)}</td>
                ))}
              </tr>
              <tr>
                <th style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', fontWeight: 'bold', padding: '2px' }}>{language === 'tr' ? 'Hesaplanan KDV' : 'V.A.T'}</th>
                {summaryData.map((item, index) => (
                  <td key={index} style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', padding: '5px' }}>{item.calculatedVAT.toLocaleString('tr-TR', { minimumFractionDigits: 3 })} {getCurrencySymbol(item.currency)}</td>
                ))}
              </tr>
              <tr>
                <th style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', fontWeight: 'bold', padding: '2px' }}>{language === 'tr' ? 'Vergiler Dahil Toplam Tutar' : 'Total Amount Including Taxes'}</th>
                {summaryData.map((item, index) => (
                  <td key={index} style={{ border: '1px solid #000', textAlign: 'right', whiteSpace: 'nowrap', padding: '5px' }}>{item.totalIncludingTaxes.toLocaleString('tr-TR', { minimumFractionDigits: 3 })} {getCurrencySymbol(item.currency)}</td>
                ))}
              </tr>
            </thead>
          </table>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #000' }}>
          <tbody>
            <tr>
              <td style={{ padding: '2px' }}>
                <strong style={{ fontSize: 11, fontWeight: 'lighter', display: 'block', marginTop: '2px' }}>
                  <ol style={{ paddingLeft: '20px', margin: 0 }}>
                    <li>{language === 'tr' ? '1- ) 3 Gün içinde itiraz ve düzeltme cevabı gelmeyen formlar tarafınızca kabul edilmiş sayılır.' : '1- ) Forms that do not receive a response of objection and correction within 3 days are deemed to have been accepted by you.'}</li>
                    <li>{language === 'tr' ? '2- ) Sipariş formunda belirtilen ürünlerin haricinde gelen ürünlerin veya belirtilen ürünlerin formda belirtilen özelliklerin (teknik detay ve değerler, ambalaj ve paketleme farklılıkları) dışında gelen ürünleri teslim almama hakkı YÜCEL KİMYA TEM.MAD.SAN.TİC.LTD.ŞTİ.ne aittir.' : '2- ) YÜCEL KİMYA TEM.MAD.SAN.TİC.LTD.ŞTİ. reserves the right not to accept products that arrive outside of the specifications (technical details and values, packaging and packaging differences) specified in the order form or products other than those specified.'}</li>
                    {/* <li>{language === 'tr' ? '3- ) Döviz kuru ile yapılan satışlarda ilgili döviz kuru TCMB kuru üzerinden hesaplanacaktır.' : '3- ) In sales made with exchange rates, the relevant exchange rate will be calculated based on the CBRT rate.'}</li> */}
                  </ol>
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px', border: '1px solid #000', fontSize: '10px' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center', padding: '3px', fontWeight: 'bold', width: '7%' }}>{language === 'tr' ? 'Banka' : 'Bank'}</th>
              <th style={{ textAlign: 'center', padding: '3px', fontWeight: 'bold', width: '7%' }}>{language === 'tr' ? 'TL Hesap' : 'TL Account'}</th>
              <th style={{ textAlign: 'center', padding: '3px', fontWeight: 'bold', width: '7%' }}>{language === 'tr' ? 'USD Hesap' : 'USD Account'}</th>
              <th style={{ textAlign: 'center', padding: '3px', fontWeight: 'bold', width: '7%' }}>{language === 'tr' ? 'EUR Hesap' : 'EUR Account'}</th>
              <th style={{ textAlign: 'center', padding: '3px', fontWeight: 'bold', width: '7%' }}>{language === 'tr' ? 'Swift Kodu' : 'Swift Code'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                <div style={{ borderBottom: '1px solid #000', width: '100%', marginLeft: '5px' }}>QNB Finansbank</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ borderBottom: '1px solid #000', width: '102%', margin: '0 auto' }}>TR55 0011 1000 0000 0049 4457 53</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ borderBottom: '1px solid #000', width: '102%', margin: '0 auto' }}>TR32 0011 1000 0000 0014 2426 36</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ borderBottom: '1px solid #000', width: '110%', margin: '0 auto' }}>TR86 0011 1000 0000 0014 2426 34</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                <div style={{ borderBottom: '1px solid #000', width: '75%', marginLeft: '15px' }}>FNNBTRIS</div>
              </td>
            </tr>
            {/* <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                <div style={{ borderBottom: '1px solid #000', width: '100%', marginLeft: '5px' }}>{language === 'tr' ? 'Emlak Katılım' : 'Emlak Katılım'}</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ borderBottom: '1px solid #000', width: '102%', margin: '0 auto' }}>TR30 0021 1000 0007 5157 7000 01</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ borderBottom: '1px solid #000', width: '102%', margin: '0 auto' }}>TR46 0021 1000 0007 5157 7001 01</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ borderBottom: '1px solid #000', width: '110%', margin: '0 auto' }}>TR19 0021 1000 0007 5157 7001 02</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                <div style={{ borderBottom: '1px solid #000', width: '75%', marginLeft: '15px' }}>EMLATRISXXX</div>
              </td>
            </tr> */}
            <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                <div style={{ width: '100%', marginLeft: '5px' }}>{language === 'tr' ? 'Kuveyttürk' : 'Kuveyt Türk'}</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ width: '102%', margin: '0 auto' }}>TR14 0021 1000 0007 5157 7002 03</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ width: '102%', margin: '0 auto' }}>TR42 0021 1000 0007 5157 7002 04</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ width: '110%', margin: '0 auto' }}>TR64 0021 1000 0007 5157 7002 05</div>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                <div style={{ width: '75%', marginLeft: '15px' }}>KTEFTRISXXX</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '15px', bottom: '20px' }}>
        <tr>
          <br />
          <td style={{ width: '33%', verticalAlign: 'top' }}>
            <div style={{ color: 'darkred', textAlign: 'left', fontWeight: 'bold', paddingBottom: '3px' }}>{language === 'tr' ? 'Hazırlayan' : 'Prepared By'}</div>
            <div>
              <strong><i className="fas fa-user"></i> {language === 'tr' ? 'Adı:' : 'Name:'}</strong> {salesRepInfo.formuHazirlayanAdi}
            </div>
            <div>
              <strong><i className="fas fa-phone"></i> {language === 'tr' ? 'Telefon:' : 'Phone:'}</strong> {salesRepInfo.formuHazirlayanTelefon}
            </div>
            <div style={{ minWidth: 250 }} >
              <strong><i className="fas fa-envelope"></i> Email:</strong> {salesRepInfo.formuHazirlayanEmail}
            </div>
          </td>
          <td style={{ width: '33%', verticalAlign: 'top', textAlign: 'center' }}>
            <div style={{ color: 'darkred', fontWeight: 'bold', paddingBottom: '3px' }}>{language === 'tr' ? 'Yetkili Onayı/İmza' : 'Authorized Approval/Signature'}</div>
            <div style={{ marginTop: '60px' }}></div> {/* Placeholder for firm approval/signature */}
          </td>
          <td style={{ width: '33%', verticalAlign: 'top', textAlign: 'center' }}>
            <div style={{ color: 'darkred', fontWeight: 'bold', paddingBottom: '3px' }}>{language === 'tr' ? 'Alıcı Firma Onayı' : 'Buyer Company Approval'} <br />{language === 'tr' ? 'Kaşe/İmza' : 'Stamp/Signature'}</div>
            <div style={{ marginTop: '60px' }}></div> {/* Placeholder for stamp/signature */}
          </td>
        </tr>
      </table>
    </div>
  );
});

PrintComponent.displayName = 'PrintComponent';

PrintComponent.propTypes = {
  customerInfo: PropTypes.shape({
    cariKodu: PropTypes.string.isRequired,
    cariAdi: PropTypes.string.isRequired,
    adres: PropTypes.string.isRequired,
    vergiDairesi: PropTypes.string.isRequired,
    vergiNumarasi: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    telefon: PropTypes.string.isRequired,
    ilgiliKisiAdi: PropTypes.string.isRequired,
    ilgiliKisiTelefon: PropTypes.string.isRequired,
    ilgiliKisiEmail: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  productInfo: PropTypes.arrayOf(
    PropTypes.shape({
      urunAdi: PropTypes.string.isRequired,
      urunKodu: PropTypes.string.isRequired,
      miktar: PropTypes.number.isRequired,
      birim: PropTypes.string.isRequired,
      birimFiyat: PropTypes.number.isRequired,
      paraBirimi: PropTypes.string.isRequired,
      iskonto: PropTypes.number.isRequired,
      kdv: PropTypes.number.isRequired,
      tutar: PropTypes.number.isRequired,
    })
  ).isRequired,
  orderInfo: PropTypes.shape({
    siparisTarihi: PropTypes.string.isRequired,
    teslimatTarihi: PropTypes.string.isRequired,
    odemeSekli: PropTypes.string.isRequired,
    faturaTipi: PropTypes.string.isRequired,
    faturaAdresi: PropTypes.string.isRequired,
    faturaAciklamalari: PropTypes.string.isRequired,
  }).isRequired,
  deliveryInfo: PropTypes.shape({
    belgeNo: PropTypes.string.isRequired,
    belgeTarihi: PropTypes.string.isRequired,
    faturaTipi: PropTypes.string.isRequired,
    sevkTarihi: PropTypes.string.isRequired,
    sevkYontemi: PropTypes.string.isRequired,
    istenenSevkAdresi: PropTypes.string.isRequired,
  }).isRequired,
  otherInfo: PropTypes.shape({
    faturaTarihi: PropTypes.string.isRequired,
    musteriSiparisNumarasi: PropTypes.string.isRequired,
    ozelTalimatlar: PropTypes.string.isRequired,
    siparisNotlari: PropTypes.string.isRequired,
  }).isRequired,
  salesRepInfo: PropTypes.shape({
    formuHazirlayanAdi: PropTypes.string.isRequired,
    formuHazirlayanTelefon: PropTypes.string.isRequired,
    formuHazirlayanEmail: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

export default PrintComponent;
