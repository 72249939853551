import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Form'u import edelim
import { doc, updateDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../../environment';

function ChangeLogs() {
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');  
    if (userId) {
      const userRef = doc(db, "users", userId);
      const fetchUserModalPreference = async () => {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userShowModal = docSnap.data().showModal;
          setShowModal(userShowModal);            
          setChecked(!userShowModal);            
        }
      };
      fetchUserModalPreference();
    }
    const fetchLogs = async () => {
        const querySnapshot = await getDocs(collection(db, "logs"));
        const logsData = querySnapshot.docs.map(doc => ({
          ...doc.data()  
        }));
        setLogs(logsData);
    };
  
    fetchLogs();
  }, []);  

  const handleHide = async () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      setShowModal(false);
      const newShowModalValue = !checked;  

      const userRef = doc(db, "users", userId);
      // Firestore'da showModal değerini güncelle
      await updateDoc(userRef, { showModal: newShowModalValue }).then(() => {
        console.log('User preference updated successfully');
      }).catch((error) => {
        console.error('Error updating user preference:', error);
      });
    }
  };

  return (
    <Modal backdrop={true} centered show={showModal} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Güncelleme Notları</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin:25}}>
        {logs.map((log, index) => (
          <ul key={index}>
            {Object.values(log).map((value, idx) => (
              <li style={{color:'black'}} key={idx}>{idx+1}-{value}</li>  
            ))}
          </ul>
        ))}
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleHide}>Kapat</Button>
        <div className="d-flex justify-content-end align-items-center">
          <Form.Check 
            type="checkbox" 
            checked={checked} 
            onChange={() => setChecked(!checked)}
            label="Bir daha gösterme"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeLogs;
