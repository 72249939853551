import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ImageWithBasePath from '../../img/imagewithbasebath';
import { doc, getDoc,getDocs,collection, setDoc } from 'firebase/firestore';
import { db } from '../../../environment';
import { propTypes } from 'react-bootstrap/esm/Image';
import Swal from 'sweetalert2';
const EditUser = ({uid,onUserUpdated}) => {
    
    const [userData, setUserData] = useState({
        username: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        description: '',
        img: '',
        emailVerified: false, // Add emailVerified to state
        showModal:true
    });
    const [roles, setRoles] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const fetchRoles = async () => {
            const rolesSnapshot = await getDocs(collection(db, "roles"));
            const rolesData = rolesSnapshot.docs.map(doc => ({
                value: doc.id,
                label: doc.data().name
            }));
            setRoles(rolesData);
        };
        fetchRoles();
    }, []);

    useEffect(() => {
        if (uid) {
            const userRef = doc(db, "users", uid);
            getDoc(userRef).then((doc) => {
                if (doc.exists()) {
                    const user = doc.data();
                    setUserData({
                        ...userData,
                        username: user.username || '',
                        phone: user.phone || '',
                        email: user.email || '',
                        role: roles.find(r => r.value === user.role.value) || roles[0],
                        description: user.description || '',
                        img: user.img || '',
                        emailVerified: user.emailVerified || false, // Update emailVerified in state
                        showModal:user.showModal||true
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Hata...',
                        text: 'Kullanıcı bulunamadı!',
                    });
                }
            }).catch(error => {
                console.error("Kullanıcı verisi getirilirken hata", error);
            });
        }
    }, [uid, roles]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleRoleChange = (selectedOption) => {
        setUserData(prevState => ({
            ...prevState,
            role: selectedOption
        }));
    };
    
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        console.log("xszkgfphj",checked)

        setUserData(prevState => ({
            ...prevState,
            emailVerified: checked
        }));
    };
    const handleShowModalChange = (event) => {
        const { checked } = event.target;
        console.log("xszkgfphj",checked)

        setUserData(prevState => ({
            ...prevState,
            showModal: checked
        }));
    };
    const handleTogglePassword = () => setShowPassword(!showPassword);
    const handleToggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log('File loaded:', reader.result); // Check if the file is successfully loaded
                setUserData(prevState => ({
                    ...prevState,
                    img: reader.result
                }));
            };
            reader.onerror = (error) => {
                Swal.fire({
                    icon: 'error',
                    title: "Hata",
                    text: error,
                }); // Log any errors while reading the file
            };
            reader.readAsDataURL(file);
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault(); // Formun default submit işlemini engelle

        if (!userData.username || !userData.email || !userData.role||!userData.password) {
            Swal.fire({
                icon: 'error',
                title: 'Hata!',
                text: 'Kullanıdı adı, E-posta, ve Rol gerekli alanlar!',
            });
            return;
        }
        if(showPassword!=showConfirmPassword){
            Swal.fire({
                icon: 'error',
                title: 'Hata!',
                text: 'Şifreler aynı olmalı!',
            });
            return
        }

        try {
            const userRef = doc(db, "users", uid);
            await setDoc(userRef, userData, { merge: true }); // Kullanıcı verilerini güncelle

            Swal.fire(
                'Başarılı!',
                'Kullanıcı başarıyla güncellendi!',
                'success'
            ).then(result=>{
                if (result.isConfirmed) {
                    onUserUpdated(); // Trigger the refresh
                  }
            })
           
            
        } catch (error) {
            console.error("Error updating user:", error);
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Kullanıcı güncelleme işlemi başarısız!.',
            });
        }
    };
    return (
        <div>
            {/* Edit User */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Kullanıcı Güncelle</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="new-employee-field">
                                                    <span>Profil Fotoğrafı</span>
                                                    <div className="profile-pic-upload edit-pic">
                                                        <div className="profile-pic">
                                                            <span>
                                                            {userData.img ? (
                                                                    <img src={userData.img} className="user-editer" alt="User" />
                                                                ) : (
                                                                    <ImageWithBasePath src="assets/img/customer/no-imagecustomer.png" className="user-editer" alt="Default" />
                                                                )}
                                                            </span>
                                                            <div className="close-img">
                                                                <i data-feather="x" className="info-img" />
                                                            </div>
                                                        </div>
                                                        <div className="input-blocks mb-0">
                                                            <div className="image-upload mb-0">
                                                                <input onChange={handleImageChange} type="file" />
                                                                <div className="image-uploads">
                                                                    <h4>Resmi Değiştir</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Kullanıcı Adı</label>
                                                    <input value={userData.username} onChange={handleInputChange} name="username" type="text"  />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Telefon No</label>
                                                    <input value={userData.phone}  onChange={handleInputChange} name="phone" type="text"  />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>E-posta</label>
                                                    <input value={userData.email} onChange={handleInputChange} name='email' type="email"  />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Rol</label>
                                                    <Select
    value={userData.role}
    onChange={handleRoleChange}
    options={roles}  // 'status' yerine 'roles' kullanıldı
    placeholder="Rol seçiniz"
   
/>


                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Şifre</label>
                                                    <div className="pass-group">
                                                        <input
                                                        onChange={handleInputChange}
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="pass-input"
                                                            name='password'
                                                        />
                                                        <span
                                                            className={`fas toggle-password ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                            onClick={handleTogglePassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Şifreyi onayla</label>
                                                    <div className="pass-group">
                                                    <input
                                                    name='password'
                                                    onChange={handleInputChange}
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        className="pass-input"
                                                    />
                                                    <span
                                                        className={`fas toggle-password ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                        onClick={handleToggleConfirmPassword}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-blocks m-0">
                                                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                        <p>Doğrulandı:</p>
                                                        <input
                                                        checked={userData.emailVerified}
                                                            onChange={handleCheckboxChange}
                                                            type="checkbox" id="user2123123" className="check"
                                                        />
                                                        <label htmlFor="user2123123" className="checktoggle"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-blocks m-0">
                                                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                        <p>Güncellemeleri Göster:</p>
                                                        <input
                                                        checked={userData.showModal}
                                                            onChange={handleShowModalChange}
                                                            type="checkbox" id="user21231233" className="check"
                                                        />
                                                        <label htmlFor="user21231233" className="checktoggle"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-0 input-blocks">
                                                    <label className="form-label">Açıklama</label>
                                                    <textarea
                                                    name='description'
                                                        className="form-control mb-1"
                                                    />
                                                    <p>Maksimum 600 karakter</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                İptal
                                            </button>
                                            <button  type="submit" className="btn btn-submit">
    Değişiklikleri kaydet
</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit User */}
        </div>
    )
}

export default EditUser
EditUser.propTypes = {
    uid: propTypes.string,
    onUserUpdated:propTypes.string,
  };