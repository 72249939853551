import React from "react";
import PropTypes from "prop-types";
import logo from '../../../../assets/yclkimya.png';
import "./PDFComponent.css"; // Custom styles for the PDF

const PDFComponent = React.forwardRef((props, ref) => {
  const { formData, selectedStocks } = props;

  return (
    <div ref={ref} className="pdf-container">
      {/* PDF Header */}
      <div className="pdf-header">
        <div className="header-left">
          <img src={logo} alt="Company Logo" className="pdf-logo" />
        </div>
        <div className="header-right">
          <h2>Depolar Arası Sevk Fişi</h2>
          <p><strong>Belge No:</strong> {formData.evrakNo}</p>
          <p><strong>Tarih:</strong> {formData.asilTeslimTarihi?.format("DD.MM.YYYY HH:mm:ss")}</p>
        </div>
      </div>

      {/* PDF Details */}
      <div className="pdf-details">
        <div className="detail-row">
          <p><strong>Çıkış Deposu:</strong> {formData.cikisDeposu}</p>
          <p><strong>Varış Deposu:</strong> {formData.varisDeposu}</p>
        </div>
        <div className="detail-row">
          <p><strong>Sevk Adresi:</strong> {formData.sevkAdres}</p>
          <p><strong>Taşıyan Firma:</strong> {formData.tasiyanFirma}</p>
        </div>
      </div>

      {/* Stock List */}
      <h3 className="section-title">Stok Listesi</h3>
      <table className="pdf-table">
        <thead>
          <tr>
            <th>Stok Adı</th>
            <th>Miktar</th>
            <th>Birim</th>
          </tr>
        </thead>
        <tbody>
          {selectedStocks.map((stock, index) => (
            <tr key={index}>
              <td>{stock.stokAdi}</td>
              <td>{stock.miktar}</td>
              <td>{stock.unit}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Signature Section */}
      <div className="signature-section">
        <div className="signature-block">
          <p>Belgeyi Teslim Eden:</p>
          <p>Adı/Soyadı: ________________________</p>
          <p>İmza: _____________________________</p>
        </div>
        <div className="signature-block">
          <p>Belgeyi Teslim Alan:</p>
          <p>Adı/Soyadı: ________________________</p>
          <p>İmza: _____________________________</p>
        </div>
      </div>
    </div>
  );
});

PDFComponent.propTypes = {
  formData: PropTypes.shape({
    cikisDeposu: PropTypes.string,
    varisDeposu: PropTypes.string,
    evrakNo: PropTypes.string,
    sevkAdres: PropTypes.string,
    asilTeslimTarihi: PropTypes.object,  // Moment.js date
    tasiyanFirma: PropTypes.string,
  }).isRequired,
  selectedStocks: PropTypes.arrayOf(
    PropTypes.shape({
      stokAdi: PropTypes.string.isRequired,
      miktar: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
    })
  ).isRequired,
};

// Assign the display name
PDFComponent.displayName = "PDFComponent";

export default PDFComponent;
